import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    {
        ...schemaGenerators.formCard(),
        elements: [
            { key: "label1", type: "h2", label: "onboarding.completedFirstStep" },
            {
                key: "label2",
                type: "label",
                label: "onboarding.informationOnCompanyProfile",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
