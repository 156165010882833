import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, lastSavedFieldState, } from "~/store/selectors/formData.selectors";
export function CustomsAccountStart() {
    var _a;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const lastSavedField = useSelector(lastSavedFieldState);
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "customsAccountNumber",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (e) => {
        dispatch(patchFormField({
            fieldName: e.target.name,
            fieldValue: e.target.value,
            id,
            flowId,
            pageSlug: "customsAccountNumber",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.your_customs_account"), subtitle: t("onboarding.zazAccount.customsAccountNumber"), description: t("onboarding.zazAccount.customsAccountNumberDescription") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: [t("onboarding.zazAccount.openAccountDetails"), " "] }), _jsx(RadioGroup, { value: (_a = formData === null || formData === void 0 ? void 0 : formData.enterZazNumberOrSecurityDeposit) === null || _a === void 0 ? void 0 : _a.fieldValue, children: _jsxs(Stack, { direction: "column", children: [_jsx(Radio, { value: "zaz-account", name: "enterZazNumberOrSecurityDeposit", onChange: updateField, children: t("onboarding.actions.yes") }), _jsx(Radio, { value: "security-deposit", onChange: updateField, name: "enterZazNumberOrSecurityDeposit", children: t("onboarding.actions.no") })] }) }), lastSavedField === "enterZazNumberOrSecurityDeposit" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" }))] }), _jsx(FormFooter, { pageSlug: "customsAccountNumber", processSlug: "administrativeProcesses", isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
