import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, Skeleton, useTheme } from "@chakra-ui/react";
import { MSD_PARTNER_ID } from "@crossborderx/schemas-package";
import { useGetUserByEmailQuery } from "~/pages/users/redux/users.api";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
export const TableAvatar = (props) => {
    const { email, companyName, companyId, tooltipKey, ...rest } = props;
    const { data: user, isFetching: isFetchingUser } = useGetUserByEmailQuery(email, {
        skip: !email,
    });
    const { textStyles } = useTheme();
    const userName = user ? [user.firstName, user.lastName].join(" ") : email;
    const label = `${userName} ${companyName && `(${companyName})`}`;
    return (_jsx(TooltipWrapper, { hasTooltip: (!!companyName || !!email) && !isFetchingUser, label: label, tooltipKey: tooltipKey, children: isFetchingUser ? (_jsx(Skeleton, { w: "9", h: "9", m: 0.5, rounded: "full" })) : (_jsx(Avatar, { name: userName, variant: "table", sx: {
                ".chakra-avatar__initials": {
                    ...textStyles.labelUppercase,
                    color: "brand.white",
                },
            }, backgroundColor: companyId !== MSD_PARTNER_ID ? "brand.sky" : "brand.mare", m: 0.5, ...rest })) }));
};
