import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.zazAccount.customsAccountNumber",
        description: "onboarding.zazAccount.customsAccountNumberDescription",
    }),
    {
        ...schemaGenerators.formCard(),
        groupType: "radio",
        forKey: "enter-zaz-number-or-security-deposity",
        defaultValue: "zaz-account",
        elements: [
            {
                key: "label2",
                type: "label",
                label: "onboarding.zazAccount.openAccountDetails",
            },
            {
                key: "zaz-account",
                type: "radio",
                value: "zaz-account",
                forKey: "enter-zaz-number-or-security-deposity",
                label: "onboarding.actions.yes",
            },
            {
                key: "security-deposit",
                type: "radio",
                value: "security-deposit",
                forKey: "enter-zaz-number-or-security-deposity",
                label: "onboarding.actions.no",
            },
        ],
    },
    {
        ...schemaGenerators.formFooter(false, "ENTER_ZAZ_ACCOUNT_NUMBER"),
        rule: {
            effect: "SHOW",
            targetKey: "enter-zaz-number-or-security-deposity",
            targetValue: "zaz-account",
        },
    },
    {
        ...schemaGenerators.formFooter(false, "ENTER_SECURITY_DEPOSIT"),
        rule: {
            effect: "SHOW",
            targetKey: "enter-zaz-number-or-security-deposity",
            targetValue: "security-deposit",
        },
    },
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
