import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Text, } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { getCountryDropdownOptions, translatedCountryName, } from "~/helpers/country-codes";
import { getShippingCountriesOptions } from "~/utils/countries";
import { FlagIcon } from "../FlagIcon/FlagIcon";
export const CountrySingleSelect = ({ allSelectedCountries, selectedCountry, setSelectedCountry, allowAll, fieldLabel, isDisabled = false, }) => {
    const { t } = useTranslation();
    const countryName = selectedCountry
        ? translatedCountryName(selectedCountry)
        : "";
    const options = useMemo(() => {
        return allowAll
            ? getCountryDropdownOptions()
            : getShippingCountriesOptions({
                selectedCountries: isDisabled
                    ? []
                    : allSelectedCountries.filter((c) => c !== selectedCountry),
                addedCountries: [],
                usedCountries: [],
                shipDirection: ShipDirection.FROM,
            });
    }, [selectedCountry]);
    const getMenuOptions = useMemo(() => options.map(({ value, label }) => {
        return (_jsx(MenuItem, { _hover: { bg: "sky.10" }, fontSize: "lg", w: "full", p: "3", cursor: "pointer", onClick: () => setSelectedCountry ? setSelectedCountry(value) : undefined, children: label }));
    }), [selectedCountry]);
    const optionItems = getMenuOptions;
    return (_jsxs(Box, { w: "full", children: [_jsx(Text, { textStyle: "labelUppercase", mb: "2", children: fieldLabel ||
                    t("clients.detail.services.tariffingModal.countryTitle") }), _jsxs(Menu, { matchWidth: true, children: [_jsx(MenuButton, { role: "group", disabled: isDisabled, cursor: isDisabled ? "default" : "pointer", w: "full", children: _jsxs(InputGroup, { children: [_jsx(Input, { disabled: isDisabled, tabIndex: -1, color: "brand.black", _disabled: { color: "brand.black", backgroundColor: "grey.5" }, _groupFocus: { borderColor: "brand.mare" }, type: "search", size: "lg", placeholder: t("clients.detail.services.tariffingModal.countryPlaceholder"), pl: "12", value: countryName }), _jsx(InputLeftElement, { h: "full", children: selectedCountry ? (_jsx(FlagIcon, { countryCode: selectedCountry, style: { display: "inline-block" }, boxSize: 6 })) : (_jsx(Icon, { name: "globe", w: "18px", h: "18px", color: "grey.40" })) }), !isDisabled && (_jsx(InputRightElement, { h: "full", children: _jsx(Icon, { name: "chevron-down", w: "12px", h: "6px", color: "grey.40" }) }))] }) }), setSelectedCountry && (_jsxs(MenuList, { py: "2", maxH: 300, overflow: "auto", children: [_jsx(MenuItem, { _hover: { bg: "sky.10" }, fontSize: "lg", textColor: "grey.60", w: "full", p: "3", cursor: "pointer", onClick: () => setSelectedCountry(""), children: t("clients.detail.services.tariffingModal.countryPlaceholder") }), optionItems] }))] })] }));
};
