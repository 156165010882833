import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Select from "~/components/Select/Select";
import { getCountryCodeLabelKeyPairs } from "~/helpers/country-codes";
function CountrySelect(props) {
    const { t } = useTranslation();
    const { error, label, register = () => ({ name: "countryCode" }), onChange, defaultValue, emptyOption = false, isDisabled = false, } = props;
    const DEFAULT_VALUE = !emptyOption ? "DE" : ""; // Germany code
    const options = emptyOption
        ? {
            "": t("onboarding.forms.details.countryEmptyOption"),
            ...getCountryCodeLabelKeyPairs(),
        }
        : getCountryCodeLabelKeyPairs();
    return (_jsx(Select, { w: { md: "full", sm: "70%" }, placeholder: label, error: error, label: t("onboarding.forms.details.country"), defaultValue: defaultValue || DEFAULT_VALUE, ...register(), onChange: onChange, options: options, isDisabled: isDisabled }));
}
export default CountrySelect;
