import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "./auth.api";
const initialState = {
    token: null,
    graphToken: null,
    legacyToken: null,
    tokenExpiry: null,
    permissions: null,
    user: null,
};
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.token = action.payload.token;
            state.graphToken = action.payload.graphToken;
            state.tokenExpiry = action.payload.tokenExpiry;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        resetAuth: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
            state.token = null;
            state.user = null;
            state.permissions = null;
        });
        builder.addMatcher(authApi.endpoints.getMe.matchFulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addMatcher(authApi.endpoints.getLegacyToken.matchFulfilled, (state, action) => {
            state.legacyToken = action.payload.token;
        });
        builder.addMatcher(authApi.endpoints.getMyPermissions.matchFulfilled, (state, action) => {
            state.permissions = action.payload;
        });
    },
});
// export const {} = authSlice.actions;
export const authReducer = authSlice.reducer;
export const { setAuth, resetAuth, setUser } = authSlice.actions;
export const authSelector = (state) => {
    return state[authSlice.name];
};
