import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { chakraComponents, } from "chakra-react-select";
import { getCodeDropdownOptions } from "~/helpers/country-codes";
import { ControlledSelect } from "../ControlledSelect/ControlledSelect";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { FlagIcon } from "../FlagIcon/FlagIcon";
const Placeholder = (props) => (_jsx(chakraComponents.Placeholder, { ...props, children: _jsx(FlagIcon, { countryCode: "xx", boxSize: 6, style: { margin: 0, padding: 0 } }) }));
const SingleValue = ({ data, ...props }) => (_jsx(chakraComponents.SingleValue, { data: data, ...props, children: _jsx(FlagIcon, { countryCode: data.iso2, boxSize: 6, style: { margin: 0, padding: 0 } }) }));
const Option = ({ children, ...props }) => (_jsxs(chakraComponents.Option, { ...props, children: [_jsx(FlagIcon, { countryCode: props.data.iso2, boxSize: 6, style: { marginRight: "8px" } }), children] }));
export const PhoneInput = ({ controlProps, phoneNumber, errors, }) => {
    var _a, _b;
    const items = getCodeDropdownOptions();
    const components = {
        Placeholder,
        SingleValue,
        Option,
    };
    return (_jsxs(_Fragment, { children: [_jsxs(InputGroup, { maxW: "full", children: [_jsx(InputLeftAddon, { p: "0", m: "0", borderWidth: "1px", borderColor: "var(--chakra-colors-grey-20)", borderRightColor: "transparent", _focusWithin: {
                            borderColor: "var(--chakra-colors-brand-black)",
                        }, children: _jsx(ControlledSelect, { controlProps: controlProps, options: items, components: components, variant: "unstyled", chakraStyles: {
                                valueContainer: (provided) => ({
                                    ...provided,
                                    m: 0,
                                    p: 0,
                                }),
                                inputContainer: (provided) => ({
                                    ...provided,
                                    marginLeft: "3",
                                    minW: "24px",
                                    p: 0,
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    w: "max-content",
                                    minW: "100%",
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    marginLeft: "3",
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    marginLeft: "3",
                                }),
                            } }) }), _jsx(Input, { ...phoneNumber, type: "tel" })] }), (errors.phoneCode || errors.phoneNumber) && (_jsx(ErrorMessage, { text: (_b = (_a = (errors.phoneCode || errors.phoneNumber)) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : "" }))] }));
};
