import { jsx as _jsx } from "react/jsx-runtime";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { useLocation, useNavigate } from "@tanstack/react-location";
import React, { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useOnboardingDataContext } from "./OnboardingDataContextProvider";
const FormContext = React.createContext({});
export const useFormContext = () => useContext(FormContext);
export function FormContextProvider({ children, formSchema, formStepKey, onboardingService, }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { onboardingData, setOnboardingData } = useOnboardingDataContext();
    const { register, handleSubmit, formState, getValues, setValue, watch, control, reset, } = useForm({
        mode: "onChange",
        resolver: ajvResolver(formSchema),
        shouldUnregister: true,
    });
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if ((name === null || name === void 0 ? void 0 : name.includes("billing_address")) && type === "change") {
                const index = name.split("[")[1].split("]")[0];
                const lastValue = value.company_address[index]
                    .billing_address;
                const companyAddress = value.company_address.map((address, i) => {
                    if (i !== parseInt(index, 10))
                        return {
                            ...address,
                            billing_address: ["0"],
                        };
                    return {
                        ...address,
                        billing_address: lastValue,
                    };
                });
                reset({ ...value, company_address: companyAddress });
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const previousValues = useMemo(() => {
        const formValues = formStepKey && (onboardingData === null || onboardingData === void 0 ? void 0 : onboardingData[formStepKey]);
        if (formValues)
            reset(formValues);
        return formValues || {};
    }, [onboardingData, reset, formStepKey]);
    const handleTransition = (transition) => {
        if (formSchema) {
            if (transition === "PREVIOUS") {
                location.history.back();
            }
            if (transition === "SAVE") {
                const formValues = getValues();
                setOnboardingData({ [formStepKey]: formValues });
                navigate({ to: "/onboarding" });
            }
            handleSubmit((value) => {
                onboardingService.send({
                    type: transition,
                    value,
                });
                setOnboardingData({ [formStepKey]: value });
            }, (...args) => {
                // eslint-disable-next-line
                console.error("React hook form validation error:", args, getValues());
            })();
        }
        else {
            onboardingService.send({
                type: transition,
                value: {},
            });
        }
    };
    return ((onboardingData && (_jsx(FormContext.Provider, { value: {
            register,
            handleSubmit,
            formState,
            getValues,
            setValue,
            handleTransition,
            watch,
            control,
            previousValues,
        }, children: children }))) ||
        null);
}
export default FormContextProvider;
