import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as CSV } from "~/assets/icons/icon_docu_CSV.svg";
import { ReactComponent as DOC } from "~/assets/icons/icon_docu_DOC.svg";
import { ReactComponent as PDF } from "~/assets/icons/icon_docu_PDF.svg";
import { ReactComponent as PNG } from "~/assets/icons/icon_docu_PNG.svg";
function FileIcon({ file }) {
    if (file.file.type.includes("image"))
        return _jsx(PNG, {});
    if (file.file.type.includes("pdf"))
        return _jsx(PDF, {});
    if (file.file.type.includes("csv"))
        return _jsx(CSV, {});
    if (file.file.type.includes("doc"))
        return _jsx(DOC, {});
    return _jsx(DOC, {});
}
export default FileIcon;
