import { jsx as _jsx } from "react/jsx-runtime";
import { useCheckboxGroup, useRadioGroup, } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useController, } from "react-hook-form";
import { useFormContext } from "../FormContextProvider";
import { LayoutComponent } from "./layout";
const FormGroupContext = React.createContext({
    getFieldProps: () => ({}),
});
export const useFormGroupContext = () => useContext(FormGroupContext);
const useGroupProps = (groupType, forKey, field, defaultValue) => {
    if (groupType === "radio") {
        const { getRootProps, getRadioProps } = useRadioGroup({
            name: forKey,
            onChange: field.onChange,
            value: defaultValue || field.value,
        });
        return { getRootProps, getFieldProps: getRadioProps };
    }
    const { getCheckboxProps } = useCheckboxGroup({
        onChange: field.onChange,
        value: field.value,
    });
    return { getRootProps: () => ({}), getFieldProps: getCheckboxProps };
};
export function GroupLayoutComponent({ children, ...layoutNode }) {
    const { groupType, forKey, defaultValue } = layoutNode;
    if (!forKey)
        throw new Error(`Missing 'forKey' in GroupLayout`);
    const { control } = useFormContext();
    const { field } = useController({
        control,
        name: forKey,
        defaultValue,
    });
    const { getRootProps, getFieldProps } = useGroupProps(groupType, forKey, field);
    return (_jsx(FormGroupContext.Provider, { value: { getFieldProps }, children: _jsx(LayoutComponent, { wrapperProps: getRootProps(), ...layoutNode, children: children }) }));
}
