import { jsx as _jsx } from "react/jsx-runtime";
import ProcessOverviewItem from "~/components/ProcessOverviewItem/ProcessOverviewItem";
import i18n from "~/i18n";
export default function ProcessOverviewItemEmpty() {
    const overview = {
        when: "",
        who: "",
        what: "",
        day: "1",
        icon: "process-overview-empty",
        title: i18n.t("processOverview.comingSoon"),
    };
    return _jsx(ProcessOverviewItem, { ...overview });
}
