export var CustomsClassificationStatus;
(function (CustomsClassificationStatus) {
    CustomsClassificationStatus["PENDING"] = "PENDING";
    CustomsClassificationStatus["ON_TIME"] = "ON_TIME";
    CustomsClassificationStatus["NEAR_DEADLINE"] = "NEAR_DEADLINE";
    CustomsClassificationStatus["CRITICAL"] = "CRITICAL";
    CustomsClassificationStatus["COMPLETED"] = "COMPLETED";
    CustomsClassificationStatus["OVERDUE"] = "OVERDUE";
})(CustomsClassificationStatus || (CustomsClassificationStatus = {}));
export var CustomsClassificationStatusTranslationKey;
(function (CustomsClassificationStatusTranslationKey) {
    CustomsClassificationStatusTranslationKey["PENDING"] = "pending";
    CustomsClassificationStatusTranslationKey["ON_TIME"] = "onTime";
    CustomsClassificationStatusTranslationKey["NEAR_DEADLINE"] = "nearDeadline";
    CustomsClassificationStatusTranslationKey["CRITICAL"] = "critical";
    CustomsClassificationStatusTranslationKey["COMPLETED"] = "completed";
    CustomsClassificationStatusTranslationKey["OVERDUE"] = "overdue";
})(CustomsClassificationStatusTranslationKey || (CustomsClassificationStatusTranslationKey = {}));
export var CustomsClassificationStatusColor;
(function (CustomsClassificationStatusColor) {
    CustomsClassificationStatusColor["PENDING"] = "grey.40";
    CustomsClassificationStatusColor["ON_TIME"] = "success.500";
    CustomsClassificationStatusColor["NEAR_DEADLINE"] = "warning.100";
    CustomsClassificationStatusColor["CRITICAL"] = "danger.500";
    CustomsClassificationStatusColor["COMPLETED"] = "success.500";
    CustomsClassificationStatusColor["OVERDUE"] = "danger.500";
})(CustomsClassificationStatusColor || (CustomsClassificationStatusColor = {}));
export var CustomsClassificationStatusTooltipTranslationKey;
(function (CustomsClassificationStatusTooltipTranslationKey) {
    CustomsClassificationStatusTooltipTranslationKey["PENDING"] = "";
    CustomsClassificationStatusTooltipTranslationKey["ON_TIME"] = "hoursRemaining";
    CustomsClassificationStatusTooltipTranslationKey["NEAR_DEADLINE"] = "hoursRemaining";
    CustomsClassificationStatusTooltipTranslationKey["CRITICAL"] = "lessThanRemaining";
    CustomsClassificationStatusTooltipTranslationKey["OVERDUE"] = "overdue";
    CustomsClassificationStatusTooltipTranslationKey["COMPLETED"] = "completed";
})(CustomsClassificationStatusTooltipTranslationKey || (CustomsClassificationStatusTooltipTranslationKey = {}));
export var CustomsClassificationStatusBorder;
(function (CustomsClassificationStatusBorder) {
    CustomsClassificationStatusBorder["PENDING"] = "none";
    CustomsClassificationStatusBorder["ON_TIME"] = "2px";
    CustomsClassificationStatusBorder["NEAR_DEADLINE"] = "none";
    CustomsClassificationStatusBorder["CRITICAL"] = "none";
    CustomsClassificationStatusBorder["COMPLETED"] = "none";
    CustomsClassificationStatusBorder["OVERDUE"] = "2px";
})(CustomsClassificationStatusBorder || (CustomsClassificationStatusBorder = {}));
