import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { Alert, Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSignatureCreateMutation } from "~/api/hooks/signatures";
import { useAuthDataQuery } from "~/api/hooks/users";
import Button from "~/components/Button/Button";
import { ChangeRecipientComponent } from "~/pages/trials/components/change-recipient";
export const SignatureComponent = ({ templateName, onSignatureData, signature, createNew, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { params: { id: clientId, flowId }, } = useMatch();
    const { data: authData } = useAuthDataQuery();
    const [recipient, setRecipient] = useState(undefined);
    const [isSentOnce, setIsSentOnce] = useState(false);
    const [isReadyToSign, setIsReadyToSign] = useState(true);
    const { data, isLoading, error, mutate } = useSignatureCreateMutation(clientId, flowId, templateName, recipient);
    useEffect(() => {
        if (recipient) {
            if (signature && recipient.email !== (signature === null || signature === void 0 ? void 0 : signature.signerClient)) {
                mutate();
            }
            setRecipient(undefined);
        }
    }, [recipient]);
    useEffect(() => {
        if (onSignatureData && data) {
            onSignatureData(data);
        }
    }, [data]);
    const handleChangeRecipient = (value) => {
        setRecipient({
            name: `${value.firstName} ${value.lastName}`,
            email: value.email,
            emailBlurb: value.message,
        });
    };
    const handleRefreshDocument = () => {
        mutate();
        setIsReadyToSign(true);
    };
    useEffect(() => {
        if (createNew) {
            handleRefreshDocument();
        }
    }, [createNew]);
    const openSignDocument = () => {
        setIsReadyToSign(false);
        setIsSentOnce(true);
        setRecipient(undefined);
        window.open(signature === null || signature === void 0 ? void 0 : signature.objectUrl, "_blank", "noopener,noreferrer");
    };
    return (_jsxs(VStack, { alignItems: "start", children: [_jsxs(Text, { pt: 4, children: [t("onboarding.vatNumberAndCustomsAccount.unsigned.currentSignerEmail"), ": ", signature === null || signature === void 0 ? void 0 : signature.signerClient] }), error && (_jsx(Box, { children: _jsx(Alert, { status: "error", title: (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : "There was an error" }) })), _jsxs(HStack, { children: [_jsx(Button, { colorScheme: "blue", onClick: () => openSignDocument(), my: "3", p: "3", disabled: !isReadyToSign ||
                            isLoading ||
                            (signature === null || signature === void 0 ? void 0 : signature.signerClient) !== ((_b = authData === null || authData === void 0 ? void 0 : authData.user) === null || _b === void 0 ? void 0 : _b.email), isLoading: isLoading, children: t("onboarding.vatNumberAndCustomsAccount.unsigned.signButton") }), isSentOnce && !isLoading && !isReadyToSign && (_jsx(Button, { colorScheme: "blue", onClick: () => handleRefreshDocument(), my: "3", p: "3", children: t("onboarding.vatNumberAndCustomsAccount.unsigned.refreshButton") }))] }), _jsx(ChangeRecipientComponent, { onSubmitData: handleChangeRecipient, disabled: !!recipient || isLoading })] }));
};
