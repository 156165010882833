import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import processOverviews, { hasAllFieldsFilled, } from "~/process-overviews/inbound-process";
import { InboundCompleteOverview } from "~/process-overviews/inbound-process/overview";
export function InboundCompleteProcessOverview() {
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const { data: flowFields } = useGetOnboardingFieldsFlows(id, flowId);
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.inboundProcesses.title"), subtitle: t("onboarding.inboundProcesses.completeOverview.title"), description: "" }), !hasAllFieldsFilled(processOverviews, flowFields) && (_jsxs(Alert, { status: "warning", children: [_jsx(AlertIcon, {}), t("onboarding.inboundProcesses.completeOverview.alert")] })), _jsx(InboundCompleteOverview, {}), _jsx(FormFooter, {})] }));
}
