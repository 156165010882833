import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Input, InputRightElement, useToast, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "~/assets/icons/CopyIcon";
export const CopyKeyInput = ({ apiKey, }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const onCopyKey = () => {
        navigator.clipboard.writeText(apiKey);
        toast({
            title: t("apiKeys.keyCopySuccess"),
            status: "success",
            duration: 3000,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Input, { disabled: true, value: apiKey }), _jsx(InputRightElement, { children: _jsx(IconButton, { onClick: onCopyKey, color: "grey.40", "aria-label": t("apiKeys.copyKeyCTA"), variant: "unstyled", icon: _jsx(CopyIcon, {}) }) })] }));
};
