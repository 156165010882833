import * as schemaGenerators from "../../helpers/schema-generators";
const presentationTimeOptions = Array.from({ length: 10 }, (_, i) => i + 1).reduce((acc, current) => ({ ...acc, [current]: `${current}:00` }), {});
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.outboundProcesses.title",
        subtitle: "onboarding.outboundProcesses.import.title",
        description: "onboarding.outboundProcesses.import.description",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "customsClearanceTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.import.sections.customsClearance.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "customsClearanceQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.import.sections.customsClearance.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "customsClearance.method",
                            elements: [
                                {
                                    type: "radio",
                                    value: "msDirect",
                                    key: "msDirect",
                                    label: "onboarding.outboundProcesses.import.sections.customsClearance.options.msDirect",
                                    forKey: "customsClearance.method",
                                },
                                {
                                    type: "radio",
                                    value: "myself",
                                    key: "myself",
                                    label: "onboarding.outboundProcesses.import.sections.customsClearance.options.myself",
                                    forKey: "customsClearance.method",
                                },
                                {
                                    type: "radio",
                                    value: "UKP",
                                    key: "UKP",
                                    label: "onboarding.outboundProcesses.import.sections.customsClearance.options.UKP",
                                    forKey: "customsClearance.method",
                                },
                                {
                                    type: "radio",
                                    value: "another",
                                    key: "another",
                                    label: "onboarding.outboundProcesses.import.sections.customsClearance.options.another",
                                    forKey: "customsClearance.method",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            rule: {
                                effect: "SHOW",
                                targetKey: "customsClearance.method",
                                targetValue: "another",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "customsClearance.details.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "customsClearance.details.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "customsClearance.details.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "customsClearance.details.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "customsClearance.details.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "customsClearance.details.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "borderOfficeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.import.sections.borderOffice.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "borderOffice.location",
                            elements: [
                                {
                                    type: "radio",
                                    value: "Basel",
                                    key: "Basel",
                                    label: "onboarding.outboundProcesses.import.sections.borderOffice.options.Basel",
                                    forKey: "borderOffice.location",
                                },
                                {
                                    type: "radio",
                                    value: "Thayngen",
                                    key: "Thayngen",
                                    label: "onboarding.outboundProcesses.import.sections.borderOffice.options.Thayngen",
                                    forKey: "borderOffice.location",
                                },
                                {
                                    type: "radio",
                                    value: "Rheineck",
                                    key: "Rheineck",
                                    label: "onboarding.outboundProcesses.import.sections.borderOffice.options.Rheineck",
                                    forKey: "borderOffice.location",
                                },
                                {
                                    type: "radio",
                                    value: "Kreuzlingen",
                                    key: "Kreuzlingen",
                                    label: "onboarding.outboundProcesses.import.sections.borderOffice.options.Kreuzlingen",
                                    forKey: "borderOffice.location",
                                },
                                {
                                    type: "radio",
                                    value: "another",
                                    key: "borderOfficeOther",
                                    label: "onboarding.outboundProcesses.import.sections.borderOffice.options.other",
                                    forKey: "borderOffice.location",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            rule: {
                                effect: "SHOW",
                                targetKey: "borderOffice.location",
                                targetValue: "another",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "borderOffice.details.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "borderOffice.details.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "borderOffice.details.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "borderOffice.details.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "borderOffice.details.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "borderOffice.details.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "customsClearance",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "presentationLocationTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.import.sections.presentationLocation.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "presentationLocationQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.import.sections.presentationLocation.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "presentationLocation.location",
                            elements: [
                                {
                                    type: "radio",
                                    value: "StGallen",
                                    key: "StGallen",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.StGallen",
                                    forKey: "presentationLocation.location",
                                },
                                {
                                    type: "radio",
                                    value: "Muttenz",
                                    key: "Muttzenz",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.Muttenz",
                                    forKey: "presentationLocation.location",
                                },
                                {
                                    type: "radio",
                                    value: "Arbon",
                                    key: "Arbon",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.Arbon",
                                    forKey: "presentationLocation.location",
                                },
                                {
                                    type: "radio",
                                    value: "AnderGrenze",
                                    key: "AnderGrenze",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.AnderGrenze",
                                    forKey: "presentationLocation.location",
                                },
                                {
                                    type: "radio",
                                    value: "WorminghallUKP",
                                    key: "WorminghallUKP",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.WorminghallUKP",
                                    forKey: "presentationLocation.location",
                                },
                                {
                                    type: "radio",
                                    value: "another",
                                    key: "presentationLocationAnother",
                                    label: "onboarding.outboundProcesses.import.sections.presentationLocation.options.other",
                                    forKey: "presentationLocation.location",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            rule: {
                                effect: "SHOW",
                                targetKey: "presentationLocation.location",
                                targetValue: "another",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "presentationLocation.details.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "presentationLocation.details.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "presentationLocation.details.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "presentationLocation.details.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "presentationLocation.details.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "presentationLocation.details.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "presentationTimeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.import.sections.presentationTime.question",
                        },
                        {
                            key: "presentationLocation.presentationTime",
                            label: "onboarding.outboundProcesses.import.sections.presentationTime.placeholder",
                            type: "select",
                            options: presentationTimeOptions,
                        },
                    ],
                },
            ],
            nodeKey: "presentationLocation",
        }),
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        customsClearance: {
            type: "object",
            properties: {
                method: { type: "string" },
                details: {
                    type: "object",
                },
            },
        },
        borderOffice: {
            type: "object",
            properties: {
                location: { type: "string" },
                details: {
                    type: "object",
                },
            },
        },
        presentationLocation: {
            type: "object",
            properties: {
                location: { type: "string" },
                presentationTime: { type: "string" },
                details: {
                    type: "object",
                },
            },
        },
    },
    additionalProperties: true,
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
