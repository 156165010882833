import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.securityDeposit.setUpDirectDebit",
        description: "onboarding.securityDeposit.setUpDirectDebitDescription",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: [
                    " 1.",
                    "onboarding.securityDeposit.downloadAuthorizationForm",
                    " ",
                    {
                        href: "https://mvpfactory.atlassian.net/wiki/spaces/MSDCX2/pages/3335422009/Overview+of+all+relevant+documents",
                        targetBlank: true,
                        displayLabel: "onboarding.actions.here",
                    },
                ],
            },
            {
                key: "label3",
                type: "label",
                label: ["2. ", "onboarding.securityDeposit.completAndSend"],
            },
            {
                key: "label4",
                type: "label",
                label: [
                    {
                        strong: true,
                        displayLabel: "onboarding.securityDeposit.agreementDirectDebit",
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
