import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { DeclarationOfAccessionPDF } from "~/components/GeneratedForm/CustomComponents/vat-and-customs";
export function YourCustomAccountCustomsDeclarationOfAccession() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.your_customs_account"), subtitle: t("onboarding.zazAccount.declarationOfAccession"), description: t("onboarding.zazAccount.descriptionDeclarationOfAccession") }), _jsx(FormBody, { gap: "4", children: _jsx(DeclarationOfAccessionPDF, {}) }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
