import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.technicalSetup.title",
        subtitle: "onboarding.technicalSetup.verifyUpload.title",
        description: "onboarding.technicalSetup.verifyUpload.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: "onboarding.technicalSetup.verifyUpload.description",
            },
            {
                key: "csv-uploaded-file",
                type: "file",
                label: "",
                multiple: false,
            },
            {
                key: "verify-uploaded-file",
                type: "customComponent",
                componentName: "technicalSetup.UploadVerification",
            },
            {
                key: "label3",
                type: "label",
                label: "onboarding.technicalSetup.verifyUpload.initialCheckMessage",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
