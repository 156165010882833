import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Grid, Link, Text, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CopyText } from "~/components/CopyText/CopyText";
import { CountryLabeledFlag } from "~/components/CountryLabeledFlag/CountryLabeledFlag";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import Icon from "~/components/Icon/Icon";
import { SeeMoreDescription } from "../SeeMoreDescription/SeeMoreDescription";
export const ArticleInfoView = ({ article, setIsEditView }) => {
    var _a;
    const { t } = useTranslation();
    const renderFreeFields = () => {
        const fields = [];
        for (let i = 1; i <= 10; i += 1) {
            const propertyName = `freeField${i}`;
            const text = article[propertyName];
            fields.push(_jsxs(Box, { children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.freeFieldsHeadline", {
                            index: i,
                        }) }), _jsx(Text, { children: text || "-" })] }, `article-free-field-${i}`));
        }
        return fields;
    };
    const headerDescription = ((_a = article === null || article === void 0 ? void 0 : article.countries.find((c) => c.countryCode === "DE")) === null || _a === void 0 ? void 0 : _a.description) || "-";
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { flexShrink: "0", paddingX: "10", children: [_jsx(Text, { textStyle: "h3", fontWeight: "700", mb: 2, overflow: "hidden", style: {
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                        }, children: headerDescription }), _jsxs(Flex, { justifyContent: "space-between", w: "full", alignItems: "center", children: [_jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(Text, { color: "grey.60", children: ["#", article.articleNumber] }), article.urlLink && (_jsxs(_Fragment, { children: [_jsx(Text, { mx: "3", color: "grey.60", children: "\u00B7" }), _jsxs(Link, { href: article.urlLink, isExternal: true, display: "flex", alignItems: "center", color: "brand.sky", children: [t("articles.detail.articleLink"), _jsx(Icon, { w: "4", ml: "4", name: "external" })] })] }))] }), _jsx(Tooltip, { hasArrow: true, bg: "brand.black", placement: "top", label: t("articles.detail.editArticle"), children: _jsx(Flex, { as: "button", onClick: setIsEditView, color: "grey.40", cursor: "pointer", _hover: { color: "var(--chakra-colors-brand-black)" }, children: _jsx(Icon, { name: "pen", w: "18px" }) }) })] })] }), _jsxs(Box, { paddingX: "10", children: [_jsx(Grid, { gap: "8", py: "8", children: article.countries.map((country) => (_jsx(SeeMoreDescription, { description: country.description || "-", title: t("articles.detail.articleDescriptionCountryHeadline", {
                                country: country.countryCode,
                            }) }))) }), _jsxs(Box, { children: [_jsx(Text, { mb: "4", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.classificationNumbersHeadline") }), _jsx(Grid, { rowGap: "6", columnGap: "8", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", overflowX: "hidden", children: article.countries.map(({ countryCode, tariffingCode }) => (_jsxs(Flex, { alignItems: "center", children: [_jsx(FlagIcon, { boxSize: "6", countryCode: countryCode }), _jsx(Box, { flexGrow: "1", ml: "2", children: _jsx(CopyText, { text: tariffingCode }) })] }, `country-tariffing-code-${countryCode}`))) })] }), _jsxs(Grid, { gap: "8", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", pt: "8", overflowX: "hidden", children: [_jsxs(Box, { children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.countryOfOriginHeadline") }), _jsx(CountryLabeledFlag, { country: article.countryOfOrigin })] }), _jsxs(Box, { children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.manufacturedInHeadline") }), _jsx(CountryLabeledFlag, { country: article.manufacturingCountry })] }), _jsxs(Box, { children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.articleValueHeadline") }), _jsx(Text, { children: article.articleValue || article.articleValueUnit
                                            ? `${article.articleValue} ${article.articleValueUnit}`
                                            : "-" })] }), _jsxs(Box, { children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.netWeightHeadline") }), _jsx(Text, { children: article.netWeight || article.netWeightUnit
                                            ? `${article.netWeight} ${article.netWeightUnit}`
                                            : "-" })] })] }), _jsx(Text, { pt: "8", mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.materialDescriptionHeadline") }), _jsx(Text, { children: article.materialDescription || "-" }), _jsx(Text, { pt: "8", mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: t("articles.detail.deImportTariffHeadline") }), _jsx(Text, { children: article.deImportTariffingCode || "-" }), _jsx(Grid, { gap: "8", gridTemplateColumns: "1fr", py: "8", overflowX: "hidden", children: renderFreeFields() })] })] }));
};
