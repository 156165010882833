import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import { Modal } from "~/components/Modal/Modal";
import { RouteMap } from "~/routing/route.config";
import { useDeleteKeyByIdMutation } from "../../redux/keys.api";
export const DeleteKeyModal = ({ show, handleHide, item, }) => {
    const [deleteKey, { isLoading, isError, reset }] = useDeleteKeyByIdMutation();
    const [keyName, setKeyName] = useState("");
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (show)
            setKeyName("");
    }, [show]);
    const handleSubmit = async () => {
        if (keyName) {
            const res = await deleteKey(item.id);
            if ("data" in res) {
                toast({
                    title: t("apiKeys.deleteKeySuccess"),
                    status: "success",
                    duration: 3000,
                });
                navigate({ to: RouteMap.API_KEYS });
            }
        }
    };
    const handleChange = (e) => {
        if (isError)
            reset();
        setKeyName(e.target.value);
    };
    const disableSubmit = keyName !== item.name;
    return (_jsx(Modal, { title: t("apiKeys.deleteKeyModalTitle"), isOpen: show, onClose: handleHide, primaryAction: handleSubmit, primaryTitle: t("apiKeys.deleteKeyModalCTA"), primaryActionLoading: isLoading, primaryActionDisabled: disableSubmit, primaryVariant: "danger", children: _jsxs(Box, { mt: 2, children: [_jsx("p", { children: t("apiKeys.deleteKeyModalText", { keyName: item.name }) }), _jsx(Text, { mt: 6, mb: 2, textStyle: "labelUppercase", children: t("apiKeys.deleteKeyModalInputLabel") }), _jsx(Input, { mb: 6, isInvalid: isError, value: keyName, onChange: handleChange }), _jsx(Banner, { text: t("apiKeys.deleteKeyModalBanner") })] }) }));
};
