export function hasAllFieldsFilled(processOverviews, flowFields) {
    return !processOverviews
        .map((overviewFunc) => {
        const overview = overviewFunc(flowFields);
        return !(overview.who === "" ||
            overview.what === "" ||
            overview.when === "");
    })
        .some((bool) => !bool);
}
