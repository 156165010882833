import { contactInformationFormNodesAndSchema, contactPersonFormNodesAndSchema, overviewFormNodesAndSchema, } from "../schemas/company-details";
export const COMPANY_DETAILS = {
    company_details: {
        id: "company_details",
        initial: "overview",
        meta: {
            eventName: "COMPANY_DETAILS",
            label: "onboarding.companyDetails.title",
        },
        states: {
            overview: {
                meta: {
                    label: "onboarding.overview",
                    ...overviewFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "contact_information",
                },
            },
            contact_information: {
                meta: {
                    label: "onboarding.companyDetails.contactInformation.title",
                    ...contactInformationFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "contact_persons",
                },
            },
            contact_persons: {
                meta: {
                    label: "onboarding.companyDetails.contactPerson.title",
                    ...contactPersonFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "overview",
                },
            },
        },
    },
};
