import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useAppDispatch } from "~/store/hooks";
import { fetchFormById } from "~/store/actions/formData.actions";
export function ApplicationVatUidNumberFiscalSecurityDepositCompleted() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "vatApplicationStart",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.securityDepositCompleted.headline"), subtitle: t("onboarding.vat.flow.securityDepositCompleted.subtitle") }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { fontSize: "2xl", children: t("onboarding.vat.flow.securityDepositCompleted.title") }), _jsx(Text, { children: t("onboarding.vat.flow.securityDepositCompleted.description") })] }), _jsx(FormFooter, { pageSlug: "vatApplicationStart", processSlug: "administrativeProcesses", isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, nextUrl: `/clients/${id}/onboarding/${flowId}/administrative-processes/zaz-account-start` })] }));
}
