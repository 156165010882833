import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BulkIcon } from "~/assets/icons/BulkIcon";
import { IconButton } from "~/components/IconButton/IconButton";
export const renderMultipleOccurrenceFilter = ({ value = "", onBulkFilter, hasShipFromDuplicates, children, }) => {
    const { t } = useTranslation();
    return (_jsxs(Flex, { gap: 4, children: [children, hasShipFromDuplicates && (_jsx(IconButton, { tooltipLabel: t("basics.multipleOccurrences"), hasTooltip: true, onClick: (e) => {
                    e.stopPropagation();
                    onBulkFilter(value);
                }, children: _jsx(BulkIcon, {}) }))] }));
};
