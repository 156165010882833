import { EPermissionCodes } from "@crossborderx/schemas-package";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import legacy from "./legacy-sidebarItems";
const sidebarItems = {
    [AccountTypes.INTERNAL]: [
        {
            iconName: "home-outline",
            textKey: "home",
            to: "/",
        },
        {
            iconName: "people-outline",
            textKey: "partners",
            to: "/partners",
        },
        {
            iconName: "briefcase-outline",
            textKey: "clients",
            to: "/clients",
        },
        {
            iconName: "adjustments-outline",
            textKey: "settings",
            collapsedItems: [
                {
                    iconName: "briefcase-outline",
                    textKey: "translations",
                    to: "/settings/translations",
                },
                {
                    iconName: "briefcase-outline",
                    textKey: "exportTranslations",
                    to: "/settings/translations/export",
                },
            ],
        },
        ...legacy.interalUserSidebarItems,
        {
            iconName: "warehouse-outline",
            textKey: "customsClassification",
            to: "/customs-classification",
            permissions: [
                EPermissionCodes.CLIENTS_CUSTOM_CLASSIFICATION_SERVICE_SETTINGS_MANAGE,
                EPermissionCodes.CLIENTS_ALL_SERVICES_SETTINGS_MANAGE,
            ],
        },
    ],
    [AccountTypes.PARTNER]: [
        {
            iconName: "home-outline",
            textKey: "home",
            to: "/",
        },
        {
            iconName: "briefcase-outline",
            textKey: "companyProfile",
            to: "/company-profile",
        },
        ...legacy.partnerUserSidebarItems,
        {
            iconName: "warehouse-outline",
            textKey: "customsClassification",
            to: "/customs-classification",
            permissions: [EPermissionCodes.PARTNER_TARI_IMPORTS_MANAGE],
        },
        {
            iconName: "briefcase-outline",
            textKey: "clients",
            to: "/clients",
        },
    ],
    [AccountTypes.CLIENT]: [
        {
            iconName: "home-outline",
            textKey: "home",
            to: "/",
        },
        {
            iconName: "document-outline",
            textKey: "documents",
            to: "/documents",
        },
        {
            iconName: "briefcase-outline",
            textKey: "companyProfile",
            to: "/company-profile",
        },
        ...legacy.clientUserSidebarItems,
        {
            iconName: "warehouse-outline",
            textKey: "customsClassification",
            to: "/customs-classification",
            permissions: [
                EPermissionCodes.CLIENT_CUSTOM_CLASSIFICATION_SERVICE_SETTINGS_MANAGE,
                EPermissionCodes.CLIENT_ALL_SERVICES_SETTINGS_VIEW,
                EPermissionCodes.CLIENT_ALL_SERVICES_SETTINGS_MANAGE,
                EPermissionCodes.CLIENT_TARI_IMPORTS_MANAGE,
            ],
        },
    ],
    default: [],
};
export default sidebarItems;
