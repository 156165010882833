import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
export default function Infobox({ infoText }) {
    const { t } = useTranslation();
    return (_jsx(Flex, { maxW: { md: "full", xl: "33%" }, direction: "column", flexGrow: 1, bgColor: "#F7F8FF", p: "5", children: _jsxs(Flex, { direction: "column", children: [_jsx(Text, { fontWeight: "bold", color: "blue.500", mb: "4", children: t(`${infoText}.infoBox.title`) }), _jsx(Flex, { direction: "column", children: typeof t(`${infoText}.infoBox.description`, {
                        returnObjects: true,
                    }) === "string" ? (_jsx(_Fragment, { children: t(`${infoText}.infoBox.description`)
                            .split("/n")
                            .map((el) => {
                            return (_jsx(Text, { color: "gray.500", mb: "4", 
                                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                dangerouslySetInnerHTML: {
                                    __html: el,
                                } }));
                        }) })) : (Object.keys(t(`${infoText}.infoBox.description`, {
                        returnObjects: true,
                    })).map((el, index) => {
                        return (_jsx(Text, { fontWeight: index % 2 === 0 ? "bold" : "normal", fontStyle: index % 2 !== 0 ? "italic" : "normal", mb: index % 2 === 0 ? "0" : "4", 
                            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                            dangerouslySetInnerHTML: {
                                __html: t(`${infoText}.infoBox.description`, {
                                    returnObjects: true,
                                })[el],
                            } }));
                    })) })] }) }));
}
