import { z } from "zod";
import { CountryOptionSchema } from "~/components/CountrySelect/CountrySelect";
export const EditTariffingCountryValidationSchema = z.object({
    shipTo: CountryOptionSchema.array().optional(),
    shipFrom: CountryOptionSchema.array().optional(),
});
export const EditTariffingCountryBodySchema = z.object({
    shipTo: z.string().array(),
    shipFrom: z.string().array(),
});
