import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, FormControl, Radio, RadioGroup, Stack, Text, } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useGetOnboardingPageFields } from "~/api/hooks/onboarding";
import Checkbox from "~/components/Checkbox/Checkbox";
import { CountrySelect } from "~/components/GeneratedForm/CustomComponents/base";
import OnboardingFieldMessage from "~/components/OnboardingFieldMessage/OnboardingFieldMessage";
import TextArea from "~/components/TextArea/TextArea";
import TextField from "~/components/TextField/TextField";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData } from "~/store/selectors/formData.selectors";
export function InboundProcessReturnPickUp() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const [specialPallet, setSpecialPallet] = useState("");
    const { data: outboundCarrierData } = useGetOnboardingPageFields(id, flowId, "outboundProcess", "exportFromEu");
    const [outboundCarrier, setOutboundCarrier] = useState();
    const pageForm = {
        id,
        flowId,
        processSlug: "inboundProcess",
        pageSlug: "returnPickUp",
    };
    useEffect(() => {
        dispatch(fetchFormById({
            ...pageForm,
        }));
    }, []);
    useEffect(() => {
        const dataObj = outboundCarrierData === null || outboundCarrierData === void 0 ? void 0 : outboundCarrierData.reduce((r, a) => {
            // eslint-disable-next-line no-param-reassign
            r[a.fieldName] = a;
            return r;
        }, Object.create(null));
        setOutboundCarrier(dataObj);
    }, [outboundCarrierData]);
    useEffect(() => {
        var _a;
        setSpecialPallet(((_a = formData === null || formData === void 0 ? void 0 : formData.specialPallet) === null || _a === void 0 ? void 0 : _a.fieldValue) || "");
    }, [formData]);
    const updateOnChangeField = (e) => {
        dispatch(patchFormField({
            fieldName: e.target.name,
            fieldValue: e.target.value,
            ...pageForm,
        }));
    };
    const updateOnBlurField = (e) => {
        if (["companyName", "street", "streetNumber", "zipCode", "city"].includes(e.target.name)) {
            if (!(formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound)) {
                dispatch(patchFormField({
                    fieldName: "sameCarrierAsOutbound",
                    fieldValue: "no",
                    ...pageForm,
                }));
            }
        }
        dispatch(patchFormField({
            fieldName: e.target.name,
            fieldValue: e.target.value,
            ...pageForm,
        }));
    };
    const companyNameDefaultValue = () => {
        var _a, _b, _c, _d;
        if (((_a = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _a === void 0 ? void 0 : _a.fieldValue) === "yes") {
            if (((_b = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _b === void 0 ? void 0 : _b.fieldValue) === "viaMS") {
                return "Via MS Direct";
            }
            return (_c = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyName) === null || _c === void 0 ? void 0 : _c.fieldValue;
        }
        return (_d = formData === null || formData === void 0 ? void 0 : formData.companyName) === null || _d === void 0 ? void 0 : _d.fieldValue;
    };
    const register = () => { };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.inboundProcesses.title"), subtitle: t("onboarding.inboundProcesses.returnPickUp.title"), description: "" }), _jsx(FormBody, { children: _jsx(Flex, { wrap: "wrap", w: "full", justifyContent: "start", gap: "5", children: _jsxs(Flex, { direction: "column", w: "full", gap: 3, children: [_jsx(Text, { fontSize: "xl", children: t("onboarding.inboundProcesses.returnPickUp.pickUpFrequency") }), _jsx(Text, { fontSize: "md", children: t("onboarding.inboundProcesses.returnPickUp.carrierToPickUp") }), _jsxs(Box, { children: [_jsx(Checkbox, { isChecked: ((_a = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _a === void 0 ? void 0 : _a.fieldValue) === "yes", name: "sameCarrierAsOutbound", label: t("onboarding.inboundProcesses.returnPickUp.sameCarrierAsOutbound"), onChange: (e) => {
                                            dispatch(patchFormField({
                                                fieldName: "sameCarrierAsOutbound",
                                                fieldValue: e.target.checked ? "yes" : "no",
                                                ...pageForm,
                                            }));
                                        } }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound })] }), _jsxs(Flex, { direction: "column", w: "full", gap: 3, children: [_jsxs(FormControl, { children: [_jsx(TextField, { label: t("onboarding.forms.details.nameOnly"), placeholder: t("onboarding.forms.details.nameOnly"), name: "companyName", defaultValue: companyNameDefaultValue(), onBlur: updateOnBlurField, disabled: ((_b = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _b === void 0 ? void 0 : _b.fieldValue) === "yes" }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.companyName })] }, "companyName"), _jsxs(Flex, { direction: "row", gap: 3, children: [_jsxs(FormControl, { children: [_jsx(TextField, { label: t("onboarding.forms.details.street"), placeholder: t("onboarding.forms.details.street"), name: "street", defaultValue: ((_c = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _c === void 0 ? void 0 : _c.fieldValue) === "yes" &&
                                                            ((_d = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _d === void 0 ? void 0 : _d.fieldValue) !== "viaMS"
                                                            ? (_e = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyStreet) === null || _e === void 0 ? void 0 : _e.fieldValue
                                                            : (_f = formData === null || formData === void 0 ? void 0 : formData.street) === null || _f === void 0 ? void 0 : _f.fieldValue, onBlur: updateOnBlurField, disabled: ((_g = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _g === void 0 ? void 0 : _g.fieldValue) === "yes" }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.street })] }, "street"), _jsxs(FormControl, { children: [_jsx(TextField, { label: t("onboarding.forms.details.number"), placeholder: t("onboarding.forms.details.number"), name: "streetNumber", defaultValue: ((_h = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _h === void 0 ? void 0 : _h.fieldValue) === "yes" &&
                                                            ((_j = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _j === void 0 ? void 0 : _j.fieldValue) !== "viaMS"
                                                            ? (_k = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyNumber) === null || _k === void 0 ? void 0 : _k.fieldValue
                                                            : (_l = formData === null || formData === void 0 ? void 0 : formData.streetNumber) === null || _l === void 0 ? void 0 : _l.fieldValue, onBlur: updateOnBlurField, disabled: ((_m = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _m === void 0 ? void 0 : _m.fieldValue) === "yes" }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.streetNumber })] }, "streetNumber")] }), _jsxs(Flex, { direction: "row", gap: 3, children: [_jsxs(FormControl, { children: [_jsx(TextField, { label: t("onboarding.forms.details.zipCode"), placeholder: t("onboarding.forms.details.zipCode"), name: "zipCode", defaultValue: ((_o = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _o === void 0 ? void 0 : _o.fieldValue) === "yes" &&
                                                            ((_p = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _p === void 0 ? void 0 : _p.fieldValue) !== "viaMS"
                                                            ? (_q = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyZipCode) === null || _q === void 0 ? void 0 : _q.fieldValue
                                                            : (_r = formData === null || formData === void 0 ? void 0 : formData.zipCode) === null || _r === void 0 ? void 0 : _r.fieldValue, onBlur: updateOnBlurField, disabled: ((_s = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _s === void 0 ? void 0 : _s.fieldValue) === "yes" }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.zipCode })] }, "zipCode"), _jsxs(FormControl, { children: [_jsx(TextField, { label: t("onboarding.forms.details.city"), placeholder: t("onboarding.forms.details.city"), name: "city", defaultValue: ((_t = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _t === void 0 ? void 0 : _t.fieldValue) === "yes" &&
                                                            ((_u = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _u === void 0 ? void 0 : _u.fieldValue) !== "viaMS"
                                                            ? (_v = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyCity) === null || _v === void 0 ? void 0 : _v.fieldValue
                                                            : (_w = formData === null || formData === void 0 ? void 0 : formData.city) === null || _w === void 0 ? void 0 : _w.fieldValue, onBlur: updateOnBlurField, disabled: ((_x = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _x === void 0 ? void 0 : _x.fieldValue) === "yes" }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.city })] }, "city")] }), _jsxs(FormControl, { children: [_jsx(CountrySelect, { register: register, defaultValue: ((_y = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _y === void 0 ? void 0 : _y.fieldValue) === "yes" &&
                                                    ((_z = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _z === void 0 ? void 0 : _z.fieldValue) !== "viaMS"
                                                    ? (_0 = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.myselfCompanyCity) === null || _0 === void 0 ? void 0 : _0.fieldValue
                                                    : (_1 = formData === null || formData === void 0 ? void 0 : formData.countryCode) === null || _1 === void 0 ? void 0 : _1.fieldValue, isDisabled: ((_2 = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _2 === void 0 ? void 0 : _2.fieldValue) === "yes", onChange: (e) => {
                                                    dispatch(patchFormField({
                                                        fieldName: "countryCode",
                                                        fieldValue: e.target.value,
                                                        ...pageForm,
                                                    }));
                                                    return Promise.resolve(true);
                                                } }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.countryCode })] }, "countryCode")] }, ((_3 = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _3 === void 0 ? void 0 : _3.fieldValue) === "yes"
                                ? (_4 = outboundCarrier === null || outboundCarrier === void 0 ? void 0 : outboundCarrier.exportOrganizer) === null || _4 === void 0 ? void 0 : _4.fieldValue
                                : (_5 = formData === null || formData === void 0 ? void 0 : formData.sameCarrierAsOutbound) === null || _5 === void 0 ? void 0 : _5.fieldValue), _jsx(Text, { fontSize: "md", children: t("onboarding.inboundProcesses.returnPickUp.specialPallet") }), _jsxs(RadioGroup, { defaultValue: (_6 = formData === null || formData === void 0 ? void 0 : formData.specialPallet) === null || _6 === void 0 ? void 0 : _6.fieldValue, onChange: (e) => setSpecialPallet(e), children: [_jsxs(Stack, { direction: "column", gap: 1, children: [_jsx(Radio, { value: "yes", name: "specialPallet", onChange: updateOnChangeField, children: t("onboarding.forms.answers.yes") }), _jsx(Radio, { value: "no", name: "specialPallet", onChange: updateOnChangeField, children: t("onboarding.forms.answers.no") })] }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.specialPallet })] }), specialPallet === "yes" && (_jsxs(FormControl, { children: [_jsx(TextArea, { label: t("onboarding.inboundProcesses.returnPickUp.specialInstructions"), placeholder: t("onboarding.inboundProcesses.returnPickUp.specialInstructions"), name: "instructions", value: (_7 = formData === null || formData === void 0 ? void 0 : formData.instructions) === null || _7 === void 0 ? void 0 : _7.fieldValue, onBlur: updateOnBlurField }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.instructions })] }, "instructions"))] }) }) }), _jsx(FormFooter, {})] }));
}
