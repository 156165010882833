import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.outboundProcesses.title",
        subtitle: "onboarding.outboundProcesses.delivery.title",
        description: "onboarding.outboundProcesses.delivery.description",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "placeofDeliveryTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.delivery.sections.placeOfDelivery.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "placeOfDeliveryQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.delivery.sections.placeOfDelivery.question1",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            elements: [
                                {
                                    type: "text",
                                    key: "placeOfDelivery.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "placeOfDelivery.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "placeOfDelivery.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "placeOfDelivery.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "placeOfDelivery.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "placeOfDelivery.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "placeOfDeliveryQuestion2",
                            type: "h3",
                            label: "onboarding.outboundProcesses.delivery.sections.placeOfDelivery.question2",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            groupType: "radio",
                            forKey: "additionalPlaceOfDelivery.isAdditional",
                            elements: [
                                {
                                    type: "radio",
                                    value: "yes",
                                    key: "additionalPlaceYes",
                                    label: "onboarding.forms.answers.yes",
                                    forKey: "additionalPlaceOfDelivery.isAdditional",
                                },
                                {
                                    type: "radio",
                                    value: "no",
                                    key: "additionalPlaceYes",
                                    label: "onboarding.forms.answers.no",
                                    forKey: "additionalPlaceOfDelivery.isAdditional",
                                },
                                {
                                    type: "layout",
                                    direction: "vertical",
                                    rule: {
                                        effect: "SHOW",
                                        targetKey: "additionalPlaceOfDelivery.isAdditional",
                                        targetValue: "yes",
                                    },
                                    elements: [
                                        {
                                            type: "text",
                                            key: "additionalPlaceOfDelivery.name",
                                            label: "onboarding.forms.details.name",
                                        },
                                        {
                                            type: "text",
                                            key: "additionalPlaceOfDelivery.street",
                                            label: "onboarding.forms.details.street",
                                        },
                                        {
                                            type: "text",
                                            key: "additionalPlaceOfDelivery.street_number",
                                            label: "onboarding.forms.details.number",
                                        },
                                        {
                                            type: "text",
                                            key: "additionalPlaceOfDelivery.zip_code",
                                            label: "onboarding.forms.details.zipCode",
                                        },
                                        {
                                            type: "text",
                                            key: "additionalPlaceOfDelivery.location",
                                            label: "onboarding.forms.details.city",
                                        },
                                        {
                                            key: "additionalPlaceOfDelivery.country",
                                            type: "customComponent",
                                            componentName: "base.CountrySelect",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "placeOfDelivery",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "lastMileTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.delivery.sections.lastMile.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "lastMileQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.delivery.sections.lastMile.question1",
                        },
                        {
                            key: "lastMile.carrier",
                            type: "select",
                            label: "",
                            options: {
                                postMSdirect: "onboarding.outboundProcesses.delivery.sections.lastMile.options.postMSdirect",
                                postSevenSenders: "onboarding.outboundProcesses.delivery.sections.lastMile.options.postSevenSenders",
                                postDirect: "onboarding.outboundProcesses.delivery.sections.lastMile.options.postDirect",
                                DPDMSdirect: "onboarding.outboundProcesses.delivery.sections.lastMile.options.DPDMSdirect",
                                DPDSevenSenders: "onboarding.outboundProcesses.delivery.sections.lastMile.options.DPDSevenSenders",
                                DPDDirect: "onboarding.outboundProcesses.delivery.sections.lastMile.options.DPDDirect",
                            },
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "lastMileQuestion2",
                            type: "h3",
                            label: "onboarding.outboundProcesses.delivery.sections.lastMile.question2",
                        },
                        {
                            key: "lastMile.frankingNumber",
                            type: "text",
                            label: "onboarding.outboundProcesses.delivery.sections.lastMile.placeholder",
                        },
                    ],
                },
            ],
            nodeKey: "lastMile",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "deliveryOptionsTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.delivery.sections.deliveryOptions.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "deliveryOptionsQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.delivery.sections.deliveryOptions.question1",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            groupType: "checkbox",
                            options: { gap: 3 },
                            forKey: "deliveryOptions",
                            elements: [
                                {
                                    key: "deliveryOptionsSaturday",
                                    type: "checkbox",
                                    value: "saturday",
                                    label: "onboarding.outboundProcesses.delivery.sections.deliveryOptions.options.saturday",
                                },
                                {
                                    key: "deliveryOptionsEvening",
                                    type: "checkbox",
                                    value: "evening",
                                    label: "onboarding.outboundProcesses.delivery.sections.deliveryOptions.options.evening",
                                },
                                {
                                    key: "deliveryOptionsSameday",
                                    type: "checkbox",
                                    value: "sameday",
                                    label: "onboarding.outboundProcesses.delivery.sections.deliveryOptions.options.sameday",
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "deliveryOptions",
        }),
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        placeOfDelivery: {
            type: "object",
        },
        // This one's not final
        additionalPlaceOfDelivery: {
            type: "object",
        },
        lastMile: {
            type: "object",
            properties: {
                carrier: { type: "string" },
                frankingNumber: { type: "string" },
            },
        },
    },
    additionalProperties: true,
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
