import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "~/components/Drawer/Drawer";
import Icon from "~/components/Icon/Icon";
import { UserOverlayList } from "~/components/UserOverlayList/UserOverlayList";
import { RouteMap } from "~/routing/route.config";
import { useGetClientUsersQuery, useLazyGetClientByIdQuery, } from "../../redux/clients.api";
import { ClientContactInfo } from "./ClientContactInfo";
export const ClientInfoOverlay = ({ clientId, onClose, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [getClient, { data: client, isFetching }] = useLazyGetClientByIdQuery();
    const { data: users, isFetching: isFetchingUsers } = useGetClientUsersQuery((client === null || client === void 0 ? void 0 : client.id) || 0, {
        skip: !client,
    });
    useEffect(() => {
        if (clientId) {
            getClient(clientId);
        }
    }, [clientId]);
    const handleGoToDetail = () => {
        if (client)
            navigate({ to: `${RouteMap.CLIENTS}/${client.id}` });
    };
    const renderServices = () => {
        return _jsx("p", { children: "TBD" });
    };
    return (_jsx(Drawer, { isOpen: !!clientId, onClose: onClose, children: isFetching || !client ? (_jsx(Flex, { h: "full", w: "full", justifyContent: "center", alignItems: "center", children: _jsx(Spinner, { color: "brand.mare" }) })) : (_jsxs(Flex, { flexDirection: "column", h: "full", children: [_jsx(Text, { textStyle: "h3", fontWeight: "700", mb: 2, children: client.name }), _jsx(Text, { textStyle: "h4", children: t("clients.drawer.accountType") }), client.description && (_jsx(Text, { mt: 2, textStyle: "body", children: client.description })), _jsx(ClientContactInfo, { client: client }), _jsxs(_Fragment, { children: [_jsx(Text, { mt: 4, pb: 3, borderBottom: "1px solid", borderBottomColor: "grey.10", textStyle: "labelUppercase", mb: 4, children: t("clients.drawer.serviceHeader") }), false ? (_jsx(Box, { children: renderServices() })) : (_jsx(Text, { color: "grey.40", children: t("clients.drawer.noServicesText") }))] }), _jsx(Text, { mt: 8, pb: 3, borderBottom: "1px solid", borderBottomColor: "grey.10", textStyle: "labelUppercase", mb: 4, children: t("clients.drawer.teamHeader") }), _jsx(UserOverlayList, { users: users, isLoading: isFetchingUsers }), _jsx(Flex, { h: "full", flex: "1", mt: 12, pb: 8, flexDirection: "column", justifyContent: "flex-end", children: _jsx(Button, { rightIcon: _jsx(Icon, { ml: 1, w: "18px", h: "14px", name: "arrow-right", color: "white" }), onClick: handleGoToDetail, children: t("clients.drawer.goToDetailsCTA") }) })] })) }));
};
