import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.productClassification.title",
        description: "onboarding.productDetails.productClassification.description",
    }),
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "product-classification-two",
            infoboxText: "onboarding.productDetails.productClassification.swissTariffNumberInfo",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    groupType: "radio",
                    forKey: "tariff-number",
                    options: {
                        pt: 2,
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label2",
                            type: "h3",
                            label: "onboarding.productDetails.productClassification.shipToClassification",
                        },
                        {
                            key: "label2",
                            type: "label",
                            label: "onboarding.productDetails.productClassification.alreadyHaveTariffNumber",
                        },
                        {
                            type: "radio",
                            key: "tariff-number",
                            label: "onboarding.actions.yes",
                            value: "yes",
                            forKey: "tariff-number",
                        },
                        {
                            type: "radio",
                            key: "no",
                            label: "onboarding.actions.no",
                            value: "no",
                            forKey: "tariff-number",
                        },
                    ],
                },
            ],
        }),
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
