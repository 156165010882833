import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ServiceCode } from "~/enums/service-code.enum";
import { useGetProviderPartnersQuery } from "~/pages/partners/redux/partners.api";
export const ServiceProviderSelect = ({ shipTo, shipFrom, handleSelect, selectedProviderId }) => {
    const { t } = useTranslation();
    const { data, isFetching } = useGetProviderPartnersQuery({
        ...(shipTo && { shipTo }),
        ...(shipFrom && { shipFrom }),
        serviceCode: ServiceCode.TARIFFING,
    });
    const partners = data || [];
    return (_jsxs(Box, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: "4", children: t("clients.detail.services.tariffingModal.serviceProviderTitle") }), _jsx(Select, { placeholder: isFetching
                    ? t("basics.loading")
                    : !partners.length
                        ? t("clients.detail.services.tariffingModal.noProvidersFound")
                        : t("clients.detail.services.tariffingModal.serviceProviderPlaceholder"), variant: "formSelect", value: selectedProviderId || "", onChange: (e) => handleSelect(e.target.value), disabled: isFetching || !partners.length, children: partners.map((p) => (_jsx("option", { value: p.serviceId, children: p.name }, `partner-option-${p.serviceId}`))) })] }));
};
