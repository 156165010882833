import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.securityDeposit.kind",
        description: "onboarding.securityDeposit.kindOfSecuritydescription",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: "onboarding.securityDeposit.informationText",
            },
            {
                type: "radio",
                key: "security_deposit_type",
                label: "onboarding.securityDeposit.cashDeposit",
                value: "Bar Depot einrichten (empfohlen)",
                forKey: "security_deposit_type",
            },
            {
                key: "label3",
                type: "label",
                label: "onboarding.securityDeposit.cashDepositDetails",
            },
            {
                type: "radio",
                key: "security_deposit_type_2",
                label: "onboarding.securityDeposit.generalGuarantee",
                value: "Generalbürgschaft einrichten",
                forKey: "security_deposit_type",
            },
            {
                key: "label4",
                type: "label",
                label: "onboarding.securityDeposit.downloadGeneralGuarantee",
            },
            {
                key: "label5",
                type: "label",
                label: "onboarding.securityDeposit.federalOffice",
            },
            {
                key: "label6",
                type: "label",
                label: "onboarding.securityDeposit.finance",
            },
            {
                key: "label7",
                type: "label",
                label: "onboarding.securityDeposit.taubenstrasse",
            },
            {
                key: "label8",
                type: "label",
                label: "onboarding.securityDeposit.bern",
            },
            {
                key: "label9",
                type: "label",
                label: "",
            },
            {
                key: "label10",
                type: "label",
                label: [
                    {
                        href: "https://mvpfactory.atlassian.net/wiki/spaces/MSDCX2/pages/3335422009/Overview+of+all+relevant+documents",
                        targetBlank: true,
                        displayLabel: "onboarding.securityDeposit.downloadHere",
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        security_deposit_type: {
            type: "string",
            minLength: 1,
        },
    },
    required: ["security_deposit_type"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
