import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, Text, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { PhoneInput } from "~/components/PhoneInput/PhoneInput";
import { getCodeDropdownOptions } from "~/helpers/country-codes";
import { EditCompanyDetailsValidationSchema } from "~/pages/partners/schemas/schemas";
import { useUpdateClientByIdMutation } from "../../redux/clients.api";
export const EditContactDetailsModal = ({ isOpen, setIsOpen, client }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const toast = useToast();
    const { control, register, handleSubmit, reset, formState: { errors, isDirty }, } = useForm({
        defaultValues: {
            name: client.name,
            email: client.email,
            phoneCode: getCodeDropdownOptions().find((item) => item.value === (client.phoneCode || "+49")),
            phoneNumber: client.phoneNumber,
            website: client.website,
        },
        resolver: zodResolver(EditCompanyDetailsValidationSchema),
    });
    const [editCompany, { isLoading }] = useUpdateClientByIdMutation();
    const handleClose = () => {
        setIsOpen(false);
        reset();
    };
    const onSubmit = async (data) => {
        const { phoneCode, ...rest } = data;
        const body = {
            ...rest,
            phoneCode: phoneCode.value,
        };
        try {
            const res = await editCompany({
                id: client.id,
                ...body,
            });
            if ("data" in res) {
                toast({
                    title: t("clients.editCompanyDetailsModal.success"),
                    status: "success",
                    duration: 3000,
                });
            }
            setIsOpen(false);
        }
        catch (err) {
            toast({
                title: t("error.badRequest.editCompanyDetails"),
                status: "error",
                duration: 3000,
            });
        }
    };
    useEffect(() => {
        if (!isOpen)
            reset();
    }, [isOpen]);
    return (_jsx(Modal, { title: t("clients.editCompanyDetailsModal.title"), isOpen: isOpen, onClose: handleClose, primaryAction: handleSubmit(onSubmit), primaryActionDisabled: isLoading || !isDirty, primaryActionLoading: isLoading, primaryTitle: t("basics.save"), children: _jsxs("form", { children: [_jsxs(Box, { display: "flex", flexDir: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.detail.profile.companyNameLabel") }), _jsx(Input, { ...register("name") }), errors.name && _jsx(ErrorMessage, { text: (_a = errors.name.message) !== null && _a !== void 0 ? _a : "" })] }), _jsxs(Box, { display: "flex", flexDir: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.detail.profile.email") }), _jsx(Input, { ...register("email") }), errors.email && _jsx(ErrorMessage, { text: (_b = errors.email.message) !== null && _b !== void 0 ? _b : "" })] }), _jsxs(Box, { display: "flex", gap: "4", children: [_jsxs(Box, { display: "flex", flexDir: "column", gap: "2", flexBasis: "50%", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.detail.profile.phoneLabel") }), _jsx(PhoneInput, { controlProps: { name: "phoneCode", control }, phoneNumber: register("phoneNumber"), errors: errors })] }), _jsxs(Box, { display: "flex", flexDir: "column", gap: "2", flexBasis: "50%", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.detail.profile.websiteLabel") }), _jsx(Input, { ...register("website") }), errors.website && (_jsx(ErrorMessage, { text: (_c = errors.website.message) !== null && _c !== void 0 ? _c : "" }))] })] })] }) }));
};
