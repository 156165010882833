import { deliveryFormNodesAndSchema, exportEUFormNodesAndSchema, importFormNodesAndSchema, originWarehouseFormNodesAndSchema, outboundProcessOverviewFormNodesAndSchema, processOverviewFormNodesAndSchema, transportUKFormNodesAndSchema, } from "../schemas/outbound-process";
export const OUTBOUND_PROCESS = {
    outbound_process: {
        initial: "overview",
        id: "outbound_process",
        meta: {
            eventName: "OUTBOUND_PROCESS",
            label: "onboarding.outboundProcesses.title",
        },
        states: {
            overview: {
                meta: {
                    label: "onboarding.overview",
                    ...outboundProcessOverviewFormNodesAndSchema,
                },
                on: {
                    SAVE: "origin_warehouse",
                    NEXT: "origin_warehouse",
                },
            },
            origin_warehouse: {
                meta: {
                    label: "onboarding.outboundProcesses.originWarehouse.title",
                    ...originWarehouseFormNodesAndSchema,
                },
                on: {
                    SAVE: "origin_warehouse",
                    NEXT: "export_EU",
                },
            },
            export_EU: {
                meta: {
                    label: "onboarding.outboundProcesses.exportEU.title",
                    ...exportEUFormNodesAndSchema,
                },
                on: {
                    SAVE: "export_EU",
                    NEXT: "transport_UK",
                },
            },
            transport_UK: {
                meta: {
                    label: "onboarding.outboundProcesses.transportUK.title",
                    ...transportUKFormNodesAndSchema,
                },
                on: {
                    SAVE: "transport_UK",
                    NEXT: "import",
                },
            },
            import: {
                meta: {
                    label: "onboarding.outboundProcesses.import.title",
                    ...importFormNodesAndSchema,
                },
                on: {
                    SAVE: "import",
                    NEXT: "delivery",
                },
            },
            delivery: {
                meta: {
                    label: "onboarding.outboundProcesses.delivery.title",
                    ...deliveryFormNodesAndSchema,
                },
                on: {
                    SAVE: "delivery",
                    NEXT: "processOverview",
                },
            },
            processOverview: {
                meta: {
                    label: "Process overview",
                    ...processOverviewFormNodesAndSchema,
                },
                on: {
                    // TODO
                    NEXT: "overview",
                },
            },
        },
    },
};
