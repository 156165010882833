import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, Input, InputGroup, Text, useToast, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "~/components/Card/Card";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import Grid from "~/components/Grid/Grid";
import { useGetKeyPermissionsQuery, useUpdateKeyMutation, } from "../../redux/keys.api";
import { CopyKeyInput } from "../CopyKeyInput/CopyKeyInput";
import { KeyPermissionSection } from "../KeyPermissionSection/KeyPermissionSection";
export const KeyEditView = ({ item, onCancel, }) => {
    const { t } = useTranslation();
    const { data: permissionsRes } = useGetKeyPermissionsQuery();
    const [updateKey, { isLoading, isError, reset, isSuccess }] = useUpdateKeyMutation();
    const toast = useToast();
    const [keyName, setKeyName] = useState(item.name);
    const handleChangeKeyName = (e) => {
        setKeyName(e.target.value);
        reset();
    };
    const getSelectedPermissions = () => {
        const permissions = {};
        item.permissions.forEach((domain) => domain.sections.forEach((section) => {
            permissions[section.name] = section.permissions.map(({ code }) => code);
        }));
        return permissions;
    };
    const [selectedPermissions, setSelectedPermissions] = useState(getSelectedPermissions());
    const handleToggleSection = (sectionName) => {
        const permissons = { ...selectedPermissions };
        if (permissons[sectionName])
            delete permissons[sectionName];
        else
            permissons[sectionName] = [];
        setSelectedPermissions(permissons);
    };
    const handleTogglePermission = (sectionName, permissionCode) => {
        const permissions = [...selectedPermissions[sectionName]];
        if (permissions.includes(permissionCode))
            permissions.splice(permissions.indexOf(permissionCode), 1);
        else
            permissions.push(permissionCode);
        setSelectedPermissions((sections) => ({
            ...sections,
            [sectionName]: permissions,
        }));
    };
    const handleSubmit = async () => {
        let newPermissions = [];
        Object.values(selectedPermissions).forEach((permissionCodes) => {
            newPermissions = [...newPermissions, ...permissionCodes];
        });
        const body = {
            id: item.id,
            permissions: newPermissions,
            ...(keyName !== item.name && { name: keyName }),
        };
        const res = await updateKey(body);
        if ("data" in res) {
            toast({
                title: t("apiKeys.detail.editKeySuccess"),
                status: "success",
                duration: 3000,
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            reset();
            onCancel();
        }
    }, [isSuccess]);
    const formIsInvalid = !keyName.length;
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mb: 4, children: _jsx("h3", { children: t("apiKeys.detail.generalTitle") }) }), _jsxs(Card, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("apiKeys.detail.nameLabel") }), _jsx(Input, { value: keyName, onChange: handleChangeKeyName, isInvalid: !keyName.length || isError }), isError && _jsx(ErrorMessage, { text: t("error.badRequest.duplicateKey") }), _jsx(Text, { textStyle: "labelUppercase", mb: 2, mt: 6, children: t("apiKeys.detail.apiKeyLabel") }), _jsx(InputGroup, { mb: 3, children: _jsx(CopyKeyInput, { apiKey: item.key }) })] }), _jsx(Box, { mb: 4, mt: 6, children: _jsx("h3", { children: t("apiKeys.detail.permissionsTitle") }) }), _jsxs(Card, { children: [_jsx(Text, { children: t("apiKeys.detail.editPermissionsText") }), _jsx(Grid, { gap: "4", templateColumns: "1", mt: 6, mb: 2, children: permissionsRes === null || permissionsRes === void 0 ? void 0 : permissionsRes.map((domain) => {
                            return domain.sections.map((section) => (_jsx(KeyPermissionSection, { section: section, isSelected: !!selectedPermissions[section.name], selectedPermissions: selectedPermissions[section.name], onToggleSection: () => handleToggleSection(section.name), onTogglePermission: (code) => handleTogglePermission(section.name, code) }, `permission-section-${section.name}}`)));
                        }) })] }), _jsxs(Flex, { mt: 8, justifyContent: "space-between", alignItems: "center", children: [_jsx(Button, { variant: "outline", size: "lg", onClick: onCancel, isDisabled: isLoading, colorScheme: "button.dark", background: "white", children: t("apiKeys.detail.cancelEditCTA") }), _jsx(Button, { size: "lg", onClick: handleSubmit, isLoading: isLoading, isDisabled: formIsInvalid, children: t("apiKeys.detail.saveKeyCTA") })] })] }));
};
