import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet, useLocation, useMatch, useNavigate, } from "@tanstack/react-location";
import { useEffect } from "react";
import { useGetOnboardingFlow } from "~/api/hooks/onboarding";
import { useAuthDataQuery } from "~/api/hooks/users";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { SuccessManager } from "~/components/SuccessManager/SuccessManager";
import { useGetClientByIdQuery } from "~/pages/clients/redux/clients.api";
import StepsList from "./components/steps-list";
import StepsOverview from "./components/steps-overview";
function OnBoardingLayout() {
    const { pathname: currentPathname } = useLocation().current;
    const { pathname, params: { id, flowId }, } = useMatch();
    const navigate = useNavigate();
    const clientId = Number(id);
    const { data: authData } = useAuthDataQuery();
    const { data: flow } = useGetOnboardingFlow(clientId, flowId);
    const { data: client, isFetching: isFetchingClient, isError, } = useGetClientByIdQuery(clientId);
    useEffect(() => {
        var _a, _b;
        // if user is another client than the one of the flow, redirect to home
        if (flow && authData) {
            if ((((_a = authData.user) === null || _a === void 0 ? void 0 : _a.userType) === AccountTypes.CLIENT &&
                flow.accountId !== ((_b = authData.user) === null || _b === void 0 ? void 0 : _b.accountId)) ||
                isError) {
                navigate({ to: "/" });
            }
        }
    }, [authData, flow, isError]);
    // on the landingpage, we display the steps overview as the list is present anyways
    const isLandingPage = currentPathname === pathname;
    return (_jsxs(Grid, { py: "40px", px: "50px", boxShadow: "inset 0 10px 10px -10px rgba(0, 0, 0, 0.25)", gap: "40px", templateColumns: "repeat(12, 1fr)", children: [_jsx(GridItem, { colSpan: 8, children: _jsx(Outlet, {}) }), _jsx(GridItem, { colSpan: 4, children: _jsxs(Grid, { gap: 8, children: [_jsx(GridItem, { children: (client === null || client === void 0 ? void 0 : client.responsible) && (_jsx(SuccessManager, { responsible: client.responsible, isLoading: isFetchingClient })) }), _jsx(GridItem, { children: isLandingPage ? (_jsx(StepsOverview, {})) : (_jsx(StepsList, { countryCode: (flow === null || flow === void 0 ? void 0 : flow.countryCode) || "CH" }, (flow === null || flow === void 0 ? void 0 : flow.countryCode) || "CH")) })] }) })] }));
}
export default OnBoardingLayout;
