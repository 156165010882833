import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterMultiselect } from "~/components/FilterBar/components/FilterMultiselect/FilterMultiselect";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { Table } from "~/components/Table/Table";
import { WithPermission } from "~/components/WithPermission/WithPermission";
import { SHIP_TO_COUNTRIES } from "~/consts/countries";
import { translatedCountryName } from "~/helpers/country-codes";
import { useParamFilter } from "~/hooks/useParamFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { OrderDirection } from "~/types/pagination.type";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { ClientCountryCell } from "../../components/ClientCountryCell/ClientCountryCell";
import { ClientInfoOverlay } from "../../components/ClientInfoOverlay/ClientInfoOverlay";
import { StatusRow } from "../../components/ClientStatusCell/ClientStatusCell";
import { CreateClientModal } from "../../components/CreateClientModal/CreateClientModal";
import { useGetAllClientsQuery, useGetClientFiltersQuery, } from "../../redux/clients.api";
export const ClientOverviewPage = () => {
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [filteredAssignees, setFilteredAssignees] = useParamFilter("assignee");
    const [filteredCountries, setFilteredCountries] = useParamFilter("countries");
    const [filteredStatuses, setFilteredStatuses] = useParamFilter("status");
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [showCreateClientModal, setShowCreateClientModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const { t } = useTranslation();
    const { data: clients, isLoading: isLoadingClients, isFetching: isFetchingClients, } = useGetAllClientsQuery({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        searchQuery,
        assigneeEmail: filteredAssignees,
        status: filteredStatuses,
        countries: filteredCountries,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
    });
    const { data: filters, isLoading: isFetchingFilters } = useGetClientFiltersQuery();
    const headerActions = (_jsx(WithPermission, { permission: [
            EPermissionCodes.CLIENTS_ACCOUNTS_CREATE,
            EPermissionCodes.CLIENTS_ACCOUNTS_MANAGE,
        ], children: _jsx(Button, { onClick: () => setShowCreateClientModal(true), children: t("clients.createClientCTA") }) }));
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: () => t("clients.table.nameHeader"),
            size: 200,
        }),
        columnHelper.accessor("shipToCountry", {
            header: () => t("clients.table.countryHeader"),
            cell: ({ row }) => ClientCountryCell(row.original),
            enableSorting: false,
            size: 160,
        }),
        columnHelper.accessor("responsible", {
            header: () => t("clients.table.responsibleHeader"),
            enableSorting: false,
        }),
        columnHelper.accessor("status", {
            header: () => t("clients.table.statusHeader"),
            cell: ({ row }) => StatusRow(row.original),
            size: 140,
        }),
    ];
    const countryOptions = SHIP_TO_COUNTRIES.map((value) => ({
        title: translatedCountryName(value),
        value,
    })) || [];
    const statusOptions = (filters === null || filters === void 0 ? void 0 : filters.statuses.map((value) => ({
        title: t(`accountStatus.${value}`),
        value,
    }))) || [];
    return (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { actions: headerActions, title: t("clients.pageHeadline") }), _jsxs(ContentSection, { children: [_jsx(FilterBar, { children: _jsxs(Flex, { gap: 4, alignItems: "center", children: [_jsx(FilterSearch, { value: search, setValue: setSearch, disabled: isFetchingFilters }), _jsx(FilterMultiselect, { disabled: isFetchingFilters, options: countryOptions, selectedOptions: filteredCountries.length ? filteredCountries.split(",") : [], setSelectedOptions: (countries) => setFilteredCountries(countries.join(",")), placeholder: t("clients.countriesFilterPlaceholder"), menuLabel: t("clients.countriesFilterHeadline"), selectedKey: "clients.countriesFilterCount" }), _jsx(FilterMultiselect, { disabled: isFetchingFilters, options: statusOptions, selectedOptions: filteredStatuses.length ? filteredStatuses.split(",") : [], setSelectedOptions: (statuses) => setFilteredStatuses(statuses.join(",")), placeholder: t("clients.statusFilterPlaceholder"), menuLabel: t("clients.statusFilterHeadline"), selectedKey: "clients.statusFilterCount" })] }) }), _jsx(Table, { columns: columns, data: clients, onRowClick: (row) => setSelectedClient(row.original.id), sorting: sorting, onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: setPagination, isLoading: isLoadingClients, isFetching: isFetchingClients })] }), _jsx(CreateClientModal, { show: showCreateClientModal, handleHide: () => setShowCreateClientModal(false) }), _jsx(ClientInfoOverlay, { clientId: selectedClient, onClose: () => setSelectedClient(null) })] }));
};
