import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Alert, AlertIcon, Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useState } from "react";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import Image from "~/components/Image/Image";
import { InboundCompleteOverview } from "~/process-overviews/inbound-process/overview";
import { OutBoundPCompleteProcess } from "~/process-overviews/outbound-process/overview";
import * as inbound from "~/process-overviews/inbound-process";
import * as outbound from "~/process-overviews/outbound-process";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import { hasAllFieldsFilled } from "~/process-overviews/helpers";
export function GoLive() {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const { params: { id, flowId }, } = useMatch();
    const { data: flowFields } = useGetOnboardingFieldsFlows(id, flowId);
    return (_jsxs(Box, { py: "28px", px: "40px", h: "full", children: [_jsx(FormHeader, { title: t("onboarding.goLive.title"), subtitle: t("onboarding.goLive.subtitle") }), _jsxs(FormBody, { children: [_jsxs(HStack, { children: [_jsx(Text, { mb: "4", 
                                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                dangerouslySetInnerHTML: {
                                    __html: t("onboarding.goLive.description"),
                                } }), _jsx(Image, { name: "go-live", mimeType: "png", alt: t("login.imageAlt"), width: "20%" })] }), !hasAllFieldsFilled(inbound.default, flowFields) && tabIndex === 0 && (_jsxs(Alert, { status: "warning", children: [_jsx(AlertIcon, {}), t("onboarding.outboundProcesses.completeOverview.alert")] })), !hasAllFieldsFilled(outbound.default, flowFields) &&
                        tabIndex === 1 && (_jsxs(Alert, { status: "warning", children: [_jsx(AlertIcon, {}), t("onboarding.inboundProcesses.completeOverview.alert")] }))] }), _jsxs(Tabs, { onChange: (index) => setTabIndex(index), children: [_jsxs(TabList, { children: [_jsx(Tab, { children: t("onboarding.outboundProcesses.title") }), _jsx(Tab, { children: t("onboarding.inboundProcesses.title") })] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(OutBoundPCompleteProcess, {}) }), _jsx(TabPanel, { children: _jsx(InboundCompleteOverview, {}) })] })] }), _jsx(FormFooter, { isBackVisible: false, isCompleted: true })] }));
}
