import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Flex, Menu, MenuButton, MenuList, Skeleton, Text, Tooltip, } from "@chakra-ui/react";
import { useMemo } from "react";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { useGetUserByEmailQuery } from "~/pages/users/redux/users.api";
const AssigneeListOption = ({ user, handleClick, selectedOptions, }) => {
    const { data: fetchedUser, isFetching: isFetchingUser } = useGetUserByEmailQuery(user.email, {
        skip: !user.email,
    });
    const userName = fetchedUser
        ? [fetchedUser.firstName, fetchedUser.lastName].join(" ")
        : user.email;
    const name = user && "companyName" in user
        ? `${userName} (${user.companyName})`
        : !!user.firstName && !!user.lastName
            ? [user.firstName, user.lastName].join(" ")
            : user.email;
    const displayName = "firstName" in user && "lastName" in user
        ? `${user.firstName[0]}${user.lastName[0]}`
        : fetchedUser
            ? (fetchedUser.firstName[0] + fetchedUser.lastName[0]).toLocaleUpperCase()
            : `${user.email.split("@")[0][0]}${user.email.split("@")[1][0]}`;
    return (_jsx(Flex, { py: "1.5", children: _jsx(Checkbox, { textStyle: "body", isChecked: selectedOptions.includes(user.email), onChange: handleClick, children: isFetchingUser ? (_jsx(Skeleton, { w: "10", h: "5" })) : (_jsxs(Flex, { children: [_jsx(Flex, { justifyContent: "center", alignItems: "center", w: "5", h: "5", cursor: "pointer", boxSizing: "content-box", rounded: "full", bgColor: "userType" in user && user.userType === AccountTypes.PARTNER
                            ? "brand.sky"
                            : "brand.mare", border: "2px solid", color: "white", fontSize: "10", fontWeight: "500", mr: "1", children: displayName }), _jsx(Text, { children: name })] })) }, `user-dd-option--${user.email}`) }));
};
const AssigneeOption = ({ user, handleClick, selectedOptions, }) => {
    const optionsSelected = !!selectedOptions.length;
    const isSelected = selectedOptions.indexOf(user.email) !== -1;
    const { data: fetchedUser, isFetching: isFetchingUser } = useGetUserByEmailQuery(user.email, {
        skip: !user.email,
    });
    const userName = fetchedUser
        ? [fetchedUser.firstName, fetchedUser.lastName].join(" ")
        : user.email;
    const name = user && "companyName" in user
        ? `${userName} (${user.companyName})`
        : !!user.firstName && !!user.lastName
            ? [user.firstName, user.lastName].join(" ")
            : user.email;
    const displayName = "firstName" in user && "lastName" in user
        ? `${user.firstName[0]}${user.lastName[0]}`
        : fetchedUser
            ? (fetchedUser.firstName[0] + fetchedUser.lastName[0]).toLocaleUpperCase()
            : `${user.email.split("@")[0][0]}${user.email.split("@")[1][0]}`;
    return (_jsx(Tooltip, { hasArrow: true, bg: "brand.black", placement: "top", label: name, children: isFetchingUser ? (_jsx(Skeleton, { w: "9", h: "9", m: 0.5, rounded: "full" })) : (_jsx(Flex, { justifyContent: "center", alignItems: "center", w: "8", h: "8", cursor: "pointer", boxSizing: "content-box", rounded: "full", bgColor: "userType" in user && user.userType === AccountTypes.PARTNER
                ? "brand.sky"
                : "brand.mare", opacity: isSelected || !optionsSelected ? 1 : 0.4, border: "2px solid", borderColor: isSelected ? "white" : "transparent", color: "white", boxShadow: isSelected ? "0 0 0 2px #0092D4" : "", m: 0.5, fontSize: "sm", fontWeight: "500", onClick: handleClick, children: displayName })) }));
};
export const AssigneeFilter = ({ selectedOptions, options, setSelectedOptions, }) => {
    const showAllOptions = options.length <= 6;
    const handleSelect = (email) => {
        const newOptions = [...selectedOptions];
        const i = newOptions.indexOf(email);
        if (i === -1)
            newOptions.push(email);
        else
            newOptions.splice(i, 1);
        setSelectedOptions(newOptions);
    };
    const hasSelectedFromDropdown = useMemo(() => {
        let hasSelected = false;
        if (!showAllOptions) {
            const hiddenOptions = options.slice(5);
            hiddenOptions.forEach((option) => {
                if (selectedOptions.includes(option.email)) {
                    hasSelected = true;
                }
            });
        }
        return hasSelected;
    }, [selectedOptions]);
    return (_jsx(Flex, { gap: 2, children: showAllOptions ? (options.map((user) => (_jsx(AssigneeOption, { handleClick: () => handleSelect(user.email), user: user, selectedOptions: selectedOptions }, `user-option--${user.email}`)))) : (_jsxs(_Fragment, { children: [options.slice(0, 5).map((user) => (_jsx(AssigneeOption, { handleClick: () => handleSelect(user.email), user: user, selectedOptions: selectedOptions }, `user-option--${user.email}`))), _jsxs(Menu, { children: [_jsx(MenuButton, { children: _jsxs(Flex, { justifyContent: "center", alignItems: "center", w: "8", h: "8", cursor: "pointer", boxSizing: "content-box", rounded: "full", border: "2px solid", borderColor: "brand.mare", color: "brand.mare", fontSize: "sm", boxShadow: hasSelectedFromDropdown ? "0 0 0 2px #0092D4" : "none", fontWeight: "500", children: ["+", options.length - 5] }) }), _jsx(MenuList, { p: "4", children: options.slice(5).map((user) => (_jsx(AssigneeListOption, { handleClick: () => handleSelect(user.email), user: user, selectedOptions: selectedOptions }, `user-list-option--${user.email}`))) })] })] })) }));
};
