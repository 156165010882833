import { ONBOARDING_V2_BASEURL, api } from "~/store/api";
export const onboardingBaseUrl = `${ONBOARDING_V2_BASEURL}/clients`;
export const onboardingApi = api
    .enhanceEndpoints({ addTagTypes: ["ONBOARDING", "ONBOARDING_STATUS"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getOnboardingStatus: builder.query({
            query: ({ clientId, flowId }) => ({
                url: `${onboardingBaseUrl}/${clientId}/onboarding-flows/${flowId}/status`,
            }),
            providesTags: ["ONBOARDING_STATUS"],
        }),
    }),
});
export const { useGetOnboardingStatusQuery } = onboardingApi;
