import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import Grid from "~/components/Grid/Grid";
import { OnboardingFormFooter } from "~/components/OnboardingFormFooter/OnboardingFormFooter";
import { OnboardingFormHeader } from "~/components/OnboardingFormHeader/OnboardingFormHeader";
import { ServiceCode } from "~/enums/service-code.enum";
import { useLazyGetClientServicesQuery } from "~/pages/clients/redux/clients.api";
import { useGetOnboardingFlow } from "~/api/hooks/onboarding";
import { Card } from "~/components/Card/Card";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { translatedCountryName } from "~/helpers/country-codes";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
export const CustomsClassification = () => {
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const clientId = Number(id);
    const { data: flow } = useGetOnboardingFlow(clientId, flowId);
    const [fetchServices, { data: services, isSuccess, isError }] = useLazyGetClientServicesQuery();
    useEffect(() => {
        if (flow)
            fetchServices({
                clientId,
                service: ServiceCode.TARIFFING,
                shipTo: flow.countryCode,
            });
    }, [flow]);
    const shipToServices = (services === null || services === void 0 ? void 0 : services.filter((service) => service.serviceData.direction === ShipDirection.TO)) || [];
    const shipFromServices = (services === null || services === void 0 ? void 0 : services.filter((service) => service.serviceData.direction === ShipDirection.FROM)) || [];
    const renderServices = (direction) => {
        const isShipTo = direction === ShipDirection.TO;
        const finishedLoading = isSuccess || isError;
        const items = isShipTo ? shipToServices : shipFromServices;
        if (!finishedLoading)
            return _jsx(Skeleton, { w: "full", h: "72px" });
        return items.length ? (_jsx(Grid, { gap: "4", templateColumns: "1fr", children: items === null || items === void 0 ? void 0 : items.map((service) => {
                const countryCode = (isShipTo
                    ? service.serviceData.shipTo
                    : service.serviceData.shipFrom) || "";
                return (_jsxs(Card, { children: [_jsxs(Flex, { alignItems: "center", mb: "6", children: [_jsx(FlagIcon, { boxSize: "30", countryCode: countryCode }), _jsx(Text, { textStyle: "h4", ml: "14px", children: translatedCountryName(countryCode) })] }), _jsx(Text, { textStyle: "labelUppercase", mb: "2", children: t("onboarding.productDetails.customsClassification.servicesHeader") }), _jsx(Text, { children: service.serviceData.services
                                .map((s) => {
                                var _a;
                                switch (s.code) {
                                    case PartnerServiceCode.INITIAL_CLASSIFICATION:
                                        return t("clients.detail.services.tariffing.initialClassification");
                                    case PartnerServiceCode.CONTINUOUS_CLASSIFICATION:
                                        return t("clients.detail.services.tariffing.continuousClassification", {
                                            hours: (_a = s.data) === null || _a === void 0 ? void 0 : _a.timeWindow,
                                        });
                                    default:
                                        return "";
                                }
                            })
                                .join(", ") })] }));
            }) })) : (_jsx(Card, { children: t("onboarding.productDetails.customsClassification.noServiceMessage") }));
    };
    const HeaderComponent = (_jsx(Banner, { text: t("onboarding.productDetails.customsClassification.notice") }));
    return (_jsxs(_Fragment, { children: [_jsx(OnboardingFormHeader, { title: t("onboarding.productDetails.customsClassification.title"), subtitle: t("onboarding.productDetails.customsClassification.subtitle"), description: t("onboarding.productDetails.customsClassification.info"), component: HeaderComponent }), _jsxs(Box, { mb: "10", children: [_jsx(Text, { textStyle: "h3", mb: "4", children: t("onboarding.productDetails.customsClassification.shipToTitle") }), renderServices(ShipDirection.TO)] }), _jsxs(Box, { mb: "10", children: [_jsx(Text, { textStyle: "h3", mb: "4", children: t("onboarding.productDetails.customsClassification.shipFromTitle") }), renderServices(ShipDirection.FROM)] }), _jsx(OnboardingFormFooter, { sendPageAsSeen: true, pageSlug: "customsClassification", processSlug: "productDetails" })] }));
};
