import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returnClosure.title",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "often-returns",
                options: {
                    pt: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label2",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnPickUp.pickUpFrequency",
                    },
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnClosure.oftenReturnsCompleted",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "often-returns-time",
                                label: "onboarding.inboundProcesses.returnClosure.afterCertainTimeWindow",
                                value: "after_a_certain_time_window",
                                forKey: "often-returns",
                            },
                            {
                                type: "radio",
                                key: "often-returns-qtd",
                                label: "onboarding.inboundProcesses.returnClosure.minimumQuantityPallets",
                                value: "when_a_minimum_quantity_of_pallets_is_reached",
                                forKey: "often-returns",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                        },
                        elements: [
                            {
                                key: "certain-time-dropdown",
                                type: "select",
                                label: "Please select",
                                options: {
                                    daily: "onboarding.forms.frequency.daily",
                                    fourTimesByWeek: "onboarding.forms.frequency.4xweek",
                                    threeTimesByWeek: "onboarding.forms.frequency.3xweek",
                                    twoTimesByWeek: "onboarding.forms.frequency.2xweek",
                                    weekly: "onboarding.forms.frequency.weekly",
                                    lessThanOnceByWeek: "onboarding.forms.frequency.lessThankWeekly",
                                    onceInByMonth: "onboarding.forms.frequency.monthly",
                                    lessThanOneByMonth: "onboarding.forms.frequency.lessThanMonthly",
                                },
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "often-returns",
                            targetValue: "after_a_certain_time_window",
                        },
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                        },
                        elements: [
                            {
                                key: "min-qtd-number",
                                type: "text",
                                isTextarea: true,
                                label: "onboarding.inboundProcesses.returnClosure.number",
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "often-returns",
                            targetValue: "when_a_minimum_quantity_of_pallets_is_reached",
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h3",
                label: "onboarding.inboundProcesses.returnClosure.systemForClosure",
            },
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "system-for-returns-closure",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnClosure.whichSystemForClosure",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "vis-magic",
                                label: "onboarding.inboundProcesses.returnClosure.visMagic",
                                value: "vis-magic",
                                forKey: "system-for-returns-closure",
                            },
                            {
                                type: "radio",
                                key: "as400",
                                label: "onboarding.inboundProcesses.returnClosure.as400",
                                value: "as400",
                                forKey: "system-for-returns-closure",
                            },
                            {
                                type: "radio",
                                key: "own-system",
                                label: "onboarding.inboundProcesses.returnClosure.ownSystem",
                                value: "own-system",
                                forKey: "system-for-returns-closure",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                        },
                        elements: [
                            {
                                key: "name-of-the-system",
                                type: "text",
                                isTextarea: true,
                                label: "onboarding.inboundProcesses.returnProcessing.productsNotIdentified.nameOfSystem",
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "system-for-returns-closure",
                            targetValue: "own-system",
                        },
                    },
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnClosure.toWhomSendAVI",
                    },
                    {
                        key: "email",
                        type: "customComponent",
                        componentName: "base.IncrementedControlsArray",
                        componentProps: {
                            maxControlNodes: 19,
                            forKey: "company_address",
                            controlNodes: [
                                {
                                    key: "email",
                                    type: "text",
                                    label: "onboarding.companyInformation.email",
                                },
                            ],
                            incrementerButtonNode: {
                                key: "add-email",
                                type: "button",
                                variant: "text",
                                label: "onboarding.inboundProcesses.returnClosure.addAdditionalMailAddress",
                            },
                            decrementerButtonNode: {
                                key: "remove-email",
                                type: "button",
                                variant: "error",
                                label: "onboarding.inboundProcesses.returnClosure.removeAdditionalMailAddress",
                            },
                        },
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
