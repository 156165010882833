import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, FormControl, FormErrorMessage, InputGroup, } from "@chakra-ui/react";
import { forwardRef, memo, useImperativeHandle, useRef, } from "react";
function Component(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
{ name, label, value, onChange, error, placeholder, options, isChecked, ...rest }, ref) {
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);
    return (_jsxs(FormControl, { isInvalid: Boolean(error), ...rest, children: [_jsx(InputGroup, { size: "md", children: _jsx(Checkbox, { id: name, name: name, value: value, onChange: onChange, isChecked: isChecked, colorScheme: "secondary", rounded: "sm", children: label }) }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(FormErrorMessage, { children: error.message })) : null] }));
}
export default memo(forwardRef(Component));
