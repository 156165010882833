import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import { memo } from "react";
import theme from "~/theme";
export default memo(({ children, variant = "primary", ...rest }) => (_jsx(Button, { colorScheme: "primary", rounded: "none", minW: "132px", w: "auto", h: "36px", _hover: {
        bg: variant === "secondary"
            ? theme.colors.secondary[400]
            : theme.colors.primary[400],
    }, _disabled: {
        bg: variant === "secondary"
            ? theme.colors.secondary[300]
            : theme.colors.primary[300],
    }, ...rest, children: children })));
