import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { clientsApi, useCreateClientServiceMutation, } from "~/pages/clients/redux/clients.api";
import { servicesApi } from "~/pages/services/redux/services.api";
import { ShipmentForm } from "../ShipmentForm/ShipmentForm";
export const AddShipFromModal = ({ isOpen, selectedCountries, shipTo, setIsOpen, }) => {
    const { params: { id }, } = useMatch();
    const defaultFormValues = useMemo(() => ({
        shipFrom: null,
        shipTo,
        services: [],
        providedServiceId: null,
        responsibleEmployee: null,
    }), []);
    const { t } = useTranslation();
    const toast = useToast();
    const dispatch = useDispatch();
    const [service, setService] = useState(defaultFormValues);
    const [createService, { isLoading }] = useCreateClientServiceMutation();
    useEffect(() => {
        if (isOpen)
            setService(defaultFormValues);
    }, [isOpen]);
    const handleSubmit = async () => {
        if (service) {
            const { responsibleEmployee, providedServiceId, ...serviceData } = service;
            const params = {
                clientId: Number(id),
                serviceCode: ServiceCode.TARIFFING,
                serviceData: { ...serviceData, direction: ShipDirection.FROM },
                responsibleEmployee,
                providedServiceId: Number(providedServiceId),
            };
            const res = await createService(params);
            if ("data" in res) {
                toast({
                    title: t("clients.detail.services.tariffingModal.successAdding"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
                dispatch(clientsApi.util.invalidateTags(["CLIENT_SERVICES"]));
                dispatch(servicesApi.util.invalidateTags(["SERVICE"]));
            }
        }
    };
    const handleUpdateService = (newService) => {
        setService(newService);
    };
    const primaryActionDisabled = !(service === null || service === void 0 ? void 0 : service.responsibleEmployee);
    return (_jsx(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("clients.detail.services.tariffingModal.addShipFromTitle"), primaryTitle: t("basics.save"), primaryAction: handleSubmit, primaryActionDisabled: primaryActionDisabled, primaryActionLoading: isLoading, children: _jsx(Box, { mt: 8, children: _jsx(ShipmentForm, { selectedCountries: selectedCountries, service: service, setService: handleUpdateService, type: ShipDirection.FROM, shipTo: shipTo }) }) }));
};
