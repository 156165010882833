import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, InputGroup, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Card } from "~/components/Card/Card";
import { CopyKeyInput } from "../CopyKeyInput/CopyKeyInput";
export const KeyDetailView = ({ item, onDelete, }) => {
    const { t } = useTranslation();
    const hasKeys = !!item.permissions.length;
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mb: 4, children: _jsx("h3", { children: t("apiKeys.detail.generalTitle") }) }), _jsxs(Card, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("apiKeys.detail.apiKeyLabel") }), _jsx(InputGroup, { children: _jsx(CopyKeyInput, { apiKey: item.key }) }), _jsx(Text, { textStyle: "labelUppercase", mb: 2, mt: 6, children: t("apiKeys.detail.lastUpdateLabel") }), _jsx(Text, { mb: 3, color: "grey.60", children: t("apiKeys.detail.keyUpdatedBy", {
                            updatedBy: item.updatedBy.name,
                            updatedAt: format(new Date(item.updatedAt), "dd.MM.yyyy, HH:mm"),
                        }) })] }), _jsx(Box, { mb: 4, mt: 6, children: _jsx("h3", { children: t("apiKeys.detail.permissionsTitle") }) }), _jsx(Card, { children: _jsxs(Grid, { gap: 6, mb: 3, children: [_jsx(Text, { children: hasKeys
                                ? t("apiKeys.detail.permissionsText")
                                : t("apiKeys.detail.noPermissionsText") }), hasKeys &&
                            item.permissions.map((domain) => {
                                return domain.sections.map((section) => (_jsxs(Box, { children: [_jsx("h4", { children: t(`permissions.sections.${section.name}`) }), _jsx(Text, { mt: 2, children: section.permissions
                                                .map((permission) => t(`permissions.codes.${permission.code}`))
                                                .join(", ") })] })));
                            })] }) }), _jsx(Box, { mt: 8, children: _jsx(Button, { variant: "danger", size: "lg", onClick: onDelete, children: t("apiKeys.detail.deleteKeyCTA") }) })] }));
};
