import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { customsClassificationApi, useDeleteImportedArticlesMutation, } from "~/pages/customs-classification/redux/customsClassification.api";
import { TableSelectionMode } from "~/types/table-selection.type";
export const DeleteImportedArticlesModal = ({ isOpen, setIsOpen, articleIds, selectionMode, totalItems }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { params: { id }, } = useMatch();
    const [deleteImportedArticles, { isLoading }] = useDeleteImportedArticlesMutation();
    const handleDelete = async () => {
        try {
            await deleteImportedArticles({
                id,
                params: { articleIds: articleIds.join(","), type: selectionMode },
            });
            toast({
                title: t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.success"),
                status: "success",
                duration: 3000,
            });
            dispatch(customsClassificationApi.util.invalidateTags([
                "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
            ]));
            setIsOpen(false);
        }
        catch (err) {
            toast({
                title: t("error.badRequest.deleteArticle"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const articleCount = selectionMode === TableSelectionMode.ALL
        ? totalItems
        : selectionMode === TableSelectionMode.EXCLUDE
            ? totalItems - articleIds.length
            : articleIds.length;
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.title"), primaryVariant: "danger", primaryTitle: t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.primaryCTA"), primaryAction: handleDelete, primaryActionLoading: isLoading, children: [_jsx(Text, { mt: 2, children: articleCount > 1
                    ? t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.pluralSubtitle", { count: articleCount })
                    : t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.subtitle") }), _jsx(Box, { mt: 6, px: 4, py: 3, backgroundColor: "warning.20", borderColor: "warning.40", borderWidth: "1px", borderRadius: "4px", children: _jsx(Text, { children: t("customsClassification.tabs.errorCases.detail.deleteImportedArticlesModal.note") }) })] }));
};
