import { useMatch } from "@tanstack/react-location";
import { useGetOnboardingFlow } from "~/api/hooks/onboarding";
import i18n from "~/i18n";
export default function exportCh() {
    const when = "-";
    const { params: { id, flowId }, } = useMatch();
    const { data: flow } = useGetOnboardingFlow(Number(id), flowId);
    const who = i18n.t("processOverview.msDirect");
    const what = `${i18n.t("processOverview.inboundProcess.exportCh.export")}
    <br>${i18n.t("processOverview.inboundProcess.exportCh.preparation")}`;
    return {
        when,
        who,
        what,
        icon: "exporteu",
        title: (flow === null || flow === void 0 ? void 0 : flow.countryCode) === "GB"
            ? i18n.t("processOverview.inboundProcess.exportUk.title")
            : i18n.t("processOverview.inboundProcess.exportCh.title"),
        day: "1-2",
    };
}
