import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Box } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import ProcessOverviewItem from "~/components/ProcessOverviewItem/ProcessOverviewItem";
import ProcessOverviewItemEmpty from "~/components/ProcessOverviewItemEmpty/ProcessOverviewItemEmpty";
import processOverviews, { hasAllFieldsFilled, } from "~/process-overviews/inbound-process";
export function InboundCompleteOverview() {
    const { params: { id, flowId }, } = useMatch();
    const { data: flowFields } = useGetOnboardingFieldsFlows(id, flowId);
    let processOverviewItems = (_jsx(Box, { mt: "8", mb: "8", children: processOverviews.map((overview) => {
            return (_createElement(ProcessOverviewItem, { ...overview(flowFields), key: overview(flowFields).title }));
        }) }));
    if (!hasAllFieldsFilled(processOverviews, flowFields)) {
        processOverviewItems = _jsx(ProcessOverviewItemEmpty, {});
    }
    return (_jsx(Box, { mt: "8", mb: "8", children: processOverviewItems }));
}
