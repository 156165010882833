import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { useGetArticleNotesByIdQuery } from "~/pages/customs-classification/redux/customsClassification.api";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { checkAccountType } from "~/utils/checkAccountType";
import { ArticleDrawerContext } from "./ImportArticleDrawer";
import { NewNoteField } from "./NewNoteField";
import { NoteItem } from "./NoteItem";
export const ArticleNotesTab = ({ isPendingUpload }) => {
    const { importId, articleId } = useContext(ArticleDrawerContext);
    const { data: notes } = useGetArticleNotesByIdQuery({
        articleId,
        importId,
    });
    return (_jsxs(Box, { pt: "8", children: [_jsx(Box, { px: "10", children: notes === null || notes === void 0 ? void 0 : notes.data.map((note) => (_jsx(NoteItem, { note: note }, `note-item-${note.id}`))) }), !isPendingUpload && !checkAccountType(AccountTypes.CLIENT) && (_jsx(NewNoteField, {}))] }));
};
