import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useCallback, useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SignatureStatus, SignatureTemplateNames, } from "@crossborderx/schemas-package";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useGetSignatureQuery } from "~/api/hooks/signatures";
import Infobox from "~/components/InfoBox/InfoBox";
import { SignatureComponent } from "~/components/Signature/Signature";
export function ApplicationVatUidTaxSignDocuments() {
    const signatureTemplate = SignatureTemplateNames.FiscalSwissRepresentation;
    const { t } = useTranslation();
    const [signature, setSignature] = useState({
        status: SignatureStatus.Unsigned,
    });
    const navigate = useNavigate();
    const { params: { id: clientId, flowId }, } = useMatch();
    const [createNew, setToCreateNewSignature] = useState(false);
    const handleOnSignatureData = useCallback((data) => {
        if (flowId && (data === null || data === void 0 ? void 0 : data.status) === SignatureStatus.Signed) {
            // signature has been signed
            navigate({
                to: `/clients/${clientId}/onboarding/${flowId}/administrative-processes/tax-documents-sign-info`,
            });
        }
        else {
            // signature is not signed and has just been refreshed
            setSignature(data);
        }
    }, []);
    const { data: signatureData, isFetched } = useGetSignatureQuery(clientId, flowId, signatureTemplate, !signature.objectUrl);
    useEffect(() => {
        if (isFetched) {
            if (signatureData)
                setSignature(signatureData);
            else {
                setSignature({
                    status: SignatureStatus.Unsigned,
                });
                setToCreateNewSignature(true);
            }
        }
    }, [isFetched]);
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.taxDocuments.headline"), subtitle: t("onboarding.vat.flow.taxDocuments.subtitle"), description: t("onboarding.vat.flow.taxDocuments.description") }), _jsx(FormBody, { gap: "0", children: _jsxs(Flex, { direction: "row", justifyContent: "space-between", children: [_jsx(Flex, { direction: "column", w: { md: "100%", xl: "64%" }, pr: { xl: "20px" }, justifyContent: "space", gap: "5", p: "5", overflow: "hidden", children: _jsxs(Box, { flex: "1", children: [_jsx(Text, { fontSize: "2xl", children: t("onboarding.vat.flow.taxDocuments.signStatementTitle") }), _jsx(Text, { children: t("onboarding.vatNumberAndCustomsAccount.unsigned.signStatementDescription") }), _jsx(SignatureComponent, { templateName: signatureTemplate, onSignatureData: handleOnSignatureData, signature: signature, createNew: createNew })] }) }), _jsx(Infobox, { infoText: "onboarding.vat.flow.taxDocuments" })] }) }), _jsx(FormFooter, { isBackVisible: false, isSaveForLaterVisible: true, isResetVisible: true, vatPage: "2.3", nextUrl: `/clients/${clientId}/onboarding/${flowId}/administrative-processes/tax-documents-sign-info` })] }));
}
