import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import clientSideConfig from "~/client-side-config";
import { useAuthDataQuery } from "./users";
// Doc: https://mvpfactory.atlassian.net/wiki/spaces/MSDCX2/pages/3371991043/SSO+Integration+with+Shell+UI+using+fetch
function getMvcAppRootUrl(app) {
    if (app === "gatewayCH") {
        return clientSideConfig.msGatewayChRootUrl;
    }
    if (app === "gatewayUK") {
        return clientSideConfig.msGatewayUkRootUrl;
    }
    if (app === "clarify") {
        return clientSideConfig.msClarifyRootUrl;
    }
    throw new Error("App not recognized.");
}
export function useClearMvcAppCookieMutation() {
    const queryClient = useQueryClient();
    const apps = ["gatewayCH", "gatewayUK", "clarify"];
    return useMutation(async () => {
        const results = await Promise.all(apps.map((app) => fetch(`${getMvcAppRootUrl(app)}api/${app === "clarify" ? "crossborderx/" : ""}cookies/clear?token=1&lang=1`)));
        return results.every((result) => result.ok);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["mvc_cookie"]);
        },
    });
}
export function useMvcAppCookieQuery(app, isFetchEnabled) {
    const { i18n } = useTranslation();
    const { data: authData } = useAuthDataQuery();
    const lang = i18n.resolvedLanguage;
    const legacyToken = authData === null || authData === void 0 ? void 0 : authData.legacyToken;
    return useQuery(["mvc_cookie", legacyToken, lang, app], async () => {
        if (legacyToken) {
            return fetch(`${getMvcAppRootUrl(app)}api/${app === "clarify" ? "crossborderx/" : ""}cookies/set?lang=${encodeURIComponent(lang)}&token=${encodeURIComponent(legacyToken)}`, {
                mode: "no-cors",
                credentials: "include",
            });
        }
        return true;
    }, { enabled: isFetchEnabled });
}
