import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE } from "~/consts/customs-classification-cases-max-size";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { clientsApi, useUploadClientDocumentMutation, } from "../../redux/clients.api";
export const UploadDocumentModal = ({ isOpen, setIsOpen }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useAppSelector(authSelector);
    const [uploadFile, { isLoading: isUploading, isError }] = useUploadClientDocumentMutation();
    const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE,
    });
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };
    const handleFileUpload = async () => {
        if (user === null || user === void 0 ? void 0 : user.accountId) {
            const formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            formData.append("group", "onboarding");
            try {
                const res = await uploadFile({
                    clientId: user.accountId,
                    body: formData,
                }).unwrap();
                if (res) {
                    dispatch(clientsApi.util.invalidateTags(["CLIENT_DOCUMENTS"]));
                    toast({
                        title: t("clients.detail.documents.uploadDocumentModal.success"),
                        status: "success",
                        duration: 3000,
                    });
                    setIsOpen(false);
                }
            }
            catch (err) { } // eslint-disable-line no-empty
        }
    };
    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("clients.detail.documents.uploadDocumentModal.title"), primaryTitle: t("clients.detail.documents.uploadDocumentModal.cta"), primaryAction: handleFileUpload, primaryActionDisabled: !acceptedFiles.length, primaryActionLoading: isUploading, children: [_jsxs(Flex, { direction: "column", alignItems: "center", justifyContent: "center", flex: 1, gap: 3, mt: 4, minHeight: 24, backgroundColor: "sky.10", borderRadius: "4px", borderColor: "brand.sky", borderWidth: "2px", borderStyle: "dashed", cursor: "pointer", ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), acceptedFiles.length ? (_jsx(Text, { children: acceptedFiles[0].name })) : (_jsx(Text, { children: _jsx(Trans, { components: {
                                cta: _jsx(Text, { as: "span", color: "brand.sky", fontWeight: "700" }),
                            }, i18nKey: "clients.detail.documents.uploadDocumentModal.dragAndDropZone.description", values: {
                                CTA: t("clients.detail.documents.uploadDocumentModal.dragAndDropZone.cta"),
                            } }) }))] }), isError && (_jsx(ErrorMessage, { text: t("error.badRequest.somethingWentWrong") }))] }));
};
