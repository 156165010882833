import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Link from "~/components/Link/Link";
// adds support for using /n as a linebreak inside any InterpolatedLabel
const parseLineBreaks = (label) => {
    const splitLines = label.split("/n");
    return splitLines.length === 1 ? (_jsx("span", { children: splitLines[0] })) : (_jsx(_Fragment, { children: splitLines.map((line) => (_jsx("p", { children: line }))) }));
};
const wrapWithStrong = (el, renderString) => el.strong ? (_jsx(Text, { as: "span", fontWeight: "semibold", children: renderString(el.displayLabel) })) : (_jsx(_Fragment, { children: renderString(el.displayLabel) }));
const wrapWithLink = (el, renderString) => (_jsx(Link, { to: el.href, ...{
        target: (el.targetBlank && "_blank") || undefined,
        rel: (el.targetBlank && "noopener noreferrer") || undefined,
    }, children: wrapWithStrong(el, renderString) }));
export const parseInterpolatedLabel = (label, t) => {
    const renderString = (val) => parseLineBreaks(t(val));
    if (Array.isArray(label)) {
        return (_jsx(_Fragment, { children: label.map((el) => {
                if (typeof el === "string")
                    return _jsx(_Fragment, { children: renderString(el) });
                return el.href !== undefined
                    ? wrapWithLink({ ...el, href: el.href }, renderString)
                    : wrapWithStrong(el, renderString);
            }) }));
    }
    return _jsx(_Fragment, { children: renderString(label) });
};
export function InterpolatedLabelComponent({ node: { label, type }, }) {
    // TODO implement correct translation mechanism
    const { t } = useTranslation();
    return (_jsx(Text, { fontSize: (type === "h1" && "3xl") ||
            (type === "h2" && "2xl") ||
            (type === "h3" && "xl") ||
            undefined, children: parseInterpolatedLabel(label, t) }));
}
export default InterpolatedLabelComponent;
