import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.application_vat_uid_number",
        subtitle: "onboarding.declarationOfFiscalRepresentation.declarationOfFiscalRepresentation",
        description: "onboarding.declarationOfFiscalRepresentation.companyDetailsForOfficialTaxRepresentation",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label3",
                type: "label",
                label: [
                    "1. ",
                    "onboarding.declarationOfFiscalRepresentation.downloadDocument",
                    " ",
                    { href: "/", displayLabel: "onboarding.actions.here" },
                ],
            },
            {
                key: "files",
                type: "file",
                label: "onboarding.uploadFilesArea.files",
                multiple: true,
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        files: {
            type: "array",
            items: {
                type: "string",
            },
            // minItems: 1,
            uniqueItems: true,
        },
    },
    additionalProperties: false,
    required: ["files"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
