import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, getFormLoadingState, } from "~/store/selectors/formData.selectors";
export function OutBoundProcessTransportDetails() {
    var _a;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const { params: { id, flowId }, } = useMatch();
    const formLoading = useSelector(getFormLoadingState);
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "transportDetails",
            processSlug: "outboundProcess",
        }));
    }, []);
    const updateRadioField = (e) => {
        dispatch(patchFormField({
            fieldName: e.target.name,
            fieldValue: e.target.value,
            id,
            flowId,
            processSlug: "outboundProcess",
            pageSlug: "transportDetails",
        }));
    };
    return formLoading ? (_jsx("div", { children: "Loading" })) : (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.outboundProcesses.title"), subtitle: t("onboarding.outboundProcesses.transportUK.label"), description: t("onboarding.outboundProcesses.transportUK.description") }), _jsx(Text, { fontSize: "2xl", mt: 4, mb: 2, children: t("onboarding.outboundProcesses.transportUK.title") }), _jsx(FormBody, { children: _jsx(Flex, { wrap: "wrap", w: { md: "100%", xl: "64%" }, justifyContent: "start", gap: "5", children: _jsxs(Flex, { direction: "column", w: "full", gap: 3, children: [_jsx(Text, { fontSize: "xl", children: t("onboarding.outboundProcesses.transportUK.question") }), _jsx(RadioGroup, { defaultValue: (_a = formData === null || formData === void 0 ? void 0 : formData.transportDetails) === null || _a === void 0 ? void 0 : _a.fieldValue, children: _jsx(Stack, { direction: "column", gap: 1, children: Object.keys(t("onboarding.outboundProcesses.transportUK.options", {
                                        returnObjects: true,
                                    })).map((el) => {
                                        return (_jsx(Radio, { value: el, name: "transportDetails", onChange: updateRadioField, children: t("onboarding.outboundProcesses.transportUK.options", {
                                                returnObjects: true,
                                            })[el] }));
                                    }) }) })] }) }) }), _jsx(FormFooter, {})] }));
}
