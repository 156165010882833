import { useMemo } from "react";
import { daysCalculation } from "~/helpers/outbound-overview/days-calculation";
import i18n from "~/i18n";
export default function transport(flowFields) {
    var _a, _b, _c;
    const cutOffTime = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "cutOffTime" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const exportOrganizer = ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "exportOrganizer" &&
        data.pageSlug === "exportFromEu")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    const myselfCompanyName = ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "myselfCompanyName" &&
        data.pageSlug === "exportFromEu")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
    let what = "";
    if (exportOrganizer === "viaMS")
        what = i18n.t("processOverview.outboundProcess.transport.whatMsDirect");
    if (exportOrganizer === "myself")
        what = i18n.t("processOverview.outboundProcess.transport.whatMyself", {
            answer: myselfCompanyName,
        });
    what += `<br />${i18n.t("processOverview.outboundProcess.transport.duration")}`;
    const calculatedDay = useMemo(() => daysCalculation(flowFields), [flowFields]);
    const checkDayText = (day) => {
        switch (day) {
            case 2:
            case 5:
                return "01-02";
            case 3:
                return "02-03";
            default:
                return undefined;
        }
    };
    return {
        when: `${cutOffTime.substring(0, 2)}:${cutOffTime.substring(2, 4)}`,
        who: exportOrganizer === "myself" ? myselfCompanyName : "MS Direct",
        what,
        day: checkDayText(calculatedDay),
        icon: "transport",
        title: i18n.t("processOverview.outboundProcess.transport.title"),
    };
}
