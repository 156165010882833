import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { Box } from "@chakra-ui/react";
import { Outlet, ReactLocation, Router } from "@tanstack/react-location";
import { ReactLocationDevtools } from "@tanstack/react-location-devtools";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useADAccessTokenQuery } from "~/api/hooks/users";
import { graphApiRequest, loginRequest } from "~/auth-config";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { isProduction } from "~/consts/envs";
import { useLazyGetLegacyTokenQuery, useLazyGetMeQuery, useLazyGetMyPermissionsQuery, } from "~/pages/auth/redux/auth.api";
import { authSelector, setAuth } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { NotAuthorizedPage } from "~/components/NotAuthorizedPage/NotAuthorizedPage";
import { Index } from "~/layouts/app";
import { PUBLIC_ROUTES } from "./route.config";
import { publicRoutes, routeConfig } from "./routes";
const location = new ReactLocation();
export const Routing = () => {
    const onPublicRoute = PUBLIC_ROUTES.includes(location.current.pathname);
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const { token, user, tokenExpiry } = useAppSelector(authSelector);
    const accounts = useADAccessTokenQuery(onPublicRoute);
    const [getMe, me] = useLazyGetMeQuery();
    const [getMyPermissions, myPermissions] = useLazyGetMyPermissionsQuery();
    const [getLegacyToken, legacyToken] = useLazyGetLegacyTokenQuery();
    useEffect(() => {
        if (token) {
            getMe()
                .then(() => getMyPermissions())
                .then(() => getLegacyToken());
        }
    }, [token]);
    const loginDispatch = () => {
        if (accounts) {
            const apiRequest = {
                ...loginRequest,
                account: accounts[0],
            };
            const graphRequest = {
                ...graphApiRequest,
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(apiRequest)
                .then(async (apiResponse) => {
                instance
                    .acquireTokenSilent(graphRequest)
                    .then(async (graphResponse) => {
                    var _a;
                    dispatch(setAuth({
                        token: apiResponse.accessToken,
                        graphToken: graphResponse.accessToken,
                        tokenExpiry: (_a = apiResponse.expiresOn) === null || _a === void 0 ? void 0 : _a.getTime(),
                    }));
                });
            })
                .catch(() => instance.loginRedirect(loginRequest));
        }
    };
    useEffect(() => {
        let timeout;
        if (tokenExpiry && accounts) {
            const distance = tokenExpiry - Date.now();
            timeout = setTimeout(() => {
                loginDispatch();
            }, distance);
        }
        return () => clearTimeout(timeout);
    }, [tokenExpiry]);
    const isFetching = onPublicRoute
        ? false
        : !(accounts === null || accounts === void 0 ? void 0 : accounts.length) ||
            !user ||
            me.isLoading ||
            myPermissions.isLoading ||
            legacyToken.isLoading;
    const routes = user
        ? [...routeConfig[user === null || user === void 0 ? void 0 : user.userType], ...publicRoutes]
        : publicRoutes;
    if (me.error || myPermissions.isError || legacyToken.isError) {
        return _jsx(NotAuthorizedPage, {});
    }
    return isFetching ? (_jsx(Box, { w: "100vw", h: "100vh", children: _jsx(FullPageLoader, {}) })) : (_jsxs(Router, { location: location, routes: routes, children: [!onPublicRoute ? _jsx(Index, {}) : _jsx(Outlet, {}), !isProduction && _jsx(ReactLocationDevtools, { initialIsOpen: false })] }));
};
