import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { ViewIcon } from "~/assets/icons/ViewIcon";
import { RouteMap } from "~/routing/route.config";
export const KeyItem = ({ item }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const { name, id, updatedAt, updatedBy, key } = item;
    const onCopyKey = () => {
        navigator.clipboard.writeText(key);
        toast({
            title: t("apiKeys.keyCopySuccess"),
            status: "success",
            duration: 3000,
        });
    };
    const onViewKey = () => {
        navigate({ to: `${RouteMap.API_KEYS}/${id}` });
    };
    return (_jsxs(Flex, { bg: "white", p: 6, borderRadius: 4, children: [_jsxs(Box, { flex: 1, mr: 6, children: [_jsx(Heading, { pb: 1, as: "h5", fontSize: 16, lineHeight: 6, color: "brand.black", children: name }), _jsx("p", { color: "grey.60", children: t("apiKeys.keyUpdatedBy", {
                            updatedBy: updatedBy.name,
                            updatedAt: format(new Date(updatedAt), "dd.MM.yyyy, HH:mm"),
                        }) })] }), _jsxs(Flex, { flex: 0, alignItems: "center", children: [_jsx(Button, { leftIcon: _jsx(ViewIcon, {}), onClick: onViewKey, mr: 4, variant: "outline", colorScheme: "button.dark", children: t("apiKeys.viewKeyCTA") }), _jsx(Button, { leftIcon: _jsx(CopyIcon, {}), onClick: onCopyKey, children: t("apiKeys.copyKeyCTA") })] })] }));
};
