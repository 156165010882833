import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip, } from "@chakra-ui/react";
import { DotsIcon } from "~/assets/icons/DotsIcon";
import { IconButton } from "~/components/IconButton/IconButton";
export const TableRowActions = ({ tableActions, isGrouped = false, }) => {
    return isGrouped ? (_jsx(Menu, { variant: "actions", children: _jsxs(_Fragment, { children: [_jsx(MenuButton, { children: _jsx(IconButton, { children: _jsx(DotsIcon, {}) }) }), _jsx(MenuList, { children: tableActions.map((action) => (_jsxs(MenuItem, { onClick: () => action.onClick(), children: [action.element, _jsx(Text, { children: action.label })] }, `Table__Row__Action__${action.label}`))) })] }) })) : (_jsx(Box, { display: "flex", alignItems: "center", gap: "4", children: tableActions.map((action) => (_jsx(Tooltip, { hasArrow: true, bg: "brand.black", placement: "top", label: action.label, children: _jsx(Box, { as: "button", onClick: (e) => {
                    e.stopPropagation();
                    action.onClick();
                }, color: "grey.40", boxSize: "6", cursor: !action.disabled ? "pointer" : "not-allowed", _hover: !action.disabled
                    ? { color: "var(--chakra-colors-brand-black)" }
                    : {}, children: action.element }) }, `Table__Row__Action__${action.label}`))) }));
};
export const renderTableRowActions = ({ tableActions, isGrouped, }) => (_jsx(Box, { onClick: (e) => e.stopPropagation(), children: _jsx(TableRowActions, { tableActions: tableActions, isGrouped: isGrouped }) }));
