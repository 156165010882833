import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridItem, Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "~/assets/icons/edit.svg";
import DataTable from "~/components/DataTable/DataTable";
import TextFieldFilter from "~/components/DataTable/FilterFields/TextFieldFilter";
import { Slot } from "~/components/Slots/Slots";
export default function Translations() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const rowActions = useMemo(() => [
        {
            label: "edit",
            icon: _jsx(EditIcon, {}),
            onClick: ({ original }) => navigate({ to: `/settings/translations/${original.key}` }),
        },
    ], []);
    const columns = useMemo(() => [
        {
            Header: t("translations.key"),
            accessor: "key",
            Filter: TextFieldFilter,
            width: "auto",
        },
        {
            Header: t("translations.DE"),
            accessor: "DE",
            Filter: TextFieldFilter,
            width: "250px",
        },
        {
            Header: t("translations.EN"),
            accessor: "EN",
            Filter: TextFieldFilter,
            width: "250px",
        },
    ], []);
    return (_jsxs(_Fragment, { children: [_jsx(Slot, { name: "left-nav", children: _jsx(Text, { fontSize: "2xl", fontWeight: "bold", children: t("translations.translationsSettings") }) }), _jsx(GridItem, { colStart: 1, colEnd: 13, children: _jsx(DataTable, { columns: columns, rowActions: rowActions, data: "/onboarding/api/translations/table", hideDetailsLink: true, manualPagination: false }) })] }));
}
