import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.outboundProcesses.title",
        subtitle: "onboarding.outboundProcesses.exportEU.title",
        description: "onboarding.outboundProcesses.exportEU.description",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "exportCompanyLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.exportEU.sections.exportCompany.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "exportOrganizerQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.exportEU.sections.exportCompany.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "export_company.export_organizer",
                            elements: [
                                {
                                    type: "radio",
                                    value: "viaMS",
                                    key: "exportOrganizerMS",
                                    label: "onboarding.outboundProcesses.exportEU.sections.exportCompany.options.viaMS",
                                    forKey: "export_company.export_organizer",
                                },
                                {
                                    type: "radio",
                                    value: "myself",
                                    key: "exportOrganizerMyself",
                                    label: "onboarding.outboundProcesses.exportEU.sections.exportCompany.options.myself",
                                    forKey: "export_company.export_organizer",
                                },
                                {
                                    type: "radio",
                                    value: "another",
                                    key: "exportOrganizerAnother",
                                    label: "onboarding.outboundProcesses.exportEU.sections.exportCompany.options.another",
                                    forKey: "export_company.export_organizer",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            rule: {
                                effect: "SHOW",
                                targetKey: "export_company.export_organizer",
                                targetValue: "another",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "export_company.organizer_details.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "export_company.organizer_details.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "export_company.organizer_details.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "export_company.organizer_details.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "export_company.organizer_details.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "export_company.organizer_details.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "export_company",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "carrierLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.exportEU.sections.carrier.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "carrierQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.exportEU.sections.carrier.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "carrier.name",
                            elements: [
                                {
                                    type: "radio",
                                    value: "Dachser",
                                    key: "dachser",
                                    label: "Dachser",
                                    forKey: "carrier.name",
                                },
                                {
                                    type: "radio",
                                    value: "Gebruder Weiss",
                                    key: "Gebruder Weiss",
                                    label: "Gebruder Weiss",
                                    forKey: "carrier.name",
                                },
                                {
                                    type: "radio",
                                    value: "Bisquolm",
                                    key: "Bisquolm",
                                    label: "Bisquolm",
                                    forKey: "carrier.name",
                                },
                                {
                                    type: "radio",
                                    value: "other",
                                    key: "carrierOther",
                                    label: "Gebruder Weiss",
                                    forKey: "carrier.name",
                                },
                                {
                                    type: "radio",
                                    value: "another",
                                    key: "exportOrganizerAnother",
                                    label: "onboarding.outboundProcesses.exportEU.sections.carrier.another_carrier_option",
                                    forKey: "carrier.name",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            rule: {
                                effect: "SHOW",
                                targetKey: "carrier.name",
                                targetValue: "another",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "carrier.details.name",
                                    label: "onboarding.forms.details.name",
                                },
                                {
                                    type: "text",
                                    key: "carrier.details.street",
                                    label: "onboarding.forms.details.street",
                                },
                                {
                                    type: "text",
                                    key: "carrier.details.street_number",
                                    label: "onboarding.forms.details.number",
                                },
                                {
                                    type: "text",
                                    key: "carrier.details.zip_code",
                                    label: "onboarding.forms.details.zipCode",
                                },
                                {
                                    type: "text",
                                    key: "carrier.details.location",
                                    label: "onboarding.forms.details.city",
                                },
                                {
                                    key: "carrier.details.country",
                                    type: "customComponent",
                                    componentName: "base.CountrySelect",
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "export_company",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "clearanceMethodLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.exportEU.sections.clearance_method.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "clearanceQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.exportEU.sections.clearance_method.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "clearance.method",
                            elements: [
                                {
                                    type: "radio",
                                    value: "transitDocument",
                                    key: "transitDocument",
                                    label: "onboarding.outboundProcesses.exportEU.sections.clearance_method.options.transit_document",
                                    forKey: "clearance.method",
                                },
                                {
                                    type: "radio",
                                    value: "borderClearance",
                                    key: "borderClearance",
                                    label: "onboarding.outboundProcesses.exportEU.sections.clearance_method.options.border_clearance",
                                    forKey: "clearance.method",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            rule: {
                                effect: "SHOW",
                                targetKey: "clearance.method",
                                targetValue: "transitDocument",
                            },
                            elements: [
                                {
                                    key: "transitDocumentCreator",
                                    type: "h3",
                                    label: "onboarding.outboundProcesses.exportEU.sections.clearance_method.question2",
                                },
                                {
                                    type: "text",
                                    key: "clearance.transitDocumentCreator",
                                    label: "",
                                    rule: {
                                        effect: "ENABLE",
                                        targetKey: "clearance.method",
                                        targetValue: "transitDocument",
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "export_company",
            infoboxText: "onboarding.outboundProcesses.exportEU.sections.clearance_method.infobox",
        }),
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        export_company: {
            type: "object",
            properties: {
                export_organizer: {
                    type: "string",
                },
                organizer_details: {
                    type: "object",
                    properties: {},
                },
            },
        },
        carrier: {
            type: "object",
            properties: {
                name: {
                    type: "string",
                },
                details: {
                    type: "object",
                    properties: {},
                },
            },
        },
        clearance: {
            type: "object",
            properties: {
                method: {
                    type: "string",
                },
                // transitDocumentCreator: {
                //   type: 'string'
                // }
            },
        },
    },
    additionalProperties: true,
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
