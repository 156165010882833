import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Circle, Flex, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { createColumnHelper } from "@tanstack/react-table";
import { formatRelative } from "date-fns";
import saveAs from "file-saver";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "~/assets/icons/DeleteIcon";
import { DownloadIcon } from "~/assets/icons/DownloadIcon";
import { ReplaceIcon } from "~/assets/icons/ReplaceIcon";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Table } from "~/components/Table/Table";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { ClassificationCasesStatus, ClassificationCasesStatusTranslationKey, } from "~/pages/customs-classification/enums/classification-case-status.enum";
import { importsBaseUrl, useGetAllErrorCasesQuery, useLazyGetImportMetaByIdQuery, } from "~/pages/customs-classification/redux/customsClassification.api";
import { RouteMap } from "~/routing/route.config";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { checkAccountType } from "~/utils/checkAccountType";
import { locale } from "~/utils/locale";
import { DeleteErrorCaseModal } from "./components/DeleteErrorCaseModal/DeleteErrorCaseModal";
import { ReplaceImportModal } from "./components/ReplaceImportModal/ReplaceImportModal";
const MAX_ERROR_CASES = 50;
export const ErrorCasesTab = ({ client, }) => {
    const [idToDelete, setIdToDelete] = useState(null);
    const [idToReplace, setIdToReplace] = useState(null);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, onPaginationChange] = useParamPagination();
    const toast = useToast();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token } = useAppSelector(authSelector);
    const { data: errorCases, isLoading: isLoadingErrorCases, isFetching: isFetchingErrorCases, } = useGetAllErrorCasesQuery({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
        ...(!!client && { clientId: client.id }),
    });
    const [getImportMeta, { isFetching: isFetchingImportMeta }] = useLazyGetImportMetaByIdQuery();
    const handleDownload = async (id) => {
        setIsDownloadLoading(true);
        try {
            const importMeta = await getImportMeta(id).unwrap();
            const res = await fetch(withBaseUrl(`${importsBaseUrl}/${id}/download`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.ok) {
                const data = await res.blob();
                const { fileName } = importMeta;
                saveAs(data, fileName);
            }
        }
        catch (e) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
        setIsDownloadLoading(false);
    };
    const isImportValid = (c) => c.status !== ClassificationCasesStatus.FAILED;
    const tableActions = (c) => [
        ...(isImportValid(c)
            ? [
                {
                    label: t("basics.download"),
                    element: _jsx(DownloadIcon, {}),
                    onClick: () => handleDownload(c.id),
                },
            ]
            : []),
        ...(checkAccountType(AccountTypes.CLIENT)
            ? [
                {
                    label: t("basics.replace"),
                    element: _jsx(ReplaceIcon, {}),
                    onClick: () => setIdToReplace(c.id),
                },
                {
                    label: t("basics.delete"),
                    element: _jsx(DeleteIcon, {}),
                    onClick: () => setIdToDelete(c.id),
                },
            ]
            : []),
    ];
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("id", {
            header: () => t("customsClassification.tabs.errorCases.table.id"),
            cell: ({ getValue }) => getValue(),
            enableSorting: false,
        }),
        columnHelper.accessor("status", {
            header: () => t("customsClassification.tabs.errorCases.table.status"),
            cell: ({ getValue }) => {
                const status = getValue();
                const color = "danger.500";
                const translationKey = status === ClassificationCasesStatus.PENDING
                    ? ClassificationCasesStatusTranslationKey.HAS_ERRORS
                    : ClassificationCasesStatusTranslationKey.FILE_REJECTED;
                return (_jsxs(Flex, { gap: 3, alignItems: "center", children: [_jsx(Circle, { size: 4, backgroundColor: color }), _jsx(Text, { children: t(`customsClassification.status.${translationKey}`) })] }));
            },
            enableSorting: false,
        }),
        columnHelper.accessor("createdAt", {
            header: () => t("customsClassification.tabs.errorCases.table.createdAt"),
            cell: ({ row }) => formatRelative(new Date(row.original.createdAt), new Date(), {
                locale,
            }),
        }),
        columnHelper.accessor("articlesCount", {
            header: () => t("customsClassification.tabs.errorCases.table.datasets"),
            cell: ({ row, getValue }) => isImportValid(row.original) ? getValue() : "-",
        }),
        columnHelper.accessor("errorsCount", {
            header: () => t("customsClassification.tabs.errorCases.table.errors"),
            cell: ({ getValue, row }) => isImportValid(row.original)
                ? getValue() > MAX_ERROR_CASES
                    ? `${MAX_ERROR_CASES}+`
                    : getValue()
                : "-",
        }),
        columnHelper.display({
            id: "actions",
            size: undefined,
            header: () => t("customsClassification.tabs.errorCases.table.actions"),
            cell: ({ row }) => renderTableRowActions({ tableActions: tableActions(row.original) }),
        }),
    ];
    const handleNavigateToErrorCaseDetails = (row) => {
        const { id, errorsCount } = row.original;
        if (errorsCount < MAX_ERROR_CASES && isImportValid(row.original)) {
            if (client) {
                navigate({ to: `/clients/${client.id}/error-cases/${id}` });
            }
            else
                navigate({
                    to: `${RouteMap.CUSTOMS_CLASSIFICATION}/error-cases/${id}`,
                });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Text, { textStyle: "h3", mt: "8", mb: "6", children: t("customsClassification.tabs.errorCases.name") }), _jsx(Table, { data: errorCases, columns: columns, onRowClick: handleNavigateToErrorCaseDetails, sorting: sorting, onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: onPaginationChange, isLoading: isLoadingErrorCases, isFetching: isFetchingErrorCases || isDownloadLoading || isFetchingImportMeta, getRowStyles: (row) => ({
                    backgroundColor: row.original.errorsCount > MAX_ERROR_CASES ||
                        !isImportValid(row.original)
                        ? "var(--chakra-colors-danger-10)"
                        : "transparent",
                    cursor: row.original.errorsCount > MAX_ERROR_CASES ||
                        !isImportValid(row.original)
                        ? "auto"
                        : "pointer",
                }) }), _jsx(DeleteErrorCaseModal, { id: idToDelete, setId: setIdToDelete }), _jsx(ReplaceImportModal, { id: idToReplace, setId: setIdToReplace })] }));
};
