import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { TabNavigation, } from "~/components/TabNavigation/TabNavigation";
import { RouteMap } from "~/routing/route.config";
import { PartnerClients } from "../../components/PartnerClients/PartnerClients";
import { PartnerProfile } from "../../components/PartnerProfile/PartnerProfile";
import { PartnerServices } from "../../components/PartnerServices/PartnerServices";
import { useGetPartnerByIdQuery } from "../../redux/partners.api";
export const PartnerDetailPage = () => {
    const { params: { id }, } = useMatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: partner, isError, isFetching, } = useGetPartnerByIdQuery(Number(id));
    useEffect(() => {
        if (isError) {
            navigate({ to: RouteMap.PARTNERS });
        }
    }, [isError]);
    const breadcrumbs = [
        {
            title: t("partners.pageHeadline"),
            link: RouteMap.PARTNERS,
        },
    ];
    const tabs = partner
        ? [
            {
                title: t("partners.detail.tabs.profile"),
                value: "profile",
                element: _jsx(PartnerProfile, { partner: partner }),
            },
            {
                title: t("partners.detail.tabs.services"),
                value: "services",
                element: _jsx(PartnerServices, {}),
            },
            {
                title: t("partners.detail.tabs.clients"),
                value: "clients",
                element: _jsx(PartnerClients, { partner: partner }),
            },
        ]
        : [];
    return isFetching || !partner ? (_jsx(FullPageLoader, {})) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: partner.name, breadcrumbs: breadcrumbs }), _jsx(ContentSection, { children: _jsx(TabNavigation, { tabs: tabs }) })] }));
};
