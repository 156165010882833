import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Center, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useToast, } from "@chakra-ui/react";
import { CountryCode, CurrencyUnit, WeightUnit, } from "@crossborderx/schemas-package";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import saveAs from "file-saver";
import { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from "~/assets/icons/ChevronDownIcon";
import { DeleteIcon } from "~/assets/icons/DeleteIcon";
import { DownloadIcon } from "~/assets/icons/DownloadIcon";
import { ReplaceIcon } from "~/assets/icons/ReplaceIcon";
import { WarningTriangleIcon } from "~/assets/icons/WarningTriangleIcon";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Switch } from "~/components/Switch/Switch";
import { Table } from "~/components/Table/Table";
import { renderTableCheckbox } from "~/components/Table/components/TableCheckbox/TableCheckbox";
import { TableSelectHeader } from "~/components/Table/components/TableSelectHeader/TableSelectHeader";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
import { FREE_FIELDS } from "~/consts/free-fields";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { EditableField } from "~/pages/customs-classification/components/EditableField/EditableField";
import { EditableSelect } from "~/pages/customs-classification/components/EditableSelect/EditableSelect";
import { customsClassificationApi, importsBaseUrl, useGetArticleErrorCasesByImportIdQuery, useGetImportMetaByIdQuery, useMarkImportAsDoneMutation, useUpdateErrorCaseArticleMutation, } from "~/pages/customs-classification/redux/customsClassification.api";
import { RouteMap } from "~/routing/route.config";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { TableSelectionMode } from "~/types/table-selection.type";
import { checkAccountType } from "~/utils/checkAccountType";
import { useIdRowSelection } from "~/hooks/useIdRowSelection";
import { ReplaceImportModal } from "../ErrorCases/components/ReplaceImportModal/ReplaceImportModal";
import { DeleteImportedArticlesModal } from "./components/DeleteImportedArticlesModal/DeleteImportedArticlesModal";
import { ErrorCaseOverlay } from "./components/ErrorCaseOverlay/ErrorCaseOverlay";
export const ErrorCaseDetailPage = () => {
    var _a;
    const [isShowingErrors, setIsShowingErrors] = useState(true);
    const [overlayIds, setOverlayIds] = useState(null);
    const [completedField, setCompletedField] = useState(null);
    const [isDownloading, setIsDownloadLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [idToReplace, setIdToReplace] = useState(null);
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const { selectedRows, setSelectedRows, reset, resetAll, selectionMode, setSelectionMode, inPageMode, setInPageMode, showBulkActions, } = useIdRowSelection();
    useEffect(() => {
        resetAll();
    }, [searchQuery, isShowingErrors]);
    const completedFieldTimeout = useRef(null);
    const toast = useToast();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { params: { id, clientId }, } = useMatch();
    const { token } = useAppSelector(authSelector);
    const { data: importMeta } = useGetImportMetaByIdQuery(Number(id));
    const { data, isLoading, isFetching } = useGetArticleErrorCasesByImportIdQuery({
        id: Number(id),
        params: {
            page: pagination.pageIndex,
            size: pagination.pageSize,
            searchQuery,
            ...(!!sorting[0] && {
                order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
                orderBy: sorting[0].id || null,
            }),
            having: isShowingErrors ? "errors" : "no-errors",
        },
    });
    const [updateArticle] = useUpdateErrorCaseArticleMutation();
    const [markAsDone, { isLoading: isMarkingAsDone }] = useMarkImportAsDoneMutation();
    const handleDownload = async () => {
        if (data) {
            setIsDownloadLoading(true);
            try {
                const res = await fetch(withBaseUrl(`${importsBaseUrl}/${id}/articles/export?`) +
                    new URLSearchParams({
                        articleIds: selectedRows.join(),
                        type: selectionMode,
                    }), {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (res.ok) {
                    const download = await res.blob();
                    const filename = `articles_export_${format(new Date(), "yyyyMMdd_HHmmss")}.csv`;
                    saveAs(download, filename);
                }
            }
            catch (e) {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
            setIsDownloadLoading(false);
        }
    };
    const handleUpdateOnBlur = async ({ field, value, articleId, }) => {
        try {
            const res = await updateArticle({
                body: { field, value },
                importId: Number(id),
                articleId: Number(articleId),
            });
            if ("data" in res) {
                // if there are no errors, set field as completed, otherwise just invalidate the tag
                if (!res.data.errors) {
                    const foundArticle = data === null || data === void 0 ? void 0 : data.data.find((article) => article.id === res.data.id);
                    // only show the completed field effect if the article had errors before editing
                    if (foundArticle === null || foundArticle === void 0 ? void 0 : foundArticle.errors) {
                        dispatch(customsClassificationApi.util.invalidateTags([
                            "CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID",
                        ]));
                    }
                    else {
                        setCompletedField(res.data.id);
                    }
                }
                else {
                    dispatch(customsClassificationApi.util.invalidateTags([
                        "CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID",
                    ]));
                }
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.updateArticleField"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const handleImportUpdate = async () => {
        try {
            const res = await markAsDone({ id });
            if ("data" in res) {
                toast({
                    title: t("customsClassification.tabs.errorCases.detail.filterBar.markAsDone.success"),
                    status: "success",
                    duration: 3000,
                });
                dispatch(customsClassificationApi.util.invalidateTags([
                    "CUSTOMS_CLASSIFICATION_ERROR_CASES",
                ]));
                navigate({ to: RouteMap.CUSTOMS_CLASSIFICATION });
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.markAsDone"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const breadcrumbs = clientId
        ? [
            {
                title: t("clients.pageHeadline"),
                link: RouteMap.CLIENTS,
            },
            {
                title: t("customsClassification.tabs.errorCases.name"),
                link: `${RouteMap.CLIENTS}/${clientId}?tab=error-cases`,
            },
        ]
        : [
            {
                title: t("customsClassification.pageHeadline"),
                link: RouteMap.CUSTOMS_CLASSIFICATION,
            },
            {
                title: t("customsClassification.tabs.errorCases.name"),
                link: `${RouteMap.CUSTOMS_CLASSIFICATION}?tab=error-cases`,
            },
        ];
    const pill = useMemo(() => {
        var _a;
        return data && (data === null || data === void 0 ? void 0 : data.metadata.errorCount) > 0
            ? {
                text: t("{{errors}} remaining", {
                    errors: (_a = data === null || data === void 0 ? void 0 : data.metadata.errorCount) !== null && _a !== void 0 ? _a : 0,
                }),
                icon: _jsx(WarningTriangleIcon, { color: "danger.500" }),
            }
            : undefined;
    }, [data]);
    const columnHelper = createColumnHelper();
    const cell = useCallback(({ getValue, column: { id: field }, row: { index, original: { id: articleId } }, }) => {
        var _a;
        const editableSelects = [
            "shipFromCountry",
            "shipToCountry",
            "countryOfOrigin",
            "manufacturingCountry",
            "netWeightUnit",
            "articleValueUnit",
        ];
        let options = [];
        switch (field) {
            case "shipFromCountry":
            case "shipToCountry":
            case "countryOfOrigin":
            case "manufacturingCountry":
                options = Object.values(CountryCode);
                break;
            case "netWeightUnit":
                options = Object.values(WeightUnit);
                break;
            case "articleValueUnit":
                options = Object.values(CurrencyUnit);
                break;
        }
        const errorCases = ((_a = data === null || data === void 0 ? void 0 : data.data[index].errors) === null || _a === void 0 ? void 0 : _a.filter((item) => item.property === field)) ||
            [];
        return useMemo(() => editableSelects.includes(field) ? (_jsx(EditableSelect, { isDisabled: !!clientId, fieldName: field, defaultValue: getValue(), onBlur: (value) => handleUpdateOnBlur({ articleId, field, value }), isError: !!errorCases.length, options: options })) : (_jsx(EditableField, { isDisabled: !!clientId, defaultValue: getValue(), onBlur: (value) => handleUpdateOnBlur({ articleId, field, value }), isError: !!errorCases.length })), []);
    }, [data]);
    const freeFields = FREE_FIELDS.map((n) => columnHelper.accessor(`freeField${n}`, {
        header: () => t("customsClassification.tabs.errorCases.detail.table.freeField", {
            n,
        }),
        cell,
        size: 220,
    }));
    const columns = [
        columnHelper.display({
            id: "select",
            header: () => (_jsx(TableSelectHeader, { selectionMode: selectionMode, setSelectionMode: setSelectionMode, selectedItems: selectedRows, setSelectedItems: reset, itemCount: (data === null || data === void 0 ? void 0 : data.pagination.totalItems) || 0, pageData: (data === null || data === void 0 ? void 0 : data.data) || [], inPageMode: inPageMode, setInPageMode: setInPageMode })),
            cell: ({ row }) => renderTableCheckbox(row.original.id, selectedRows, setSelectedRows, selectionMode, setSelectionMode),
            enableSorting: false,
        }),
        columnHelper.accessor("articleNumber", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.article"),
            cell,
            size: 130,
        }),
        columnHelper.accessor("shipFromTariffingCode", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.shipFromTariffNumber", {
                country: importMeta === null || importMeta === void 0 ? void 0 : importMeta.shipFromCountry,
            }),
            cell,
            size: 300,
        }),
        columnHelper.accessor("shipToTariffingCode", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.shipToTariffNumber", {
                country: importMeta === null || importMeta === void 0 ? void 0 : importMeta.shipToCountry,
            }),
            cell,
            size: 280,
        }),
        columnHelper.accessor("shipFromDescription", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.shipFromArticleDescription"),
            cell,
            size: 280,
        }),
        columnHelper.accessor("shipToDescription", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.shipToArticleDescription"),
            cell,
            size: 280,
        }),
        columnHelper.accessor("materialDescription", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.materialDescription"),
            cell,
            size: 280,
        }),
        columnHelper.accessor("netWeight", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.netWeight"),
            cell,
            size: 140,
        }),
        columnHelper.accessor("netWeightUnit", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.netWeightUnit"),
            cell,
            size: 140,
        }),
        columnHelper.accessor("countryOfOrigin", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.countryOfOrigin"),
            cell,
            size: 200,
        }),
        columnHelper.accessor("manufacturingCountry", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.manufacturingCountry"),
            cell,
            size: 230,
        }),
        columnHelper.accessor("urlLink", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.urlLink"),
            cell,
            size: 220,
        }),
        columnHelper.accessor("articleValue", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.articleValue"),
            cell,
            size: 160,
        }),
        columnHelper.accessor("articleValueUnit", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.articleValueUnit"),
            cell,
            size: 140,
        }),
        columnHelper.accessor("deImportTariffingCode", {
            header: () => t("customsClassification.tabs.errorCases.detail.table.DEImportTariffNumber"),
            cell,
            size: 240,
        }),
        ...freeFields,
    ];
    const getRowStyles = useCallback((row) => {
        var _a;
        const styles = {};
        const { id: itemId } = row.original;
        const isSelected = selectionMode === TableSelectionMode.ALL ||
            (selectionMode === TableSelectionMode.INCLUDE &&
                selectedRows.includes(itemId)) ||
            (selectionMode === TableSelectionMode.EXCLUDE &&
                !selectedRows.includes(itemId));
        if ((_a = row.original.errors) === null || _a === void 0 ? void 0 : _a.length)
            Object.assign(styles, {
                backgroundColor: "var(--chakra-colors-warning-10)",
            });
        if (isSelected)
            Object.assign(styles, {
                backgroundColor: "var(--chakra-colors-sky-5)",
            });
        if (completedField === row.original.id)
            Object.assign(styles, {
                backgroundColor: "var(--chakra-colors-success-20)",
            });
        return styles;
    }, [completedField, selectedRows]);
    const hasErrors = useMemo(() => (data ? data.metadata.errorCount > 0 : true), [data]);
    useEffect(() => {
        if (completedField) {
            if (completedFieldTimeout.current) {
                clearTimeout(completedFieldTimeout.current);
            }
            completedFieldTimeout.current = setTimeout(() => {
                setCompletedField(null);
                dispatch(customsClassificationApi.util.invalidateTags([
                    "CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID",
                ]));
            }, 1000);
        }
    }, [completedField]);
    return (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: t("customsClassification.tabs.errorCases.detail.title", { id }), breadcrumbs: breadcrumbs, pill: pill }), _jsxs(ContentSection, { children: [_jsxs(FilterBar, { children: [_jsxs(Center, { gap: 4, children: [_jsx(FilterSearch, { value: search, setValue: setSearch }), _jsxs(Center, { justifyContent: "center", "align-items": "center", children: [_jsx(Switch, { isChecked: isShowingErrors, onChange: () => setIsShowingErrors((prev) => !prev) }), _jsx(Text, { children: t("customsClassification.tabs.errorCases.detail.filterBar.switch") })] })] }), _jsxs(Flex, { gap: 3, children: [checkAccountType(AccountTypes.CLIENT) && (_jsx(TooltipWrapper, { label: t("basics.replace"), hasTooltip: true, children: _jsx(Button, { variant: "secondary", onClick: () => {
                                                setIdToReplace(Number(id));
                                            }, children: _jsx(ReplaceIcon, {}) }) })), _jsx(TooltipWrapper, { label: t("customsClassification.tabs.errorCases.detail.filterBar.actions.tooltip"), hasTooltip: !showBulkActions, children: _jsx(Menu, { variant: "actions", children: ({ isOpen }) => (_jsxs(_Fragment, { children: [_jsx(MenuButton, { as: Button, variant: "secondary", rightIcon: _jsx(ChevronDownIcon, { transform: `rotate(${isOpen ? "180deg" : "0deg"})`, transition: "transform 300ms" }), isDisabled: !showBulkActions || isDownloading, isLoading: isDownloading, children: t("customsClassification.tabs.errorCases.detail.filterBar.actions.buttonCTA") }), _jsxs(MenuList, { children: [_jsxs(MenuItem, { gap: 4, onClick: handleDownload, children: [_jsx(DownloadIcon, { boxSize: 5 }), _jsx(Text, { children: t("basics.download") })] }), checkAccountType(AccountTypes.CLIENT) && (_jsxs(MenuItem, { gap: 4, onClick: () => setIsDeleteModalOpen(true), children: [_jsx(DeleteIcon, { boxSize: 5 }), _jsx(Text, { children: t("basics.delete") })] }))] })] })) }) }), checkAccountType(AccountTypes.CLIENT) && (_jsx(TooltipWrapper, { label: t("customsClassification.tabs.errorCases.detail.filterBar.markAsDone.tooltip"), hasTooltip: hasErrors, children: _jsx(Button, { onClick: handleImportUpdate, isDisabled: hasErrors || isMarkingAsDone, isLoading: isMarkingAsDone, children: t("customsClassification.tabs.errorCases.detail.filterBar.markAsDone.buttonCTA") }) }))] })] }), _jsx(Table, { data: data, columns: columns, isLoading: isLoading, isFetching: isFetching, getRowStyles: getRowStyles, pagination: pagination, onPaginationChange: setPagination, sorting: sorting, onSortingChange: onSortingChange, onRowClick: clientId
                            ? undefined
                            : (row) => setOverlayIds({
                                importId: Number(id),
                                articleId: row.original.id,
                            }) })] }), _jsx(ErrorCaseOverlay, { ids: overlayIds, setIds: setOverlayIds }), _jsx(DeleteImportedArticlesModal, { totalItems: (_a = data === null || data === void 0 ? void 0 : data.pagination.totalItems) !== null && _a !== void 0 ? _a : 0, isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen, articleIds: selectedRows, selectionMode: selectionMode }), _jsx(ReplaceImportModal, { id: idToReplace, setId: setIdToReplace, redirectPath: RouteMap.CUSTOMS_CLASSIFICATION })] }));
};
