import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import { withTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "reflect-metadata";
import { SlotsProvider } from "~/components/Slots/Slots";
import { Routing } from "./routing/Routing";
import { Store } from "./store/store";
const queryClient = new QueryClient();
function App() {
    return (_jsx(Provider, { store: Store, children: _jsx(Suspense, { fallback: "loading", children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(SlotsProvider, { children: _jsx(Routing, {}) }) }) }) }));
}
export default withTranslation()(App);
