import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, VStack } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button/Button";
import Image from "~/components/Image/Image";
var ConfirmationEvents;
(function (ConfirmationEvents) {
    ConfirmationEvents["DocumentsConsent"] = "documents-consent";
    ConfirmationEvents["DocumentSigned"] = "document-signed";
})(ConfirmationEvents || (ConfirmationEvents = {}));
export default function Confirmation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search: { event }, } = useMatch();
    const e = event[0];
    return (_jsxs(VStack, { h: "full", w: "full", py: "7", px: "10", alignItems: "flex-start", children: [_jsx(Flex, { justifyContent: "center", textAlign: "center", py: "20px", children: _jsx(Image, { name: "logo-square", width: "100%", maxWidth: "40px" }) }), _jsxs(Text, { fontSize: "2xl", as: "h1", pb: "4", children: [e === ConfirmationEvents.DocumentsConsent &&
                        t("confirmation.documents.successConsentMessage"), e === ConfirmationEvents.DocumentSigned &&
                        t("confirmation.documents.successSignedMessage")] }), _jsx(Button, { rounded: "none", variant: "outline", colorScheme: "blue", my: 3, onClick: () => navigate({ to: "/" }), children: t("confirmation.documents.goToAppAction") })] }));
}
