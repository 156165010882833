import { z } from "zod";
import { NON_EMPTY_REFINEMENT_MESSAGE, nonEmptyRefinement, } from "~/utils/nonEmptyRefinement";
const CountryOption = z.object({
    value: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    label: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
});
export const EditAddressValidationSchema = z.object({
    street: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    number: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    city: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    zipCode: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    country: CountryOption.required(),
});
export const EditAddressBodySchema = EditAddressValidationSchema.deepPartial()
    .omit({
    country: true,
})
    .extend({
    country: z.string().optional(),
});
