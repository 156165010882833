import { jsx as _jsx } from "react/jsx-runtime";
import { get } from "~/api/utils";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { isProduction } from "~/consts/envs";
import { Placeholder } from "~/layouts/app/components/Placeholder/Placeholder";
import OnBoardingLayout from "~/layouts/onboarding";
import { Logout } from "~/pages/auth/pages/Logout";
import { ClientDetailPage } from "~/pages/clients/pages/ClientDetail/ClientDetail";
import { ClientDocumentsPage } from "~/pages/clients/pages/ClientDocumentsPage/ClientDocumentsPage";
import { ClientOverviewPage } from "~/pages/clients/pages/ClientOverview/ClientOverview";
import { ClientProfilePage } from "~/pages/clients/pages/ClientProfile/ClientProfile";
import Confirmation from "~/pages/confirmation";
import { CustomsClassificationPage } from "~/pages/customs-classification/pages/client/CustomsClassification/CustomsClassification";
import { ErrorCaseDetailPage } from "~/pages/customs-classification/pages/client/ErrorCaseDetail/ErrorCaseDetail";
import { CustomsClassificationDetailPage } from "~/pages/customs-classification/pages/partner/CustomsClassificationDetail/CustomsClassificationDetail";
import { CustomsClassificationOverviewPage } from "~/pages/customs-classification/pages/partner/CustomsClassificationOverview/CustomsClassificationOverview";
import { DashboardPlaceholder } from "~/pages/dashboard/pages/DashboardPlaceholder/DashboardPlaceholder";
import Iframe from "~/pages/iframe";
import { KeyDetailPage } from "~/pages/keys/pages/KeyDetail/KeyDetail";
import { KeyOverviewPage } from "~/pages/keys/pages/KeyOverview/KeyOverview";
import CH from "~/pages/onboarding/CH/routes";
import { PartnerClientsPage } from "~/pages/partners/pages/PartnerClientsPage/PartnerClientsPage";
import { PartnerDetailPage } from "~/pages/partners/pages/PartnerDetail/PartnerDetail";
import { PartnerOverviewPage } from "~/pages/partners/pages/PartnerOverview/PartnerOverview";
import { PartnerProfilePage } from "~/pages/partners/pages/PartnerProfile/PartnerProfile";
import { ProfilePage } from "~/pages/profile/pages/ProfilePage";
import Translations from "~/pages/translation-settings";
import BackUpTranslation from "~/pages/translation-settings/BackUpTranslation";
import EditTranslation from "~/pages/translation-settings/editTranslation";
import { UserDetail } from "~/pages/users/pages/UserDetail/UserDetail";
export const routeConfig = {
    [AccountTypes.INTERNAL]: [
        { path: "/partners/:id/user", element: _jsx(UserDetail, {}) },
        { path: "/partners/:id", element: _jsx(PartnerDetailPage, {}) },
        { path: "/partners", element: _jsx(PartnerOverviewPage, {}) },
        {
            path: "/clients/:id/onboarding/:flowId",
            element: _jsx(OnBoardingLayout, {}),
            children: [...CH],
        },
        { path: "/clients/:id/user", element: _jsx(UserDetail, {}) },
        {
            path: "/clients/:clientId/error-cases/:id",
            element: _jsx(ErrorCaseDetailPage, {}),
        },
        { path: "/clients/:id", element: _jsx(ClientDetailPage, {}) },
        { path: "/clients", element: _jsx(ClientOverviewPage, {}) },
        { path: "/profile", element: _jsx(ProfilePage, {}) },
        {
            path: "/settings",
            children: [
                { path: "/translations/export", element: _jsx(BackUpTranslation, {}) },
                {
                    path: "/translations/:key",
                    element: _jsx(EditTranslation, {}),
                    loader: async ({ params: { key } }) => ({
                        translationData: await get("/onboarding/api/translations/table").then((data) => data.data.find((translation) => translation.key === key)),
                    }),
                },
                { path: "/translations", element: _jsx(Translations, {}) },
            ],
        },
        { path: "/gateway/ch/:path", element: _jsx(Iframe, { app: "gatewayCH" }) },
        { path: "/gateway/uk/:path", element: _jsx(Iframe, { app: "gatewayUK" }) },
        { path: "/clarify/:path", element: _jsx(Iframe, { app: "clarify" }) },
        { path: "/confirmation", element: _jsx(Confirmation, {}) },
        { path: "/", element: _jsx(DashboardPlaceholder, {}) },
        {
            path: "/customs-classification/:id",
            element: _jsx(CustomsClassificationDetailPage, {}),
        },
        {
            path: "/customs-classification",
            element: _jsx(CustomsClassificationOverviewPage, {}),
        },
    ],
    [AccountTypes.PARTNER]: [
        { path: "/", element: _jsx(DashboardPlaceholder, {}) },
        { path: "/profile", element: _jsx(ProfilePage, {}) },
        { path: "/company-profile/user", element: _jsx(UserDetail, {}) },
        { path: "/company-profile", element: _jsx(PartnerProfilePage, {}) },
        { path: "/gateway/ch/:path", element: _jsx(Iframe, { app: "gatewayCH" }) },
        { path: "/gateway/uk/:path", element: _jsx(Iframe, { app: "gatewayUK" }) },
        { path: "/clients", element: _jsx(PartnerClientsPage, {}) },
        {
            path: "/customs-classification/:id",
            element: _jsx(CustomsClassificationDetailPage, {}),
        },
        {
            path: "/customs-classification",
            element: _jsx(CustomsClassificationOverviewPage, {}),
        },
    ],
    [AccountTypes.CLIENT]: [
        { path: "/", element: _jsx(DashboardPlaceholder, {}) },
        { path: "/outbound-shipments", element: _jsx(Placeholder, {}) },
        { path: "/inbound-shipments", element: _jsx(Placeholder, {}) },
        { path: "/clarification-cases", element: _jsx(Placeholder, {}) },
        { path: "/documents", element: _jsx(ClientDocumentsPage, {}) },
        { path: "/settings", element: _jsx(Placeholder, {}) },
        {
            path: "/clients/:id/onboarding/:flowId",
            element: _jsx(OnBoardingLayout, {}),
            children: [...CH],
        },
        { path: "/profile", element: _jsx(ProfilePage, {}) },
        { path: "/company-profile/user", element: _jsx(UserDetail, {}) },
        { path: "/company-profile", element: _jsx(ClientProfilePage, {}) },
        { path: "/gateway/ch/:path", element: _jsx(Iframe, { app: "gatewayCH" }) },
        { path: "/gateway/uk/:path", element: _jsx(Iframe, { app: "gatewayUK" }) },
        { path: "/confirmation", element: _jsx(Confirmation, {}) },
        {
            path: "/customs-classification/error-cases/:id",
            element: _jsx(ErrorCaseDetailPage, {}),
        },
        {
            path: "/customs-classification/:id",
            element: _jsx(CustomsClassificationDetailPage, {}),
        },
        { path: "/customs-classification", element: _jsx(CustomsClassificationPage, {}) },
    ],
    default: [],
};
// TODO check if this is used or just legacy code
if (!isProduction) {
    routeConfig[AccountTypes.PARTNER] = [
        ...routeConfig[AccountTypes.PARTNER],
        { path: "/api-and-services/credentials/:id", element: _jsx(KeyDetailPage, {}) },
        { path: "/api-and-services/credentials", element: _jsx(KeyOverviewPage, {}) },
    ];
}
export const publicRoutes = [
    { path: "/logout", element: _jsx(Logout, {}) },
    { path: "*", element: _jsx("h1", { children: "404 Not found" }) },
];
