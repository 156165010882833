import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { InputIcon } from "~/assets/icons/InputIcon";
import { OutputIcon } from "~/assets/icons/OutputIcon";
import { CountrySelect } from "~/components/CountrySelect/CountrySelect";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { useEnablePartnerServiceWithCountriesMutation } from "~/pages/partners/redux/partners.api";
import { EnableTariffingValidationSchema, } from "~/pages/partners/schemas/enable-service.schema";
export const EnableTariffingServiceModal = ({ isOpen, service, setIsOpen, usedCountries }) => {
    var _a, _b;
    const { params: { id }, } = useMatch();
    const { t } = useTranslation();
    const toast = useToast();
    const [enableTariffing, { isLoading: isEnableTariffingLoading }] = useEnablePartnerServiceWithCountriesMutation();
    const { control, handleSubmit, reset, setValue, watch, formState: { errors, isDirty }, } = useForm({
        defaultValues: {
            shipTo: [],
            shipFrom: [],
        },
        resolver: zodResolver(EnableTariffingValidationSchema),
    });
    const onSubmit = async (data) => {
        const { shipTo: shipToValues, shipFrom: shipFromValues } = data;
        const body = {
            serviceData: {
                shipTo: shipToValues.map((item) => item.value),
                shipFrom: shipFromValues.map((item) => item.value),
            },
            serviceCode: ServiceCode.TARIFFING,
        };
        try {
            const res = await enableTariffing({
                id,
                body,
                ...(service.id ? { serviceId: service.id } : {}),
            });
            if ("data" in res) {
                toast({
                    title: t("partners.enableTariffingModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.enableTariffing"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const selectedShipToCountries = useMemo(() => {
        return watch("shipTo").map((item) => item.value);
    }, [watch()]);
    const selectedShipFromCountries = useMemo(() => {
        return watch("shipFrom").map((item) => item.value);
    }, [watch()]);
    const handleClose = () => {
        setIsOpen(false);
        reset();
    };
    useEffect(() => {
        if (!isOpen)
            reset();
    }, [isOpen]);
    return (_jsx(Modal, { isOpen: isOpen, onClose: handleClose, title: t("partners.enableTariffingModal.title"), primaryTitle: t("partners.enableTariffingModal.primaryTitle"), primaryAction: handleSubmit(onSubmit), primaryActionDisabled: !isDirty, primaryActionLoading: isEnableTariffingLoading, children: _jsxs(Flex, { direction: "column", gap: 8, children: [_jsx(Text, { mt: 2, children: _jsx(Trans, { components: { bold: _jsx(Text, { as: "span", fontWeight: 700 }) }, i18nKey: "partners.enableTariffingModal.subtitle" }) }), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { gap: 4, children: [_jsx(InputIcon, { boxSize: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("partners.enableTariffingModal.shipTo") })] }), _jsx(CountrySelect, { controlProps: { name: "shipTo", control }, setValue: setValue, selectedCountries: selectedShipToCountries, usedCountries: usedCountries.shipTo, shipDirection: ShipDirection.TO }), ((_a = errors.shipTo) === null || _a === void 0 ? void 0 : _a.message) && (_jsx(ErrorMessage, { text: errors.shipTo.message }))] }), _jsx(Divider, {}), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { gap: 4, children: [_jsx(OutputIcon, { boxSize: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("partners.enableTariffingModal.shipFrom") })] }), _jsx(CountrySelect, { controlProps: { name: "shipFrom", control }, setValue: setValue, selectedCountries: selectedShipFromCountries, usedCountries: usedCountries.shipFrom, shipDirection: ShipDirection.FROM }), ((_b = errors.shipFrom) === null || _b === void 0 ? void 0 : _b.message) && (_jsx(ErrorMessage, { text: errors.shipFrom.message }))] })] }) }));
};
