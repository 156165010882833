import { jsx as _jsx } from "react/jsx-runtime";
import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Drawer } from "~/components/Drawer/Drawer";
import { useLazyGetArticleByIdQuery } from "~/pages/customs-classification/redux/customsClassification.api";
import { ArticleEditView } from "./ArticleEditView";
import { ArticleInfoView } from "./ArticleInfoView";
export const ArticleDetailDrawer = ({ articleId, onClose, }) => {
    const [isEditView, setIsEditView] = useState(false);
    const [getArticle, { data: article, isFetching }] = useLazyGetArticleByIdQuery();
    useEffect(() => {
        if (articleId) {
            getArticle(articleId);
            setIsEditView(false);
        }
    }, [articleId]);
    const showLoader = isFetching || !article;
    return (_jsx(Drawer, { isOpen: !!articleId, onClose: onClose, isWide: true, children: showLoader ? (_jsx(Flex, { h: "full", w: "full", justifyContent: "center", alignItems: "center", children: _jsx(Spinner, { color: "brand.mare" }) })) : (_jsx(Flex, { flexDirection: "column", h: "full", children: isEditView ? (_jsx(ArticleEditView, { article: article, handleCloseEditView: () => {
                    setIsEditView(false);
                    onClose();
                } })) : (_jsx(ArticleInfoView, { article: article, setIsEditView: () => setIsEditView(true) })) })) }));
};
