import { useNavigate, useSearch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "~/consts/pagination";
export const useParamPagination = (config) => {
    const { defaultSize = DEFAULT_PAGINATION.pageSize, defaultPage = DEFAULT_PAGINATION.pageIndex, } = config || {};
    const search = useSearch();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        pageIndex: Number(search.page) || defaultPage,
        pageSize: Number(search.size) || defaultSize,
    });
    useEffect(() => {
        const pageIndex = Number.isNaN(Number(search.page))
            ? 0
            : Number(search.page);
        if (pageIndex !== pagination.pageIndex) {
            setPagination((prev) => ({
                ...prev,
                pageIndex,
            }));
        }
    }, [search]);
    useEffect(() => {
        navigate({
            search: (prev) => ({
                ...prev,
                page: pagination.pageIndex,
                size: pagination.pageSize,
            }),
            replace: true,
        });
    }, [pagination]);
    return [pagination, setPagination];
};
