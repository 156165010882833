import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Select, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import { ServiceCode } from "~/enums/service-code.enum";
import { useGetProviderPartnersQuery, useLazyGetCompanyUsersQuery, } from "~/pages/partners/redux/partners.api";
export const EmployeeSelect = ({ shipTo, shipFrom, providerId, handleSelect, selectedEmployee, }) => {
    const { t } = useTranslation();
    const { data: serviceProviders, isFetching: isFetchingProviders } = useGetProviderPartnersQuery({
        ...(shipTo && { shipTo }),
        ...(shipFrom && { shipFrom }),
        serviceCode: ServiceCode.TARIFFING,
    });
    const [fetchUsers, { isFetching: isFetchingUsers }] = useLazyGetCompanyUsersQuery();
    const [users, setUsers] = useState([]);
    const fetchProviderUsers = async () => {
        var _a;
        const partnerId = ((_a = serviceProviders === null || serviceProviders === void 0 ? void 0 : serviceProviders.find((p) => p.serviceId === Number(providerId))) === null || _a === void 0 ? void 0 : _a.partnerId) || 0;
        const res = await fetchUsers(partnerId).unwrap();
        if (res) {
            setUsers(res);
        }
        else {
            setUsers([]);
        }
    };
    useEffect(() => {
        if (serviceProviders)
            fetchProviderUsers();
    }, [serviceProviders, providerId]);
    const isFetching = isFetchingProviders || isFetchingUsers;
    return (_jsxs(Box, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: "4", children: t("clients.detail.services.tariffingModal.employeeTitle") }), _jsx(Select, { placeholder: isFetching
                    ? t("basics.loading")
                    : !users.length
                        ? t("clients.detail.services.tariffingModal.noUsersFound")
                        : t("clients.detail.services.tariffingModal.employeePlaceholder"), variant: "formSelect", value: selectedEmployee || "", onChange: (e) => handleSelect(e.target.value), disabled: isFetching || !users.length, children: users.map((user) => (_jsxs("option", { value: user.email, children: [user.firstName, " ", user.lastName] }, `employee-option-${user.id}`))) }), !isFetching && !users.length && (_jsx(Box, { pt: "3", children: _jsx(Banner, { text: t("clients.detail.services.tariffingModal.noUsersFoundAddOne") }) }))] }));
};
