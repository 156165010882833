import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import TextField from "~/components/TextField/TextField";
import OnboardingFieldMessage from "~/components/OnboardingFieldMessage/OnboardingFieldMessage";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData } from "~/store/selectors/formData.selectors";
export function YourCustomAccountCustomsAccountNumber() {
    var _a;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "zazAccountNumber",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (fieldName, fieldValue) => {
        dispatch(patchFormField({
            fieldName,
            fieldValue,
            id,
            flowId,
            pageSlug: "zazAccountNumber",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.zazAccount.headline"), subtitle: t("onboarding.vat.flow.zazAccount.subtitle") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: [t("onboarding.vat.flow.zazAccount.title"), " "] }), _jsx(TextField, { placeholder: t("onboarding.vat.flow.zazAccount.accountNumber"), name: "zazAccount", onBlur: (e) => updateField("zazAccount", e.target.value), defaultValue: (_a = formData === null || formData === void 0 ? void 0 : formData.zazAccount) === null || _a === void 0 ? void 0 : _a.fieldValue, label: t("onboarding.vat.flow.zazAccount.accountNumber") }), _jsx(OnboardingFieldMessage, { savedFieldData: formData === null || formData === void 0 ? void 0 : formData.zazAccount })] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, nextUrl: `/clients/${id}/onboarding/${flowId}/administrative-processes/customs-complete`, vatPage: "0", pageSlug: "zazAccountNumber", processSlug: "administrativeProcesses" })] }));
}
