import { useMemo } from "react";
import { daysCalculation } from "~/helpers/outbound-overview/days-calculation";
import i18n from "~/i18n";
export default function durationUntilCustomsReleaseTwo(flowFields) {
    var _a, _b;
    const when = "-";
    let who = "";
    const customClearanceMethod = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "CustomClearanceMethod" &&
        data.pageSlug === "exportFromEu")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const customsOfficeCompanyName = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsBorderAuthority" &&
        data.pageSlug === "customsDeclaration")) === null || _b === void 0 ? void 0 : _b.fieldValue;
    let borderCustomsOffice;
    if (customClearanceMethod === "transit_document" &&
        customsOfficeCompanyName) {
        borderCustomsOffice = customsOfficeCompanyName;
    }
    if (customClearanceMethod === "border_clearance") {
        borderCustomsOffice = i18n.t("processOverview.outboundProcess.durationUntilCustomsReleaseTwo.borderCustomsOfficeName");
    }
    if (borderCustomsOffice) {
        who = i18n.t("processOverview.outboundProcess.durationUntilCustomsReleaseTwo.borderCustomsOffice", { office: borderCustomsOffice });
    }
    const what = `<p>${i18n.t("processOverview.outboundProcess.durationUntilCustomsReleaseTwo.whatOne")}</p>
  <p>${i18n.t("processOverview.outboundProcess.durationUntilCustomsReleaseTwo.whatTwo")}</p>
  <p>${i18n.t("processOverview.outboundProcess.durationUntilCustomsReleaseTwo.whatThree")}</p>`;
    const calculatedDay = useMemo(() => daysCalculation(flowFields), [flowFields]);
    const checkDayText = (day) => {
        switch (day) {
            case 2:
            case 5:
                return "02";
            case 3:
                return "03";
            default:
                return undefined;
        }
    };
    return {
        when,
        who,
        what,
        day: checkDayText(calculatedDay),
        icon: "duration-until-custom-release",
        title: i18n.t("processOverview.outboundProcess.durationUntilCustomsRelease.title"),
    };
}
