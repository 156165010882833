/* eslint no-param-reassign: ["error", { "props": false }] */
import { createAction, createReducer } from "@reduxjs/toolkit";
import { fetchFormById, fetchSavedFormById, hideSuccessMessage, patchFormField, } from "../actions/formData.actions";
const initialState = {
    data: [],
    savedFormData: {},
    savedFieldIsValid: true,
    savedField: "",
    ui: {
        loading: false,
    },
};
export const changeValue = createAction("changeValue");
export const FormDataReducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchSavedFormById.fulfilled, (state, action) => {
        state.savedFormData[action.payload.pageSlug] = {
            [action.payload.processSlug]: action.payload.data,
        };
        state.ui.loading = false;
    });
    builder.addCase(fetchSavedFormById.pending, (state) => {
        state.ui.loading = true;
    });
    builder.addCase(fetchSavedFormById.rejected, (state) => {
        state.ui.loading = false;
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(fetchFormById.fulfilled, (state, action) => {
        state.ui.loading = false;
        state.data = action.payload;
    });
    builder.addCase(hideSuccessMessage.fulfilled, (state, _action) => {
        state.savedField = "";
    });
    builder.addCase(fetchFormById.pending, (state) => {
        state.savedField = "";
        state.ui.loading = true;
    });
    builder.addCase(fetchFormById.rejected, (state) => {
        state.ui.loading = false;
    });
    builder.addCase(patchFormField.fulfilled, (state, action) => {
        const index = state.data
            .map((x) => x.fieldName)
            .indexOf(action.payload.fieldName);
        state.savedField = action.payload.fieldName;
        state.savedFieldIsValid = action.payload.isValid;
        if (index === -1) {
            state.data.push(action.payload);
        }
        else {
            state.data[index] = action.payload;
        }
        if (action.payload.fieldName === "hasBillingAddress" &&
            action.payload.fieldValue === "yes") {
            const newState = state.data.filter((x) => ![
                "street.1",
                "city.1",
                "number.1",
                "zipCode.1",
                "country.1",
            ].includes(x.fieldName));
            state.data = newState;
        }
    });
    builder.addCase(patchFormField.rejected, (state, action) => {
        var _a;
        if ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.processId) {
            // state.data[action.payload.processId] = action.payload.oldValue;
        }
    });
    builder.addCase(changeValue, (state, action) => {
        const index = state.data
            .map((x) => x.fieldName)
            .indexOf(action.payload.fieldName);
        if (index > -1) {
            state.data[index].fieldValue = action.payload.fieldValue;
            state.savedField = action.payload.fieldName;
        }
    });
});
