import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parseLabels } from "../../helpers/parse-labels";
export var StepStatus;
(function (StepStatus) {
    StepStatus["Active"] = "active";
    StepStatus["Completed"] = "completed";
    StepStatus["Error"] = "error";
})(StepStatus || (StepStatus = {}));
const getColor = (status) => {
    if (!status)
        return "secondary.400";
    switch (status) {
        case StepStatus.Active:
            return "secondary.500";
        case StepStatus.Completed:
            return "success.500";
        case StepStatus.Error:
            return "error.500";
        default:
            return "secondary.500";
    }
};
function ChevronItem({ description, title, itemIndex, status, }) {
    const { t } = useTranslation();
    const formattedItemIndex = useMemo(() => {
        return itemIndex + 1;
    }, [itemIndex]);
    return (_jsxs(VStack, { w: "full", h: "fit-content", spacing: 4, position: "relative", children: [_jsxs(VStack, { w: "full", h: "auto", shadow: "base", borderLeft: 1, borderRight: 1, position: "relative", pb: 4, style: {
                    marginTop: 0,
                }, bgColor: "white", textAlign: "center", children: [itemIndex === 0 && (_jsx(Box, { w: "full", height: "4", style: { margin: 0 }, zIndex: 2, bgColor: "rgba(0, 0, 0, 0.1)", clipPath: "polygon(-4% 0, 104% 0, 50% 100%)" })), _jsx(Text, { fontSize: "2xl", pt: itemIndex === 0 ? 0 : "12", children: _jsxs(_Fragment, { children: [formattedItemIndex, ". ", t(title)] }) }), parseLabels(description, t)] }), _jsxs(VStack, { w: "full", h: "auto", shadow: "base", position: "relative", style: {
                    marginTop: 0,
                }, children: [_jsx(Box, { w: "full", height: "4", style: { margin: 0 }, position: "absolute", zIndex: 2, bgColor: getColor(status), clipPath: "polygon(-4% 0, 104% 0, 50% 100%)" }), _jsx(Box, { w: "full", height: "8", style: { margin: 0 }, position: "absolute", top: 0, zIndex: 1, left: 0, bgColor: "rgba(0, 0, 0, 0.1)", clipPath: "polygon(-4% 0, 104% 0, 50% 100%)" })] })] }));
}
function ShippingProcesses({ steps, title }) {
    const { t } = useTranslation();
    const middleStepItems = steps.slice(0, -1);
    const lastStepItem = steps[steps.length - 1];
    return (_jsxs(VStack, { w: "full", h: "full", spacing: "0", children: [_jsx(HStack, { w: "full", h: "20", bgColor: "secondary.500", justifyContent: "center", children: _jsx(Text, { fontSize: "2xl", color: "white", children: t(title) }) }), _jsxs(VStack, { w: "full", spacing: "0", children: [middleStepItems.map((item, index) => (_jsx(ChevronItem, { title: item.title, status: item.status, description: item.description, itemIndex: index }, item.title))), _jsxs(VStack, { w: "full", h: "auto", bgColor: "white", p: "5", shadow: "base", borderBottomStyle: "solid", position: "relative", style: {
                            marginTop: 0,
                        }, borderBottomWidth: "16px", borderBottomColor: getColor(lastStepItem.status), textAlign: "center", children: [_jsx(Text, { fontSize: "2xl", pt: "4", children: _jsxs(_Fragment, { children: [steps.length, ". ", t(lastStepItem === null || lastStepItem === void 0 ? void 0 : lastStepItem.title)] }) }), parseLabels(lastStepItem.description, t)] })] })] }));
}
export default ShippingProcesses;
