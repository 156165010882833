import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormErrorMessage, FormLabel, InputGroup, Select, } from "@chakra-ui/react";
import { forwardRef, memo, useImperativeHandle, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
function Component({ name, label, defaultValue, value, onChange, error, placeholder, options, sortOptionByKey, sortOptionByValue, ...rest }, ref) {
    const { t } = useTranslation();
    const [newValue, setNewValue] = useState(defaultValue || value || "");
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);
    const handleChange = (event) => {
        if (value)
            return;
        setNewValue(event.target.value);
        if (onChange)
            onChange(event);
    };
    return (_jsxs(FormControl, { isInvalid: Boolean(error), ...rest, children: [label ? _jsx(FormLabel, { htmlFor: name, children: label }) : null, _jsx(InputGroup, { size: "md", children: _jsx(Select, { id: name, ref: innerRef, placeholder: placeholder, name: name, value: newValue, onChange: handleChange, bgColor: "secondary.50", borderColor: "secondary.300", rounded: "none", h: "32px", children: options &&
                        Object.entries(options)
                            .sort((a, b) => {
                            let sortVal = 0;
                            if (sortOptionByValue) {
                                sortVal = a[1] > b[1] ? 1 : -1;
                            }
                            else if (sortOptionByKey) {
                                sortVal = a[0] > b[0] ? 1 : -1;
                            }
                            return sortVal;
                        })
                            .map(([optionValue, optionLabel]) => (_jsx(Box, { as: "option", value: optionValue, borderWidth: "1", borderStyle: "solid", borderColor: "secondary.50", lineHeight: "50px", children: t(optionLabel, { defaultValue: optionLabel }) }, optionValue))) }) }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(FormErrorMessage, { children: error.message })) : null] }));
}
export default memo(forwardRef(Component));
