import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Tag, TagCloseButton, TagLabel, TagLeftIcon, Text, } from "@chakra-ui/react";
import { chakraComponents, } from "chakra-react-select";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { GlobeIcon } from "~/assets/icons/GlobeIcon";
import { getCountryByIsoCode, getShippingCountriesOptions, isCountryUsed, } from "~/utils/countries";
import { ControlledSelect } from "../ControlledSelect/ControlledSelect";
import { FlagIcon } from "../FlagIcon/FlagIcon";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
const FLAG_SIZE = "20px";
export const CountryOptionSchema = z.object({
    label: z.string(),
    value: z.string(),
});
const Placeholder = (props) => {
    const { t } = useTranslation();
    return (_jsx(chakraComponents.Placeholder, { ...props, children: _jsxs(Flex, { gap: 3, children: [_jsx(GlobeIcon, { boxSize: "24px" }), _jsx(Text, { color: "grey.60", children: t("basics.addOneOrMoreCountries") })] }) }));
};
export const CountrySelect = ({ controlProps, setValue, selectedCountries, addedCountries = [], usedCountries = [], shipDirection, }) => {
    const components = { Placeholder };
    const { t } = useTranslation();
    const handleRemove = (countryCode) => {
        setValue(controlProps.name, selectedCountries
            .filter((code) => code !== countryCode)
            .map((code) => ({
            value: code,
            label: getCountryByIsoCode(code),
        })), { shouldDirty: true, shouldValidate: true });
    };
    const renderFlag = useCallback((countryCode) => (_jsx(FlagIcon, { countryCode: countryCode, style: { display: "inline-block" } })), []);
    return (_jsxs(Flex, { direction: "column", children: [_jsx(ControlledSelect, { components: components, options: getShippingCountriesOptions({
                    selectedCountries,
                    addedCountries,
                    usedCountries,
                    shipDirection,
                }), controlShouldRenderValue: false, controlProps: controlProps, isClearable: false, isMulti: true }), !!selectedCountries.length && (_jsx(Flex, { gap: 2, flexWrap: "wrap", mt: "4", children: selectedCountries.map((countryCode) => (_jsx(TooltipWrapper, { hasTooltip: isCountryUsed({
                        country: countryCode,
                        usedCountries,
                    }), label: t("partners.detail.services.tariffing.disabledCountryTooltip"), children: _jsxs(Tag, { variant: isCountryUsed({ country: countryCode, usedCountries })
                            ? "disabledCountry"
                            : "country", gap: "2", children: [_jsx(TagLeftIcon, { boxSize: FLAG_SIZE, as: () => renderFlag(countryCode) }), _jsx(TagLabel, { textStyle: "body", children: getCountryByIsoCode(countryCode) }), !isCountryUsed({ country: countryCode, usedCountries }) && (_jsx(TagCloseButton, { onClick: () => handleRemove(countryCode) }))] }, `Controlled__Select__Value__Item__${controlProps.name}__${countryCode}`) }, `Ship__To__Country__Tag__Tooltip__${countryCode}`))) }))] }));
};
