import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
export const PartnerServicePlaceholders = {
    [ServiceCode.TARIFFING]: {
        status: ServiceStatus.DISABLED,
        serviceCode: ServiceCode.TARIFFING,
        serviceData: {
            shipFrom: [],
            shipTo: [],
        },
    },
};
