import { getCountryCodeLabelKeyPairs } from "~/helpers/country-codes";
import i18n from "~/i18n";
export default function deliveryOfGoods(flowFields) {
    var _a, _b;
    const when = "-";
    const who = i18n.t("processOverview.msDirect");
    const whichCustomsOfficeCity = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whichCustomsOfficeCity" &&
        data.pageSlug === "returnPlaceOfPresentation")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    let wareHouseCountry = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wareHouseCountry" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _b === void 0 ? void 0 : _b.fieldValue;
    if (wareHouseCountry)
        wareHouseCountry = getCountryCodeLabelKeyPairs()[wareHouseCountry];
    let what = "";
    if (whichCustomsOfficeCity && wareHouseCountry) {
        what = `${i18n.t("processOverview.inboundProcess.deliveryOfGoods.presentation", {
            city: whichCustomsOfficeCity,
        })}
    <br>${i18n.t("processOverview.inboundProcess.deliveryOfGoods.release", {
            country: wareHouseCountry,
        })} <br> ${i18n.t("processOverview.inboundProcess.deliveryOfGoods.timing")}`;
    }
    // warehouseCountry could be empty if we selected "same as outbound flow" – TBD: how to handle?
    return {
        when,
        who,
        what,
        title: i18n.t("processOverview.inboundProcess.deliveryOfGoods.title"),
    };
}
