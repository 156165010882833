import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import { Modal } from "~/components/Modal/Modal";
import { useMarkImportAsDoneMutation } from "~/pages/customs-classification/redux/customsClassification.api";
import { RouteMap } from "~/routing/route.config";
export const MarkAsDoneModal = ({ isOpen, setIsOpen, importMeta }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const { params: { id }, } = useMatch();
    const navigate = useNavigate();
    const [markAsDone, { isLoading }] = useMarkImportAsDoneMutation();
    const handleConfirm = async () => {
        try {
            const res = await markAsDone({ id });
            if ("data" in res) {
                toast({
                    title: t("customsClassificationTariffing.confirmModal.successMessage"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
                navigate({ to: RouteMap.CUSTOMS_CLASSIFICATION });
            }
            else if (res.error) {
                toast({
                    title: t("error.badRequest.markAsDone"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.markAsDone"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const articleCount = (importMeta === null || importMeta === void 0 ? void 0 : importMeta.emptyRecordsWithNotes) || 0;
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("customsClassificationTariffing.confirmModal.title"), primaryVariant: "green", primaryTitle: t("customsClassificationTariffing.confirmModal.primaryCTA"), primaryAction: handleConfirm, primaryActionLoading: isLoading, children: [_jsx(Text, { my: 4, children: articleCount > 0 &&
                    t("customsClassificationTariffing.confirmModal.untariffedText", {
                        count: articleCount,
                    }) }), _jsx(Banner, { text: t("customsClassificationTariffing.confirmModal.undoWarning") })] }));
};
