import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useAppDispatch } from "~/store/hooks";
import { fetchFormById } from "~/store/actions/formData.actions";
export function ApplicationVatUidExternalRepresentationSignInfo() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "vatApplicationStart",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.externalRepresentation.headline"), subtitle: t("onboarding.vat.flow.externalRepresentation.subtitle") }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { children: t("onboarding.vat.flow.externalRepresentation.description") }), _jsxs(Flex, { direction: "column", mb: "4", children: [_jsx(Text, { children: t("onboarding.vat.flow.externalRepresentation.listinfo") }), Object.keys(t("onboarding.vat.flow.externalRepresentation.list", {
                                returnObjects: true,
                            }))
                                .filter((el) => el && el.length > 0)
                                .map((el, index) => {
                                return (t("onboarding.vat.flow.externalRepresentation.list", {
                                    returnObjects: true,
                                })[el].length > 0 && (_jsxs(Text, { children: [index + 1, ".", " ", t("onboarding.vat.flow.externalRepresentation.list", {
                                            returnObjects: true,
                                        })[el]] })));
                            })] }), _jsx(Text, { children: t("onboarding.vat.flow.externalRepresentation.endlist") })] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, vatPage: "3.2", pageSlug: "vatApplicationStart", processSlug: "administrativeProcesses", nextUrl: `/clients/${id}/onboarding/${flowId}/administrative-processes/vat-uid-number` })] }));
}
