import { jsx as _jsx } from "react/jsx-runtime";
import { formNodeFilters } from "@crossborderx/onboarding-machine";
import { ButtonComponent, ControlFieldComponent, InterpolatedLabelComponent, } from "../FormComponents";
export const renderElement = (node) => {
    const isTextLabel = formNodeFilters.isTextLabel(node);
    const isField = formNodeFilters.isField(node);
    const isButton = formNodeFilters.isButton(node);
    return ((isTextLabel && (_jsx(InterpolatedLabelComponent, { node: node }, node.key))) ||
        (isField && _jsx(ControlFieldComponent, { node: node }, node.key)) ||
        (isButton && _jsx(ButtonComponent, { node: node }, node.key)));
};
