import { useMutation, useQuery, useQueryClient } from "react-query";
import { ONBOARDING_V2_BASEURL } from "~/store/api";
import { get, put } from "../utils";
export function useOnboardingDataQuery(id) {
    return useQuery(["onboarding", id], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows`);
    }, {
        enabled: !!id,
    });
}
export function useGetOnboardingFlows(id) {
    return useQuery(["onboarding-flows"], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows`);
    }, { refetchOnWindowFocus: false });
}
export function useGetOnboardingFieldsFlows(id, flowId) {
    return useQuery(["onboarding-flow-fieds-by-id"], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/fields`);
    }, { refetchOnWindowFocus: false });
}
export function useGetOnboardingFlow(id, flowId) {
    return useQuery(["onboarding-flow"], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}`);
    }, { refetchOnWindowFocus: false });
}
export function useGetLandingPageFlow(id, flowId) {
    return useQuery(["onboarding-flow-landing"], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/landing`);
    });
}
export function useGetOnboardingPageFields(id, flowId, processSlug, pageSlug) {
    return useQuery(["onboarding-page-fields"], async () => {
        return get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}`);
    });
}
export function useOnboardingDataMutation(id) {
    const queryClient = useQueryClient();
    return useMutation(async (input) => {
        await put(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows`, input);
        return input;
    }, {
        onSuccess: (newOnboardingState) => {
            queryClient.setQueryData(["onboarding", id], newOnboardingState);
        },
    });
}
