import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { patchFormField, resetProcess } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
export const ResetModal = ({ id, isOpen, onClose, flowId, pageSlug, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const onClickHandler = () => {
        dispatch(resetProcess({ id, flowId, pageSlug }));
        dispatch(patchFormField({
            fieldName: "activestep",
            fieldValue: "0",
            id,
            flowId,
            pageSlug: "administrativeProcesses",
            processSlug: "activestep",
        }));
        navigate({
            to: `/clients/${id}/onboarding/${flowId}`,
        });
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, title: t("onboarding.actions.reset.modalTitle"), primaryTitle: t("onboarding.actions.reset.modalBtnOk"), primaryAction: onClickHandler, primaryVariant: "danger", secondaryTitle: t("onboarding.actions.reset.modalBtnCancel"), children: _jsx(Text, { mt: "2", color: "grey.60", children: t("onboarding.actions.reset.modalContent") }) }));
};
