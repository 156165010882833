import { get } from "lodash";
export const getFormLoadingState = (s) => s.formData.ui.loading;
export const lastSavedFieldState = (s) => s.formData.savedField;
export const lastSavedFieldIsValidState = (s) => s.formData.savedFieldIsValid;
// Normalize the data to be easier to display.
export const getFormData = (s) => {
    const d = s.formData.data;
    return d.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.fieldName] = a;
        return r;
    }, Object.create(null));
};
export const getSavedForm = (s, pageSlug, processSlug) => {
    return get(s.formData.savedFormData, pageSlug, processSlug);
};
