import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { ServicePlaceholder } from "~/components/Service/Service";
import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useGetAllServicesQuery } from "~/pages/services/redux/services.api";
import { useAppSelector } from "~/store/hooks";
import { checkAccountType } from "~/utils/checkAccountType";
import { getShippingCountriesList } from "~/utils/countries";
import { useGetSingleClientServiceMutation } from "../../redux/clients.api";
import { AddShipToCountryModal } from "./components/AddShipToCountryModal/AddShipToCountryModal";
import { CountryDropdown } from "./components/CountryDropdown/CountryDropdown";
import { Tariffing } from "./components/Tariffing/Tariffing";
export const ClientServices = ({ client, }) => {
    const [isInitializing, setIsInitializing] = useState(true);
    const [clientServices, setClientServices] = useState([]);
    const [isAddShipToCountryModalOpen, setIsAddShipToCountryModalOpen] = useState(false);
    const { shipmentDestination } = client;
    const sortedCountries = useMemo(() => [...shipmentDestination].sort((a, b) => a.localeCompare(b)), [client]);
    const [selectedCountry, setSelectedCountry] = useState(sortedCountries[0]);
    const { user } = useAppSelector(authSelector);
    const { accountId } = user || {};
    const { data: allServices, isFetching } = useGetAllServicesQuery();
    const [fetchService] = useGetSingleClientServiceMutation();
    useEffect(() => {
        if (isFetching && !isInitializing)
            setIsInitializing(true);
    }, [isFetching]);
    const { pathname, params: { id }, } = useMatch();
    const pageType = pathname.split("/")[1];
    const isOwnUserView = pageType === "company-profile" && accountId;
    const clientId = isOwnUserView ? accountId : Number(id);
    const { t } = useTranslation();
    const fetchClientServices = async () => {
        setClientServices([]);
        if (allServices) {
            const services = [];
            for (const service of allServices) {
                const args = {
                    clientId,
                    service: service.code,
                };
                if (selectedCountry && service.code === ServiceCode.TARIFFING)
                    args.shipTo = selectedCountry;
                const res = await fetchService(args).unwrap();
                if (res)
                    services.push(...res);
            }
            // check if we have TariffingService in the data, if not, add placeholder for it
            if (!services.find((s) => s.serviceCode === ServiceCode.TARIFFING)) {
                services.push({
                    status: ServiceStatus.DISABLED,
                    serviceCode: ServiceCode.TARIFFING,
                    serviceData: {
                        shipFrom: "",
                        services: [],
                        shipTo: selectedCountry,
                        direction: ShipDirection.TO,
                    },
                });
            }
            setClientServices(services);
            setIsInitializing(false);
        }
    };
    useEffect(() => {
        if (selectedCountry && !isFetching)
            fetchClientServices();
    }, [selectedCountry, isFetching]);
    const renderServices = () => {
        if (isInitializing)
            return _jsx(ServicePlaceholder, {});
        const reducedServices = clientServices.reduce((acc, service) => {
            if (!acc[service.serviceCode])
                acc[service.serviceCode] = [];
            acc[service.serviceCode].push(service);
            return acc;
        }, {});
        return Object.keys(reducedServices).map((service, index) => {
            switch (service) {
                case ServiceCode.TARIFFING:
                    return (_jsx(Tariffing, { services: reducedServices[service], selectedCountry: selectedCountry, isLoaded: !isInitializing }, `Client__Service__${service}_${index}`));
                default:
                    return null;
            }
        });
    };
    const areAllCountriesAdded = getShippingCountriesList({
        selectedCountries: client === null || client === void 0 ? void 0 : client.shipmentDestination,
        shipDirection: ShipDirection.TO,
    }).length === 0;
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", mt: "6", children: [_jsx(CountryDropdown, { countries: sortedCountries, selectedCountry: selectedCountry, setSelectedCountry: setSelectedCountry }), !areAllCountriesAdded && checkAccountType(AccountTypes.INTERNAL) && (_jsx(Button, { size: "lg", onClick: () => setIsAddShipToCountryModalOpen(true), children: t("clients.detail.services.addShipToCountryButton") }))] }), _jsxs(Box, { maxW: "servicesContainer", children: [_jsx(Text, { mt: "12", mb: "6", textStyle: "h2", children: t("clients.detail.tabs.services") }), checkAccountType(AccountTypes.CLIENT) && (_jsx(Box, { mb: "6", children: _jsx(Banner, { text: t("clients.detail.services.notice") }) })), _jsx(Flex, { direction: "column", gap: "4", mb: 12, children: renderServices() })] }), _jsx(AddShipToCountryModal, { isOpen: isAddShipToCountryModalOpen, setIsOpen: setIsAddShipToCountryModalOpen })] }));
};
