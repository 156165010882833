import i18n from "~/i18n";
export default function arrivalOfReturnShipmentsAndProcessing(flowFields) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const when = i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.when");
    let who = "";
    const whoHandlesReturns = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whoHandlesReturns" && data.pageSlug === "returns")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    if (whoHandlesReturns === "ms-direct") {
        who = i18n.t("processOverview.msDirect");
    }
    if (whoHandlesReturns === "we-ourselves") {
        who =
            ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" &&
                data.pageSlug === "contactInformation")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    }
    if (whoHandlesReturns === "other-patners") {
        who =
            ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returns")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
    }
    let what = "";
    if (whoHandlesReturns === "ms-direct") {
        const whichBarcode = (_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whichBarcode" &&
            data.pageSlug === "returnProcessing")) === null || _d === void 0 ? void 0 : _d.fieldValue;
        let barCode;
        if (whichBarcode === "EAN18") {
            barCode = "EAN18 Barcode";
        }
        if (whichBarcode === "qr-codes") {
            barCode = "QR Code";
        }
        if (whichBarcode === "other") {
            barCode = (_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whichBarcodeIdentificationType" &&
                data.pageSlug === "returnProcessing")) === null || _e === void 0 ? void 0 : _e.fieldValue;
        }
        if (barCode) {
            what = `${i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.whatBarCode", {
                barCode,
            })}<br>`;
        }
    }
    let productsNotIdentifiedCapture;
    const productsNotIdentified = (_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "productsNotIdentified" &&
        data.pageSlug === "returnProcessing")) === null || _f === void 0 ? void 0 : _f.fieldValue;
    if (productsNotIdentified === "capture-ms-clarify") {
        productsNotIdentifiedCapture = "MS Clarify";
    }
    if (productsNotIdentified === "capture-own-system") {
        productsNotIdentifiedCapture = (_g = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "productsNotIdentifiedNameOfSystem" &&
            data.pageSlug === "returnProcessing")) === null || _g === void 0 ? void 0 : _g.fieldValue;
    }
    if (productsNotIdentifiedCapture) {
        what += `${i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.productsNotIdentifiedCapture", {
            capture: productsNotIdentifiedCapture,
        })}<br>`;
    }
    const wantUsToDoQualityCheck = (_h = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wantUsToDoQualityCheck" &&
        data.pageSlug === "returnProcessing")) === null || _h === void 0 ? void 0 : _h.fieldValue;
    if (wantUsToDoQualityCheck === "yes") {
        let goodsClassification = (_j = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsClassification" &&
            data.pageSlug === "returnProcessing")) === null || _j === void 0 ? void 0 : _j.fieldValue;
        if (goodsClassification === "a-b-goods") {
            goodsClassification = i18n.t("onboarding.inboundProcesses.returnProcessing.goodsClassification.abGoods");
        }
        if (goodsClassification === "a-b-c-goods") {
            goodsClassification = i18n.t("onboarding.inboundProcesses.returnProcessing.goodsClassification.abcGoods");
        }
        if (goodsClassification === "no-classification") {
            goodsClassification = i18n.t("onboarding.inboundProcesses.returnProcessing.goodsClassification.noClassification");
        }
        if (goodsClassification === "other") {
            goodsClassification = (_k = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsClassificationOther" &&
                data.pageSlug === "returnProcessing")) === null || _k === void 0 ? void 0 : _k.fieldValue;
        }
        if (goodsClassification) {
            what += `${i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.goodsClassification", {
                classification: goodsClassification,
            })}<br>`;
        }
    }
    let goodsPack = (_l = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsPack" && data.pageSlug === "returnProcessing")) === null || _l === void 0 ? void 0 : _l.fieldValue;
    if (goodsPack && ["original", "plasticFoil", "polybag"].includes(goodsPack)) {
        goodsPack = i18n.t(`onboarding.inboundProcesses.returnProcessing.goodsPack.${goodsPack}`);
    }
    if (goodsPack === "other") {
        goodsPack = (_m = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsPackOther" &&
            data.pageSlug === "returnProcessing")) === null || _m === void 0 ? void 0 : _m.fieldValue;
    }
    if (goodsPack) {
        what += `${i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.goodsPack", {
            packing: goodsPack,
        })}<br>`;
    }
    const newLabelsBePrinted = (_o = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "newLabelsBePrinted" &&
        data.pageSlug === "returnProcessing")) === null || _o === void 0 ? void 0 : _o.fieldValue;
    if (newLabelsBePrinted === "yes") {
        what += i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.relabeling");
    }
    return {
        when,
        who,
        what,
        icon: "delivery-packaging",
        title: i18n.t("processOverview.inboundProcess.arrivalOfReturnShipmentsAndProcessing.title"),
    };
}
