import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Select from "~/components/Select/Select";
import { getCountryCodePhonePrefixKeyPairs } from "~/helpers/country-codes";
const DEFAULT_VALUE = "49"; // Germany phone prefix code
function CountryCodesSelect(props) {
    const { t } = useTranslation();
    const { error, label, register = () => ({ name: "phoneCode" }) } = props;
    return (_jsx(Select, { maxW: "100", placeholder: label, error: error, label: t("onboarding.forms.details.phoneCode"), defaultValue: DEFAULT_VALUE, ...register(), options: getCountryCodePhonePrefixKeyPairs() }));
}
export default CountryCodesSelect;
