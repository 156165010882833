import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from "@chakra-ui/react";
import { useFormContext } from "../FormContextProvider";
import { InterpolatedLabelComponent } from "./interpolated-label";
const layoutNodeDefaults = {
    w: "full",
    h: "auto",
    alignItems: "flex-start",
    justifyContent: "space-between",
};
export function LayoutComponent({ children, wrapperProps, ...layoutNode }) {
    const { direction, labelNode, rule } = layoutNode;
    const { watch } = useFormContext();
    const ruleMatched = (rule === null || rule === void 0 ? void 0 : rule.targetKey) !== undefined &&
        ((rule === null || rule === void 0 ? void 0 : rule.targetKey) === true || watch(rule === null || rule === void 0 ? void 0 : rule.targetKey) === (rule === null || rule === void 0 ? void 0 : rule.targetValue));
    const ruleEffect = (rule === null || rule === void 0 ? void 0 : rule.effect) || undefined;
    const isHidden = (ruleMatched && ruleEffect === "HIDE") ||
        (!ruleMatched && ruleEffect === "SHOW");
    return (_jsxs(_Fragment, { children: [labelNode && _jsx(InterpolatedLabelComponent, { node: labelNode }), _jsx(Flex, { ...{
                    ...layoutNodeDefaults,
                    ...layoutNode.options,
                    direction: direction === "horizontal" ? "row" : "column",
                    ...wrapperProps,
                    ...(isHidden && { display: "none" }),
                }, children: children })] }));
}
