import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.application_vat_uid_number",
        subtitle: "onboarding.declarationOfSubordination.title",
        description: "onboarding.declarationOfSubordination.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: [
                    "1. ",
                    "onboarding.declarationOfSubordination.downloadDocument",
                    " ",
                    { displayLabel: "onboarding.actions.here", href: "/" },
                ],
            },
            {
                key: "label3",
                type: "label",
                label: [
                    "2. ",
                    "onboarding.declarationOfSubordination.upload",
                    " ",
                    {
                        strong: true,
                        displayLabel: "onboarding.declarationOfSubordination.signed",
                    },
                    " ",
                    "onboarding.declarationOfSubordination.declarationOfSubordinationHere",
                ],
            },
            {
                key: "files",
                type: "file",
                label: "onboarding.uploadFilesArea.files",
                multiple: true,
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        files: {
            type: "array",
            items: {
                type: "string",
            },
            // minItems: 1,
            uniqueItems: true,
        },
    },
    additionalProperties: false,
    required: ["files"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
