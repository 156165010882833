import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePostArticleNoteMutation } from "~/pages/customs-classification/redux/customsClassification.api";
import { ArticleDrawerContext } from "./ImportArticleDrawer";
export const NewNoteField = () => {
    const { importId, articleId } = useContext(ArticleDrawerContext);
    const [value, setValue] = useState("");
    const { t } = useTranslation();
    const [createNote, { isLoading }] = usePostArticleNoteMutation();
    const handleCreateNote = async () => {
        await createNote({ importId, articleId, value });
        setValue("");
    };
    const textCount = 320 - value.length;
    return (_jsxs(Box, { bg: "sky.5", px: "10", pt: "4", pb: "6", children: [_jsx(Text, { textStyle: "labelUppercase", mb: "16px", children: t("articles.detail.newNoteHeader") }), _jsx(Textarea, { mb: "4", bg: "white", value: value, onChange: (e) => setValue(e.target.value), resize: "none", p: "3", placeholder: t("articles.detail.newNotePlaceholder"), h: "128px" }), _jsxs(Flex, { alignItems: "center", w: "full", justifyContent: "space-between", children: [_jsx(Text, { textStyle: "bodySmall", color: textCount < 0 ? "danger.500" : "grey.60", children: t("articles.detail.newNoteCounter", { count: textCount }) }), _jsx(Button, { isLoading: isLoading, isDisabled: !value.length || textCount <= 0, variant: "primary", onClick: handleCreateNote, children: t("articles.detail.newNoteCTA") })] })] }));
};
