import { getNestedValue } from "~/helpers/nested-object";
import * as base from "./base";
import * as outboundProcess from "./outbound-process";
import * as productDetails from "./product-details";
import * as technicalSetup from "./technical-setup";
import * as vatAndCustoms from "./vat-and-customs";
export const customComponentsRegistry = {
    vatAndCustoms,
    technicalSetup,
    productDetails,
    outboundProcess,
    base,
};
export function getCustomComponent(componentName) {
    return getNestedValue(customComponentsRegistry, componentName);
}
