import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.exportRegulation.title",
        description: "onboarding.productDetails.exportRegulation.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "h3",
                label: "onboarding.productDetails.exportRegulation.goodsSubjectAuthorization",
            },
            {
                type: "layout",
                direction: "vertical",
                forKey: "require_authorization",
                groupType: "radio",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.productDetails.exportRegulation.shipRequireAuthorization",
                    },
                    {
                        type: "radio",
                        key: "require_authorization",
                        label: "onboarding.actions.no",
                        value: "no",
                        forKey: "require_authorization",
                    },
                    {
                        type: "radio",
                        key: "yes",
                        label: "onboarding.actions.yes",
                        value: "yes",
                        forKey: "require_authorization",
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        rule: {
            effect: "SHOW",
            targetKey: "require_authorization",
            targetValue: "yes",
        },
        elements: [
            {
                key: "label4",
                type: "h3",
                label: "onboarding.productDetails.exportRegulation.permitsInPlace",
            },
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                options: {
                    pt: 2,
                    gap: 3,
                },
                forKey: "permits_in_place",
                elements: [
                    {
                        type: "radio",
                        key: "permits_in_place_yes",
                        label: "onboarding.actions.yes",
                        value: "yes",
                        forKey: "permits_in_place",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label5",
                                type: "text",
                                isTextarea: true,
                                label: "onboarding.productDetails.exportRegulation.whichAreAvailable",
                            },
                            {
                                key: "file1",
                                type: "file",
                                label: "onboarding.uploadFilesArea.dragFilesToUpload",
                                multiple: false,
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "permits_in_place",
                            targetValue: "yes",
                        },
                    },
                    {
                        type: "radio",
                        key: "permits_in_place_no",
                        label: "onboarding.actions.no",
                        value: "no",
                        forKey: "permits_in_place",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "checkbox",
                        forKey: "assist_permits",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label6",
                                type: "label",
                                label: "onboarding.productDetails.exportRegulation.assistApplyingPermits",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                groupType: "checkbox",
                                options: { gap: 3 },
                                forKey: "assist_permits",
                                elements: [
                                    {
                                        key: "assist_permits_yes",
                                        type: "checkbox",
                                        value: "yes",
                                        label: "onboarding.actions.yes",
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "permits_in_place",
                            targetValue: "no",
                        },
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
