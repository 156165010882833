import { VatRoutes, alreadyHaveVatIdRoutes, registerVatId, } from "~/layouts/onboarding/components/steps-list/routes";
export default function generateVatUrlSteps(value) {
    if (!value)
        return [];
    switch (value.fieldValue) {
        case "register-vat-id":
            return registerVatId;
        case "already-have-vat-id":
            return alreadyHaveVatIdRoutes;
        case "external-tax-id":
            return VatRoutes;
        default:
            return VatRoutes;
    }
}
