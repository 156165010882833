import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { clientsApi, useRemoveClientServiceMutation, } from "~/pages/clients/redux/clients.api";
import { servicesApi } from "~/pages/services/redux/services.api";
export const RemoveTariffingCountryModal = ({ handleClose, service, shipDirection }) => {
    const { params: { id }, } = useMatch();
    const isShipTo = shipDirection === ShipDirection.TO;
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [removeService, { isLoading: isRemoveLoading }] = useRemoveClientServiceMutation();
    const handleRemove = async () => {
        if (service) {
            try {
                const res = await removeService({
                    clientId: Number(id),
                    clientServiceId: service.id,
                });
                if ("data" in res) {
                    toast({
                        title: t("clients.removeTariffingCountryModal.success"),
                        status: "success",
                        duration: 3000,
                    });
                }
                handleClose();
                dispatch(clientsApi.util.invalidateTags(["CLIENT_SERVICES"]));
                dispatch(servicesApi.util.invalidateTags(["SERVICE"]));
            }
            catch (err) {
                toast({
                    title: t("error.badRequest.removeTariffingCountry"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };
    return (_jsxs(Modal, { isOpen: !!shipDirection, onClose: handleClose, title: t("clients.removeTariffingCountryModal.title", {
            direction: t(`clients.removeTariffingCountryModal.${isShipTo ? "shipTo" : "shipFrom"}`),
        }), primaryTitle: t("clients.removeTariffingCountryModal.primaryTitle"), primaryAction: handleRemove, primaryVariant: "danger", primaryActionDisabled: isRemoveLoading, primaryActionLoading: isRemoveLoading, children: [_jsx(Text, { mt: "2", color: "grey.60", children: t("clients.removeTariffingCountryModal.subtitle") }), isShipTo && (_jsx(Box, { backgroundColor: "warning.20", borderRadius: "4px", borderWidth: "1px", borderColor: "warning.40", px: 4, py: 3, mt: 6, children: _jsx(Text, { children: t("clients.removeTariffingCountryModal.shipToNote") }) }))] }));
};
