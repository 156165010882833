import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { WarningTriangleIcon } from "~/assets/icons/WarningTriangleIcon";
import Icon from "~/components/Icon/Icon";
export var PillVariant;
(function (PillVariant) {
    PillVariant["SUCCESS"] = "SUCCESS";
    PillVariant["WARNING"] = "WARNING";
})(PillVariant || (PillVariant = {}));
export const Pill = ({ text, variant }) => {
    const { SUCCESS, WARNING } = PillVariant;
    const config = useMemo(() => {
        let backgroundColor;
        let icon;
        let textWeight = 400;
        let textColor = "brand.black";
        switch (variant) {
            case SUCCESS:
                backgroundColor = "success.500";
                icon = _jsx(Icon, { name: "check-round", color: "brand.white", w: "5" });
                textColor = "brand.white";
                textWeight = 700;
                break;
            case WARNING:
                backgroundColor = "brand.white";
                icon = _jsx(WarningTriangleIcon, { color: "danger.500" });
                break;
        }
        return { backgroundColor, icon, textColor, textWeight };
    }, [variant, text]);
    return (_jsxs(Center, { h: "8", px: 3, gap: 2, whiteSpace: "nowrap", backgroundColor: config.backgroundColor, borderRadius: "full", children: [config.icon, _jsx(Text, { color: config.textColor, fontWeight: config.textWeight, children: text })] }));
};
