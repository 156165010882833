import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, lastSavedFieldState, } from "~/store/selectors/formData.selectors";
export function ApplicationVatUidTaxInSwitzerland() {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const lastSavedField = useSelector(lastSavedFieldState);
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "vatResponsible",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (e) => {
        dispatch(patchFormField({
            fieldName: "taxTepresentation",
            fieldValue: e.target.value,
            id,
            flowId,
            pageSlug: "vatResponsible",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.zazStart.headline"), subtitle: t("onboarding.vat.flow.zazStart.subtitle") }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { fontSize: "2xl", children: t("onboarding.vat.flow.zazStart.title") }), _jsx(RadioGroup, { value: (_a = formData === null || formData === void 0 ? void 0 : formData.taxTepresentation) === null || _a === void 0 ? void 0 : _a.fieldValue, children: _jsxs(Stack, { direction: "column", children: [_jsxs(Radio, { value: "no", onChange: updateField, alignItems: "flex-start", children: [_jsx(Text, { fontWeight: "bold", children: t("onboarding.vat.flow.zazStart.option1.title") }), _jsx(Text, { children: t("onboarding.vat.flow.zazStart.option1.description") })] }), _jsxs(Radio, { value: "yes", onChange: updateField, alignItems: "flex-start", children: [_jsx(Text, { fontWeight: "bold", children: t("onboarding.vat.flow.zazStart.option2.title") }), _jsx(Text, { children: t("onboarding.vat.flow.zazStart.option2.description") })] })] }) }), lastSavedField === "taxTepresentation" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" }))] }), _jsx(FormFooter, { isNextDisabled: ((_b = formData === null || formData === void 0 ? void 0 : formData.taxTepresentation) === null || _b === void 0 ? void 0 : _b.fieldValue) === undefined, isBackVisible: false, isResetVisible: true, isSaveForLaterVisible: true, pageSlug: "vatResponsible", processSlug: "administrativeProcesses", vatPage: ((_c = formData === null || formData === void 0 ? void 0 : formData.taxTepresentation) === null || _c === void 0 ? void 0 : _c.fieldValue) === "yes" ? "1.4" : "1.5", nextUrl: ((_d = formData === null || formData === void 0 ? void 0 : formData.taxTepresentation) === null || _d === void 0 ? void 0 : _d.fieldValue) === "yes"
                    ? `/clients/${id}/onboarding/${flowId}/administrative-processes/zaz-account-number`
                    : `/clients/${id}/onboarding/${flowId}/administrative-processes/calculation-of-security-deposit` }, (_e = formData === null || formData === void 0 ? void 0 : formData.taxTepresentation) === null || _e === void 0 ? void 0 : _e.fieldValue)] }));
}
