import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "~/components/Drawer/Drawer";
import { DrawerTabNavigation } from "~/components/DrawerTabNavigation/DrawerTabNavigation";
import Icon from "~/components/Icon/Icon";
import { useLazyGetArticleNotesByIdQuery, useLazyGetImportArticleByIdQuery, } from "~/pages/customs-classification/redux/customsClassification.api";
import { ArticleDetailsTab } from "./ArticleDetailsTab";
import { ArticleNotesTab } from "./ArticleNotesTab";
export var ImportArticlesDrawerTabs;
(function (ImportArticlesDrawerTabs) {
    ImportArticlesDrawerTabs["DETAILS"] = "DETAILS";
    ImportArticlesDrawerTabs["NOTES"] = "NOTES";
})(ImportArticlesDrawerTabs || (ImportArticlesDrawerTabs = {}));
const defaultContext = {
    articleId: 0,
    importId: 0,
};
export const ArticleDrawerContext = createContext(defaultContext);
export const ImportArticleDrawer = ({ articleId, onClose, isViewOnly = false, importMeta, isPendingUpload = false, activeTab = ImportArticlesDrawerTabs.DETAILS, }) => {
    const [articleContext, setArticleContext] = useState(defaultContext);
    const { t } = useTranslation();
    const { params: { id }, } = useMatch();
    const importId = Number(id);
    const [tab, setTab] = useState(0);
    const [getArticle, { data: article, isFetching }] = useLazyGetImportArticleByIdQuery();
    const [getArticleNotes, { data: articleNotes, isFetching: isFetchingNotes }] = useLazyGetArticleNotesByIdQuery();
    useEffect(() => {
        if (articleId) {
            getArticle({ articleId, importId });
            getArticleNotes({ articleId, importId });
            setTab(activeTab === ImportArticlesDrawerTabs.NOTES ? 1 : 0);
            setArticleContext({ articleId, importId });
        }
    }, [articleId]);
    const tabs = article
        ? [
            {
                title: t("articles.detail.detailsHeader"),
                value: "details",
                element: (_jsx(ArticleDetailsTab, { isViewOnly: isViewOnly, importMeta: importMeta, article: article })),
            },
            {
                title: t("articles.detail.notesHeader", {
                    count: articleNotes === null || articleNotes === void 0 ? void 0 : articleNotes.data.length,
                }),
                value: "notes",
                element: _jsx(ArticleNotesTab, { isPendingUpload: isPendingUpload }),
            },
        ]
        : [];
    const showLoader = isFetching || !article || isFetchingNotes || !articleNotes;
    return (_jsx(ArticleDrawerContext.Provider, { value: articleContext, children: _jsx(Drawer, { isOpen: !!articleId, onClose: onClose, isWide: true, children: showLoader ? (_jsx(Flex, { h: "full", w: "full", justifyContent: "center", alignItems: "center", children: _jsx(Spinner, { color: "brand.mare" }) })) : (_jsxs(Flex, { flexDirection: "column", h: "full", children: [_jsxs(Box, { flexShrink: "0", paddingX: "10", children: [_jsx(Text, { textStyle: "h3", fontWeight: "700", mb: 2, overflow: "hidden", style: {
                                    display: "-webkit-box",
                                    WebkitLineClamp: 4,
                                    WebkitBoxOrient: "vertical",
                                }, children: article.shipFromDescription }), _jsxs(Flex, { alignItems: "center", mb: 6, children: [_jsxs(Text, { color: "grey.60", children: ["#", article.articleNumber] }), article.urlLink && (_jsxs(_Fragment, { children: [_jsx(Text, { mx: "3", color: "grey.60", children: "\u00B7" }), _jsxs(Link, { href: article.urlLink, isExternal: true, display: "flex", alignItems: "center", color: "brand.sky", children: [t("articles.detail.articleLink"), _jsx(Icon, { w: "4", ml: "4", name: "external" })] })] }))] })] }), _jsx(Box, { overflow: "hidden", children: _jsx(DrawerTabNavigation, { tabs: tabs, headerProps: { marginX: 10 }, tabsProps: {
                                defaultIndex: tab,
                                tabIndex: tab,
                                onChange: setTab,
                            } }) })] })) }) }));
};
