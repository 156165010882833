import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import InputError from "~/components/InputError/InputError";
import { lastSavedFieldState } from "~/store/selectors/formData.selectors";
import InputSuccess from "../InputSuccess/InputSuccess";
export default function OnboardingFieldMessage({ savedFieldData, }) {
    const lastSavedField = useSelector(lastSavedFieldState);
    if (lastSavedField &&
        savedFieldData &&
        lastSavedField === savedFieldData.fieldName) {
        if (savedFieldData.fieldValue === "") {
            return null;
        }
        if (!savedFieldData.isValid) {
            return _jsx(InputError, { text: "FIELD_SAVE_ERROR" });
        }
        return _jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" });
    }
    return null;
}
