import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { useOnboardingDataMutation, useOnboardingDataQuery, } from "~/api/hooks/onboarding";
const OnboardingDataContext = React.createContext({
    onboardingData: {},
    setOnboardingData: () => null,
});
export const useOnboardingDataContext = () => useContext(OnboardingDataContext);
export function OnboardingDataContextProvider({ children, onboardingId, }) {
    const { data: fetchedData, refetch } = useOnboardingDataQuery(onboardingId);
    const onboardingDataMutation = useOnboardingDataMutation(onboardingId);
    const [data, setData] = useState({});
    useEffect(() => setData((prevData) => {
        return { ...fetchedData, ...prevData };
    }), [fetchedData]);
    return (_jsx(OnboardingDataContext.Provider, { value: {
            onboardingData: data,
            setOnboardingData: (value) => {
                setData((prevData) => {
                    const newData = { ...prevData, ...value };
                    onboardingDataMutation.mutate(newData);
                    refetch();
                    return newData;
                });
            },
        }, children: children }));
}
export default OnboardingDataContextProvider;
