import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServicePlaceholder } from "~/components/Service/Service";
import { ServiceCode } from "~/enums/service-code.enum";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { PartnerServicePlaceholders } from "~/pages/services/consts/emptyServices";
import { useGetAllServicesQuery } from "~/pages/services/redux/services.api";
import { useAppSelector } from "~/store/hooks";
import { useLazyGetPartnerServicesQuery } from "../../redux/partners.api";
import { Tariffing } from "./components/Tariffing/Tariffing";
export const PartnerServices = () => {
    const { data: allServices, isFetching } = useGetAllServicesQuery();
    const [fetchService, { data: servicesData, isFetching: isFetchingPartnerServices },] = useLazyGetPartnerServicesQuery();
    const [partnerServices, setPartnerServices] = useState([]);
    const { pathname, params: { id: paramId }, } = useMatch();
    const me = useAppSelector(authSelector).user; //eslint-disable-line
    const pageType = pathname.split("/")[1];
    const isOwnUserView = pageType === "company-profile";
    const partnerId = isOwnUserView ? me.accountId : Number(paramId); //eslint-disable-line
    const { t } = useTranslation();
    const fetchPartnerServices = async () => {
        if (allServices) {
            const services = [];
            for (const service of allServices) {
                try {
                    const { data } = await fetchService({
                        partnerId,
                        service: service.code,
                    });
                    if (data)
                        services.push(data);
                    else
                        services.push(PartnerServicePlaceholders[service.code]);
                }
                catch (err) {
                    //
                }
            }
            setPartnerServices(services);
        }
    };
    const updateService = (service) => {
        const services = [...partnerServices];
        const found = services.find((s) => s.serviceCode === service.serviceCode);
        if (found)
            services.splice(services.indexOf(found), 1, service);
        else
            services.push(service);
        setPartnerServices(services);
    };
    useEffect(() => {
        if (servicesData)
            updateService(servicesData);
    }, [servicesData]);
    useEffect(() => {
        if (!isFetching)
            fetchPartnerServices();
    }, [isFetching]);
    const renderServices = () => {
        if ((isFetching || isFetchingPartnerServices) && !partnerServices.length)
            return _jsx(ServicePlaceholder, {});
        return partnerServices.map((service) => {
            switch (service.serviceCode) {
                case ServiceCode.TARIFFING:
                    return (_jsx(Tariffing, { service: service, isLoaded: !isFetching &&
                            !isFetchingPartnerServices &&
                            !!partnerServices.length }, `Partner__Service__${service.serviceCode}__${service.id}`));
                default:
                    return null;
            }
        });
    };
    return (_jsxs(Box, { maxW: "708px", children: [_jsx(Text, { my: "6", textStyle: "h2", children: t("partners.detail.tabs.services") }), _jsx(Flex, { direction: "column", gap: "4", children: renderServices() })] }));
};
