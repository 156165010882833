import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useAppDispatch } from "~/store/hooks";
import { fetchFormById } from "~/store/actions/formData.actions";
import { getFormData } from "~/store/selectors/formData.selectors";
export function ApplicationVatUidTaxDocumentsSignInfo() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const [registerOrAlreadyHaveVatId, setRegisterOrAlreadyHaveVatId] = useState(null);
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "vatApplicationStart",
            processSlug: "administrativeProcesses",
        }));
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "vatApplicationStart",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    useEffect(() => {
        var _a, _b;
        if (formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) {
            if (((_a = formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) === null || _a === void 0 ? void 0 : _a.fieldValue) === "register-vat-id")
                setRegisterOrAlreadyHaveVatId("taxDocumentsSignInfo");
            if (((_b = formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) === null || _b === void 0 ? void 0 : _b.fieldValue) ===
                "already-have-vat-id")
                setRegisterOrAlreadyHaveVatId("taxDocumentsSignInfoFlowTwo");
        }
    }, [formData]);
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.headline`), subtitle: t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.subtitle`), description: "" }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { children: t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.description`) }), _jsxs(Flex, { direction: "column", mb: "4", children: [_jsx(Text, { children: t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.listinfo`) }), Object.keys(t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.list`, {
                                returnObjects: true,
                            })).map((el, index) => {
                                return (_jsxs(Text, { children: [index + 1, ".", " ", t(`onboarding.vat.flow.${registerOrAlreadyHaveVatId}.list`, {
                                            returnObjects: true,
                                        })[el]] }));
                            })] }), _jsx(Text, { children: t("onboarding.vat.flow.taxDocumentsSignInfo.endlist") })] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, vatPage: "2.4", nextUrl: `/clients/${id}/onboarding/${flowId}/administrative-processes/vat-uid-number` })] }));
}
