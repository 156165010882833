import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { TabNavigation, } from "~/components/TabNavigation/TabNavigation";
import { WithPermission } from "~/components/WithPermission/WithPermission";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useGetClientByIdQuery } from "~/pages/clients/redux/clients.api";
import { useGetAllErrorCasesQuery } from "~/pages/customs-classification/redux/customsClassification.api";
import { RouteMap } from "~/routing/route.config";
import { useAppSelector } from "~/store/hooks";
import { AllArticlesTab } from "../AllArticles/AllArticles";
import { ClassificationCasesTab } from "../ClassificationCases/ClassificationCases";
import { ClassificationPlaceholder } from "../ClassificationPlaceholder/ClassificationPlaceholder";
import { ErrorCasesTab } from "../ErrorCases/ErrorCases";
import { ServicesTab } from "../Services/Services";
import { SettingsTab } from "../Settings/Settings";
import { UploadTariffingFileModal } from "../UploadTariffingFileModal/UploadTariffingFileModal";
export const CustomsClassificationPage = () => {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const { user } = useAppSelector(authSelector);
    if (!user || !user.accountId)
        return null;
    const { data: errorCases, isFetching: isFetchingErrors } = useGetAllErrorCasesQuery({});
    const { data: client, isFetching: isFetchingClient } = useGetClientByIdQuery(user.accountId);
    const { t } = useTranslation();
    const breadcrumbs = [
        {
            title: t("customsClassification.pageHeadline"),
            link: RouteMap.CUSTOMS_CLASSIFICATION,
        },
    ];
    const tabs = useMemo(() => [
        {
            title: t("customsClassification.tabs.classificationCases.name"),
            value: "classification-cases",
            element: _jsx(ClassificationCasesTab, {}),
        },
        ...((user === null || user === void 0 ? void 0 : user.accountId)
            ? [
                {
                    title: t("customsClassification.tabs.allArticles.name"),
                    value: "all-articles",
                    element: _jsx(AllArticlesTab, { clientId: user === null || user === void 0 ? void 0 : user.accountId }),
                },
            ]
            : []),
        {
            title: t("customsClassification.tabs.settings.name"),
            value: "settings",
            element: _jsx(SettingsTab, {}),
        },
        {
            title: t("customsClassification.tabs.errorCases.name"),
            value: "error-cases",
            element: _jsx(ErrorCasesTab, {}),
            badge: (errorCases === null || errorCases === void 0 ? void 0 : errorCases.pagination.totalItems) || undefined,
        },
        {
            title: t("customsClassification.tabs.services.name"),
            value: "services",
            element: _jsx(ServicesTab, {}),
        },
    ], [errorCases, user]);
    const headerAction = (_jsx(WithPermission, { permission: EPermissionCodes.CLIENT_TARI_IMPORTS_MANAGE, children: _jsx(Button, { onClick: () => setIsUploadModalOpen(true), children: t("customsClassification.tabs.classificationCases.uploadCTA") }) }));
    return isFetchingClient || isFetchingErrors || !client ? (_jsx(FullPageLoader, {})) : client.activeServices.TARIFFING ? (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: t("customsClassification.pageHeadline"), breadcrumbs: breadcrumbs, actions: headerAction }), _jsx(ContentSection, { children: _jsx(TabNavigation, { tabs: tabs }) }), _jsx(UploadTariffingFileModal, { isOpen: isUploadModalOpen, setIsOpen: setIsUploadModalOpen })] })) : (_jsx(ClassificationPlaceholder, {}));
};
