import i18n from "~/i18n";
export default function transport(flowFields) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const when = "-";
    let who = "";
    const sameCarrierAsOutbound = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "sameCarrierAsOutbound" &&
        data.pageSlug === "returnPickUp")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    if (sameCarrierAsOutbound === "yes") {
        const exportOrganizer = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "exportOrganizer" &&
            data.pageSlug === "exportFromEu")) === null || _b === void 0 ? void 0 : _b.fieldValue;
        if (exportOrganizer === "viaMS")
            who = i18n.t("processOverview.msDirect");
        if (exportOrganizer === "myself") {
            who =
                ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "myselfCompanyName" &&
                    data.pageSlug === "exportFromEu")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
        }
    }
    if (sameCarrierAsOutbound === "no") {
        who =
            ((_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returnPickUp")) === null || _d === void 0 ? void 0 : _d.fieldValue) || "";
    }
    let pickUpCity;
    const whoHandlesReturns = (_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whoHandlesReturns" && data.pageSlug === "returns")) === null || _e === void 0 ? void 0 : _e.fieldValue;
    if (whoHandlesReturns === "ms-direct") {
        pickUpCity = i18n.t("processOverview.msDirectAddress");
    }
    if (whoHandlesReturns &&
        ["other-patners", "we-ourselves"].includes(whoHandlesReturns)) {
        pickUpCity = (_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returns")) === null || _f === void 0 ? void 0 : _f.fieldValue;
    }
    let wareHouseCity;
    const sameAddressAsOutbound = (_g = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "sameAddressAsOutbound" &&
        data.pageSlug === "returnWarehouseLocation")) === null || _g === void 0 ? void 0 : _g.fieldValue;
    if (sameAddressAsOutbound === "yes") {
        wareHouseCity = (_h = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wareHouseCity" &&
            data.pageSlug === "deliveryFromWarehouseLocation")) === null || _h === void 0 ? void 0 : _h.fieldValue;
    }
    if (sameAddressAsOutbound === "no") {
        wareHouseCity = (_j = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "city" &&
            data.pageSlug === "returnWarehouseLocation")) === null || _j === void 0 ? void 0 : _j.fieldValue;
    }
    let what = "";
    if (who !== "" && pickUpCity && wareHouseCity) {
        what = i18n.t("processOverview.inboundProcess.transport.whatPickUp", {
            pickUpCity,
            company: who,
            wareHouseCity,
        });
    }
    return {
        when,
        who,
        what,
        icon: "transport",
        title: i18n.t("processOverview.inboundProcess.transport.title"),
        day: "2-5",
    };
}
