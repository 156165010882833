import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import ShippingProcesses from "~/components/ShippingProcesses/ShippingProcesses";
import translatable from "~/helpers/translatable";
import { useOnboardingDataContext } from "../../OnboardingDataContextProvider";
const title = translatable("onboarding.outboundProcesses.processOverview.title");
function StepOverview() {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const { onboardingData } = useOnboardingDataContext();
    const outboundProcessOriginWarehouse = onboardingData["onboarding.outbound_process.origin_warehouse"];
    const outboundProcessExportEU = onboardingData["onboarding.outbound_process.export_EU"];
    const outboundProcessImport = onboardingData["onboarding.outbound_process.import"];
    const outboundProcessDelivery = onboardingData["onboarding.outbound_process.delivery"];
    const isDPDOrPost = ((_b = (_a = outboundProcessDelivery === null || outboundProcessDelivery === void 0 ? void 0 : outboundProcessDelivery.lastMile) === null || _a === void 0 ? void 0 : _a.carrier) === null || _b === void 0 ? void 0 : _b.startsWith("post"))
        ? "POST"
        : "DPD";
    const exportOrganizer = ((_c = outboundProcessExportEU.carrier) === null || _c === void 0 ? void 0 : _c.name.includes("another"))
        ? outboundProcessExportEU.export_company.organizer_details.name
        : outboundProcessExportEU.export_company.export_organizer;
    const carrierName = ((_d = outboundProcessExportEU.carrier) === null || _d === void 0 ? void 0 : _d.name.includes("another"))
        ? outboundProcessExportEU.carrier.details.name
        : (_e = outboundProcessExportEU.carrier) === null || _e === void 0 ? void 0 : _e.name;
    const locationOfRepresentation = outboundProcessImport.presentationLocation.location.includes("another")
        ? outboundProcessImport.presentationLocation.details.name
        : outboundProcessImport.presentationLocation.location;
    const steps = [
        {
            title: translatable("onboarding.outboundProcesses.processOverview.cutOffTime"),
            description: [
                t("onboarding.outboundProcesses.processOverview.cutOffTimeInformation", {
                    time: outboundProcessOriginWarehouse.cutoffTimeSelect,
                }),
                t("onboarding.outboundProcesses.processOverview.cutOffDays", {
                    days: outboundProcessOriginWarehouse.pickupTimes.estimatedTime.join(","),
                }),
            ],
        },
        {
            title: translatable("onboarding.outboundProcesses.processOverview.exportFromEU"),
            description: t("onboarding.outboundProcesses.processOverview.exportCustomClearance", {
                firstTime: outboundProcessOriginWarehouse.cutoffTimeSelect,
                lastTime: outboundProcessOriginWarehouse.estimatedTimeSelect,
                nameOfExportCompany: exportOrganizer,
            }),
        },
        {
            title: translatable("onboarding.outboundProcesses.processOverview.collectionFromTheWarehouse"),
            description: t("onboarding.outboundProcesses.processOverview.pickUp", {
                lastTime: outboundProcessOriginWarehouse === null || outboundProcessOriginWarehouse === void 0 ? void 0 : outboundProcessOriginWarehouse.estimatedTimeSelect,
                zip: (_f = outboundProcessOriginWarehouse.warehouse) === null || _f === void 0 ? void 0 : _f.zip_code,
                city: (_g = outboundProcessOriginWarehouse.warehouse) === null || _g === void 0 ? void 0 : _g.city,
                carrier: carrierName,
            }),
        },
        {
            title: translatable("onboarding.outboundProcesses.processOverview.transport"),
            description: t("onboarding.outboundProcesses.processOverview.packages", {
                destinationCountry: "Destination Country",
                locationOfPresentation: locationOfRepresentation,
            }),
        },
        {
            title: translatable("onboarding.outboundProcesses.processOverview.import"),
            description: t("onboarding.outboundProcesses.processOverview.customClearance", {
                importCustomsClearance: outboundProcessImport.customsClearance.method,
                locationOfPresentation: locationOfRepresentation,
            }),
        },
        {
            title: translatable("onboarding.outboundProcesses.processOverview.deliveryToTheParcelService"),
            description: [
                t("onboarding.outboundProcesses.processOverview.deliveryInformation", {
                    zip: outboundProcessDelivery.placeOfDelivery.zip_code,
                    city: outboundProcessDelivery.placeOfDelivery.city,
                    dpdOrPost: isDPDOrPost,
                }),
                outboundProcessDelivery.deliveryOptions &&
                    t("onboarding.outboundProcesses.processOverview.services", {
                        deliveryOptions: outboundProcessDelivery.deliveryOptions,
                    }),
            ],
        },
    ];
    return _jsx(ShippingProcesses, { title: title, steps: steps });
}
export default StepOverview;
