import { goLiveFormNodesAndSchema } from "../schemas/go-live";
export const GO_LIVE = {
    go_live: {
        initial: "start",
        id: "go_live",
        meta: {
            eventName: "GO_LIVE",
            label: "onboarding.landing.go_live",
        },
        states: {
            start: {
                meta: {
                    label: "onboarding.overview",
                    ...goLiveFormNodesAndSchema,
                },
                on: {
                    NEXT: "start",
                },
            },
        },
    },
};
