import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CopyText } from "../CopyText/CopyText";
import { OnboardingFormSection } from "../OnboardingFormSection/OnboardingFormSection";
export const SFTPPaths = {
    tariFilesImport: "/customs-classifications/tari_import",
    tariFilesExport: "/customs-classifications/tari_export",
};
export const SFTPCard = () => {
    const { t } = useTranslation();
    return (_jsx(OnboardingFormSection, { title: t("onboarding.sftpSetup.title"), children: _jsxs(Box, { children: [_jsx(Text, { mb: "8", children: t("onboarding.sftpSetup.subtitle") }), _jsx(Text, { textStyle: "labelBlack", children: t("onboarding.sftpSetup.server") }), _jsx(Text, { textStyle: "bodyPlaceholder", pt: "2", pb: "4", mb: "4", borderBottom: "1px solid", borderBottomColor: "grey.10", children: t("onboarding.sftpSetup.sentViaEmail") }), _jsx(Text, { textStyle: "labelBlack", children: t("onboarding.sftpSetup.username") }), _jsx(Text, { textStyle: "bodyPlaceholder", pt: "2", pb: "4", mb: "4", borderBottom: "1px solid", borderBottomColor: "grey.10", children: t("onboarding.sftpSetup.sentViaEmail") }), _jsx(Text, { textStyle: "labelBlack", children: t("onboarding.sftpSetup.password") }), _jsx(Text, { textStyle: "bodyPlaceholder", pt: "2", pb: "4", mb: "4", borderBottom: "1px solid", borderBottomColor: "grey.10", children: t("onboarding.sftpSetup.sentViaEmail") }), _jsx(Text, { textStyle: "labelBlack", children: t("onboarding.sftpSetup.tariFilesImport") }), _jsx(Box, { pt: "2", pb: "4", mb: "4", borderBottom: "1px solid", borderBottomColor: "grey.10", children: _jsx(Box, { as: "span", w: "auto", display: "inline-block", children: _jsx(CopyText, { textProps: { color: "brand.black" }, text: SFTPPaths.tariFilesImport }) }) }), _jsx(Text, { textStyle: "labelBlack", children: t("onboarding.sftpSetup.tariFilesExport") }), _jsx(Box, { pt: "2", as: "span", w: "auto", display: "inline-block", children: _jsx(CopyText, { textProps: { color: "brand.black" }, text: SFTPPaths.tariFilesExport }) })] }) }));
};
