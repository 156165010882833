import i18n from "~/i18n";
export default function arrivalAtTheWarehouse(flowFields) {
    var _a, _b, _c, _d, _e, _f, _g;
    const when = "-";
    let who = "";
    const sameCarrierAsOutbound = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "sameCarrierAsOutbound" &&
        data.pageSlug === "returnPickUp")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    if (sameCarrierAsOutbound === "yes") {
        const exportOrganizer = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "exportOrganizer" &&
            data.pageSlug === "exportFromEu")) === null || _b === void 0 ? void 0 : _b.fieldValue;
        if (exportOrganizer === "viaMS")
            who = i18n.t("processOverview.msDirect");
        if (exportOrganizer === "myself") {
            who =
                ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "myselfCompanyName" &&
                    data.pageSlug === "exportFromEu")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
        }
    }
    if (sameCarrierAsOutbound === "no") {
        who =
            ((_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returnPickUp")) === null || _d === void 0 ? void 0 : _d.fieldValue) || "";
    }
    let wareHouseCity;
    const sameAddressAsOutbound = (_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "sameAddressAsOutbound" &&
        data.pageSlug === "returnWarehouseLocation")) === null || _e === void 0 ? void 0 : _e.fieldValue;
    if (sameAddressAsOutbound === "yes") {
        wareHouseCity = (_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wareHouseCity" &&
            data.pageSlug === "deliveryFromWarehouseLocation")) === null || _f === void 0 ? void 0 : _f.fieldValue;
    }
    if (sameAddressAsOutbound === "no") {
        wareHouseCity = (_g = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "city" &&
            data.pageSlug === "returnWarehouseLocation")) === null || _g === void 0 ? void 0 : _g.fieldValue;
    }
    let what = "";
    if (wareHouseCity) {
        what = `${i18n.t("processOverview.inboundProcess.arrivalAtTheWarehouse.whatArrival")}
    <br>${i18n.t("processOverview.inboundProcess.arrivalAtTheWarehouse.whatCity", {
            city: wareHouseCity,
        })} <br> ${i18n.t("processOverview.inboundProcess.arrivalAtTheWarehouse.whatUpload")}`;
    }
    return {
        when,
        who,
        what,
        icon: "cutoffwarehouse",
        title: i18n.t("processOverview.inboundProcess.arrivalAtTheWarehouse.title"),
        day: "3-6",
    };
}
