import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE } from "~/consts/customs-classification-cases-max-size";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { importsBaseUrl } from "~/pages/customs-classification/redux/customsClassification.api";
import { useAppSelector } from "~/store/hooks";
export const TestImportFileUpload = () => {
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const toast = useToast();
    const { t } = useTranslation();
    const { token, user } = useAppSelector(authSelector);
    const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE,
    });
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };
    const handleFileUpload = async () => {
        if (user === null || user === void 0 ? void 0 : user.accountId) {
            setIsFetching(true);
            const formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            formData.append("clientId", String(user.accountId));
            try {
                const res = await fetch(withBaseUrl(`${importsBaseUrl}/test`), {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                });
                if (res.ok) {
                    setIsError(false);
                    reset();
                    toast({
                        title: t("onboarding.productDetails.defineImportFile.testUpload.success"),
                        status: "success",
                        duration: 5000,
                    });
                }
                else {
                    setIsError(true);
                }
                setIsFetching(false);
            }
            catch (err) {
                setIsError(true);
            }
        }
    };
    return (_jsxs(Flex, { direction: "column", children: [_jsxs(Flex, { direction: "column", alignItems: "center", justifyContent: "center", flex: 1, gap: 3, minHeight: 20, backgroundColor: "sky.10", borderRadius: "4px", borderColor: "brand.sky", borderWidth: "2px", borderStyle: "dashed", cursor: "pointer", ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), acceptedFiles.length ? (_jsx(Text, { children: acceptedFiles[0].name })) : (_jsx(Text, { children: _jsx(Trans, { components: {
                                cta: _jsx(Text, { as: "span", color: "brand.sky", fontWeight: "700" }),
                            }, i18nKey: "customsClassification.uploadTariffingFileModal.dragAndDropZone.description", values: {
                                CTA: t("customsClassification.uploadTariffingFileModal.dragAndDropZone.cta"),
                            } }) }))] }), _jsx(Button, { alignSelf: "flex-end", size: "lg", mt: "4", isLoading: isFetching, isDisabled: !acceptedFiles.length, onClick: handleFileUpload, children: t("onboarding.productDetails.defineImportFile.testUpload.cta") }), isError && (_jsx(ErrorMessage, { text: t("error.badRequest.somethingWentWrong") }))] }));
};
