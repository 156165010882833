import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { CountrySelect } from "~/components/CountrySelect/CountrySelect";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAddShipmentDestinationMutation, useGetClientByIdQuery, } from "~/pages/clients/redux/clients.api";
import { AddShipToCountryValidationSchema } from "~/pages/clients/schemas/AddShipToCountrySchema";
import { useAppSelector } from "~/store/hooks";
export const AddShipToCountryModal = ({ isOpen, setIsOpen }) => {
    var _a;
    const { t } = useTranslation();
    const toast = useToast();
    const { user } = useAppSelector(authSelector);
    const { accountId } = user || {};
    const { pathname, params: { id }, } = useMatch();
    const pageType = pathname.split("/")[1];
    const isOwnUserView = pageType === "company-profile" && accountId;
    const clientId = isOwnUserView ? accountId : Number(id);
    const { data: client } = useGetClientByIdQuery(clientId);
    const [addShipToCountry, { isLoading }] = useAddShipmentDestinationMutation();
    const { control, handleSubmit, setValue, watch, reset, formState: { errors, isDirty }, } = useForm({
        defaultValues: {
            shipTo: [],
        },
        resolver: zodResolver(AddShipToCountryValidationSchema),
    });
    const values = useMemo(() => { var _a; return (_a = watch("shipTo").map((country) => country.value)) !== null && _a !== void 0 ? _a : []; }, [watch()]);
    const onSubmit = async (data) => {
        const body = {
            shipTo: data.shipTo.map((country) => country.value),
        };
        try {
            const res = await addShipToCountry({
                id: Number(id),
                body,
            });
            if ("data" in res) {
                toast({
                    title: t("clients.detail.services.addShipToCountryModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.addTariffingCountry"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const handleClose = () => {
        setIsOpen(false);
        reset();
    };
    useEffect(() => {
        if (!isOpen)
            reset();
    }, [isOpen]);
    return (_jsx(Modal, { isOpen: isOpen, onClose: handleClose, title: t("clients.detail.services.addShipToCountryModal.title"), primaryTitle: t("clients.detail.services.addShipToCountryModal.primaryCta"), primaryAction: handleSubmit(onSubmit), primaryActionDisabled: !isDirty, primaryActionLoading: isLoading, children: _jsxs(Flex, { direction: "column", gap: 4, mt: 8, children: [_jsx(CountrySelect, { controlProps: {
                        name: "shipTo",
                        control,
                    }, setValue: setValue, selectedCountries: values, usedCountries: client === null || client === void 0 ? void 0 : client.shipmentDestination, shipDirection: ShipDirection.TO }), ((_a = errors.shipTo) === null || _a === void 0 ? void 0 : _a.message) && (_jsx(ErrorMessage, { text: errors.shipTo.message }))] }) }));
};
