import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { Avatar } from "../Avatar/Avatar";
export const UserItem = ({ user }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user: me } = useAppSelector(authSelector);
    const handleClick = () => {
        if ((me === null || me === void 0 ? void 0 : me.email) === user.email)
            navigate({ to: "/profile" });
        else
            navigate({ to: `user?email=${user.email}` });
    };
    const userUnderCreation = user.internalCreationDate
        ? !(Date.now() - user.internalCreationDate > 60000)
        : false;
    return (_jsxs(Flex, { p: 2, onClick: userUnderCreation ? undefined : handleClick, borderRadius: "4px", bg: "transparent", opacity: userUnderCreation ? 0.5 : 1, _hover: userUnderCreation ? {} : { bgColor: "white" }, transitionDuration: "200ms", alignItems: "center", cursor: userUnderCreation ? "default" : "pointer", role: "group", children: [_jsx(Avatar, { user: user }), _jsxs(Box, { ml: 3, flexGrow: 1, overflow: "hidden", children: [_jsxs(Text, { children: [user.firstName, " ", user.lastName] }), _jsx(Text, { whiteSpace: "nowrap", overflow: "hidden", textStyle: "bodySmaller", color: "grey.60", textOverflow: "ellipsis", children: userUnderCreation
                            ? t("partners.detail.profile.teamMemberPending")
                            : user.email })] }), !userUnderCreation && (_jsx(Icon, { color: "grey.40", opacity: 0, transitionDuration: "200ms", _groupHover: { opacity: 1 }, name: "chevron-thick", w: "7.2px", mr: 2 }))] }));
};
