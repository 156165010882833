import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Image from "~/components/Image/Image";
import { getCountryCodeLabelKeyPairs } from "~/helpers/country-codes";
export default function InitialTariffing({ countries, tariffNumber, countryCode, }) {
    const { t } = useTranslation();
    const codes = getCountryCodeLabelKeyPairs();
    return (_jsx(VStack, { w: "full", h: "full", children: countries.map((el, index) => {
            return (_jsxs(HStack, { w: "full", children: [_jsxs(VStack, { w: "full", alignItems: "flex-start", children: [_jsx(Text, { fontWeight: "bold", children: t("onboarding.productDetails.initialProductClassification.fromWarehouseLocation") }), _jsx(Text, { children: codes[el] || el }), _jsx(Text, { fontWeight: "bold", children: t("onboarding.productDetails.initialProductClassification.toWarehouseLocation") }), _jsxs(Text, { children: [" ", codes[countryCode] || countryCode, " "] })] }), _jsxs(VStack, { w: "full", alignItems: "flex-start", children: [_jsx(Text, { fontWeight: "bold", children: t("onboarding.productDetails.initialProductClassification.tarifnumberAvailable") }), tariffNumber[index] === "yes" ? (_jsx(Image, { name: "check-icon", alt: "tariffnumber-available" })) : (_jsx(Image, { name: "error-icon", alt: "tariffnumber-available" }))] })] }, el));
        }) }));
}
