import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import { Link } from "@tanstack/react-location";
import { ArrowRightIcon } from "~/assets/icons/ArrowRightIcon";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { getCountryByIsoCode } from "~/utils/countries";
export const ClientOnboardingItem = ({ clientId, item }) => {
    const flowLink = `/clients/${clientId}/onboarding/${item.id}`;
    return (_jsxs(Flex, { as: Link, to: flowLink, justifyContent: "space-between", alignItems: "center", backgroundColor: "brand.white", borderBottomWidth: "1px", borderBottomColor: "grey.10", p: 4, _first: { borderTopRadius: "4px" }, _last: { borderBottomRadius: "4px" }, children: [_jsxs(Flex, { gap: 3, alignItems: "center", children: [_jsx(FlagIcon, { countryCode: item.countryCode, boxSize: 5 }), _jsx(Text, { children: getCountryByIsoCode(item.countryCode) })] }), _jsx(ArrowRightIcon, { boxSize: 6 })] }, `Onboarding__Link__${item.countryCode}__${item.id}`));
};
