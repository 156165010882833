export var ClassificationCasesStatus;
(function (ClassificationCasesStatus) {
    ClassificationCasesStatus["FILE_PROCESSING"] = "FILE_PROCESSING";
    ClassificationCasesStatus["PENDING"] = "PENDING";
    ClassificationCasesStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ClassificationCasesStatus["COMPLETED"] = "COMPLETED";
    ClassificationCasesStatus["FAILED"] = "FAILED";
})(ClassificationCasesStatus || (ClassificationCasesStatus = {}));
export var ClassificationCasesStatusTranslationKey;
(function (ClassificationCasesStatusTranslationKey) {
    ClassificationCasesStatusTranslationKey["FILE_PROCESSING"] = "fileProcessing";
    ClassificationCasesStatusTranslationKey["PENDING"] = "pending";
    ClassificationCasesStatusTranslationKey["IN_PROGRESS"] = "inProgress";
    ClassificationCasesStatusTranslationKey["COMPLETED"] = "completed";
    ClassificationCasesStatusTranslationKey["FAILED"] = "failed";
    ClassificationCasesStatusTranslationKey["HAS_ERRORS"] = "hasErrors";
    ClassificationCasesStatusTranslationKey["FILE_REJECTED"] = "fileRejected";
})(ClassificationCasesStatusTranslationKey || (ClassificationCasesStatusTranslationKey = {}));
export var ClassificationCasesStatusColor;
(function (ClassificationCasesStatusColor) {
    ClassificationCasesStatusColor["FILE_PROCESSING"] = "grey.40";
    ClassificationCasesStatusColor["PENDING"] = "grey.40";
    ClassificationCasesStatusColor["IN_PROGRESS"] = "warning.100";
    ClassificationCasesStatusColor["COMPLETED"] = "success.500";
    ClassificationCasesStatusColor["FAILED"] = "danger.500";
})(ClassificationCasesStatusColor || (ClassificationCasesStatusColor = {}));
