import { useNavigate, useSearch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "~/consts/pagination";
import { OrderDirection } from "~/types/pagination.type";
export const useParamSorting = () => {
    const search = useSearch();
    const navigate = useNavigate();
    const isSorted = !!search.orderBy;
    const [sorting, setSorting] = useState(isSorted
        ? [
            {
                id: search.orderBy,
                desc: search.orderDir === OrderDirection.DESC,
            },
        ]
        : []);
    useEffect(() => {
        navigate({
            search: (prev) => {
                const newSearch = {
                    ...prev,
                    page: DEFAULT_PAGINATION.pageIndex,
                    ...(sorting[0]
                        ? {
                            orderBy: sorting[0].id,
                            orderDir: sorting[0].desc
                                ? OrderDirection.DESC
                                : OrderDirection.ASC,
                        }
                        : {}),
                };
                if (!sorting[0]) {
                    delete newSearch.orderBy;
                    delete newSearch.orderDir;
                }
                return newSearch;
            },
            replace: true,
        });
    }, [sorting]);
    return [sorting, setSorting];
};
