import { z } from "zod";
import { AccountStatus } from "../types";
export const nonEmptyRefinement = (value) => {
    var _a;
    return ((_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0) >= 1;
};
export const NON_EMPTY_REFINEMENT_MESSAGE = {
    message: "required",
};
export const NO_UNDERSCORES_REGEX = /^[^_]*$/;
export const createClientSchema = z.object({
    name: z
        .string({ required_error: "required" })
        .regex(NO_UNDERSCORES_REGEX, "no_underscores")
        .refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    status: z.nativeEnum(AccountStatus),
    responsible: z
        .string({ required_error: "required" })
        .refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    shipToCountry: z
        .array(z.string())
        .refine((val) => val.length >= 1, NON_EMPTY_REFINEMENT_MESSAGE),
});
