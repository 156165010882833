import { jsx as _jsx } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useDeleteImportMutation } from "~/pages/customs-classification/redux/customsClassification.api";
export const DeleteErrorCaseModal = ({ id, setId }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [deleteErrorCase, { isLoading }] = useDeleteImportMutation();
    const handleDelete = async () => {
        if (id) {
            try {
                const res = await deleteErrorCase({ id });
                if ("data" in res) {
                    toast({
                        title: t("customsClassification.tabs.errorCases.deleteErrorCaseModal.success"),
                        status: "success",
                        duration: 3000,
                    });
                    setId(null);
                }
            }
            catch (e) {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };
    return (_jsx(Modal, { isOpen: !!id, onClose: () => setId(null), title: t("customsClassification.tabs.errorCases.deleteErrorCaseModal.title"), primaryTitle: t("customsClassification.tabs.errorCases.deleteErrorCaseModal.primaryCTA"), primaryAction: handleDelete, primaryActionLoading: isLoading, primaryVariant: "danger", children: _jsx(Text, { mt: "2", children: t("customsClassification.tabs.errorCases.deleteErrorCaseModal.subtitle") }) }));
};
