import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useEffect, useState, } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE } from "~/consts/customs-classification-cases-max-size";
import { useReplaceImportMutation } from "~/pages/customs-classification/redux/customsClassification.api";
export const ReplaceImportModal = ({ id, setId, redirectPath, }) => {
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [replaceFile, { isLoading }] = useReplaceImportMutation();
    const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE,
    });
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };
    const handleFileUpload = async () => {
        if (id) {
            const formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            try {
                const res = await replaceFile({ id, body: formData });
                if ("data" in res) {
                    setIsError(false);
                    setId(null);
                    if (redirectPath)
                        navigate({
                            to: redirectPath,
                            search: () => ({
                                tab: "error-cases",
                            }),
                        });
                    else {
                        navigate({
                            search: () => ({
                                tab: "classification-cases",
                            }),
                        });
                    }
                }
                else {
                    setIsError(true);
                }
            }
            catch (error) {
                setIsError(true);
            }
        }
    };
    useEffect(() => {
        if (!id)
            reset();
    }, [id]);
    return (_jsxs(Modal, { title: t("customsClassification.tabs.errorCases.replaceArticleModal.title"), isOpen: !!id, onClose: () => setId(null), primaryTitle: t("customsClassification.tabs.errorCases.replaceArticleModal.primaryCTA"), primaryAction: () => handleFileUpload(), primaryVariant: "danger", primaryActionLoading: isLoading, primaryActionDisabled: isLoading || !acceptedFiles.length, children: [_jsx(Text, { mt: 2, children: t("customsClassification.tabs.errorCases.replaceArticleModal.subtitle") }), _jsxs(Flex, { direction: "column", alignItems: "center", justifyContent: "center", flex: 1, gap: 3, mt: 6, minHeight: 24, backgroundColor: "sky.10", borderRadius: "4px", borderColor: "brand.sky", borderWidth: "2px", borderStyle: "dashed", cursor: "pointer", ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), acceptedFiles.length ? (_jsx(Text, { children: acceptedFiles[0].name })) : (_jsx(Text, { children: _jsx(Trans, { components: {
                                cta: _jsx(Text, { as: "span", color: "brand.sky", fontWeight: "700" }),
                            }, i18nKey: "customsClassification.tabs.errorCases.replaceArticleModal.dragAndDropZone.description", values: {
                                CTA: t("customsClassification.tabs.errorCases.replaceArticleModal.dragAndDropZone.cta"),
                            } }) }))] }), isError && (_jsx(Box, { mt: 6, children: _jsx(ErrorMessage, { text: t("error.badRequest.somethingWentWrong") }) })), _jsx(Box, { mt: 6, px: 4, py: 3, backgroundColor: "warning.20", borderColor: "warning.40", borderWidth: "1px", borderRadius: "4px", children: _jsx(Text, { children: t("customsClassification.tabs.errorCases.replaceArticleModal.note") }) })] }));
};
