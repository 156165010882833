import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, remove } from "~/api/utils";
import { onboardingApi } from "~/pages/onboarding/redux/onboarding.api";
import { ONBOARDING_V2_BASEURL } from "../api";
import { Store } from "../store";
export const fetchSavedFormById = createAsyncThunk("formData/fetchSavedFormById", async ({ id, flowId, processSlug, pageSlug, }) => {
    const data = await get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}`);
    return {
        processSlug,
        pageSlug,
        data,
    };
    // return data as FormFieldData[];
});
export const fetchFormById = createAsyncThunk("formData/fetchById", async ({ id, flowId, processSlug, pageSlug, }) => {
    const data = await get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}`);
    return data;
});
export const hideSuccessMessage = createAsyncThunk("formData/hideSuccessMessage", async () => {
    return true;
});
export const patchFormField = createAsyncThunk("formData/patchFormField", async ({ id, fieldName, fieldValue, flowId, processSlug, pageSlug, }) => {
    //
    hideSuccessMessage();
    try {
        const data = await patch(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}`, {
            fieldName,
            fieldValue,
        });
        Store.dispatch(onboardingApi.util.invalidateTags(["ONBOARDING_STATUS"]));
        return data;
    }
    catch (e) {
        // TODO: call error action with error.
        // const rejectedValue: { fieldName: string; fieldValue: string } = { fieldName, fieldValue };
        // return thunkAPI.rejectWithValue(rejectedValue);
    }
    return {};
});
export const resetProcess = createAsyncThunk("formData/resetProcess", async ({ id, flowId, pageSlug, }) => {
    // const data: { [processField: string]: any } = await get(
    //   `${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}`
    // );
    // return data as FormFieldData[];
    // eslint-disable-next-line no-debugger
    try {
        await remove(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${pageSlug}`);
    }
    catch (e) {
        // TODO: error
    }
    return {};
});
