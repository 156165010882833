import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
export function ProductDetailInitialOverview() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.productDetails.title"), subtitle: t("onboarding.productDetails.overview.subtitle") }), _jsx(FormBody, { children: _jsx(Text, { children: t("onboarding.productDetails.overview.description") }) }), _jsx(FormFooter, {})] }));
}
