import { AUTH_V1_BASEURL, api } from "~/store/api";
const keyBaseUrl = `${AUTH_V1_BASEURL}/api-key`;
export const keyApi = api
    .enhanceEndpoints({ addTagTypes: ["API_KEY"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getAllKeys: builder.query({
            query: () => ({
                url: keyBaseUrl,
            }),
            providesTags: ["API_KEY"],
        }),
        getKeyById: builder.query({
            query: (id) => ({
                url: `${keyBaseUrl}/${id}`,
            }),
            providesTags: ["API_KEY"],
        }),
        deleteKeyById: builder.mutation({
            query: (id) => ({
                url: `${keyBaseUrl}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["API_KEY"],
        }),
        createKey: builder.mutation({
            query: (name) => ({
                method: "POST",
                url: keyBaseUrl,
                body: { name },
            }),
            invalidatesTags: ["API_KEY"],
        }),
        updateKey: builder.mutation({
            query: ({ id, ...body }) => ({
                method: "PATCH",
                url: `${keyBaseUrl}/${id}`,
                body,
            }),
            invalidatesTags: ["API_KEY"],
        }),
        getKeyPermissions: builder.query({
            query: () => ({
                url: "/permissions/partners",
            }),
        }),
    }),
});
export const { useGetAllKeysQuery, useCreateKeyMutation, useGetKeyByIdQuery, useDeleteKeyByIdMutation, useUpdateKeyMutation, useGetKeyPermissionsQuery, } = keyApi;
export const authApiReducer = keyApi.reducer;
export const authApiMiddleware = keyApi.middleware;
