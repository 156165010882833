import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Flex } from "@chakra-ui/react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Icon from "~/components/Icon/Icon";
export const OrderOption = ({ option, id, text, index, moveCard, handleSelect, }) => {
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "CARD",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            var _a;
            if (!previewRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = (_a = previewRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: "CARD",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(dragRef);
    drop(preview(previewRef));
    return (_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", px: "4", py: "2", ref: previewRef, "data-handler-id": handlerId, opacity: isDragging ? 0 : 1, flex: 1, minW: 0, children: [_jsx(Checkbox, { isChecked: option.isVisible, onChange: handleSelect, isTruncated: true, children: text }), _jsx(Flex, { alignItems: "center", cursor: "grab", pr: "1", ref: dragRef, children: _jsx(Icon, { color: "grey.40", name: "dragger", width: "10px" }) })] }));
};
