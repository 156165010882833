import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.outboundProcesses.title",
        subtitle: "onboarding.outboundProcesses.originWarehouse.title",
        description: "onboarding.outboundProcesses.originWarehouse.description",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "approvedExporterLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.originWarehouse.sections.approvedExporter.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    groupType: "radio",
                    forKey: "approved_exporter.is_exporter",
                    elements: [
                        {
                            key: "approvedExporterQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.approvedExporter.question",
                        },
                        {
                            type: "radio",
                            value: "yes",
                            key: "approvedExporterYes",
                            label: "onboarding.forms.answers.yes",
                            forKey: "approved_exporter.is_exporter",
                        },
                        {
                            key: "approved_exporter.exporter_number",
                            type: "text",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.approvedExporter.placeholder",
                            rule: {
                                effect: "DISABLE",
                                targetKey: "approved_exporter.is_exporter",
                                targetValue: "no",
                            },
                        },
                        {
                            value: "no",
                            type: "radio",
                            key: "approvedExporterNo",
                            label: "onboarding.forms.answers.no",
                            forKey: "approved_exporter.is_exporter",
                        },
                    ],
                },
            ],
            infoboxText: "onboarding.outboundProcesses.originWarehouse.sections.approvedExporter.infobox",
            nodeKey: "approved_exporter",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "pickupTimesLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "frequencyQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.frequency.question",
                        },
                        {
                            key: "frequencySelect",
                            type: "select",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.frequency.placeholder",
                            options: {
                                daily: "onboarding.forms.frequency.daily",
                                "4xweek": "onboarding.forms.frequency.4xweek",
                                "3xweek": "onboarding.forms.frequency.3xweek",
                                "2xweek": "onboarding.forms.frequency.2xweek",
                                monthly: "onboarding.forms.frequency.monthly",
                            },
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    groupType: "checkbox",
                    forKey: "pickupTimes.estimatedTime",
                    elements: [
                        {
                            key: "estimatedTimeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.question",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox1",
                            value: "mon",
                            label: "onboarding.forms.weekdays.mon",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox2",
                            value: "tue",
                            label: "onboarding.forms.weekdays.tue",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox3",
                            value: "wed",
                            label: "onboarding.forms.weekdays.wed",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox4",
                            value: "thu",
                            label: "onboarding.forms.weekdays.thu",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox5",
                            value: "fri",
                            label: "onboarding.forms.weekdays.fri",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox6",
                            value: "sat",
                            label: "onboarding.forms.weekdays.sat",
                        },
                        {
                            type: "checkbox",
                            key: "estimatedTime.checkbox7",
                            value: "sun",
                            label: "onboarding.forms.weekdays.sun",
                        },
                        {
                            key: "estimatedTimeSelect",
                            type: "select",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.placeholder",
                            options: {
                                "0001": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.selectOptions.0001",
                                "0100": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.selectOptions.0100",
                                "0200": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.selectOptions.0200",
                            },
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "cutoffTimeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.cutoffTime.question",
                        },
                        {
                            key: "cutoffTimeSelect",
                            type: "select",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.cutoffTime.placeholder",
                            options: {
                                "0001": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.cutoffTime.selectOptions.0001",
                                "0100": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.cutoffTime.selectOptions.0100",
                                "0200": "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.cutoffTime.selectOptions.0200",
                            },
                        },
                    ],
                },
            ],
            infoboxText: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.infobox",
            nodeKey: "pickup_times",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "delieryDetailsLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.originWarehouse.sections.deliveryDetails.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "plannedVolumeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.deliveryDetails.plannedVolume.question1",
                        },
                        {
                            type: "text",
                            key: "delivery_details.paletten",
                            label: "Paletten",
                        },
                        { type: "text", key: "delivery_details.pakete", label: "Pakete" },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "mailableParcelsQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.deliveryDetails.plannedVolume.question2",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            groupType: "radio",
                            forKey: "delivery_details.mailable_parcels",
                            elements: [
                                {
                                    type: "radio",
                                    value: "yes",
                                    key: "mailableParcelsYes",
                                    label: "onboarding.forms.answers.yes",
                                    forKey: "delivery_details.mailable_parcels",
                                },
                                {
                                    value: "no",
                                    type: "radio",
                                    key: "mailableParcelsNo",
                                    label: "onboarding.forms.answers.no",
                                    forKey: "delivery_details.mailable_parcels",
                                },
                            ],
                        },
                    ],
                },
            ],
            infoboxText: "onboarding.outboundProcesses.originWarehouse.sections.deliveryDetails.plannedVolume.infobox",
            nodeKey: "delivery_details",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "shippingLocationsLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.originWarehouse.sections.shippingLocations.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "warehouseQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.shippingLocations.question1",
                        },
                        {
                            type: "text",
                            key: "warehouse.name",
                            label: "onboarding.forms.details.name",
                        },
                        {
                            type: "text",
                            key: "warehouse.street",
                            label: "onboarding.forms.details.street",
                        },
                        {
                            type: "text",
                            key: "warehouse.street_number",
                            label: "onboarding.forms.details.number",
                        },
                        {
                            type: "text",
                            key: "warehouse.zip_code",
                            label: "onboarding.forms.details.zipCode",
                        },
                        {
                            type: "text",
                            key: "warehouse.location",
                            label: "onboarding.forms.details.city",
                        },
                        {
                            key: "warehouse.country",
                            type: "customComponent",
                            componentName: "base.CountrySelect",
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "inlandCustomsOfficeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.shippingLocations.question2",
                        },
                        {
                            type: "text",
                            key: "inland_office.name",
                            label: "onboarding.forms.details.name",
                        },
                        {
                            type: "text",
                            key: "inland_office.street",
                            label: "onboarding.forms.details.street",
                        },
                        {
                            type: "text",
                            key: "inland_office.street_number",
                            label: "onboarding.forms.details.number",
                        },
                        {
                            type: "text",
                            key: "inland_office.zip_code",
                            label: "onboarding.forms.details.zipCode",
                        },
                        {
                            type: "text",
                            key: "inland_office.location",
                            label: "onboarding.forms.details.city",
                        },
                        {
                            key: "inland_office.country",
                            type: "customComponent",
                            componentName: "base.CountrySelect",
                        },
                    ],
                },
            ],
            infoboxText: "onboarding.outboundProcesses.originWarehouse.sections.shippingLocations.infobox",
            nodeKey: "shipping_locations",
        }),
    },
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "loadingEquipmentLabel",
                type: "h2",
                label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    groupType: "radio",
                    forKey: "loading_equipment.palet_type",
                    elements: [
                        {
                            key: "palletTypeQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.question1",
                        },
                        {
                            type: "radio",
                            key: "palletType1",
                            forKey: "loading_equipment.palet_type",
                            value: "exchangeEuro",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.palletTypes.exchangeEuro",
                        },
                        {
                            type: "radio",
                            key: "palletType2",
                            forKey: "loading_equipment.palet_type",
                            value: "exchangeDisposable",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.palletTypes.exchangeDisposable",
                        },
                        {
                            type: "radio",
                            key: "palletType3",
                            forKey: "loading_equipment.palet_type",
                            value: "purchaseDisposable",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.palletTypes.purchaseDisposable",
                        },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "palletNumberQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.question2",
                        },
                        { type: "text", key: "loading_equipment.pallet_number", label: "" },
                    ],
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3, mt: 6 },
                    elements: [
                        {
                            key: "palletFrequencyQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.question3",
                        },
                        {
                            key: "loading_equipment.pallet_frequency",
                            type: "select",
                            options: {
                                weekly: "onboarding.forms.frequency.weekly",
                                biweekly: "onboarding.forms.frequency.biweekly",
                                monthly: "onboarding.forms.frequency.monthly",
                                onePerQuarter: "onboarding.forms.frequency.onePerQuarter",
                            },
                            label: "onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.placeholder",
                        },
                    ],
                },
            ],
            infoboxText: "onboarding.outboundProcesses.originWarehouse.sections.loadingEquipment.infobox",
            nodeKey: "loading_equipment",
        }),
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        approved_exporter: {
            type: "object",
            properties: {
                is_approved: {
                    type: "boolean",
                },
                exporter_number: {
                    type: "string",
                },
            },
        },
    },
    additionalProperties: true,
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
