import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
import Link from "~/components/Link/Link";
import RadioButton from "~/components/RadioButton/RadioButton";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, lastSavedFieldState, } from "~/store/selectors/formData.selectors";
export function YourCustomAccountCustomsKindOfSecurityDeposit() {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formData = useSelector(getFormData);
    const lastSavedField = useSelector(lastSavedFieldState);
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "kindOfSecurityDeposit",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (fieldName, fieldValue) => {
        dispatch(patchFormField({
            fieldName,
            fieldValue,
            id,
            flowId,
            pageSlug: "kindOfSecurityDeposit",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.your_customs_account"), subtitle: t("onboarding.securityDeposit.kind"), description: t("onboarding.securityDeposit.kindOfSecuritydescription") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: [t("onboarding.securityDeposit.informationText"), " "] }), _jsxs(RadioGroup, { value: (_a = formData === null || formData === void 0 ? void 0 : formData.securityDepositType) === null || _a === void 0 ? void 0 : _a.fieldValue, children: [_jsx(RadioButton, { name: "security_deposit_type", value: "1", w: "min-intrinsic", onChange: (e) => {
                                    const value = e;
                                    if (value.target.checked)
                                        updateField("securityDepositType", "1");
                                }, label: t("onboarding.securityDeposit.cashDeposit") }), _jsxs(Text, { children: [t("onboarding.securityDeposit.cashDepositDetails"), " "] }), _jsx(RadioButton, { name: "security_deposit_type", value: "2", w: "min-intrinsic", onChange: (e) => {
                                    const value = e;
                                    if (value.target.checked)
                                        updateField("securityDepositType", "2");
                                }, label: t("onboarding.securityDeposit.generalGuarantee") })] }), lastSavedField === "securityDepositType" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" })), _jsxs(Text, { children: [t("onboarding.securityDeposit.downloadGeneralGuarantee"), " "] }), _jsxs(Text, { children: [t("onboarding.securityDeposit.federalOffice"), " "] }), _jsxs(Text, { children: [t("onboarding.securityDeposit.finance"), " "] }), _jsxs(Text, { children: [t("onboarding.securityDeposit.taubenstrasse"), " "] }), _jsxs(Text, { children: [t("onboarding.securityDeposit.bern"), " "] }), _jsx(Link, { to: "https://mvpfactory.atlassian.net/wiki/spaces/MSDCX2/pages/3335422009/Overview+of+all+relevant+documents", target: "_blank", rel: "noopener noreferrer", children: t("onboarding.securityDeposit.downloadHere") })] }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: [t("onboarding.securityDeposit.directDebitProcedure.title"), " "] }), _jsx(RadioGroup, { value: (_b = formData === null || formData === void 0 ? void 0 : formData.directDebitProcedure) === null || _b === void 0 ? void 0 : _b.fieldValue, children: _jsxs(Stack, { direction: "column", children: [_jsx(Radio, { value: "yes", alignItems: "flex-start", children: _jsx(Text, { fontWeight: "bold", children: t("onboarding.securityDeposit.directDebitProcedure.option1.title") }) }), _jsx(Radio, { value: "no", alignItems: "flex-start", children: _jsx(Text, { fontWeight: "bold", children: t("onboarding.securityDeposit.directDebitProcedure.option2.title") }) })] }) }), lastSavedField === "directDebitProcedure" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" }))] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
