import clientSideConfig from "~/client-side-config";
import { Store } from "~/store/store";
const createHeaders = (body) => {
    const { auth: authData } = Store.getState();
    const headers = new Headers();
    if (body !== undefined && body !== null)
        headers.set("Content-Type", "application/json");
    if (authData === null || authData === void 0 ? void 0 : authData.token) {
        headers.set("Authorization", `Bearer ${authData.token}`);
    }
    return headers;
};
const baseUrl = clientSideConfig.apiRoot;
// R stands for Result
// B stands for Body
export const request = async (method, url, params) => {
    const body = params && JSON.stringify(params);
    const headers = createHeaders(body);
    const response = await fetch(baseUrl + url, { headers, method, body });
    const result = await response.json();
    if (!response.ok) {
        // eslint-disable-next-line no-throw-literal
        throw result;
    }
    return result;
};
export const encodeQueryParams = (url, params) => {
    if (params) {
        const query = Object.keys(params)
            .filter((name) => Boolean(params[name]))
            .map((name) => {
            return `${encodeURIComponent(name)}=${encodeURIComponent(params[name])}`;
        });
        if (query.length) {
            return `${url}?${query.join("&")}`;
        }
    }
    return url;
};
export const get = async (url, query) => request("GET", encodeQueryParams(url, query));
export const post = async (url, params) => request("POST", url, params);
export const patch = async (url, params) => request("PATCH", url, params);
export const put = async (url, params) => request("PUT", url, params);
export const remove = async (url, query) => request("DELETE", encodeQueryParams(url, query));
