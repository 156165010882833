import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Flex } from "@chakra-ui/react";
import { CircleFlag } from "react-circle-flags";
import { getCountryByIsoCode } from "~/utils/countries";
/**
 * Renders a flag icon
 * @param props.countryCode - ISO 3166-1 alpha-2 country code
 * @param props.boxSize [default = 5 (20px)] - Size of the flag icon
 * @param props - All other props are passed to the CircleFlag component
 */
export const FlagIcon = ({ countryCode, boxSize = 5, ...props }) => {
    return (_jsx(Box, { as: CircleFlag, countryCode: countryCode === "GB" ? "gb" : countryCode.toLocaleLowerCase(), width: boxSize, height: boxSize, title: getCountryByIsoCode(countryCode), ...props }));
};
export const FlagIconGroup = ({ countries, gap = 2, boxSize = 5, }) => (_jsx(Flex, { flexWrap: "wrap", gap: gap, children: countries === null || countries === void 0 ? void 0 : countries.map((country) => (_jsx(FlagIcon, { countryCode: country, boxSize: boxSize }, `Flag__Icon__Group__${country}`))) }));
export const renderFlagIconGroup = (props) => (_jsx(FlagIconGroup, { ...props }));
