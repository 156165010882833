import { jsx as _jsx } from "react/jsx-runtime";
import { StackDivider, VStack } from "@chakra-ui/react";
import FileItem from "./FileItem";
function FilesList({ files, handleOnRemoveFile, isDisabled, handlerUploadFile, }) {
    return (_jsx(VStack, { w: "full", h: "full", divider: _jsx(StackDivider, { borderColor: "secondary.100" }), overflowY: "auto", maxH: "200", __css: {
            "&::-webkit-scrollbar": {
                width: "4px",
            },
            "&::-webkit-scrollbar-track": {
                width: "6px",
                background: "gray.300",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "gray.600",
                borderRadius: "full",
            },
        }, children: files.map((file, index) => (_jsx(FileItem, { file: file, index: index, totalFiles: files.length, isDisabled: isDisabled, handlerUploadFile: handlerUploadFile, handleOnRemoveFile: handleOnRemoveFile }, file.file.name))) }));
}
export default FilesList;
