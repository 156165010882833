import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.application_vat_uid_number",
        subtitle: "onboarding.declarationOfFiscalRepresentation.declarationOfFiscalRepresentationFinishedCheck",
        description: "onboarding.declarationOfFiscalRepresentation.uploadedSignedDeclarationOfFiscalRepresentation",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label3",
                type: "h3",
                label: "onboarding.declarationOfFiscalRepresentation.applicationForVatNumber",
            },
            {
                key: "label4",
                type: "label",
                label: "onboarding.declarationOfFiscalRepresentation.fillOutApplicationForVatNumber",
            },
            {
                key: "label5",
                type: "label",
                label: "onboarding.declarationOfFiscalRepresentation.applicationForVatNumberAppointment",
            },
            {
                key: "label6",
                type: "label",
                label: "onboarding.declarationOfFiscalRepresentation.applicationForVatNumberFirstStep",
            },
            {
                key: "label7",
                type: "label",
                label: "onboarding.declarationOfFiscalRepresentation.applicationForVatNumberSecondStep",
            },
            {
                key: "label8",
                type: "label",
                label: "onboarding.declarationOfFiscalRepresentation.applicationForVatNumberThirdStep",
            },
            {
                key: "label9",
                type: "label",
                label: [
                    {
                        href: "https://www.estv.admin.ch/estv/de/home/mehrwertsteuer/mwst-an-abmeldung/mwst-anmeldung-online.html",
                        targetBlank: true,
                        displayLabel: "onboarding.declarationOfFiscalRepresentation.linkToSwissTaxPage",
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
