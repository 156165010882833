import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Flex, Grid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Switch } from "~/components/Switch/Switch";
export const KeyPermissionSection = ({ section, isSelected, selectedPermissions, onToggleSection, onTogglePermission, }) => {
    const { t } = useTranslation();
    return (_jsxs(Box, { bg: "white", border: "1px solid", borderColor: "grey.20", p: 6, borderRadius: 4, children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(Switch, { onChange: onToggleSection, isChecked: isSelected }), _jsx(Text, { ml: 4, fontWeight: isSelected ? 700 : 400, color: "brand.mare", fontSize: 20, children: t(`permissions.sections.${section.name}`) })] }), isSelected && (_jsx(Grid, { gap: 4, pl: "56px", mt: 6, justifyContent: "start", children: section.permissions.map(({ code }) => {
                    const isChecked = selectedPermissions.includes(code);
                    return (_jsx(Checkbox, { isChecked: isChecked, onChange: () => onTogglePermission(code), children: t(`permissions.codes.${code}`) }, `${code}-checkbox`));
                }) }))] }));
};
