import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Tag, TagCloseButton, TagLabel, TagLeftIcon, Text, } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputIcon } from "~/assets/icons/InputIcon";
import { OutputIcon } from "~/assets/icons/OutputIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { IconButton } from "~/components/IconButton/IconButton";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Service } from "~/components/Service/Service";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { useEditPartnerServiceMutation } from "~/pages/partners/redux/partners.api";
import { checkAccountType } from "~/utils/checkAccountType";
import { getCountryByIsoCode, getEUCountries, getNonEUCountries, isCountryUsed, } from "~/utils/countries";
import { DisableTariffingServiceModal } from "../DisableTariffingModal/DisableTariffingModal";
import { EditTariffingCountryModal } from "../EditTariffingCountryModal/EditTariffingCountryModal";
import { EnableTariffingServiceModal } from "../EnableTariffingModal/EnableTariffingModal";
import { RemoveTariffingCountryModal } from "../RemoveTariffingCountryModal/RemoveTariffingCountryModal";
const FLAG_SIZE = "20px";
export const Tariffing = ({ service, isLoaded, }) => {
    const [isChecked, setIsChecked] = useState(service.status === ServiceStatus.ENABLED);
    const [isEnableTariffingServiceModalOpen, setIsEnableTariffingServiceModalOpen,] = useState(false);
    const [isDisableTariffingServiceModalOpen, setIsDisableTariffingServiceModalOpen,] = useState(false);
    const [editCountriesDirection, setEditCountriesDirection] = useState(null);
    const [removeCountriesDirection, setRemoveCountriesDirection] = useState(null);
    const [countryCodeToRemove, setCountryCodeToRemove] = useState(null);
    const [editStatus] = useEditPartnerServiceMutation();
    const { params: { id }, } = useMatch();
    const { t } = useTranslation();
    const { serviceData, status } = service;
    const { shipTo = [], shipFrom = [], usedCountries = { shipTo: [], shipFrom: [] }, } = serviceData;
    const allCountries = [...shipTo, ...shipFrom];
    const hasToCountries = useMemo(() => shipTo.length > 0, [serviceData]);
    const hasFromCountries = useMemo(() => shipFrom.length > 0, [serviceData]);
    const shipFromEU = useMemo(() => getEUCountries(shipFrom), [serviceData]);
    const shipFromNonEU = useMemo(() => getNonEUCountries(shipFrom), [serviceData]);
    const renderFlag = (countryCode) => (_jsx(FlagIcon, { countryCode: countryCode, style: { display: "inline-block" }, boxSize: FLAG_SIZE }));
    const hasUsedCountries = !usedCountries.shipTo.length && !usedCountries.shipFrom.length;
    const handleSwitch = () => {
        if (isChecked) {
            if (hasUsedCountries) {
                setIsDisableTariffingServiceModalOpen(true);
            }
        }
        else if (!isChecked) {
            if (!allCountries.length) {
                setIsEnableTariffingServiceModalOpen(true);
            }
            else {
                editStatus({
                    id,
                    serviceCode: ServiceCode.TARIFFING,
                    body: { status: ServiceStatus.ENABLED },
                });
            }
        }
    };
    const handleRemoveCountry = (direction, countryCode) => {
        setRemoveCountriesDirection(direction);
        setCountryCodeToRemove(countryCode);
    };
    useEffect(() => {
        setIsChecked(status === ServiceStatus.ENABLED);
    }, [status]);
    return (_jsxs(Service, { title: t("partners.detail.services.tariffing.title"), isSwitchChecked: isChecked, switchFn: handleSwitch, tooltipLabel: t("partners.detail.services.tariffing.disabledSwitchTooltip"), isSwitchDisabled: !hasUsedCountries, isSwitchVisible: checkAccountType(AccountTypes.INTERNAL), tagLabel: t("partners.detail.services.tariffing.activeTag"), isTagVisible: checkAccountType(AccountTypes.PARTNER), isLoaded: isLoaded, children: [_jsxs(Flex, { direction: "column", gap: 8, children: [_jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", children: [_jsxs(Flex, { gap: "4", children: [_jsx(InputIcon, { width: 6, height: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("partners.detail.services.tariffing.shipToCountry") })] }), checkAccountType(AccountTypes.INTERNAL) && (_jsx(IconButton, { onClick: () => setEditCountriesDirection(ShipDirection.TO), children: _jsx(PlusIcon, {}) }))] }), _jsx(Flex, { direction: "column", backgroundColor: "brand.white", borderColor: "grey.10", borderWidth: "1px", borderRadius: "4", px: 6, pt: 6, pb: 9, children: hasToCountries ? (_jsxs(Flex, { direction: "column", gap: "5", children: [_jsx(Text, { textStyle: "labelUppercase", color: "brand.mare", children: t("partners.detail.services.tariffing.country") }), _jsx(Flex, { gap: "3", flexWrap: "wrap", children: shipTo.map((country) => (_jsx(TooltipWrapper, { hasTooltip: checkAccountType(AccountTypes.INTERNAL) &&
                                                    isCountryUsed({
                                                        country,
                                                        usedCountries: usedCountries.shipTo,
                                                    }), label: t("partners.detail.services.tariffing.disabledCountryTooltip"), children: _jsxs(Tag, { variant: checkAccountType(AccountTypes.INTERNAL) &&
                                                        isCountryUsed({
                                                            country,
                                                            usedCountries: usedCountries.shipTo,
                                                        })
                                                        ? "disabledCountry"
                                                        : "country", gap: "2", children: [_jsx(TagLeftIcon, { boxSize: FLAG_SIZE, as: () => renderFlag(country) }), _jsx(TagLabel, { textStyle: "body", children: getCountryByIsoCode(country) }), checkAccountType(AccountTypes.INTERNAL) &&
                                                            !isCountryUsed({
                                                                country,
                                                                usedCountries: usedCountries.shipTo,
                                                            }) && (_jsx(TagCloseButton, { onClick: () => handleRemoveCountry(ShipDirection.TO, country) }))] }) }, `Ship__To__Country__Tag__Tooltip__${country}`))) })] })) : (_jsx(Text, { color: "grey.60", children: t("partners.detail.services.tariffing.noShipToCountry") })) })] }), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", children: [_jsxs(Flex, { gap: "4", children: [_jsx(OutputIcon, { width: 6, height: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("partners.detail.services.tariffing.shipFromCountry") })] }), checkAccountType(AccountTypes.INTERNAL) && (_jsx(IconButton, { onClick: () => setEditCountriesDirection(ShipDirection.FROM), children: _jsx(PlusIcon, {}) }))] }), _jsx(Flex, { direction: "column", backgroundColor: "brand.white", borderColor: "grey.10", borderWidth: "1px", borderRadius: "4", px: 6, pt: 6, pb: 9, children: hasFromCountries ? (_jsxs(Flex, { direction: "column", gap: "9", children: [shipFromEU.length > 0 && (_jsxs(Flex, { direction: "column", gap: "4", children: [_jsx(Text, { textStyle: "labelUppercase", color: "brand.mare", children: t("partners.detail.services.tariffing.EUCountries") }), checkAccountType(AccountTypes.INTERNAL) && (_jsx(Text, { children: t("partners.detail.services.tariffing.EUNote") })), _jsx(Flex, { gap: "3", flexWrap: "wrap", children: shipFromEU.map((country) => (_jsx(TooltipWrapper, { hasTooltip: checkAccountType(AccountTypes.INTERNAL) &&
                                                            isCountryUsed({
                                                                country,
                                                                usedCountries: usedCountries.shipFrom,
                                                            }), label: t("partners.detail.services.tariffing.disabledCountryTooltip"), children: _jsxs(Tag, { variant: checkAccountType(AccountTypes.INTERNAL) &&
                                                                isCountryUsed({
                                                                    country,
                                                                    usedCountries: usedCountries.shipFrom,
                                                                })
                                                                ? "disabledCountry"
                                                                : "country", gap: "2", children: [_jsx(TagLeftIcon, { boxSize: FLAG_SIZE, as: () => renderFlag(country) }), _jsx(TagLabel, { textStyle: "body", children: getCountryByIsoCode(country) }), checkAccountType(AccountTypes.INTERNAL) &&
                                                                    !isCountryUsed({
                                                                        country,
                                                                        usedCountries: usedCountries.shipFrom,
                                                                    }) && (_jsx(TagCloseButton, { onClick: () => handleRemoveCountry(ShipDirection.FROM, country) }))] }, `EU__Country__Tag__${country}`) }, `Ship__From__EU__Country__Tag__Tooltip__${country}`))) })] })), shipFromNonEU.length > 0 && (_jsxs(Flex, { direction: "column", gap: "4", children: [_jsx(Text, { textStyle: "labeUppercase", color: "brand.mare", children: t("partners.detail.services.tariffing.NonEUCountries") }), _jsx(Flex, { gap: "3", flexWrap: "wrap", children: shipFromNonEU.map((country) => (_jsx(TooltipWrapper, { hasTooltip: checkAccountType(AccountTypes.INTERNAL) &&
                                                            isCountryUsed({
                                                                country,
                                                                usedCountries: usedCountries.shipFrom,
                                                            }), label: t("partners.detail.services.tariffing.disabledCountryTooltip"), children: _jsxs(Tag, { variant: checkAccountType(AccountTypes.INTERNAL) &&
                                                                isCountryUsed({
                                                                    country,
                                                                    usedCountries: usedCountries.shipFrom,
                                                                })
                                                                ? "disabledCountry"
                                                                : "country", gap: "2", children: [_jsx(TagLeftIcon, { boxSize: FLAG_SIZE, as: () => renderFlag(country) }), _jsx(TagLabel, { textStyle: "body", children: getCountryByIsoCode(country) }), checkAccountType(AccountTypes.INTERNAL) &&
                                                                    !isCountryUsed({
                                                                        country,
                                                                        usedCountries: usedCountries.shipFrom,
                                                                    }) && (_jsx(TagCloseButton, { onClick: () => handleRemoveCountry(ShipDirection.FROM, country) }))] }, `Non__EU__Country__Tag__${country}`) }, `Ship__From__Non__EU__Country__Tag__Tooltip__${country}`))) })] }))] })) : (_jsx(Text, { color: "grey.60", children: t("partners.detail.services.tariffing.noShipFromCountry") })) })] })] }), _jsx(EnableTariffingServiceModal, { service: service, isOpen: isEnableTariffingServiceModalOpen, setIsOpen: setIsEnableTariffingServiceModalOpen, usedCountries: usedCountries }), _jsx(DisableTariffingServiceModal, { isOpen: isDisableTariffingServiceModalOpen, setIsOpen: setIsDisableTariffingServiceModalOpen }), _jsx(EditTariffingCountryModal, { handleClose: () => setEditCountriesDirection(null), shipDirection: editCountriesDirection, service: service }), _jsx(RemoveTariffingCountryModal, { handleClose: () => setRemoveCountriesDirection(null), shipDirection: removeCountriesDirection, service: service, countryCode: countryCodeToRemove })] }));
};
