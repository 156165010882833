import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable
import { Flex, Text, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useGetGroupsMutation } from "~/pages/auth/redux/auth.api";
import { useUpdateClientUserMutation } from "~/pages/clients/redux/clients.api";
import { useUpdatePartnerUserMutation } from "~/pages/partners/redux/partners.api";
import { userApi } from "~/pages/users/redux/users.api";
import { useAppDispatch } from "~/store/hooks";
import { EditUserGroupsValidationSchema } from "~/pages/users/schemas/schemas";
import { ControlledSelect } from "../ControlledSelect/ControlledSelect";
import { AccountTypes } from "../PermissionCheck/permission-codes";
export const EditUserGroupsModal = ({ isOpen, setIsOpen, user, type, companyId }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const dispatch = useAppDispatch();
    const prefillGroups = () => user.memberOf.map((item) => ({ label: item.name, value: item.id }));
    const { control, handleSubmit, reset, formState: { isDirty }, } = useForm({
        defaultValues: {
            groups: prefillGroups(),
        },
        resolver: zodResolver(EditUserGroupsValidationSchema),
    });
    const [getGroups, { data: groupsList, isLoading: isFetchingGroups }] = useGetGroupsMutation();
    useEffect(() => {
        if (isOpen) {
            getGroups(type);
        }
    }, [isOpen]);
    const [updateClientUser, { isLoading: isLoadingClient }] = useUpdateClientUserMutation();
    const [updatePartnerUser, { isLoading: isLoadingPartner }] = useUpdatePartnerUserMutation();
    const options = useMemo(() => {
        if (!groupsList)
            return [];
        return groupsList.map((item) => ({ label: item.name, value: item.id }));
    }, [groupsList]);
    const onSubmit = async ({ groups, }) => {
        const req = {
            id: companyId,
            email: user.email,
            body: {
                groups: groups.map((item) => item.value),
            },
        };
        try {
            const res = type === AccountTypes.CLIENT
                ? await updateClientUser(req)
                : await updatePartnerUser(req);
            if ("data" in res) {
                dispatch(userApi.util.updateQueryData("getUserByEmail", user.email, (u) => ({
                    ...u,
                    memberOf: groups.map((g) => ({ id: g.value, name: g.label })),
                })));
                toast({
                    title: t("profile.editUserGroupsModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    useEffect(() => {
        if (!isOpen)
            reset();
    }, [isOpen]);
    return (_jsx(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("profile.editUserGroupsModal.title"), primaryAction: handleSubmit(onSubmit), primaryActionDisabled: !isDirty, primaryActionLoading: isLoadingClient || isLoadingPartner || isFetchingGroups, primaryTitle: t("basics.save"), children: _jsxs(Flex, { as: "form", flexDir: "column", gap: "2", mt: "6", children: [_jsx(Text, { textStyle: "labelUppercase", children: t("partners.addTeamMemberModal.labels.groups") }), _jsx(ControlledSelect, { options: options, controlProps: { name: "groups", control }, placeholder: t("partners.addTeamMemberModal.placeholders.groups"), isMulti: true, isLoading: isFetchingGroups })] }) }));
};
