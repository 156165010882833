import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { Table } from "~/components/Table/Table";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { PartnerClientInfoOverlay } from "../../components/PartnerClientInfoOverlay/PartnerClientInfoOverlay";
import { useGetPartnerClientsQuery } from "../../redux/partners.api";
export const PartnerClientsPage = () => {
    const { t } = useTranslation();
    const [selectedClient, setSelectedClient] = useState(null);
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [sorting, onSortingChange] = useParamSorting();
    const { user } = useAppSelector(authSelector);
    if (!user || !user.accountId)
        return _jsx(FullPageLoader, {});
    const { data: clients, isFetching, isLoading, } = useGetPartnerClientsQuery({
        id: user === null || user === void 0 ? void 0 : user.accountId,
        searchQuery,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
    });
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: () => t("partners.detail.clients.clientName"),
            size: undefined,
        }),
        columnHelper.accessor("services", {
            header: () => t("partners.detail.clients.serviceNo"),
            size: 140,
        }),
    ];
    return isLoading ? (_jsx(FullPageLoader, {})) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: t("partners.detail.clients.headline"), breadcrumbs: [] }), _jsx(ContentSection, { children: _jsxs(Box, { h: "full", children: [_jsx(Text, { mb: 8, textStyle: "h2", children: t("partners.detail.clients.headline") }), _jsx(FilterBar, { children: _jsx(FilterSearch, { disabled: isLoading, value: search, setValue: setSearch, placeholder: t("partners.detail.clients.searchPlaceholder") }) }), _jsx(Table, { columns: columns, data: clients, onRowClick: (row) => setSelectedClient(row.original.id), sorting: sorting, onSortingChange: onSortingChange, isLoading: isLoading, isFetching: isFetching }), _jsx(PartnerClientInfoOverlay, { clientId: selectedClient, onClose: () => setSelectedClient(null) })] }) })] }));
};
