import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useReducer, } from "react";
import { createPortal } from "react-dom";
import { ActionType, initialState, reducer, } from "./Reducer/Reducer";
const Context = createContext({
    state: initialState,
    dispatch: () => undefined,
});
export function SlotsProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({ state, dispatch }), [state]);
    return _jsx(Context.Provider, { value: value, children: children });
}
export const useSubscribe = (name) => {
    const { dispatch } = useContext(Context);
    return useCallback((ref) => {
        if (ref) {
            dispatch({ type: ActionType.AddSlot, payload: { ref, name } });
        }
    }, [name]);
};
export const useSlot = (name) => {
    const { state } = useContext(Context);
    return state[name];
};
export function Slot({ name, children }) {
    const slot = useSlot(name);
    return slot ? createPortal(children, slot) : null;
}
