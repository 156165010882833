import { ServiceStatus } from "~/enums/service-status.enum";
import { userApi } from "~/pages/users/redux/users.api";
import { ONBOARDING_V1_BASEURL, ONBOARDING_V2_BASEURL, api } from "~/store/api";
export const partnerServicesUrl = `${ONBOARDING_V1_BASEURL}/partners`;
export const partnerBaseUrl = `${ONBOARDING_V2_BASEURL}/partners`;
export const partnerApi = api
    .enhanceEndpoints({ addTagTypes: ["PARTNER", "PARTNER_SERVICES"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getAllPartners: builder.query({
            query: () => ({
                url: partnerBaseUrl,
            }),
            providesTags: ["PARTNER"],
        }),
        getPartnerClients: builder.query({
            query: ({ searchQuery, orderBy, order, id }) => ({
                url: `${partnerBaseUrl}/${id}/clients`,
                params: {
                    searchQuery,
                    orderBy,
                    order,
                },
            }),
            providesTags: ["PARTNER"],
        }),
        getPaginatedPartners: builder.query({
            query: ({ page, size, searchQuery, orderBy, order }) => ({
                url: partnerBaseUrl,
                params: {
                    page,
                    size,
                    searchQuery,
                    orderBy,
                    order,
                },
            }),
            providesTags: ["PARTNER"],
        }),
        getPartnerById: builder.query({
            query: (id) => ({
                url: `${partnerBaseUrl}/${id}`,
            }),
            providesTags: ["PARTNER"],
        }),
        deletePartnerById: builder.mutation({
            query: (id) => ({
                url: `${partnerBaseUrl}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["PARTNER"],
        }),
        createPartner: builder.mutation({
            query: (name) => ({
                method: "POST",
                url: partnerBaseUrl,
                body: { name, type: "PARTNER" },
            }),
            invalidatesTags: ["PARTNER"],
        }),
        editCompany: builder.mutation({
            query: ({ id, body }) => ({
                method: "PATCH",
                url: `${partnerBaseUrl}/${id}`,
                body,
            }),
            invalidatesTags: ["PARTNER"],
        }),
        getCompanyUsers: builder.query({
            query: (id) => ({
                url: `${partnerBaseUrl}/${id}/users`,
            }),
        }),
        addPartnerTeamMember: builder.mutation({
            query: ({ id, body }) => ({
                method: "POST",
                url: `${partnerBaseUrl}/${id}/users`,
                body,
            }),
        }),
        getPartnerServices: builder.query({
            query: ({ partnerId, service }) => ({
                url: `${partnerServicesUrl}/${partnerId}/services/${service}`,
            }),
            providesTags: ["PARTNER_SERVICES"],
        }),
        enablePartnerServiceWithCountries: builder.mutation({
            query: ({ id, body, serviceId = null }) => ({
                method: serviceId ? "PATCH" : "POST",
                url: `${partnerServicesUrl}/${id}/services${serviceId ? `/${body.serviceCode}` : ""}`,
                body: {
                    ...body,
                    ...(serviceId ? { status: ServiceStatus.ENABLED } : {}),
                },
            }),
            invalidatesTags: ["PARTNER", "PARTNER_SERVICES"],
        }),
        editPartnerService: builder.mutation({
            query: ({ id, serviceCode, body }) => ({
                method: "PATCH",
                url: `${partnerServicesUrl}/${id}/services/${serviceCode}`,
                body,
            }),
            invalidatesTags: ["PARTNER", "PARTNER_SERVICES"],
        }),
        updatePartnerUser: builder.mutation({
            query: ({ id, email, body }) => ({
                method: "PATCH",
                url: `${partnerBaseUrl}/${id}/users/${email}`,
                body,
            }),
            async onQueryStarted({ id: partnerId, body }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(userApi.util.updateQueryData("getUserByEmail", data.email, (user) => ({
                        ...user,
                        ...body,
                    })));
                    dispatch(partnerApi.util.updateQueryData("getCompanyUsers", partnerId, (users) => {
                        const user = users.find((u) => u.email === data.email);
                        if (user) {
                            users.splice(users.indexOf(user), 1, {
                                ...user,
                                ...body,
                            });
                        }
                    }));
                }
            },
        }),
        reinvitePartnerUser: builder.mutation({
            query: ({ id, email }) => ({
                method: "POST",
                url: `${partnerBaseUrl}/${id}/users/${email}/invite`,
            }),
        }),
        getProviderPartners: builder.query({
            query: ({ shipTo, shipFrom, serviceCode }) => ({
                url: `${partnerServicesUrl}/services/${serviceCode}`,
                params: {
                    shipTo,
                    ...(shipFrom && { shipFrom }),
                },
            }),
            providesTags: ["PARTNER"],
        }),
        getPartnerClientById: builder.query({
            query: ({ partnerId, clientId }) => ({
                url: `${partnerBaseUrl}/${partnerId}/clients/${clientId}`,
            }),
        }),
        getPartnerClientServices: builder.query({
            query: ({ partnerId, clientId, service: serviceCode, shipTo }) => ({
                url: `${partnerBaseUrl}/${partnerId}/clients/${clientId}/services`,
                params: {
                    shipTo,
                    serviceCode,
                },
            }),
        }),
    }),
});
export const { useGetAllPartnersQuery, useGetPaginatedPartnersQuery, useLazyGetPaginatedPartnersQuery, useCreatePartnerMutation, useGetPartnerByIdQuery, useLazyGetPartnerByIdQuery, useDeletePartnerByIdMutation, useGetCompanyUsersQuery, useLazyGetCompanyUsersQuery, useEditCompanyMutation, useGetPartnerClientsQuery, useAddPartnerTeamMemberMutation, useGetPartnerServicesQuery, useEnablePartnerServiceWithCountriesMutation, useEditPartnerServiceMutation, useUpdatePartnerUserMutation, useReinvitePartnerUserMutation, useLazyGetPartnerServicesQuery, useGetProviderPartnersQuery, useLazyGetPartnerClientByIdQuery, useGetPartnerClientServicesQuery, useLazyGetPartnerClientServicesQuery, } = partnerApi;
export const authApiReducer = partnerApi.reducer;
export const authApiMiddleware = partnerApi.middleware;
