import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DocumentGroup } from "@crossborderx/schemas-package";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useGetOnboardingFlow } from "~/api/hooks/onboarding";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
import UploadFilesArea from "~/components/UploadFilesArea/UploadFilesArea";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, lastSavedFieldState, } from "~/store/selectors/formData.selectors";
export function ApplicationVatUidNumberFiscalSecurityDeposit() {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formdata = useSelector(getFormData);
    const lastSavedField = useSelector(lastSavedFieldState);
    const { params: { id, flowId }, } = useMatch();
    const { data: flowData } = useGetOnboardingFlow(Number(id), flowId);
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "securityDeposit",
            processSlug: "administrativeProcesses",
        }));
        // Need to override active step here cause from footer it will go back to 1.2 cause of multiple paths
        dispatch(patchFormField({
            fieldName: "activestep",
            fieldValue: "2.5",
            id,
            flowId,
            pageSlug: "activestep",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (e) => {
        dispatch(patchFormField({
            fieldName: "files",
            fieldValue: JSON.stringify(e.map((el) => ({
                ...el,
                file: {
                    type: el.file.type,
                    size: el.file.size,
                    name: el.file.name,
                },
            }))),
            id,
            flowId,
            pageSlug: "securityDeposit",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.securityDeposit.headline"), subtitle: t("onboarding.vat.flow.securityDeposit.subtitle"), description: "" }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { fontSize: "2xl", children: t("onboarding.vat.flow.securityDeposit.title") }), _jsx(Text, { children: t("onboarding.vat.flow.securityDeposit.info") }), _jsx(UploadFilesArea, { onFileAccepted: (acceptedFiles) => {
                            updateField(acceptedFiles);
                        }, documentGroup: DocumentGroup.ONBOARDING, accountId: flowData === null || flowData === void 0 ? void 0 : flowData.accountId, files: JSON.parse((_b = (_a = formdata === null || formdata === void 0 ? void 0 : formdata.files) === null || _a === void 0 ? void 0 : _a.fieldValue) !== null && _b !== void 0 ? _b : "[]"), placeholder: t("onboarding.uploadFilesArea.files"), multiple: true }), lastSavedField === "files" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" }))] }), _jsx(FormFooter, { pageSlug: "securityDeposit", processSlug: "administrativeProcesses", isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, vatPage: "2.6", nextUrl: `/clients/${id}/onboarding/${flowId}/administrative-processes/security-deposit-sign` })] }));
}
