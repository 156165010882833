import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { InputIcon } from "~/assets/icons/InputIcon";
import { OutputIcon } from "~/assets/icons/OutputIcon";
import { CountrySelect } from "~/components/CountrySelect/CountrySelect";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { useEditPartnerServiceMutation } from "~/pages/partners/redux/partners.api";
import { EditTariffingCountryValidationSchema, } from "~/pages/partners/schemas/edit-tariffing-country.schema";
import { getCountryByIsoCode } from "~/utils/countries";
export const EditTariffingCountryModal = ({ handleClose, shipDirection, service }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const toast = useToast();
    const [saveCountries, { isLoading }] = useEditPartnerServiceMutation();
    const usedCountries = shipDirection === ShipDirection.TO
        ? (_a = service.serviceData.usedCountries) === null || _a === void 0 ? void 0 : _a.shipTo
        : (_b = service.serviceData.usedCountries) === null || _b === void 0 ? void 0 : _b.shipFrom;
    const { params: { id }, } = useMatch();
    const isShipTo = shipDirection === ShipDirection.TO;
    const prePopulateCountries = () => {
        var _a, _b;
        const countries = {
            shipFrom: ((_a = service.serviceData.shipFrom) === null || _a === void 0 ? void 0 : _a.map((value) => ({
                value,
                label: getCountryByIsoCode(value),
            }))) || [],
            shipTo: ((_b = service.serviceData.shipTo) === null || _b === void 0 ? void 0 : _b.map((value) => ({
                value,
                label: getCountryByIsoCode(value),
            }))) || [],
        };
        return countries;
    };
    const { control, handleSubmit, setValue, watch, formState: { errors, isDirty }, } = useForm({
        defaultValues: prePopulateCountries(),
        resolver: zodResolver(EditTariffingCountryValidationSchema),
    });
    const onSubmit = async (data) => {
        const { shipTo: shipToValues, shipFrom: shipFromValues } = data;
        const body = {
            serviceData: {
                shipTo: (shipToValues === null || shipToValues === void 0 ? void 0 : shipToValues.map((item) => item.value)) || [],
                shipFrom: (shipFromValues === null || shipFromValues === void 0 ? void 0 : shipFromValues.map((item) => item.value)) || [],
            },
        };
        try {
            const res = await saveCountries({
                id,
                serviceCode: ServiceCode.TARIFFING,
                body: {
                    ...body,
                    ...(![...body.serviceData.shipTo, ...body.serviceData.shipFrom].length
                        ? { status: ServiceStatus.DISABLED }
                        : {}),
                },
            });
            if ("data" in res) {
                toast({
                    title: t("partners.addTariffingCountryModal.success"),
                    status: "success",
                    duration: 3000,
                });
                handleClose();
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.addTariffingCountry"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const selectedShipToCountries = useMemo(() => {
        var _a, _b;
        return (_b = (_a = watch("shipTo")) === null || _a === void 0 ? void 0 : _a.map((item) => item.value)) !== null && _b !== void 0 ? _b : [];
    }, [watch()]);
    const selectedShipFromCountries = useMemo(() => {
        var _a, _b;
        return (_b = (_a = watch("shipFrom")) === null || _a === void 0 ? void 0 : _a.map((item) => item.value)) !== null && _b !== void 0 ? _b : [];
    }, [watch()]);
    useEffect(() => {
        const { shipTo, shipFrom } = prePopulateCountries();
        setValue("shipTo", shipTo);
        setValue("shipFrom", shipFrom);
    }, [shipDirection, service]);
    const values = isShipTo ? selectedShipToCountries : selectedShipFromCountries;
    const transKey = isShipTo ? "shipTo" : "shipFrom";
    return (_jsx(Modal, { isOpen: !!shipDirection, onClose: handleClose, title: t("partners.addTariffingCountryModal.title", {
            direction: t(`partners.addTariffingCountryModal.${transKey}`),
        }), primaryTitle: t("basics.save"), primaryAction: handleSubmit(onSubmit), primaryActionDisabled: !isDirty, primaryActionLoading: isLoading, children: _jsxs(Flex, { direction: "column", gap: 8, children: [_jsx(Text, { mt: 2, children: _jsx(Trans, { components: { bold: _jsx(Text, { as: "span", fontWeight: 700 }) }, i18nKey: "partners.addTariffingCountryModal.subtitle", values: {
                            direction: t(`partners.addTariffingCountryModal.${transKey}`),
                        } }) }), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { gap: 4, children: [isShipTo ? (_jsx(InputIcon, { boxSize: 6, color: "brand.mare" })) : (_jsx(OutputIcon, { boxSize: 6, color: "brand.mare" })), _jsx(Text, { textStyle: "h4", children: t(`partners.addTariffingCountryModal.${transKey}`) })] }), _jsx(CountrySelect, { controlProps: {
                                name: isShipTo ? "shipTo" : "shipFrom",
                                control,
                            }, setValue: setValue, selectedCountries: values, usedCountries: usedCountries, addedCountries: values, shipDirection: shipDirection || ShipDirection.TO }), isShipTo
                            ? ((_c = errors.shipTo) === null || _c === void 0 ? void 0 : _c.message) && (_jsx(ErrorMessage, { text: (_d = errors.shipTo) === null || _d === void 0 ? void 0 : _d.message }))
                            : ((_e = errors.shipFrom) === null || _e === void 0 ? void 0 : _e.message) && (_jsx(ErrorMessage, { text: (_f = errors.shipFrom) === null || _f === void 0 ? void 0 : _f.message }))] })] }) }));
};
