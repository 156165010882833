import { z } from "zod";
const CountryOptionSchema = z.object({
    label: z.string(),
    value: z.string(),
});
export const EnableTariffingValidationSchema = z.object({
    shipTo: CountryOptionSchema.array(),
    shipFrom: CountryOptionSchema.array(),
});
export const EnableTariffingBodySchema = z.object({
    shipTo: z.string().array(),
    shipFrom: z.string().array(),
});
