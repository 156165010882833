import { useMemo } from "react";
import { daysCalculation } from "~/helpers/outbound-overview/days-calculation";
import i18n from "~/i18n";
export default function durationUntilCustomsRelease(flowFields) {
    var _a, _b;
    const when = i18n.t("processOverview.outboundProcess.durationUntilCustomsRelease.when");
    const who = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "customsOfficeCompanyName" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const wareHouseCity = ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wareHouseCity" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    const what = wareHouseCity !== ""
        ? i18n.t("processOverview.outboundProcess.durationUntilCustomsRelease.what", {
            city: wareHouseCity,
        })
        : "";
    const calculatedDay = useMemo(() => daysCalculation(flowFields), [flowFields]);
    const day = calculatedDay === 3 ? "02" : undefined;
    return {
        when,
        who,
        what,
        day,
        icon: "duration-until-custom-release",
        title: i18n.t("processOverview.outboundProcess.durationUntilCustomsRelease.title"),
    };
}
