import { useMemo } from "react";
import { daysCalculation } from "~/helpers/outbound-overview/days-calculation";
import i18n from "~/i18n";
export default function parcelDelivery(flowFields) {
    var _a;
    const when = i18n.t("processOverview.outboundProcess.parcelDelivery.when");
    const lastMileDeliveryCarrier = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "lastMileDeliveryCarrier" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const who = lastMileDeliveryCarrier;
    let what = "";
    if (lastMileDeliveryCarrier !== "") {
        what = i18n.t("processOverview.outboundProcess.parcelDelivery.what", {
            carrier: lastMileDeliveryCarrier,
        });
    }
    const calculatedDay = useMemo(() => daysCalculation(flowFields), [flowFields]);
    const checkDayText = (day) => {
        switch (day) {
            case 1:
            case 4:
                return "02-04";
            case 2:
            case 5:
                return "03-05";
            case 3:
                return "04-06";
            default:
                return undefined;
        }
    };
    return {
        when,
        who,
        day: checkDayText(calculatedDay),
        what,
        icon: "zustellung",
        title: i18n.t("processOverview.outboundProcess.parcelDelivery.title"),
    };
}
