import { congratulationFormNodesAndSchema, customizationFilesFormNodesAndSchema, overviewFormNodesAndSchema, uploadVerificationFormNodesAndSchema, } from "../schemas/technical-setup";
export const TECHNICAL_SETUP = {
    technical_setup: {
        initial: "overview",
        id: "technical_setup",
        meta: {
            eventName: "TECHNICAL_SETUP",
            label: "onboarding.technicalSetup.title",
        },
        states: {
            overview: {
                meta: {
                    label: "onboarding.overview",
                    ...overviewFormNodesAndSchema,
                },
                on: {
                    SAVE: "customization_files",
                    NEXT: "customization_files",
                },
            },
            customization_files: {
                meta: {
                    label: "onboarding.technicalSetup.customizationFiles",
                    ...customizationFilesFormNodesAndSchema,
                },
                on: {
                    SAVE: "upload_verification",
                    NEXT: "upload_verification",
                },
            },
            upload_verification: {
                meta: {
                    label: "onboarding.technicalSetup.uploadVerification",
                    ...uploadVerificationFormNodesAndSchema,
                },
                on: {
                    SAVE: "completed",
                    NEXT: "completed",
                },
            },
            completed: {
                meta: {
                    label: "onboarding.steps.completed",
                    ...congratulationFormNodesAndSchema,
                },
                type: "final",
            },
        },
    },
};
