import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Skeleton, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useGetOnboardingFlows } from "~/api/hooks/onboarding";
import { SuccessManager } from "~/components/SuccessManager/SuccessManager";
import { ClientOnboardingItem } from "./components/ClientOnboardingItem/ClientOnboardingItem";
export const ClientOnboarding = ({ client, }) => {
    const { t } = useTranslation();
    const { id, responsible } = client;
    const { data: flows, isLoading, isFetching } = useGetOnboardingFlows(id);
    return (_jsxs(Box, { h: "full", children: [_jsx(Text, { my: 8, textStyle: "h2", children: t("clients.detail.onboarding.headline") }), _jsxs(Flex, { children: [_jsx(Flex, { direction: "column", flex: 1, pb: 0, borderRadius: "4px", children: isLoading ? (_jsx(Spinner, { alignSelf: "center" })) : (_jsx(Skeleton, { isLoaded: !isFetching, children: flows && flows.length > 0 ? (flows.map((flow) => (_jsx(ClientOnboardingItem, { clientId: id, item: flow }, `client-flow-${flow.id}`)))) : (_jsx(Text, { children: t("clients.detail.onboarding.noOnboardingItems") })) })) }), _jsx(Box, { w: "detailPageSideSection", flexShrink: 0, ml: 24, children: _jsx(SuccessManager, { responsible: responsible }) })] })] }));
};
