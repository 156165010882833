import { get } from "~/api/utils";
import { ONBOARDING_V2_BASEURL } from "~/store/api";
// eslint-disable-next-line @typescript-eslint/naming-convention
var PAGE_STATUS;
(function (PAGE_STATUS) {
    PAGE_STATUS["COMPLETED"] = "COMPLETED";
    PAGE_STATUS["PENDING"] = "PENDING";
    PAGE_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
})(PAGE_STATUS || (PAGE_STATUS = {}));
export const checkPageStatus = async ({ id, flowId, processSlug, pageSlug, }) => {
    let result = false;
    try {
        const r = await get(`${ONBOARDING_V2_BASEURL}/clients/${id}/onboarding-flows/${flowId}/${processSlug}/${pageSlug}/status`);
        if (r.pageStatus === PAGE_STATUS.COMPLETED) {
            result = true;
        }
    }
    catch (e) {
        result = false;
    }
    return result;
};
