import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Image from "~/components/Image/Image";
import theme from "~/theme";
export const Logout = () => {
    const { t } = useTranslation();
    const handleLogin = () => {
        window.location.replace("/");
    };
    return (_jsx(ChakraProvider, { theme: theme, children: _jsxs(Flex, { h: "100vh", direction: "column", children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", h: "headerHeight", px: 8, py: 6, backgroundColor: "brand.white", children: [_jsx(Image, { name: "msdirect_logo", mimeType: "png" }), _jsx(Button, { variant: "link", colorScheme: "button.primary", onClick: handleLogin, children: t("logout.cta") })] }), _jsx(Flex, { flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "sky.10", children: _jsxs(Flex, { direction: "column", alignItems: "flex-start", p: 12, backgroundColor: "brand.white", children: [_jsx(Text, { textStyle: "h1", mb: "4", children: t("logout.title") }), _jsx(Text, { mb: "8", children: t("logout.subtitle") }), _jsx(Button, { onClick: handleLogin, children: t("logout.cta") })] }) })] }) }));
};
