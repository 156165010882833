import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Select, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useGetClientResponsiblesQuery, useUpdateClientByIdMutation, } from "../../redux/clients.api";
export const EditSuccessManagerModal = ({ isOpen, setIsOpen, client }) => {
    const { data: responsiblesData } = useGetClientResponsiblesQuery();
    const [successManager, setSuccessManager] = useState(client.responsible);
    const { t } = useTranslation();
    const toast = useToast();
    const [updateClient, { isLoading }] = useUpdateClientByIdMutation();
    const handleSubmit = async () => {
        if (successManager !== client.responsible) {
            const res = await updateClient({
                id: client.id,
                responsible: successManager.email,
            });
            if ("data" in res) {
                toast({
                    title: t("clients.editSuccessManagerModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
    };
    useEffect(() => {
        if (!isOpen)
            setSuccessManager(client.responsible);
    }, [isOpen]);
    const responsibleOptions = responsiblesData || [client.responsible];
    const handleChange = (e) => {
        const manager = responsibleOptions.find((res) => res.email === e.target.value);
        if (manager)
            setSuccessManager(manager);
    };
    return (_jsx(Modal, { title: t("clients.editSuccessManagerModal.title"), isOpen: isOpen, onClose: () => setIsOpen(false), primaryAction: handleSubmit, primaryActionDisabled: client.responsible === successManager, primaryActionLoading: isLoading, primaryTitle: t("basics.save"), size: "lg", children: _jsxs(Box, { display: "flex", flexDir: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.editSuccessManagerModal.selectLabel") }), _jsx(Select, { variant: "formSelect", value: successManager.email, onChange: handleChange, disabled: !responsibleOptions.length, children: responsibleOptions.map((sm) => (_jsxs("option", { value: sm.email, children: [sm.firstName, " ", sm.lastName] }, sm.email))) })] }) }));
};
