export function daysCalculation(flowFields) {
    var _a, _b, _c, _d;
    const approvedExporter = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "approvedExporter" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const authorizedExporter = ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "authorizedExporter" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    const nightLeap = ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "nightLeap" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
    const shippingMethod = ((_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "shippingMethod" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _d === void 0 ? void 0 : _d.fieldValue) || "";
    if (approvedExporter === "no" &&
        authorizedExporter === "no" &&
        nightLeap === "no" &&
        shippingMethod === "priority") {
        return 1;
    }
    if (approvedExporter === "no" &&
        authorizedExporter === "no" &&
        nightLeap === "yes" &&
        shippingMethod === "priority") {
        return 2;
    }
    if (approvedExporter === "no" &&
        authorizedExporter === "no" &&
        nightLeap === "yes" &&
        shippingMethod === "eco") {
        return 3;
    }
    if (approvedExporter === "no" &&
        authorizedExporter === "yes" &&
        nightLeap === "no") {
        return 4;
    }
    if (approvedExporter === "no" &&
        authorizedExporter === "yes" &&
        nightLeap === "yes") {
        return 5;
    }
    return 0;
}
