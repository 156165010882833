import { jsx as _jsx } from "react/jsx-runtime";
import _ from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "~/components/Checkbox/Checkbox";
import { getCustomComponent, } from "~/components/GeneratedForm/CustomComponents";
import RadioButton from "~/components/RadioButton/RadioButton";
import Select from "~/components/Select/Select";
import TextArea from "~/components/TextArea/TextArea";
import TextField from "~/components/TextField/TextField";
import UploadFilesArea from "~/components/UploadFilesArea/UploadFilesArea";
import { translateError } from "~/helpers/translate-error";
import { useFormContext } from "../FormContextProvider";
import { useFormGroupContext } from "./group-layout";
function ControlComponent({ node }) {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { register, setValue, formState: { errors }, watch, } = useFormContext();
    const ruleMatched = ((_a = node === null || node === void 0 ? void 0 : node.rule) === null || _a === void 0 ? void 0 : _a.targetKey) !== undefined &&
        (((_b = node === null || node === void 0 ? void 0 : node.rule) === null || _b === void 0 ? void 0 : _b.targetKey) === true ||
            watch((_c = node === null || node === void 0 ? void 0 : node.rule) === null || _c === void 0 ? void 0 : _c.targetKey) === ((_d = node === null || node === void 0 ? void 0 : node.rule) === null || _d === void 0 ? void 0 : _d.targetValue));
    const ruleEffect = ((_e = node === null || node === void 0 ? void 0 : node.rule) === null || _e === void 0 ? void 0 : _e.effect) || undefined;
    const isDisabled = (ruleMatched && ruleEffect === "DISABLE") ||
        (!ruleMatched && ruleEffect === "ENABLE");
    const registerField = () => {
        return register((node.type === "radio" ? node.forKey : node.key));
    };
    const setFieldValue = (v) => setValue(node.key, v);
    useEffect(() => {
        if (isDisabled)
            setFieldValue(null);
    }, [isDisabled]);
    const fieldError = translateError(t, _.get(errors, node.key));
    switch (node.type) {
        case "text": {
            const { label, isTextarea } = node;
            return isTextarea ? (_jsx(TextArea, { isDisabled: isDisabled, placeholder: t(label), error: fieldError, label: t(label), ...registerField() })) : (_jsx(TextField, { isDisabled: isDisabled, placeholder: t(label), error: fieldError, label: t(label), ...registerField() }));
        }
        case "select": {
            const { label, options } = node;
            return (_jsx(Select, { isDisabled: isDisabled, placeholder: t(label), error: fieldError, options: options, label: t(label), ...registerField() }));
        }
        case "file": {
            const { multiple, label } = node;
            const { ref } = registerField();
            return (_jsx(UploadFilesArea, { onFileAccepted: (acceptedFiles) => {
                    setFieldValue(acceptedFiles.map((file) => file.uploadedUrl));
                }, placeholder: label || "", multiple: multiple, error: fieldError, ref: ref }));
        }
        case "checkbox": {
            const { label, value } = node;
            const { getFieldProps } = useFormGroupContext();
            const fieldProps = getFieldProps({ value });
            const { name, onChange, ...componentProps } = fieldProps;
            return (_jsx(Checkbox, { name: name, value: value, onChange: onChange, isDisabled: isDisabled, error: fieldError, label: t(label), ...componentProps }));
        }
        case "radio": {
            const { label, value } = node;
            const { getFieldProps } = useFormGroupContext();
            const fieldProps = getFieldProps({ value });
            const { name, onChange, ...componentProps } = (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.name) !== undefined ? fieldProps : registerField();
            return (_jsx(RadioButton, { name: name, value: value, onChange: onChange, isDisabled: isDisabled, w: "min-intrinsic", error: fieldError, label: t(label), ...componentProps }));
        }
        case "customComponent": {
            const { componentName, componentProps, label } = node;
            const Component = getCustomComponent(componentName);
            return (_jsx(Component, { ...{
                    error: fieldError,
                    label: t(label || ""),
                    register: registerField,
                    ...componentProps,
                } }));
        }
        default:
            return null;
    }
}
export function ControlFieldComponent(props) {
    return _jsx(ControlComponent, { ...props });
}
