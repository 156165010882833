import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returns.title",
    }),
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "returns-start",
            infoboxText: "onboarding.inboundProcesses.returns.dutyDrawbackInfoText",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    groupType: "radio",
                    forKey: "customs_refund",
                    options: {
                        pt: 2,
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label2",
                            type: "h3",
                            label: "onboarding.inboundProcesses.returns.customRefunds",
                        },
                        {
                            key: "label3",
                            type: "label",
                            label: "onboarding.inboundProcesses.returns.doYouWantCustomRefunds",
                        },
                        {
                            type: "radio",
                            key: "yes",
                            label: "onboarding.actions.yes",
                            value: "yes",
                            forKey: "customs_refund",
                        },
                        {
                            type: "radio",
                            key: "customs_refund",
                            label: "onboarding.actions.no",
                            value: "no",
                            forKey: "customs_refund",
                        },
                    ],
                },
            ],
        }),
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "returns-location",
            infoboxText: "onboarding.inboundProcesses.returns.returnInfoText",
            formNodes: [
                {
                    key: "label1",
                    type: "h3",
                    label: "onboarding.inboundProcesses.returns.returnsLocation",
                },
                {
                    type: "layout",
                    direction: "vertical",
                    options: {
                        pt: 2,
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label2",
                            type: "label",
                            label: "onboarding.inboundProcesses.returns.returnsLocationDescription",
                        },
                        {
                            key: "company_information_name",
                            type: "text",
                            label: "users.name",
                        },
                        {
                            type: "layout",
                            direction: "horizontal",
                            options: {
                                gap: 3,
                            },
                            elements: [
                                {
                                    key: "company_information_street",
                                    type: "text",
                                    label: "onboarding.companyInformation.street",
                                },
                                {
                                    key: "company_information_number",
                                    type: "text",
                                    label: "onboarding.companyInformation.number",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "horizontal",
                            options: {
                                gap: 3,
                            },
                            elements: [
                                {
                                    key: "company_information_zipCode",
                                    type: "text",
                                    label: "onboarding.companyInformation.zipCode",
                                },
                                {
                                    key: "company_information_city",
                                    type: "text",
                                    label: "onboarding.companyInformation.city",
                                },
                            ],
                        },
                        {
                            key: "label6",
                            type: "customComponent",
                            componentName: "base.CountrySelect",
                        },
                    ],
                },
            ],
        }),
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h3",
                label: "onboarding.inboundProcesses.returns.returnsDetails",
            },
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "cost-of-returns",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.inboundProcesses.returns.costOfReturns.question",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "cost-of-returns",
                                label: "onboarding.inboundProcesses.returns.costOfReturns.myCustomer",
                                value: "my-customer",
                                forKey: "cost-of-returns",
                            },
                            {
                                type: "radio",
                                key: "cost-of-returns",
                                label: "onboarding.inboundProcesses.returns.costOfReturns.weTakeOver",
                                value: "we-take-over-the-costs",
                                forKey: "cost-of-returns",
                            },
                            {
                                type: "radio",
                                key: "cost-of-returns",
                                label: "onboarding.inboundProcesses.returns.costOfReturns.notRelevant",
                                value: "not-relevant",
                                forKey: "cost-of-returns",
                            },
                        ],
                    },
                    {
                        key: "select",
                        type: "select",
                        label: "onboarding.inboundProcesses.returns.returnCarrier.question",
                        options: {
                            post_contract_about_ms_direct: "onboarding.inboundProcesses.returns.returnCarrier.postContractAboutMSDirect",
                            post_contract_via_seven_senders: "onboarding.inboundProcesses.returns.returnCarrier.postContractViaSevenSenders",
                            post_direkt_contract: "onboarding.inboundProcesses.returns.returnCarrier.postDirektContract",
                            dpd_contract_via_ms_direct: "onboarding.inboundProcesses.returns.returnCarrier.dpdContractViaMSDirect",
                            dpd_contract_through_seven_senders: "onboarding.inboundProcesses.returns.returnCarrier.dpdContracThroughSevenSenders",
                            dpd_direct_contract: "onboarding.inboundProcesses.returns.returnCarrier.dpdDirectContract",
                        },
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
