import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-danger */
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Image from "~/components/Image/Image";
export default function ProcessOverviewItem({ icon, day, title, when, who, what, last, }) {
    const { t } = useTranslation();
    return (_jsxs(Flex, { direction: "row", p: "0", gap: "3", children: [_jsx(Flex, { width: "25%", pr: "6", direction: "column", alignItems: "flex-start", p: "0", children: icon && _jsx(Image, { name: icon, alt: "logo" }) }), _jsx(Flex, { width: "14%", justifyContent: "center", children: _jsxs(Flex, { direction: "column", alignItems: "center", style: { position: "relative" }, children: [last && (_jsxs(Box, { style: {
                                position: "absolute",
                                bottom: "-10px",
                                left: "50%",
                                transform: "translate(-50%, 0)",
                            }, children: [_jsx(Image, { name: "delivered", alt: "delivered" }), _jsx(Text, { fontSize: "3xl", fontWeight: "bold", children: t("processOverview.delivered") })] })), day && (_jsxs(Flex, { w: "full", direction: "column", alignItems: "center", px: "4", children: [_jsx(Text, { fontSize: "lg", children: t("processOverview.day") }), _jsx(Text, { fontSize: "2xl", fontWeight: "bold", children: day })] })), _jsx(Flex, { width: "2px", bg: "gray", flex: "1" })] }) }), _jsxs(Flex, { width: "60%", p: "0", pl: "6", direction: "column", alignItems: "left", justifyContent: "top", children: [_jsx(Box, { pb: "4", children: _jsx(Text, { fontSize: "3xl", fontWeight: "bold", children: title }) }), _jsx(HStack, { direction: "row", children: _jsxs(Box, { pb: "4", children: [_jsx(Text, { fontSize: "lg", fontWeight: "bold", children: t("processOverview.when") }), _jsx(Text, { fontSize: "lg", children: when || t("processOverview.tbd") })] }) }), _jsx(HStack, { direction: "row", children: _jsxs(Box, { pb: "4", children: [_jsx(Text, { fontSize: "lg", fontWeight: "bold", children: t("processOverview.who") }), _jsx(Text, { fontSize: "lg", children: who || t("processOverview.tbd") })] }) }), _jsx(HStack, { direction: "row", children: _jsxs(Box, { pb: "12", children: [_jsx(Text, { fontSize: "lg", fontWeight: "bold", children: t("processOverview.what") }), _jsx(Text
                                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                , { 
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                    dangerouslySetInnerHTML: {
                                        __html: what || t("processOverview.tbd"),
                                    } })] }) })] })] }));
}
