import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { saveAs } from "file-saver";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "~/assets/icons/ArrowRightIcon";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { OnboardingFormFooter } from "~/components/OnboardingFormFooter/OnboardingFormFooter";
import { OnboardingFormHeader } from "~/components/OnboardingFormHeader/OnboardingFormHeader";
import { OnboardingFormSection } from "~/components/OnboardingFormSection/OnboardingFormSection";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { ServiceCode } from "~/enums/service-code.enum";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { useGetMandatoryFields, } from "~/hooks/useGetMandatoryFIelds";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useGetClientServicesQuery } from "~/pages/clients/redux/clients.api";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
import { patchFormField } from "~/store/actions/formData.actions";
import { ARTICLES_V1_BASEURL } from "~/store/api";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { checkAccountType } from "~/utils/checkAccountType";
import { InitialClassificationSection } from "./components/InitialClassificationSection/InitialClassificationSection";
import { TestImportFileUpload } from "./components/TestImportFileUpload/TestImportFileUpload";
export const ProductDetailsDefineImportFile = ({ onSettingsPage = false }) => {
    var _a;
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname, params: { id, flowId }, } = useMatch();
    const { user, token } = useAppSelector(authSelector);
    const { mandatoryFields, mandatoryFieldsArr } = useGetMandatoryFields();
    const { data: services } = useGetClientServicesQuery({
        clientId: (_a = user === null || user === void 0 ? void 0 : user.accountId) !== null && _a !== void 0 ? _a : Number(id),
        service: ServiceCode.TARIFFING,
    });
    const hasInitialClassification = useMemo(() => {
        var _a;
        return ((_a = services === null || services === void 0 ? void 0 : services.some((service) => service.serviceData.services.some((s) => s.code === PartnerServiceCode.INITIAL_CLASSIFICATION))) !== null && _a !== void 0 ? _a : false);
    }, [services]);
    const isClientOnboarding = pathname.split("/")[1] === "clients";
    const renderFieldRow = ({ name, description, isOptional, isAndOr, }) => (_jsxs(Box, { borderTopWidth: "1px", borderTopColor: "grey.10", pb: "4", pt: "4", _first: { borderTopWidth: 0, paddingTop: 0 }, _last: { paddingBottom: 0 }, children: [_jsxs(Text, { textStyle: "label", color: "brand.black", mb: "2", children: [name, " ", isOptional && (_jsxs(Text, { as: "span", fontWeight: "400", color: "grey.60", children: ["(", t("onboarding.productDetails.defineImportFile.csvFields.optionalTag"), ")"] })), isAndOr && (_jsxs(Text, { as: "span", fontWeight: "400", color: "grey.60", children: ["(", t("onboarding.productDetails.defineImportFile.csvFields.andOrTag"), ")"] }))] }), _jsx(Text, { textStyle: "bodySmall", color: "grey.60", children: description })] }, `form-field-${name}`));
    const getFreeFields = () => {
        const fields = [];
        for (let i = 1; i <= 10; i += 1) {
            fields.push(t("onboarding.productDetails.defineImportFile.freeFields.fieldName", {
                number: i,
            }));
        }
        return fields;
    };
    const handleGenerateAndDownload = async () => {
        setIsFetching(true);
        try {
            const res = await fetch(withBaseUrl(`${ARTICLES_V1_BASEURL}/generate-template`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    headers: [...mandatoryFieldsArr, ...getFreeFields()],
                }),
            });
            if (res.ok) {
                setIsError(false);
                const data = await res.json();
                const buffer = Buffer.from(data.file.data);
                const blob = new Blob([buffer], { type: data.mimetype });
                const url = window.URL.createObjectURL(blob);
                saveAs(url, data.name);
            }
            else {
                setIsError(true);
            }
            setIsFetching(false);
        }
        catch (err) {
            setIsError(true);
        }
    };
    const mandatoryFieldItems = useMemo(() => {
        return mandatoryFields.map(renderFieldRow);
    }, []);
    const handleNavigateToTechnicalSetup = () => {
        dispatch(patchFormField({
            fieldName: "validationField",
            fieldValue: "seen",
            id,
            flowId,
            pageSlug: "defineImportFile",
            processSlug: "productDetails",
        }));
        navigate({ to: `/clients/${id}/onboarding/${flowId}/technical-setup` });
    };
    return (_jsxs(_Fragment, { children: [_jsx(OnboardingFormHeader, { title: t("onboarding.productDetails.defineImportFile.page.title"), subtitle: t("onboarding.productDetails.defineImportFile.page.overview.title"), description: onSettingsPage
                    ? t("onboarding.productDetails.defineImportFile.page.overview.settingsDescription")
                    : t("onboarding.productDetails.defineImportFile.page.overview.description"), cta: !onSettingsPage
                    ? t("onboarding.productDetails.defineImportFile.page.overview.cta")
                    : undefined, ctaFn: !onSettingsPage ? handleNavigateToTechnicalSetup : undefined, ctaIcon: !onSettingsPage ? _jsx(ArrowRightIcon, { boxSize: 5 }) : undefined }), _jsx(OnboardingFormSection, { title: t("onboarding.productDetails.defineImportFile.csvFields.title"), children: _jsx(Box, { children: mandatoryFieldItems }) }), _jsx(OnboardingFormSection, { title: t("onboarding.productDetails.defineImportFile.freeFields.title"), subtitle: t("onboarding.productDetails.defineImportFile.freeFields.description"), children: renderFieldRow({
                    name: t("onboarding.productDetails.defineImportFile.freeFields.freeFieldHeadline"),
                    description: t("onboarding.productDetails.defineImportFile.freeFields.freeFieldDescription"),
                    isOptional: true,
                }) }), _jsxs(OnboardingFormSection, { title: t("onboarding.productDetails.defineImportFile.downloadTemplate.title"), children: [_jsxs(Flex, { alignItems: "center", gap: "6", children: [_jsx(Text, { flex: 1, children: t("onboarding.productDetails.defineImportFile.downloadTemplate.description") }), _jsx(Button, { onClick: handleGenerateAndDownload, size: "lg", isLoading: isFetching, isDisabled: isFetching, children: t("onboarding.productDetails.defineImportFile.downloadTemplate.generateTemplateCTA") })] }), isError && (_jsx(ErrorMessage, { text: t("error.badRequest.somethingWentWrong") }))] }), _jsx(OnboardingFormSection, { title: t("onboarding.productDetails.defineImportFile.testUpload.title"), subtitle: t("onboarding.productDetails.defineImportFile.testUpload.description"), children: _jsx(TestImportFileUpload, {}) }), checkAccountType(AccountTypes.CLIENT) &&
                isClientOnboarding &&
                hasInitialClassification && (_jsx(OnboardingFormSection, { title: t("onboarding.productDetails.defineImportFile.initialClassification.title"), children: _jsx(InitialClassificationSection, {}) })), !onSettingsPage && (_jsx(OnboardingFormFooter, { sendPageAsSeen: true, pageSlug: "defineImportFile", processSlug: "productDetails" }))] }));
};
