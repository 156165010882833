import { jsx as _jsx } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { clientsApi, useRemoveClientServiceMutation, } from "~/pages/clients/redux/clients.api";
import { servicesApi } from "~/pages/services/redux/services.api";
export const DisableTariffingModal = ({ setIsOpen, isOpen, service }) => {
    const { params: { id }, } = useMatch();
    const toast = useToast();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [removeService, { isLoading }] = useRemoveClientServiceMutation();
    const handleRemove = async () => {
        if (service.id) {
            try {
                const res = await removeService({
                    clientId: Number(id),
                    clientServiceId: service.id,
                });
                if ("data" in res) {
                    toast({
                        title: t("clients.disableTariffingModal.success"),
                        status: "success",
                        duration: 3000,
                    });
                    setIsOpen(false);
                    dispatch(clientsApi.util.invalidateTags(["CLIENT_SERVICES"]));
                    dispatch(servicesApi.util.invalidateTags(["SERVICE"]));
                }
            }
            catch (err) {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("clients.disableTariffingModal.title"), primaryTitle: t("clients.disableTariffingModal.primaryCta"), primaryAction: handleRemove, primaryVariant: "danger", primaryActionLoading: isLoading, children: _jsx(Text, { mt: "2", color: "grey.60", children: t("clients.disableTariffingModal.subtitle") }) }));
};
