import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.categories.title",
        description: "onboarding.productDetails.categories.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h2",
                label: "onboarding.productDetails.categories.productPortfolio",
            },
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "add_another_shop_incrementer",
                        type: "customComponent",
                        componentName: "base.IncrementedControlsArray",
                        componentProps: {
                            forKey: "link-shop",
                            controlNodes: [
                                {
                                    key: "add-link-shop",
                                    type: "text",
                                    label: "onboarding.productDetails.categories.linkToYourShopOrWebsite",
                                },
                            ],
                            incrementerButtonNode: {
                                key: "add-link-shop",
                                type: "button",
                                variant: "text",
                                label: "onboarding.productDetails.categories.addAdditionalPage",
                            },
                            decrementerButtonNode: {
                                key: "remove-link-shop",
                                type: "button",
                                variant: "error",
                                label: "onboarding.productDetails.categories.removeAdditionalPage",
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "categories",
            infoboxText: "onboarding.productDetails.categories.selectOneOrMoreCategoriesInfo",
            formNodes: [
                {
                    key: "label3",
                    type: "h3",
                    label: "onboarding.productDetails.categories.title",
                },
                {
                    type: "layout",
                    direction: "horizontal",
                    groupType: "checkbox",
                    forKey: "categories",
                    options: {
                        pt: 2,
                    },
                    elements: [
                        {
                            type: "layout",
                            direction: "vertical",
                            options: {
                                gap: 3,
                            },
                            elements: [
                                {
                                    key: "checkbox1",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.fashionAndShoes",
                                },
                                {
                                    key: "checkbox2",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.multimediaAndIT",
                                },
                                {
                                    key: "checkbox3",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.homeAndLiving",
                                },
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.media",
                                },
                                {
                                    key: "checkbox5",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.office",
                                },
                                {
                                    key: "checkbox6",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.healthAndPersonalCare",
                                },
                                {
                                    key: "checkbox1",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.watchesAndJewellery",
                                },
                                {
                                    key: "checkbox2",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.universalMailer",
                                },
                                {
                                    key: "checkbox3",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.foodAndBeverages",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: {
                                gap: 3,
                            },
                            elements: [
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.sportsAndOutdoorLeisure",
                                },
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.dietarySupplements",
                                },
                                {
                                    key: "checkbox5",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.automobileMotorcycle",
                                },
                                {
                                    key: "checkbox3",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.toysAndBabie",
                                },
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.petSupplies",
                                },
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.erotic",
                                },
                                {
                                    key: "checkbox5",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.finance",
                                },
                                {
                                    key: "checkbox4",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.pharma",
                                },
                                {
                                    key: "checkbox5",
                                    type: "checkbox",
                                    label: "onboarding.productDetails.categories.categoriesTypes.industry",
                                },
                            ],
                        },
                    ],
                },
            ],
        }),
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "article-master-data",
            infoboxText: "onboarding.productDetails.categories.articleMasterDataInfoText",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: {
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label4",
                            type: "h3",
                            label: "onboarding.productDetails.categories.articleMasterData",
                        },
                        {
                            key: "label5",
                            type: "label",
                            label: [
                                "1. ",
                                {
                                    href: "/",
                                    displayLabel: "onboarding.productDetails.categories.here",
                                },
                                "onboarding.productDetails.categories.findTemplateForMasterData",
                            ],
                        },
                        {
                            key: "label6",
                            type: "label",
                            label: [
                                "2. ",
                                "onboarding.productDetails.categories.pleaseUploadCompletedFile",
                                { strong: true, displayLabel: "onboarding.actions.here" },
                            ],
                        },
                        {
                            key: "files",
                            type: "file",
                            label: "onboarding.uploadFilesArea.files",
                            multiple: true,
                        },
                        {
                            key: "label7",
                            type: "label",
                            label: "onboarding.productDetails.categories.howOftenYouAddNewProductsRange",
                        },
                        {
                            key: "country",
                            type: "select",
                            label: "",
                            options: {
                                daily: "onboarding.forms.frequency.daily",
                                weekly: "onboarding.forms.frequency.weekly",
                                monthly: "onboarding.forms.frequency.monthly",
                                onePerQuarter: "onboarding.forms.frequency.onePerQuarter",
                                onePerSeason: "onboarding.forms.frequency.onePerSeason",
                                anually: "onboarding.forms.frequency.anually",
                            },
                        },
                    ],
                },
            ],
        }),
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
