import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Skeleton, Text, Tooltip, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
export const Avatar = ({ user, size = 48, wrapperProps = {}, textProps = {}, }) => {
    var _a, _b;
    const { graphToken } = useAppSelector(authSelector);
    const [isMounted, setIsMounted] = useState(false);
    const [url, setUrl] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);
    const showInitials = (!!user.email || !!user.firstName || !!user.lastName) && isError;
    const fetchAvatar = async () => {
        try {
            const response = await fetch(`https://graph.microsoft.com/v1.0/users/${user === null || user === void 0 ? void 0 : user.id}/photo/$value`, {
                method: "GET",
                headers: {
                    "Content-Type": "image/jpeg",
                    Authorization: `Bearer ${graphToken}`,
                },
            });
            if (response.ok) {
                const src = await response.blob();
                const reader = new FileReader();
                reader.readAsDataURL(src);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setUrl(base64data);
                };
            }
            else {
                setIsError(true);
            }
            setIsFetching(false);
        }
        catch (error) {
            setIsError(true);
        }
    };
    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            fetchAvatar();
        }
    }, [isMounted]);
    const avatarSize = `${size}px`;
    const tooltipLabel = [user.firstName, user.lastName].join(" ").length > 1
        ? [user.firstName, user.lastName].join(" ")
        : user.email;
    return (_jsx(Tooltip, { hasArrow: true, bg: "brand.black", placement: "top", label: tooltipLabel, shouldWrapChildren: true, children: _jsx(Flex, { flexShrink: 0, flexGrow: 0, justifyContent: "center", alignItems: "center", borderRadius: "full", bgColor: "white", bgImage: url || "", bgSize: "cover", bgPos: "center", w: avatarSize, h: avatarSize, border: "1px solid", borderColor: "grey.20", ...wrapperProps, children: isFetching ? (_jsx(Skeleton, { w: avatarSize, h: avatarSize, rounded: "full" })) : showInitials ? (_jsx(Text, { fontWeight: 700, color: "grey.40", fontSize: "14px", ...textProps, children: !user.firstName && !user.lastName ? (user.email[0].toUpperCase()) : (_jsxs(_Fragment, { children: [(_a = user.firstName) === null || _a === void 0 ? void 0 : _a[0].toUpperCase(), (_b = user.lastName) === null || _b === void 0 ? void 0 : _b[0].toUpperCase()] })) })) : null }, `user-avatar-${user.id}`) }));
};
