import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button/Button";
import TextButton from "~/components/TextButton/TextButton";
import { useFormContext } from "../FormContextProvider";
export function ButtonComponent({ node: { key, label, transition, variant }, onClick, disabled = false, }) {
    const { handleTransition } = useFormContext();
    const { t } = useTranslation();
    const onClickHandler = () => (typeof onClick === "function" && onClick()) ||
        (transition && handleTransition(transition));
    switch (variant) {
        case "outline":
            // TODO
            return (_jsx(Button, { onClick: onClickHandler, disabled: disabled, children: t(label) }, key));
        case "text":
            return (_jsx(TextButton, { pt: "6px", pb: "2px", px: "2px", onClick: onClickHandler, disabled: disabled, children: t(label) }, key));
        case "error":
            return (_jsx(TextButton, { pt: "6px", pb: "2px", px: "2px", onClick: onClickHandler, color: "red.500", disabled: disabled, children: t(label) }, key));
        case "secondary":
            return (_jsx(Button, { colorScheme: "secondary", onClick: onClickHandler, disabled: disabled, children: t(label) }, key));
        default:
            return (_jsx(Button, { onClick: onClickHandler, disabled: disabled, children: t(label) }, key));
    }
}
