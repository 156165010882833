import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Grid, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Avatar } from "../Avatar/Avatar";
export const UserOverlayList = ({ users, isLoading, }) => {
    const { t } = useTranslation();
    const totalUsers = (users === null || users === void 0 ? void 0 : users.length) || 0;
    if (isLoading || !users)
        return (_jsxs(Grid, { templateColumns: "repeat(5, 48px)", gap: "3", children: [_jsx(Skeleton, { w: "12", h: "12", rounded: "full" }), _jsx(Skeleton, { w: "12", h: "12", rounded: "full" }), _jsx(Skeleton, { w: "12", h: "12", rounded: "full" })] }));
    return users.length ? (_jsx(Grid, { templateColumns: "repeat(5, 48px)", gap: "3", children: totalUsers <= 5 ? (users.map((user) => _jsx(Avatar, { user: user }, user.id))) : (_jsxs(_Fragment, { children: [users.slice(0, 4).map((user) => (_jsx(Avatar, { user: user }))), " ", _jsx(Flex, { justifyContent: "center", alignItems: "center", borderRadius: "full", bgColor: "grey.20", w: "48px", h: "48px", border: "1px solid", borderColor: "grey.20", children: _jsxs(Text, { fontWeight: 700, fontSize: "14px", color: "grey.40", children: ["+", totalUsers - 4] }) })] })) })) : (_jsx(Text, { color: "grey.40", children: t("partners.drawer.noUsersText") }));
};
