import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, isToday, isYesterday } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FILE_TYPES, FileTypeTag } from "~/components/FileTypeTag/FileTypeTag";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterMultiselect } from "~/components/FilterBar/components/FilterMultiselect/FilterMultiselect";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import Icon from "~/components/Icon/Icon";
import { Table } from "~/components/Table/Table";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { apiBaseUrl } from "~/helpers/withBaseUrl";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamFilter } from "~/hooks/useParamFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { clientServicesUrl, useGetClientDocumentOwnersQuery, useGetClientDocumentsQuery, } from "../../redux/clients.api";
import { DeleteFileModal } from "../DeleteFileModal/DeleteFileModal";
export const ClientDocuments = ({ client, }) => {
    const { t } = useTranslation();
    const { token } = useAppSelector(authSelector);
    const [deletedFile, setDeletedFile] = useState(null);
    const [downloadingItems, setDownloadingItems] = useState([]);
    // FILTERS
    const [filteredOwners, setFilteredOwners] = useParamFilter("owner");
    const [filteredFileTypes, setFilteredFileTypes] = useParamFilter("type");
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const { data: owners, isLoading: isLoadingOwners } = useGetClientDocumentOwnersQuery(client.id);
    const { data: documents, isLoading: isLoadingDocuments, isFetching, } = useGetClientDocumentsQuery({
        id: client.id,
        params: {
            page: pagination.pageIndex,
            size: pagination.pageSize,
            owners: filteredOwners,
            fileType: filteredFileTypes,
            searchQuery,
            ...(!!sorting[0] && {
                order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
                orderBy: sorting[0].id || null,
            }),
        },
    });
    const handleDownloadItem = async (doc) => {
        if (downloadingItems.includes(doc.id))
            return;
        setDownloadingItems((prev) => [...prev, doc.id]);
        const res = await fetch(`${apiBaseUrl}${clientServicesUrl}/${client.id}/documents/${doc.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "image/jpeg",
                Authorization: `Bearer ${token}`,
            },
        });
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.name);
        document.body.appendChild(link);
        link.click();
        setDownloadingItems((prev) => prev.filter((id) => id !== doc.id));
    };
    const tableActions = (document) => [
        {
            disabled: downloadingItems.includes(document.id),
            label: t("basics.download"),
            element: (_jsx(Icon, { display: "flex", justifyContent: "center", alignItems: "center", width: "17px", h: "19px", name: "download" })),
            onClick: () => handleDownloadItem(document),
        },
        {
            label: t("basics.delete"),
            element: (_jsx(Icon, { display: "flex", justifyContent: "center", alignItems: "center", width: "16px", h: "18px", name: "trash" })),
            onClick: () => {
                setDeletedFile(document);
            },
        },
    ];
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("createdAt", {
            header: () => t("clients.detail.documents.table.date"),
            size: 130,
            cell: ({ getValue }) => {
                const date = new Date(getValue());
                if (isToday(date))
                    return "Today";
                if (isYesterday(date))
                    return "Yesterday";
                return format(date, "dd/M/yyyy");
            },
        }),
        columnHelper.accessor("name", {
            size: undefined,
            header: () => t("clients.detail.documents.table.name"),
        }),
        columnHelper.accessor("user", {
            id: "owner",
            header: () => t("clients.detail.documents.table.owner"),
            cell: ({ getValue }) => {
                const { firstName, lastName, email } = getValue();
                return !firstName && !lastName
                    ? email
                    : [firstName, lastName].join(" ");
            },
        }),
        columnHelper.accessor("contentType", {
            id: "fileType",
            size: 130,
            header: () => t("clients.detail.documents.table.type"),
            cell: ({ row }) => (_jsx(FileTypeTag, { type: row.original.contentType, name: row.original.name })),
        }),
        columnHelper.display({
            id: "actions",
            size: undefined,
            header: "",
            cell: ({ row }) => renderTableRowActions({
                tableActions: tableActions(row.original),
            }),
        }),
    ];
    const typeOptions = useMemo(() => {
        return Object.keys(FILE_TYPES).map((value) => ({
            title: FILE_TYPES[value],
            value,
        }));
    }, []);
    const ownerOptions = (owners === null || owners === void 0 ? void 0 : owners.map(({ email, firstName, lastName }) => ({
        title: [firstName, lastName].join(" "),
        value: email,
    }))) || [];
    const disableFilters = isLoadingDocuments || isLoadingOwners;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { h: "full", children: [_jsx(Text, { my: 8, textStyle: "h2", children: t("clients.detail.documents.headline") }), _jsx(FilterBar, { children: _jsxs(Flex, { gap: 4, alignItems: "center", children: [_jsx(FilterSearch, { disabled: disableFilters, value: search, setValue: setSearch }), _jsx(FilterMultiselect, { disabled: disableFilters, options: ownerOptions, selectedOptions: filteredOwners.length ? filteredOwners.split(",") : [], setSelectedOptions: (values) => setFilteredOwners(values.join(",")), placeholder: t("clients.detail.documents.ownerSelect"), menuLabel: t("clients.detail.documents.ownerMenuLabel"), selectedKey: t("clients.detail.documents.ownerCount") }), _jsx(FilterMultiselect, { disabled: disableFilters, options: typeOptions, selectedOptions: filteredFileTypes.length ? filteredFileTypes.split(",") : [], setSelectedOptions: (values) => setFilteredFileTypes(values.join(",")), placeholder: t("clients.detail.documents.typeSelect"), menuLabel: t("clients.detail.documents.typeMenuLabel"), selectedKey: t("clients.detail.documents.typeCount") })] }) }), _jsx(Table, { isFetching: isFetching, columns: columns, data: documents, sorting: sorting, pagination: pagination, onPaginationChange: setPagination, onSortingChange: onSortingChange, isLoading: isFetching })] }), _jsx(DeleteFileModal, { clientId: client.id, file: deletedFile, handleHide: () => setDeletedFile(null) })] }));
};
