import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Grid, Select, Spinner, Text, useToast, } from "@chakra-ui/react";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "~/assets/icons/ChevronDownIcon";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { ServiceCode } from "~/enums/service-code.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { translatedCountryName } from "~/helpers/country-codes";
import { hasPermission } from "~/helpers/hasPermission";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useUpdateClientServiceMutation } from "~/pages/clients/redux/clients.api";
import { useAppSelector } from "~/store/hooks";
import { useGetCompanyUsersQuery, useGetPartnerClientServicesQuery, } from "../../redux/partners.api";
export const ClientOverlayServiceTab = ({ client, }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const { user } = useAppSelector(authSelector);
    const { data: services, refetch, isLoading, isFetching, } = useGetPartnerClientServicesQuery({
        partnerId: (user === null || user === void 0 ? void 0 : user.accountId) || 1,
        clientId: client.id,
        service: ServiceCode.TARIFFING, // TBD: we will need to fetch all services, not just tariffing, later on
    });
    const [updateService, { isLoading: isSubmitting }] = useUpdateClientServiceMutation();
    const { data: users = [], isFetching: isFetchingUsers } = useGetCompanyUsersQuery((user === null || user === void 0 ? void 0 : user.accountId) || 1);
    const getCountries = () => {
        const shipToCountries = {};
        services === null || services === void 0 ? void 0 : services.forEach((service) => {
            if (shipToCountries[service.serviceData.shipTo]) {
                shipToCountries[service.serviceData.shipTo].push(service);
            }
            else
                shipToCountries[service.serviceData.shipTo] = [service];
        });
        return shipToCountries;
    };
    const availableCountries = useMemo(() => getCountries(), [services]);
    const handleChangeEmployee = async (responsibleEmployee, service, countryCode) => {
        const { id: serviceId, providedServiceId } = service;
        if (serviceId && providedServiceId) {
            const params = {
                serviceId,
                clientId: client.id,
                responsibleEmployee,
            };
            try {
                const res = await updateService(params).unwrap();
                if (res) {
                    toast({
                        title: t("partners.detail.clients.responsibleChangedSuccess", {
                            country: countryCode,
                        }),
                        status: "success",
                        duration: 3000,
                    });
                    refetch();
                }
            }
            catch (err) {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };
    const renderServiceSelect = (s) => {
        const isShipTo = s.serviceData.direction === ShipDirection.TO;
        const countryCode = (isShipTo ? s.serviceData.shipTo : s.serviceData.shipFrom) || "";
        const showAsLoading = isFetching || isFetchingUsers || isSubmitting;
        return (_jsxs(Flex, { alignItems: "center", w: "full", children: [_jsx(FlagIcon, { countryCode: countryCode, boxSize: "32px" }), _jsx(Select, { ml: "4", placeholder: showAsLoading ? t("basics.loading") : "", variant: "formSelect", value: showAsLoading ? undefined : s.responsibleEmployee, onChange: (e) => handleChangeEmployee(e.target.value, s, countryCode), disabled: showAsLoading ||
                        !hasPermission(EPermissionCodes.PARTNER_USERS_MANAGE), children: !showAsLoading &&
                        users.map((u) => (_jsx("option", { value: u.email, children: [u.firstName, u.lastName].join(" ") }, `employee-option-${u.id}`))) })] }));
    };
    const renderServices = () => {
        return Object.keys(availableCountries).map((country) => {
            const countryServices = availableCountries[country];
            const shipToServices = (countryServices === null || countryServices === void 0 ? void 0 : countryServices.filter((service) => service.serviceData.direction === ShipDirection.TO)) || [];
            const shipFromServices = (countryServices === null || countryServices === void 0 ? void 0 : countryServices.filter((service) => service.serviceData.direction === ShipDirection.FROM)) || [];
            return (_jsx(AccordionItem, { mt: "6", mb: "8", children: ({ isExpanded }) => (_jsxs(_Fragment, { children: [_jsxs(AccordionButton, { children: [_jsx(Text, { textStyle: "h4", color: "brand.black", children: translatedCountryName(country) }), _jsx(ChevronDownIcon, { transform: `rotate(${isExpanded ? "180deg" : "0deg"})`, width: "20px", height: "20px", color: "grey.40", transition: "transform 0.3s", _groupHover: {
                                        color: "brand.black",
                                    } })] }), _jsx(AccordionPanel, { children: _jsxs(Flex, { gap: "8", flexDir: "column", children: [!!shipToServices.length && (_jsxs(Box, { maxW: "full", children: [_jsx(Text, { w: "full", pb: 3, borderBottom: "1px solid", borderBottomColor: "grey.10", textStyle: "labelUppercase", mb: 4, children: t("partners.detail.clients.shipToClassification") }), _jsx(Grid, { templateColumns: "1fr", gap: "3", children: shipToServices.map((s) => renderServiceSelect(s)) })] })), !!shipFromServices.length && (_jsxs(Box, { children: [_jsx(Text, { w: "full", pb: 3, borderBottom: "1px solid", borderBottomColor: "grey.10", textStyle: "labelUppercase", mb: 4, children: t("partners.detail.clients.shipFromClassification") }), _jsx(Grid, { templateColumns: "1fr", gap: "3", children: shipFromServices.map((s) => renderServiceSelect(s)) })] }))] }) })] })) }, `service-item-${country}`));
        });
    };
    return isLoading ? (_jsx(Flex, { h: "full", w: "full", mt: "8", justifyContent: "center", alignItems: "center", children: _jsx(Spinner, { color: "brand.mare" }) })) : (_jsxs(Flex, { flexDir: "column", h: "full", pt: "8", children: [_jsx(Text, { w: "full", textStyle: "h3", children: t("partners.detail.clients.customsClassification") }), _jsx(Accordion, { allowToggle: true, variant: "sidebarService", children: renderServices() })] }));
};
