import { z } from "zod";
import { NON_EMPTY_REFINEMENT_MESSAGE, nonEmptyRefinement, } from "~/utils/nonEmptyRefinement";
import { WEBSITE_VALIDATION, websiteValidation, } from "~/utils/websiteValidation";
const CountryOption = z.object({
    value: z.string(),
    label: z.string(),
});
const AddressSchemaValidation = z.object({
    street: z
        .string({ required_error: "required" })
        .refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    number: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    city: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    zipCode: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    country: CountryOption.required(),
});
const AddressSchema = AddressSchemaValidation.deepPartial()
    .omit({ country: true })
    .extend({
    country: z.string().optional(),
});
export const Option = z.object({
    value: z.string(),
    label: z.string(),
});
export const PhoneCodeOption = Option.extend({
    iso2: z.string(),
});
export const EditCompanyDetailsValidationSchema = z.object({
    name: z.string().refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    email: z
        .string()
        .email()
        .refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    phoneCode: PhoneCodeOption,
    phoneNumber: z
        .string()
        .refine(nonEmptyRefinement, NON_EMPTY_REFINEMENT_MESSAGE),
    website: z.string().refine(websiteValidation, WEBSITE_VALIDATION),
});
export const EditAddressValidationSchema = AddressSchemaValidation;
export const EditCompanyBodySchema = z.object({
    name: z.string().optional(),
    address: AddressSchema,
    description: z.string().optional(),
    email: z.string().optional(),
    phoneCode: z.string().optional(),
    phoneNumber: z.string().optional(),
    website: z.string().optional(),
});
export const AddTeamMemberValidationSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    groups: Option.array().min(1),
    email: z.string().email(),
    phoneCode: PhoneCodeOption.optional().nullable(),
    phoneNumber: z.string().optional(),
});
export const AddTeamMemberBodySchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    groups: z.string().array(),
    email: z.string().email(),
    mobilePhone: z.string().optional(),
});
