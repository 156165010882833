import _ from "lodash";
/**
 * Access nested object values with dotted string notation
 * @example  getNestedValue(obj, 'a.bar.baz')
 */
export function getNestedValue(obj, path) {
    const nestedValue = _.get(obj, path);
    if (nestedValue)
        return nestedValue;
    throw new Error(`Incorrect nested object key - ${path}`);
}
