import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Progress, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { ErrorCell } from "~/components/DataTable/CustomCells/ErrorCell";
import DataTable from "~/components/DataTable/DataTable";
import Image from "~/components/Image/Image";
export default function UploadVerification() {
    const mockedData = [
        {
            id: 1,
            category: "Category 1",
            number: 10,
        },
        {
            id: 2,
            category: "Category 2",
            number: 40,
        },
        {
            id: 3,
            category: "Category 3",
            number: 15,
        },
        {
            id: 4,
            category: "Category 4",
            number: 20,
        },
    ];
    const validations = ["string-length", "order", "others"];
    const errors = ["order"];
    const formattedData = mockedData.map((row) => ({
        ...row,
        category: {
            value: row.category,
            error: errors[0],
        },
    }));
    const columns = useMemo(() => [
        {
            Header: "Shipment Number",
            accessor: "id",
            Cell: ErrorCell,
        },
        {
            Header: "Product Category",
            accessor: "category",
            Cell: ErrorCell,
        },
        {
            Header: "Product Number",
            accessor: "number",
            Cell: ErrorCell,
        },
    ], []);
    return (_jsxs(VStack, { w: "full", h: "full", spacing: 6, alignItems: "flex-start", justifyContent: "flex-start", children: [_jsxs(VStack, { w: "full", alignItems: "flex-start", children: [_jsxs(VStack, { w: "full", h: "full", py: 3, alignItems: "flex-start", children: [_jsx(Text, { fontWeight: "bold", children: "Checking your file" }), _jsx(Progress, { w: "full", size: "xs", colorScheme: "secondary", value: 80, hasStripe: true })] }), _jsx(VStack, { maxH: "260", w: "auto", pb: "2", position: "relative", children: _jsx(DataTable, { columns: columns, data: formattedData, hideDetailsLink: true, hideFilter: true, hidePagination: true, hideBoxShadow: true }) })] }), _jsx(VStack, { w: "container.sm", alignItems: "flex-start", children: validations.map((validation) => (_jsxs(Flex, { gap: "1", w: "full", children: [_jsx(Image, { name: errors.includes(validation) ? "error-icon" : "check-icon", alt: validation }), _jsx(Text, { textTransform: "capitalize", children: validation })] }, validation))) })] }));
}
