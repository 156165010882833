import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { useCreateKeyMutation } from "../../redux/keys.api";
export const CreateKeyModal = ({ show, handleHide, }) => {
    const [createKey, { isLoading, isError, reset }] = useCreateKeyMutation();
    const [keyName, setKeyName] = useState("");
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (show)
            setKeyName("");
    }, [show]);
    const handleSubmit = async () => {
        if (keyName) {
            const res = await createKey(keyName.trim());
            if ("data" in res) {
                navigate({ to: res.data.id });
                toast({
                    title: t("apiKeys.createKeySuccess"),
                    status: "success",
                    duration: 3000,
                });
            }
        }
    };
    const handleChange = (e) => {
        if (isError)
            reset();
        setKeyName(e.target.value);
    };
    return (_jsx(Modal, { title: t("apiKeys.createKeyModalTitle"), isOpen: show, onClose: handleHide, primaryAction: handleSubmit, primaryTitle: t("apiKeys.createKeyModalCTA"), primaryActionLoading: isLoading, primaryActionDisabled: !keyName, children: _jsxs(Box, { mt: 2, children: [_jsx("p", { children: t("apiKeys.createKeyModalText") }), _jsx(Text, { mt: 6, mb: 2, textStyle: "labelUppercase", children: t("apiKeys.createKeyModalInputLabel") }), _jsx(Input, { isInvalid: isError, value: keyName, onChange: handleChange, placeholder: t("apiKeys.createKeyModalInputPlaceholder") }), isError && _jsx(ErrorMessage, { text: t("error.badRequest.duplicateKey") })] }) }));
};
