import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, CircularProgress, CircularProgressLabel, Flex, Text, VStack, } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useGetLandingPageFlow } from "~/api/hooks/onboarding";
function StepsOverview() {
    // this component renders the progress circle in the onboarding landing page sidebar
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const { data } = useGetLandingPageFlow(id, flowId);
    return (_jsx(VStack, { bg: "white", boxShadow: "card", py: "24px", px: "18px", spacing: "32px", children: _jsxs(Flex, { w: "full", children: [_jsx(Box, { mr: "16px", children: _jsx(CircularProgress, { value: (data === null || data === void 0 ? void 0 : data.overallProgress) || 0, color: "#49AB53", size: "64px", thickness: "10px", children: _jsxs(CircularProgressLabel, { children: [(data === null || data === void 0 ? void 0 : data.overallProgress) || 0, "%"] }) }) }), _jsxs(VStack, { alignItems: "flex-start", children: [_jsx(Text, { color: "primary.500", fontSize: "xl", children: t("onboarding.landing.progress.heading") }), _jsx(Text, { mb: 4, children: t("onboarding.landing.progress.subheading") })] })] }) }));
}
export default StepsOverview;
