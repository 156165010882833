import { useEffect, useState } from "react";
export const useStorageColumnConfig = (storageKey, defaultState) => {
    const [sorting, setSorting] = useState([]);
    const [visibility, setVisibility] = useState({});
    const [sorterOptions, setSorterOptions] = useState([]);
    const presetVisibility = (opts) => {
        const defaultVisibility = {};
        opts.forEach(function mapVisibility(option) {
            defaultVisibility[option.value] = option.isVisible;
        });
        setVisibility(defaultVisibility);
    };
    const presetSorting = (opts) => {
        const defaultSorting = [];
        opts.forEach(function mapVisibility({ value }) {
            defaultSorting.push(value);
        });
        setSorting(defaultSorting);
    };
    const setStorage = () => {
        const storageState = {
            sorting,
            visibility,
        };
        localStorage.setItem(`table-config-${storageKey}`, JSON.stringify(storageState));
    };
    const presetState = () => {
        const storedState = localStorage.getItem(`table-config-${storageKey}`);
        if (storedState) {
            const parsedState = JSON.parse(storedState);
            setSorting(parsedState.sorting);
            setVisibility(parsedState.visibility);
        }
        else {
            presetSorting(defaultState);
            presetVisibility(defaultState);
        }
    };
    const getSorterOptions = () => {
        const allOptions = [];
        sorting.forEach((value) => {
            const defaultItem = defaultState.find((item) => item.value === value);
            if (defaultItem) {
                allOptions.push({
                    value,
                    title: defaultItem.title,
                    enableSorting: defaultItem.enableSorting,
                    isVisible: visibility[value],
                });
            }
        });
        setSorterOptions(allOptions);
    };
    const handleChangeColumnSorting = (options) => {
        presetVisibility(options);
        presetSorting(options);
    };
    useEffect(() => {
        if (sorting.length) {
            setStorage();
            getSorterOptions();
        }
    }, [sorting, visibility]);
    useEffect(() => {
        if (!sorting.length) {
            presetState();
        }
    }, []);
    return [sorting, visibility, sorterOptions, handleChangeColumnSorting];
};
