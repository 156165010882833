import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { ContentType } from "~/pages/storage/types";
export const FILE_TYPES = {
    [ContentType.PDF]: "PDF",
    [ContentType.CSV]: "CSV",
    [ContentType.XLSX]: "XLSX",
    [ContentType.PNG]: "PNG",
    [ContentType.JPG]: "JPG",
};
export const FileTypeTag = ({ type, name, }) => {
    var _a;
    const props = {
        bg: "grey.40",
    };
    switch (type) {
        case ContentType.PDF:
            props.bg = "danger.60";
            break;
        case ContentType.JPG:
        case ContentType.PNG:
            props.bg = "misc.teal";
            break;
    }
    return (_jsx(Text, { rounded: "full", ...props, fontSize: "sm", fontWeight: "500", color: "white", px: "2", children: (_a = name.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toUpperCase() }));
};
