import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
const parseLineBreaks = (label) => {
    const splitLines = label.split("/n");
    return splitLines.length === 1 ? (_jsx(Text, { children: splitLines[0] })) : (_jsx(_Fragment, { children: splitLines.map((line) => (_jsx(Text, { children: line }))) }));
};
const parseLabels = (label, t) => {
    const renderString = (val) => parseLineBreaks(t(val, ""));
    if (Array.isArray(label)) {
        return _jsx(_Fragment, { children: label.map((el) => renderString(el)) });
    }
    return _jsx(_Fragment, { children: renderString(label) });
};
export { parseLabels, parseLineBreaks };
export default parseLineBreaks;
