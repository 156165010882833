import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMatch } from "@tanstack/react-location";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import ProcessOverviewItem from "~/components/ProcessOverviewItem/ProcessOverviewItem";
import processOverviews from "~/process-overviews/outbound-process";
export function ProcessOverview() {
    const { params: { id, flowId }, } = useMatch();
    const { data: flowFields } = useGetOnboardingFieldsFlows(id, flowId);
    return (_jsx(_Fragment, { children: processOverviews.map((overview, index) => {
            return (_jsx(ProcessOverviewItem, { last: index + 1 === processOverviews.length, ...overview(flowFields) }));
        }) }));
}
