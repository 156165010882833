import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { TabNavigation, } from "~/components/TabNavigation/TabNavigation";
import { hasPermission } from "~/helpers/hasPermission";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { RouteMap } from "~/routing/route.config";
import { useAppSelector } from "~/store/hooks";
import { checkAccountType } from "~/utils/checkAccountType";
import { PartnerProfile } from "../../components/PartnerProfile/PartnerProfile";
import { PartnerServices } from "../../components/PartnerServices/PartnerServices";
import { useGetPartnerByIdQuery } from "../../redux/partners.api";
export const PartnerProfilePage = () => {
    const { user } = useAppSelector(authSelector);
    if (!user || !user.accountId)
        return null;
    const { t } = useTranslation();
    const { data: partner, isFetching } = useGetPartnerByIdQuery(user === null || user === void 0 ? void 0 : user.accountId);
    const breadcrumbs = checkAccountType(AccountTypes.PARTNER)
        ? []
        : [
            {
                title: t("partners.pageHeadline"),
                link: RouteMap.PARTNERS,
            },
        ];
    const tabs = partner
        ? [
            {
                title: t("partners.detail.tabs.profile"),
                value: "profile",
                element: _jsx(PartnerProfile, { partner: partner }),
            },
            ...(hasPermission(EPermissionCodes.PARTNER_ALL_SERVICES_SETTINGS_VIEW)
                ? [
                    {
                        title: t("partners.detail.tabs.services"),
                        value: "services",
                        element: _jsx(PartnerServices, {}),
                    },
                ]
                : []),
        ]
        : [];
    return isFetching || !partner ? (_jsx(FullPageLoader, {})) : (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: partner.name, breadcrumbs: breadcrumbs }), _jsx(ContentSection, { children: _jsx(TabNavigation, { tabs: tabs }) })] }));
};
