import { api } from "~/store/api";
import { ArticleStatus, } from "../types/allArticles.type";
export const importsBaseUrl = "/articles/api/v1/imports";
export const articlesBaseUrl = "/articles/api/v1/articles";
export const customsClassificationApi = api
    .enhanceEndpoints({
    addTagTypes: [
        "CUSTOMS_CLASSIFICATION",
        "CUSTOMS_CLASSIFICATION_CASES",
        "CUSTOMS_CLASSIFICATION_ARTICLES",
        "CUSTOMS_CLASSIFICATION_IMPORT_META",
        "CUSTOMS_CLASSIFICATION_ERROR_CASES",
        "CUSTOMS_CLASSIFICATION_ARTICLES_NOTES",
        "CUSTOMS_CLASSIFICATION_ERROR_CASES",
        "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
        "CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID",
    ],
})
    .injectEndpoints({
    endpoints: (builder) => ({
        // Fetch imports (partner)
        getAllCustomsClassification: builder.query({
            query: ({ page, size, searchQuery, order, orderBy, responsibleEmployee, status, showPartners, }) => ({
                url: importsBaseUrl,
                params: {
                    page,
                    size,
                    order,
                    orderBy,
                    searchQuery,
                    showPartners,
                    ...(!!status && { status }),
                    ...(!!responsibleEmployee && {
                        responsibleEmployee: responsibleEmployee.replace("%40", "@"),
                    }),
                },
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION"],
        }),
        // Fetch articles (client)
        getAllCustomsClassificationCases: builder.query({
            query: ({ page, size, searchQuery, order, orderBy, status }) => ({
                url: importsBaseUrl,
                params: {
                    page,
                    size,
                    order,
                    orderBy,
                    searchQuery,
                    ...(!!status && { status }),
                },
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_CASES"],
        }),
        getCustomsClassificationFilters: builder.query({
            query: () => ({
                url: `${importsBaseUrl}/filters`,
            }),
        }),
        // Fetch imported articles by import
        getArticlesByImportId: builder.query({
            query: ({ id, params }) => ({
                url: `${importsBaseUrl}/${id}/articles`,
                params,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID"],
        }),
        // download import by id
        downloadImportById: builder.query({
            query: (id) => ({
                url: `${importsBaseUrl}/${id}/download`,
            }),
        }),
        // Get import data by id
        getImportMetaById: builder.query({
            query: (id) => ({
                url: `${importsBaseUrl}/${id}`,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_IMPORT_META"],
        }),
        updateImport: builder.mutation({
            query: ({ id, body }) => ({
                url: `${importsBaseUrl}/${id}`,
                method: "PATCH",
                body,
            }),
        }),
        // Patch imported articles in bulk
        bulkUpdateArticle: builder.mutation({
            query: ({ importId, ...body }) => ({
                url: `${importsBaseUrl}/${importId}/articles/bulk-update`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
                "CUSTOMS_CLASSIFICATION_IMPORT_META",
            ],
        }),
        // Fetch articles
        getAllArticles: builder.query({
            query: ({ page, size, order, orderBy, searchQuery, country, clientId, }) => ({
                url: articlesBaseUrl,
                params: {
                    page,
                    size,
                    order,
                    orderBy,
                    searchQuery,
                    ...(!!country && { country }),
                    ...(!!clientId && { clientId }),
                },
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES"],
        }),
        // Delete articles
        deleteArticles: builder.mutation({
            query: (articleIds) => ({
                url: articlesBaseUrl,
                method: "DELETE",
                body: {
                    articleIds,
                },
            }),
            invalidatesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES"],
        }),
        // Fetch import error cases
        getAllErrorCases: builder.query({
            query: ({ page, size, order, orderBy, clientId }) => ({
                url: `${importsBaseUrl}/errors-cases`,
                params: {
                    page,
                    size,
                    order,
                    orderBy,
                    ...(clientId && { clientId }),
                },
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ERROR_CASES"],
        }),
        // Replace import file
        replaceImport: builder.mutation({
            query: ({ id, body }) => ({
                url: `${importsBaseUrl}/${id}/replace`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ERROR_CASES",
                "CUSTOMS_CLASSIFICATION_CASES",
            ],
        }),
        // Delete import
        deleteImport: builder.mutation({
            query: ({ id }) => ({
                url: `${importsBaseUrl}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ERROR_CASES",
                "CUSTOMS_CLASSIFICATION_CASES",
            ],
        }),
        // Get an article by id
        getArticleById: builder.query({
            query: (id) => ({
                url: `${articlesBaseUrl}/${id}`,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES"],
        }),
        // Update an article by id
        updateSingleArticleById: builder.mutation({
            query: ({ id, body }) => ({
                url: `${articlesBaseUrl}/${id}`,
                method: "PATCH",
                body,
            }),
        }),
        // Update article while tariffing
        tariffArticleById: builder.mutation({
            query: ({ importId, articleId, body }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/tariffing`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
                "CUSTOMS_CLASSIFICATION_IMPORT_META",
            ],
        }),
        // Fetch imported article by id
        getImportArticleById: builder.query({
            query: ({ importId, articleId }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}`,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES"],
        }),
        // Get imported article comments
        getArticleNotesById: builder.query({
            query: ({ importId, articleId }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/comments`,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES_NOTES"],
        }),
        // Create imported article comment
        postArticleNote: builder.mutation({
            query: ({ importId, articleId, value }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/comments`,
                method: "POST",
                body: { value },
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
                "CUSTOMS_CLASSIFICATION_IMPORT_META",
            ],
            async onQueryStarted({ importId, articleId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(customsClassificationApi.util.updateQueryData("getArticleNotesById", { importId, articleId }, (articles) => {
                        articles.data.push(data);
                    }));
                }
            },
        }),
        // Patch imported article comment
        updateArticleNoteStatusById: builder.mutation({
            query: ({ importId, articleId, commentId, status }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/comments/${commentId}`,
                method: "PATCH",
                body: {
                    status,
                },
            }),
            async onQueryStarted({ importId, articleId, commentId }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(customsClassificationApi.util.updateQueryData("getArticleNotesById", { importId, articleId }, (articles) => {
                        const article = articles.data.find((a) => a.id === commentId);
                        if (article)
                            article.status = ArticleStatus.RESOLVED;
                    }));
                }
            },
        }),
        // Delete imported articles
        deleteImportedArticles: builder.mutation({
            query: ({ id, params }) => ({
                url: `${importsBaseUrl}/${id}/articles`,
                method: "DELETE",
                params,
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
                "CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID",
            ],
        }),
        // Fetch imported articles by import which has error cases
        getArticleErrorCasesByImportId: builder.query({
            query: ({ id, params }) => ({
                url: `${importsBaseUrl}/${id}/articles/error-cases`,
                params,
            }),
            providesTags: ["CUSTOMS_CLASSIFICATION_ERROR_CASES_BY_IMPORT_ID"],
        }),
        // Patch field in imported article (error cases)
        updateErrorCaseArticle: builder.mutation({
            query: ({ importId, articleId, body }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/error-cases`,
                method: "PATCH",
                body,
            }),
        }),
        // Patch imported article (tariffing)
        updateTariffingArticle: builder.mutation({
            query: ({ importId, articleId, article: body }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/tariffing`,
                method: "PATCH",
                body,
            }),
        }),
        // Put imported article
        bulkUpdateImportArticle: builder.mutation({
            query: ({ importId, articleId, body }) => ({
                url: `${importsBaseUrl}/${importId}/articles/${articleId}/error-cases`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID"],
        }),
        // Mark import as done
        markImportAsDone: builder.mutation({
            query: ({ id }) => ({
                url: `${importsBaseUrl}/${id}/submit`,
                method: "POST",
            }),
            invalidatesTags: [
                "CUSTOMS_CLASSIFICATION",
                "CUSTOMS_CLASSIFICATION_IMPORT_META",
            ],
        }),
    }),
});
export const { useGetAllCustomsClassificationQuery, useGetAllCustomsClassificationCasesQuery, useGetCustomsClassificationFiltersQuery, useGetArticlesByImportIdQuery, useLazyGetArticlesByImportIdQuery, useGetAllArticlesQuery, useDeleteArticlesMutation, useGetAllErrorCasesQuery, useReplaceImportMutation, useDeleteImportMutation, useLazyGetImportArticleByIdQuery, useLazyGetArticleByIdQuery, useGetImportMetaByIdQuery, useLazyGetImportMetaByIdQuery, useLazyGetArticleNotesByIdQuery, useGetArticleNotesByIdQuery, usePostArticleNoteMutation, useUpdateArticleNoteStatusByIdMutation, useUpdateImportMutation, useDeleteImportedArticlesMutation, useBulkUpdateArticleMutation, useUpdateSingleArticleByIdMutation, useGetArticleErrorCasesByImportIdQuery, useUpdateErrorCaseArticleMutation, useUpdateTariffingArticleMutation, useBulkUpdateImportArticleMutation, useMarkImportAsDoneMutation, useTariffArticleByIdMutation, useLazyDownloadImportByIdQuery, } = customsClassificationApi;
