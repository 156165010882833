import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuList, Text, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
export const FilterMultiselect = ({ options, selectedOptions, setSelectedOptions, placeholder, selectedKey, menuLabel, disabled, }) => {
    const { t } = useTranslation();
    const handleSelectValue = (value) => {
        const newOptions = [...selectedOptions];
        const i = newOptions.indexOf(value);
        if (i === -1)
            newOptions.push(value);
        else
            newOptions.splice(i, 1);
        setSelectedOptions(newOptions);
    };
    const count = selectedOptions.length;
    const isDisabled = disabled || !options.length;
    return (_jsxs(Menu, { children: [_jsx(MenuButton, { role: "group", disabled: isDisabled, cursor: isDisabled ? "default" : "pointer", children: _jsxs(InputGroup, { children: [_jsx(Input, { readOnly: true, disabled: isDisabled, tabIndex: -1, color: count ? "brand.black" : "grey.60", _groupFocus: { borderColor: "brand.mare" }, type: "search", size: "md", placeholder: placeholder, value: count ? t(selectedKey, { count }) : "" }), _jsx(InputRightElement, { h: "full", children: _jsx(Icon, { name: "chevron-down", w: "12px", h: "6px", color: "grey.40" }) })] }) }), _jsxs(MenuList, { p: "4", maxH: 300, overflow: "auto", children: [menuLabel && (_jsxs(Text, { pt: "2", pb: "3", textStyle: "labelUppercase", children: [menuLabel, " ", !!count && `(${count})`] })), options.map(({ value, title }) => {
                        return (_jsx(Flex, { py: "1.5", children: _jsx(Checkbox, { textStyle: "body", isChecked: selectedOptions.includes(value), onChange: () => handleSelectValue(value), children: title }) }, `filter-multiselect-${value}`));
                    })] })] }));
};
