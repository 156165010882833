import i18n from "~/i18n";
export default function deliveryOfGoods(flowFields) {
    var _a, _b, _c, _d, _e, _f;
    const when = "-";
    let who = "";
    const exportOrganizer = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "exportOrganizer" && data.pageSlug === "exportFromEu")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    const myselfCompanyName = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "myselfCompanyName" &&
        data.pageSlug === "exportFromEu")) === null || _b === void 0 ? void 0 : _b.fieldValue;
    if (exportOrganizer === "viaMS") {
        who = i18n.t("processOverview.msDirect");
    }
    if (exportOrganizer === "myself" && myselfCompanyName) {
        who = myselfCompanyName;
    }
    const goodsDeliveryCity = (_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsDeliveryCity" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _c === void 0 ? void 0 : _c.fieldValue;
    const otherDeliveryLocation = (_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "otherDeliveryLocation" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _d === void 0 ? void 0 : _d.fieldValue;
    const otherGoodsDeliveryCompanyName = (_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "otherGoodsDeliveryCompanyName" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _e === void 0 ? void 0 : _e.fieldValue;
    const otherGoodsDeliveryCity = (_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "otherGoodsDeliveryCity" &&
        data.pageSlug === "deliveryPoarcelHub")) === null || _f === void 0 ? void 0 : _f.fieldValue;
    let what = goodsDeliveryCity
        ? `${i18n.t("processOverview.outboundProcess.deliveryOfGoods.whatCity", {
            city: goodsDeliveryCity,
        })} <br>`
        : "";
    if (otherDeliveryLocation &&
        otherGoodsDeliveryCompanyName &&
        otherGoodsDeliveryCity) {
        what += i18n.t("processOverview.outboundProcess.deliveryOfGoods.whatOtherDeliveryLocation", {
            company: otherGoodsDeliveryCompanyName,
            city: otherGoodsDeliveryCity,
        });
    }
    return {
        when,
        who,
        what,
        icon: "cutoffwarehouse",
        title: i18n.t("processOverview.outboundProcess.deliveryOfGoods.title"),
    };
}
