import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer } from "~/components/Drawer/Drawer";
import { DrawerTabNavigation } from "~/components/DrawerTabNavigation/DrawerTabNavigation";
import { ClientContactInfo } from "~/pages/clients/components/ClientInfoOverlay/ClientContactInfo";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { useLazyGetPartnerClientByIdQuery } from "../../redux/partners.api";
import { ClientOverlayServiceTab } from "../ClientOverlayServiceTab/ClientOverlayServiceTab";
export const PartnerClientInfoOverlay = ({ clientId, onClose, }) => {
    const { t } = useTranslation();
    const { user } = useAppSelector(authSelector);
    const [getClient, { data: client, isFetching }] = useLazyGetPartnerClientByIdQuery();
    useEffect(() => {
        if ((user === null || user === void 0 ? void 0 : user.accountId) && clientId) {
            getClient({ partnerId: user.accountId, clientId });
        }
    }, [clientId]);
    const tabs = client
        ? [
            {
                title: t("partners.detail.clients.detailHeader"),
                value: "details",
                element: _jsx(ClientContactInfo, { client: client }),
            },
            {
                title: t("partners.detail.clients.serviceHeader"),
                value: "services",
                element: _jsx(ClientOverlayServiceTab, { client: client }),
            },
        ]
        : [];
    return (_jsx(Drawer, { isOpen: !!clientId, onClose: onClose, children: isFetching || !client ? (_jsx(Flex, { h: "full", w: "full", justifyContent: "center", alignItems: "center", children: _jsx(Spinner, { color: "brand.mare" }) })) : (_jsxs(Flex, { flexDirection: "column", h: "full", children: [_jsx(Text, { textStyle: "h3", fontWeight: "700", mb: 2, children: client.name }), _jsx(Text, { textStyle: "h4", mb: "8", children: t("clients.drawer.accountType") }), _jsx(DrawerTabNavigation, { tabs: tabs })] })) }));
};
