import { useMutation, useQuery } from "react-query";
import { ONBOARDING_BASEURL } from "~/store/api";
import { get, post } from "../utils";
export function useGetSignatureQuery(clientId, flowId, templateName, enabled) {
    return useQuery(["signature-get"], async () => {
        return get(`${ONBOARDING_BASEURL}/clients/${clientId}/signature`, {
            flowId,
            templateName,
        });
    }, { enabled, retry: false });
}
export function useSignatureCreateMutation(clientId, flowId, templateName, recipient) {
    return useMutation(["signature-create"], async () => {
        return post(`${ONBOARDING_BASEURL}/clients/${clientId}/signature/form`, {
            flowId,
            templateName,
            ...(recipient && {
                emailBlurb: recipient === null || recipient === void 0 ? void 0 : recipient.emailBlurb,
                recipients: [
                    {
                        email: recipient === null || recipient === void 0 ? void 0 : recipient.email,
                        name: recipient === null || recipient === void 0 ? void 0 : recipient.name,
                    },
                ],
            }),
        });
    });
}
