import * as z from "zod";
import { WEBSITE_VALIDATION, websiteValidation, } from "~/utils/websiteValidation";
export const PatchArticleSchema = z.object({
    articleNumber: z
        .string()
        .regex(/^(?!.*E\+).{1,20}$/, { message: "Invalid article number" }),
    netWeight: z.string().regex(/^(?=.*[0-9])\d{1,13}([.,]\d{1,5})?$/, {
        message: "Invalid net weight",
    }),
    netWeightUnit: z
        .string()
        .regex(/^.{2,2}$/, { message: "Invalid net weight unit" }),
    materialDescription: z
        .string()
        .regex(/^.{0,3000}$/)
        .optional(),
    countryOfOrigin: z.string().optional(),
    manufacturingCountry: z.string().optional(),
    articleValue: z.string().optional(),
    articleValueUnit: z.string().optional(),
    urlLink: z.string().refine(websiteValidation, WEBSITE_VALIDATION),
    deImportTariffingCode: z.string().optional(),
    countries: z
        .array(z.object({
        countryCode: z.string(),
        tariffingCode: z.string().optional(),
        description: z.string().optional(),
    }))
        .optional(),
    freeField1: z.string().optional(),
    freeField2: z.string().optional(),
    freeField3: z.string().optional(),
    freeField4: z.string().optional(),
    freeField5: z.string().optional(),
    freeField6: z.string().optional(),
    freeField7: z.string().optional(),
    freeField8: z.string().optional(),
    freeField9: z.string().optional(),
    freeField10: z.string().optional(),
});
