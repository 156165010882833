import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Input, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
import { useTariffArticleByIdMutation } from "../../redux/customsClassification.api";
export const AutofillInput = ({ importId, articleId, recommendedValue, }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [updateArticle, { isSuccess, reset }] = useTariffArticleByIdMutation();
    // hasFocussed is used to store if user has touched field already, to prevent blurring of field
    const [hasFocussed, setHasFocussed] = useState(true);
    const [isFocussed, setIsFocussed] = useState(false);
    const [hasAcceptedValue, setHasAcceptedValue] = useState(false);
    // this is always the last, custom value that the user typed in
    const [customValue, setCustomValue] = useState(recommendedValue || "");
    const [inputValue, setInputValue] = useState(recommendedValue || "");
    const handleFocusInput = () => {
        const input = document.getElementById(`rec-input-${articleId}`);
        if (input)
            input.focus();
    };
    useEffect(() => {
        if (isFocussed && hasFocussed)
            handleFocusInput();
    }, [isFocussed]);
    useEffect(() => {
        if (isSuccess) {
            toast({
                title: t("articles.detail.articleUpdatedSuccess"),
                status: "success",
                duration: 3000,
            });
            reset();
        }
    }, [isSuccess]);
    const handleBlurField = async () => {
        if (inputValue && hasFocussed) {
            setIsFocussed(false);
            await updateArticle({
                articleId,
                importId,
                body: { value: inputValue },
            });
        }
    };
    const handleAcceptRecommendation = () => {
        setInputValue(recommendedValue || "");
        setIsFocussed(false);
        setHasAcceptedValue(true);
    };
    const handleTextChange = (e) => {
        setInputValue(e.target.value);
        setCustomValue(e.target.value);
        setHasAcceptedValue(false);
    };
    const handleUndo = () => {
        // ditch the autocompleteValue and reset to the old custom value
        setInputValue(customValue);
        setHasAcceptedValue(false);
    };
    const getSecondaryAction = () => {
        if (hasAcceptedValue && customValue) {
            return (_jsx(TooltipWrapper, { hasTooltip: true, label: t("basics.reset"), children: _jsx(Icon, { w: 5, color: "grey.40", name: "code-undo", _hover: { color: "brand.sky" }, cursor: "pointer", transitionDuration: "200ms", onClick: handleUndo }) }));
        }
        return null;
    };
    const getTextContent = () => {
        if (isFocussed) {
            return (_jsx(Input, { minW: "180px", isInvalid: !inputValue, onFocus: () => setHasFocussed(true), id: `rec-input-${articleId}`, size: "table", value: inputValue, onChange: handleTextChange, onBlur: handleBlurField }));
        }
        return (_jsx(Text, { variant: "recommendationField", onClick: () => setIsFocussed(true), children: inputValue }));
    };
    const enableAutofill = inputValue !== recommendedValue;
    return (_jsxs(Flex, { mt: "-6px", mb: "-6px", alignItems: "center", onClick: (e) => e.stopPropagation(), children: [_jsx(Box, { w: hasAcceptedValue ? "148px" : "184px", children: getTextContent() }), hasAcceptedValue && (_jsx(Flex, { w: "9", justifyContent: "center", children: getSecondaryAction() })), _jsxs(Flex, { w: "9", justifyContent: "center", position: "relative", children: [_jsx(TooltipWrapper, { hasTooltip: true, label: inputValue !== recommendedValue
                            ? t("customsClassificationTariffing.recommendations.autofillAvailable")
                            : t("customsClassificationTariffing.recommendations.autofillUsed"), children: _jsx(Icon, { w: "20px", color: "grey.40", name: "code-autofill", _hover: { color: "brand.sky" }, cursor: enableAutofill ? "pointer" : "default", transitionDuration: "200ms", onClick: enableAutofill ? handleAcceptRecommendation : undefined }) }), inputValue !== recommendedValue && (_jsx(Box, { position: "absolute", right: "3px", w: "8px", boxSizing: "content-box", h: "8px", bg: "danger.500", border: "2px solid", borderColor: "warning.10", rounded: "full" }))] })] }));
};
