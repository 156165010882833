import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ArrowRightIcon } from "~/assets/icons/ArrowRightIcon";
import Link from "~/components/Link/Link";
// import { checkPageStatus } from '~/components/form-footer/utils';
import generateVatUrlSteps from "~/helpers/vat-process";
import routes from "~/layouts/onboarding/components/steps-list/routes";
import { patchFormField } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData } from "~/store/selectors/formData.selectors";
import { ResetModal } from "./components/ResetModal";
// coptied over logic from old onboarding to change design
export const OnboardingFormFooter = ({ isNextDisabled, isBackVisible = true, isSaveForLaterVisible = false, isResetVisible = false, nextUrl, processSlug = null, pageSlug = null, isCompleted, sendPageAsSeen, countryCode, }) => {
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const formData = useSelector(getFormData);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { pathname } = useMatch();
    const dispatch = useAppDispatch();
    const [backButtonPath, setBackButtonPath] = React.useState(`/clients/${id}/onboarding/${flowId}`);
    const [nextButtonPath, setNextButtonPath] = React.useState(`/clients/${id}/onboarding/${flowId}`);
    const [isLast, setIsLast] = React.useState(true);
    const checkPath = () => {
        const pathData = pathname.split("/");
        let hasFundRoute = false;
        routes.forEach((mainRoute) => {
            var _a;
            if (`${mainRoute.path}` === `/${pathData[5]}`) {
                const children = mainRoute.title === "onboarding.steps.application_vat_uid_number" &&
                    ((_a = formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) === null || _a === void 0 ? void 0 : _a.fieldValue)
                    ? generateVatUrlSteps(formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId)
                    : mainRoute.childrens;
                const foundIndex = children === null || children === void 0 ? void 0 : children.findIndex((child) => `${child.path}` === `/${pathData[6]}`);
                if (foundIndex) {
                    let prevIndex = foundIndex - 1;
                    let nextIndex = foundIndex + 1;
                    if (countryCode === "CH" && pathData[6] === "export_EU")
                        nextIndex += 1;
                    if (countryCode === "CH" && pathData[6] === "customs-declaration")
                        prevIndex -= 1;
                    if (children === null || children === void 0 ? void 0 : children[foundIndex - 1]) {
                        setBackButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${children[prevIndex].path}`);
                    }
                    if (children === null || children === void 0 ? void 0 : children[foundIndex + 1]) {
                        hasFundRoute = true;
                        setNextButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${children[nextIndex].path}`);
                    }
                }
                else if (children === null || children === void 0 ? void 0 : children[1]) {
                    setNextButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${children[1].path}`);
                    hasFundRoute = true;
                }
            }
        });
        setIsLast(!hasFundRoute);
    };
    const handleNextClick = async () => {
        if (isNextDisabled)
            return;
        if (sendPageAsSeen) {
            dispatch(patchFormField({
                fieldName: "validationField",
                fieldValue: "seen",
                id,
                flowId,
                pageSlug,
                processSlug,
            }));
        }
        navigate({ to: nextButtonPath });
    };
    useEffect(() => {
        if (nextUrl) {
            setNextButtonPath(nextUrl);
        }
        else
            checkPath();
    }, []);
    const nextButtonPathId = nextButtonPath.split("/").pop();
    return (_jsxs(Flex, { direction: "row", w: "full", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Flex, { direction: "row", w: "full", justifyContent: "space-between", alignItems: "center", gap: "4", children: [_jsxs(Flex, { direction: "row", alignItems: "center", gap: "4", children: [isBackVisible && (_jsx(Button, { size: "lg", onClick: () => navigate({ to: backButtonPath }), variant: "secondary", colorScheme: "secondary", children: t("onboarding.actions.back") }, "back")), isSaveForLaterVisible && (_jsx(Link, { to: `/clients/${id}/onboarding/${flowId}`, children: t("onboarding.actions.saveForLater") })), isResetVisible && (_jsx(Button, { size: "lg", onClick: onOpen, children: t("onboarding.actions.reset.buttonText") }))] }), _jsx(Button, { size: "lg", isDisabled: isNextDisabled, onClick: handleNextClick, rightIcon: !isCompleted && !isLast && id !== nextButtonPathId ? (_jsx(ArrowRightIcon, { boxSize: 6 })) : undefined, children: isCompleted
                            ? t("onboarding.goLive.buttonText")
                            : t(isLast && id === nextButtonPathId
                                ? "onboarding.actions.completeStep"
                                : "onboarding.actions.next") }, "next")] }), _jsx(ResetModal, { pageSlug: "administrativeProcesses", id: id, flowId: flowId, isOpen: isOpen, onClose: onClose })] }));
};
