import { z } from "zod";
import { CountryCode, CurrencyUnit, WeightUnit, } from "@crossborderx/schemas-package";
export const EditErrorCaseImportArticleValidationSchema = z.object({
    articleNumber: z.string(),
    articleValue: z.string().optional(),
    articleValueUnit: z.nativeEnum(CurrencyUnit).optional(),
    countryOfOrigin: z.nativeEnum(CountryCode),
    deImportTariffingCode: z.string().optional(),
    freeField1: z.string().optional(),
    freeField2: z.string().optional(),
    freeField3: z.string().optional(),
    freeField4: z.string().optional(),
    freeField5: z.string().optional(),
    freeField6: z.string().optional(),
    freeField7: z.string().optional(),
    freeField8: z.string().optional(),
    freeField9: z.string().optional(),
    freeField10: z.string().optional(),
    manufacturingCountry: z.nativeEnum(CountryCode),
    materialDescription: z.string(),
    netWeight: z.string().optional(),
    netWeightUnit: z.nativeEnum(WeightUnit).optional(),
    shipFromCountry: z.nativeEnum(CountryCode),
    shipFromTariffingCode: z.string(),
    shipFromDescription: z.string(),
    shipToCountry: z.nativeEnum(CountryCode),
    shipToTariffingCode: z.string(),
    shipToDescription: z.string(),
    urlLink: z.string().optional(),
});
