import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
export function getTask(data) {
    try {
        switch (data.administrativeProcesses[0].fieldValue) {
            case "3.2":
            case "2.4":
            case "1.1": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: ["onboarding.tasks.vat_uid_number.com1"],
                    [AccountTypes.CLIENT]: ["onboarding.tasks.vat_uid_number.client1"],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.2": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.fiscal_representation_office.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.fiscal_representation_office.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.3": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: ["onboarding.tasks.zaz_account_start.com1"],
                    [AccountTypes.CLIENT]: ["onboarding.tasks.zaz_account_start.client1"],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.4": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: ["onboarding.tasks.zaz_account_number.com1"],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.zaz_account_number.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.5": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.calculation_of_security_deposit.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.calculation_of_security_deposit.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.6": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.calculation_of_security_deposit_sign.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.calculation_of_security_deposit_sign.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "1.7": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.transfering_cash_for_deposit.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.transfering_cash_for_deposit.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "2.1": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: ["onboarding.tasks.tax_documents.com1"],
                    [AccountTypes.CLIENT]: ["onboarding.tasks.tax_documents.client1"],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "2.3": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.tax_documents_sign_info.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.tax_documents_sign_info.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "2.5": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: ["onboarding.tasks.security_deposit.com1"],
                    [AccountTypes.CLIENT]: ["onboarding.tasks.security_deposit.client1"],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "2.6": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.security_deposit_sign.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.security_deposit_sign.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            case "3.1": {
                return {
                    hasTask: true,
                    [AccountTypes.INTERNAL]: [
                        "onboarding.tasks.external_representation.com1",
                    ],
                    [AccountTypes.CLIENT]: [
                        "onboarding.tasks.external_representation.client1",
                    ],
                    [AccountTypes.PARTNER]: [],
                };
            }
            default: {
                return {
                    hasTask: false,
                };
            }
        }
    }
    catch (e) {
        return { hasTask: false };
    }
}
