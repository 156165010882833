import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.landing.go_live",
        subtitle: "onboarding.goLiveIsComing",
        description: "onboarding.goLiveIsComingDescription",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            gap: 3,
        },
        elements: [
            {
                key: "outbound-process-title",
                type: "h3",
                label: "onboarding.outboundProcesses.title",
            },
            {
                key: "outbound-process",
                type: "customComponent",
                componentName: "outboundProcess.StepsOverview",
            },
        ],
    },
    schemaGenerators.formFooter(true),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
