import { useState } from "react";
import { TableSelectionMode } from "~/types/table-selection.type";
export const useIdRowSelection = () => {
    const [inPageMode, setInPageMode] = useState(false);
    const [rowSelection, onRowSelectionChange] = useState([]);
    const [selectionMode, setSelectionMode] = useState(TableSelectionMode.INCLUDE);
    const handleRowSelection = (id) => {
        setInPageMode(false);
        if (rowSelection.includes(id)) {
            onRowSelectionChange((rs) => rs.filter((row) => row !== id));
        }
        else {
            onRowSelectionChange((rs) => [...rs, id]);
        }
    };
    const reset = (items = []) => {
        onRowSelectionChange(items);
    };
    const resetAll = () => {
        setInPageMode(false);
        onRowSelectionChange([]);
        setSelectionMode(TableSelectionMode.INCLUDE);
    };
    const showBulkActions = selectionMode !== TableSelectionMode.INCLUDE ||
        (selectionMode === TableSelectionMode.INCLUDE && !!rowSelection.length);
    return {
        selectedRows: rowSelection,
        setSelectedRows: handleRowSelection,
        reset,
        resetAll,
        selectionMode,
        setSelectionMode,
        inPageMode,
        setInPageMode,
        showBulkActions,
    };
};
