import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useUpdateClientByIdMutation } from "../../redux/clients.api";
import { AccountStatus } from "../../types";
export const ChangeStatusModal = ({ isOpen, setIsOpen, client, }) => {
    const { id, status: clientStatus } = client;
    const { t } = useTranslation();
    const toast = useToast();
    const [status, setStatus] = useState(client.status);
    const [updateClient, { isLoading }] = useUpdateClientByIdMutation();
    useEffect(() => {
        if (isOpen)
            setStatus(clientStatus);
    }, [isOpen]);
    const handleSubmit = async () => {
        try {
            const res = await updateClient({
                id,
                status,
            });
            if ("data" in res) {
                toast({
                    title: t("clients.editStatusModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    return (_jsxs(Modal, { title: t("clients.editStatusModal.title"), isOpen: isOpen, onClose: () => setIsOpen(false), primaryAction: handleSubmit, primaryActionDisabled: isLoading || status === clientStatus, primaryActionLoading: isLoading, primaryTitle: t("basics.save"), children: [_jsx(Text, { textStyle: "labelUppercase", mb: "2", mt: "8", children: t("clients.editStatusModal.titleStatus") }), _jsxs(Select, { bg: "white", value: status, onChange: (e) => setStatus(e.target.value), color: "grey.60", cursor: "pointer", children: [_jsx("option", { value: AccountStatus.ACTIVE, children: t("accountStatus.ACTIVE") }), _jsx("option", { value: AccountStatus.ONBOARDING, children: t("accountStatus.ONBOARDING") }), _jsx("option", { value: AccountStatus.PROSPECT, children: t("accountStatus.PROSPECT") })] })] }));
};
