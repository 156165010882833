export const getDirtyValues = (dirtyFields, allValues) => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }
    const dirtyFieldsObject = dirtyFields;
    const allValuesObject = allValues;
    return Object.fromEntries(Object.keys(dirtyFieldsObject).map((key) => [
        key,
        getDirtyValues(dirtyFieldsObject[key], allValuesObject[key]),
    ]));
};
