import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Circle, Flex, FormControl, Text } from "@chakra-ui/react";
import { EImportStatus } from "@crossborderx/schemas-package";
import { useNavigate } from "@tanstack/react-location";
import { createColumnHelper } from "@tanstack/react-table";
import { differenceInHours, formatRelative } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { AssigneeFilter } from "~/components/FilterBar/components/AssigneeFilter/AssigneeFilter";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { FilterSingleSelect } from "~/components/FilterBar/components/FilterSingleSelect/FilterSingleSelect";
import { renderFlagIconGroup } from "~/components/FlagIcon/FlagIcon";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Switch } from "~/components/Switch/Switch";
import { Table } from "~/components/Table/Table";
import { TableAvatar } from "~/components/TableAvatar/TableAvatar";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamFilter } from "~/hooks/useParamFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { ClientInfoOverlay } from "~/pages/clients/components/ClientInfoOverlay/ClientInfoOverlay";
import { CustomsClassificationStatus, CustomsClassificationStatusBorder, CustomsClassificationStatusColor, CustomsClassificationStatusTooltipTranslationKey, CustomsClassificationStatusTranslationKey, } from "~/pages/customs-classification/enums/customs-classification-status.enum";
import { useGetAllCustomsClassificationQuery, useGetCustomsClassificationFiltersQuery, } from "~/pages/customs-classification/redux/customsClassification.api";
import { PartnerClientInfoOverlay } from "~/pages/partners/components/PartnerClientInfoOverlay/PartnerClientInfoOverlay";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { checkAccountType } from "~/utils/checkAccountType";
import { locale } from "~/utils/locale";
export const CustomsClassificationOverviewPage = () => {
    const navigate = useNavigate();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [selectedClient, setSelectedClient] = useState(null);
    const { user } = useAppSelector(authSelector);
    const isPartnerUser = (user === null || user === void 0 ? void 0 : user.userType) === "PARTNER";
    const [filteredAssignees, setFilteredAssignees] = useParamFilter("assignee");
    const [filteredImportType, setFilteredImportType] = useParamFilter("importType");
    const [showPartnersFilter, setShowPartnersFilter] = useParamFilter("showPartners");
    const isShowingPartners = !!showPartnersFilter && showPartnersFilter !== "0";
    const { t } = useTranslation();
    const { data: customsClassification, isLoading: isLoadingCustomsClassification, isFetching: isFetchingCustomsClassification, } = useGetAllCustomsClassificationQuery({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        searchQuery,
        showPartners: isPartnerUser || isShowingPartners,
        responsibleEmployee: filteredAssignees,
        ...(!!filteredImportType && { status: filteredImportType.split(",") }),
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
    });
    const { data: filters, isLoading: isLoadingFilters } = useGetCustomsClassificationFiltersQuery();
    const radioFilterOptions = [
        {
            label: t("customsClassification.filters.importType.all"),
            value: "",
        },
        {
            label: t("customsClassification.filters.importType.open"),
            value: `${EImportStatus.IN_PROGRESS_SHIP_FROM},${EImportStatus.IN_PROGRESS_SHIP_TO}`,
        },
        {
            label: t("customsClassification.filters.importType.finished"),
            value: EImportStatus.COMPLETED,
        },
    ];
    const statusConfig = (cc) => {
        var _a;
        const { status, services } = cc;
        let returnStatus = CustomsClassificationStatus.PENDING;
        const customsClassificationService = services.find((service) => service.code === PartnerServiceCode.CONTINUOUS_CLASSIFICATION);
        if (status === EImportStatus.COMPLETED)
            returnStatus = CustomsClassificationStatus.COMPLETED;
        else if ((status === EImportStatus.IN_PROGRESS_SHIP_FROM ||
            status === EImportStatus.IN_PROGRESS_SHIP_TO) &&
            !!customsClassificationService) {
            const timestamps = [
                cc.shipFromCompletedAt,
                cc.pendingCompletedAt,
                cc.initialProcessingCompletedAt,
                cc.fileUploadCompletedAt,
                cc.createdAt,
            ];
            // if we're in ship from, we don't consider shipFromCompletedAt to be relevant
            if (status === EImportStatus.IN_PROGRESS_SHIP_FROM)
                timestamps.shift();
            // find first valid timestamp
            const validTimestamp = timestamps.find((timestamp) => !!timestamp);
            if (validTimestamp) {
                const end = new Date(validTimestamp);
                const start = new Date();
                const interval = differenceInHours(start, end);
                switch ((_a = customsClassificationService.data) === null || _a === void 0 ? void 0 : _a.timeWindow) {
                    case 24:
                        // overdue if not completed within SLA
                        if (interval >= 24) {
                            returnStatus = CustomsClassificationStatus.OVERDUE;
                        }
                        // critical for 24h SLA 0-6h left
                        else if (interval >= 18) {
                            returnStatus = CustomsClassificationStatus.CRITICAL;
                        }
                        // near deadline for 24h SLA 6-12h left
                        else if (interval >= 12) {
                            returnStatus = CustomsClassificationStatus.NEAR_DEADLINE;
                        }
                        // on time for 24h SLA 12-24h left
                        else {
                            returnStatus = CustomsClassificationStatus.ON_TIME;
                        }
                        break;
                    case 48:
                        // overdue if not completed within SLA
                        if (interval >= 48) {
                            returnStatus = CustomsClassificationStatus.OVERDUE;
                        }
                        // critical for 48h SLA 0-12h left
                        else if (interval >= 36) {
                            returnStatus = CustomsClassificationStatus.CRITICAL;
                        }
                        // near deadline for 48h SLA 12-24h left
                        else if (interval >= 24) {
                            returnStatus = CustomsClassificationStatus.NEAR_DEADLINE;
                        }
                        // on time for 48h SLA 24-48h left
                        else {
                            returnStatus = CustomsClassificationStatus.ON_TIME;
                        }
                        break;
                }
            }
        }
        return returnStatus;
    };
    const getTooltipKey = (status, cc) => {
        const { services } = cc;
        const customsClassificationService = services.find((service) => service.code === PartnerServiceCode.CONTINUOUS_CLASSIFICATION);
        if (customsClassificationService === null || customsClassificationService === void 0 ? void 0 : customsClassificationService.data) {
            const { timeWindow } = customsClassificationService.data;
            const primaryKey = `customsClassification.tooltips.${CustomsClassificationStatusTooltipTranslationKey[status]}`;
            if (status === CustomsClassificationStatus.COMPLETED)
                return t(primaryKey);
            if (status === CustomsClassificationStatus.OVERDUE)
                return t(primaryKey, { timeWindow });
            if (status === CustomsClassificationStatus.CRITICAL) {
                const timeRange = timeWindow === 24 ? "6" : "12";
                return t(primaryKey, { timeWindow, timeRange });
            }
            if (status === CustomsClassificationStatus.NEAR_DEADLINE) {
                const timeRange = timeWindow === 24 ? "6-12" : "12-24";
                return t(primaryKey, { timeWindow, timeRange });
            }
            if (status === CustomsClassificationStatus.ON_TIME) {
                const timeRange = timeWindow === 24 ? "12-24" : "24-48";
                return t(primaryKey, { timeWindow, timeRange });
            }
        }
        return null;
    };
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("id", {
            size: 60,
            header: () => t("customsClassification.table.id"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor("status", {
            size: 160,
            header: () => t("customsClassification.table.status"),
            cell: ({ row }) => useMemo(() => {
                const status = statusConfig(row.original);
                const color = CustomsClassificationStatusColor[status];
                const borderWidth = CustomsClassificationStatusBorder[status];
                const hasBorder = CustomsClassificationStatusBorder[status] !== "none";
                const tooltipKey = getTooltipKey(status, row.original);
                const translationKey = CustomsClassificationStatusTranslationKey[status];
                const content = (_jsxs(Flex, { gap: 3, alignItems: "center", children: [_jsx(Circle, { size: 4, borderWidth: borderWidth, borderColor: hasBorder ? color : "transparent", backgroundColor: !hasBorder ? color : "transparent" }), _jsx(Text, { children: t(`customsClassification.status.${translationKey}`) })] }));
                return tooltipKey ? (_jsx(TooltipWrapper, { hasTooltip: true, label: tooltipKey, children: content })) : (content);
            }, []),
        }),
        columnHelper.display({
            id: "status-step",
            size: 140,
            header: () => t("customsClassification.table.step"),
            cell: ({ row }) => t(`customsClassification.shipDirection.${row.original.status === EImportStatus.IN_PROGRESS_SHIP_TO
                ? "shipTo"
                : "shipFrom"}`),
        }),
        columnHelper.accessor("country", {
            size: 120,
            header: () => t("customsClassification.table.countries"),
            cell: ({ getValue }) => renderFlagIconGroup({ countries: [getValue()], boxSize: 6 }),
        }),
        columnHelper.accessor("createdAt", {
            size: 230,
            header: () => t("customsClassification.table.timeOfImport"),
            cell: ({ getValue }) => formatRelative(new Date(getValue()), new Date(), { locale }),
        }),
        columnHelper.accessor("clientName", {
            size: 220,
            header: () => t("customsClassification.table.client"),
            cell: ({ getValue, row }) => useMemo(() => (_jsx(Box, { overflow: "auto", textAlign: "left", children: _jsx(Button, { width: "100%", variant: "link", colorScheme: "button.primary", fontWeight: "initial", onClick: (e) => {
                        e.stopPropagation();
                        setSelectedClient(row.original.clientId);
                    }, children: _jsx(Text, { isTruncated: true, children: getValue() || row.original.clientId }) }) })), []),
        }),
        columnHelper.accessor("responsibleEmployee", {
            size: 80,
            header: () => t("customsClassification.table.who"),
            cell: ({ getValue, row }) => useMemo(() => {
                return (_jsx(TableAvatar, { email: getValue(), companyId: row.original.partnerId, companyName: row.original.partnerName }));
            }, []),
        }),
        columnHelper.accessor("articlesCount", {
            size: 130,
            header: () => t("customsClassification.table.datasets"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor("emptyRecords", {
            header: () => t("customsClassification.table.empty"),
            cell: ({ getValue }) => getValue(),
            size: 100,
        }),
        ...(!checkAccountType(AccountTypes.PARTNER)
            ? [
                columnHelper.accessor("autofill", {
                    header: () => t("customsClassification.table.autofills"),
                    cell: ({ getValue }) => getValue(),
                    size: 300,
                }),
                columnHelper.accessor("recommendations", {
                    header: () => t("customsClassification.table.recs"),
                    cell: ({ getValue }) => getValue(),
                    size: 100,
                }),
            ]
            : []),
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: t("customsClassification.pageHeadline") }), _jsxs(ContentSection, { children: [_jsxs(FilterBar, { children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(FilterSearch, { disabled: isLoadingCustomsClassification, value: search, placeholder: t("customsClassification.table.searchByClientOrId"), setValue: setSearch }), _jsx(Box, { ml: "4", flexShrink: "0", children: _jsx(FilterSingleSelect, { placeholder: t("customsClassification.table.importStatus"), options: radioFilterOptions, setSelectedOption: (value) => setFilteredImportType(value), selectedOption: filteredImportType }) }), !isPartnerUser && (_jsxs(FormControl, { display: "flex", alignItems: "center", ml: "4", children: [_jsx(Switch, { isChecked: isShowingPartners, onChange: () => setShowPartnersFilter(isShowingPartners ? "0" : "1") }), _jsx(Text, { children: t("customsClassification.table.showPartners") })] }))] }), filters && !isLoadingFilters && (_jsx(AssigneeFilter, { options: (filters === null || filters === void 0 ? void 0 : filters.responsibleEmployees) || [], selectedOptions: filteredAssignees.length ? filteredAssignees.split(",") : [], setSelectedOptions: (assignees) => setFilteredAssignees(assignees.join(",")) }))] }), _jsx(Table, { columns: columns, data: customsClassification, onRowClick: ({ original: { id } }) => navigate({ to: id }), sorting: sorting, onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: setPagination, isLoading: isLoadingCustomsClassification, isFetching: isFetchingCustomsClassification, getRowStyles: (row) => ({
                            opacity: row.original.status === EImportStatus.COMPLETED ? "0.37" : "1",
                        }) })] }), checkAccountType(AccountTypes.PARTNER) ? (_jsx(PartnerClientInfoOverlay, { clientId: selectedClient, onClose: () => setSelectedClient(null) })) : (_jsx(ClientInfoOverlay, { clientId: selectedClient, onClose: () => setSelectedClient(null) }))] }));
};
