import { useMatch } from "@tanstack/react-location";
import { useGetOnboardingFlow } from "~/api/hooks/onboarding";
import i18n from "~/i18n";
export default function importCh(flowFields) {
    var _a;
    const when = "-";
    const { params: { id, flowId }, } = useMatch();
    const { data: flow } = useGetOnboardingFlow(Number(id), flowId);
    const who = i18n.t("processOverview.msDirect");
    const customClearanceMethod = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "CustomClearanceMethod" &&
        data.pageSlug === "exportFromEu")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    const what = `${(flow === null || flow === void 0 ? void 0 : flow.countryCode) === "GB"
        ? i18n.t("processOverview.outboundProcess.importUk.import")
        : i18n.t("processOverview.outboundProcess.importCh.import")}
    <br>${i18n.t("processOverview.outboundProcess.importCh.preparation")}
    <br>${customClearanceMethod === "transit_document"
        ? i18n.t("processOverview.outboundProcess.importCh.transitDocument")
        : ""}`;
    return {
        when,
        who,
        what,
        icon: "import-ch",
        title: (flow === null || flow === void 0 ? void 0 : flow.countryCode) === "GB"
            ? i18n.t("processOverview.outboundProcess.importUk.title")
            : i18n.t("processOverview.outboundProcess.importCh.title"),
    };
}
