import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useMatch } from "@tanstack/react-location";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SignatureStatus, SignatureTemplateNames, } from "@crossborderx/schemas-package";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import { useGetSignatureQuery } from "~/api/hooks/signatures";
import Infobox from "~/components/InfoBox/InfoBox";
import { SignatureComponent } from "~/components/Signature/Signature";
export function YourCustomAccountCustomsCalculationOfSecurityDepositSign() {
    const signatureTemplate = SignatureTemplateNames.DeclarationMembershipZaz;
    const { t } = useTranslation();
    const [signature, setSignature] = useState({
        status: SignatureStatus.Unsigned,
    });
    const { params: { id: clientId, flowId }, } = useMatch();
    const [createNew, setToCreateNewSignature] = useState(false);
    const { data: signatureData, isFetched } = useGetSignatureQuery(clientId, flowId, signatureTemplate, !signature.objectUrl);
    useEffect(() => {
        if (isFetched) {
            if (signatureData)
                setSignature(signatureData);
            else {
                setSignature({
                    status: SignatureStatus.Unsigned,
                });
                setToCreateNewSignature(true);
            }
        }
    }, [isFetched]);
    return (_jsxs(_Fragment, { children: [(signature === null || signature === void 0 ? void 0 : signature.status) === SignatureStatus.Unsigned && (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.vat.flow.calculationSecurityDeposit.headline"), subtitle: t("onboarding.vat.flow.calculationSecurityDeposit.subtitle") }), _jsx(FormBody, { gap: "0", children: _jsxs(Flex, { direction: "row", justifyContent: "space-between", children: [_jsx(Flex, { direction: "column", w: { md: "100%", xl: "64%" }, pr: { xl: "20px" }, justifyContent: "space", gap: "5", p: "5", overflow: "hidden", children: _jsxs(Box, { flex: "1", children: [_jsx(Text, { fontSize: "2xl", children: t("onboarding.vat.flow.calculationSecurityDeposit.signStatementTitle") }), _jsx(Text, { children: t("onboarding.vatNumberAndCustomsAccount.unsigned.signStatementDescription") }), _jsx(SignatureComponent, { templateName: signatureTemplate, onSignatureData: (data) => setSignature(data), signature: signature, createNew: createNew })] }) }), _jsx(Infobox, { infoText: "onboarding.vat.flow.calculationSecurityDeposit" })] }) })] })), (signature === null || signature === void 0 ? void 0 : signature.status) === SignatureStatus.Signed && (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.application_vat_uid_number"), subtitle: t("onboarding.vatNumberAndCustomsAccount.signed.title") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: [t("onboarding.vatNumberAndCustomsAccount.signed.documentSigned"), ".\u00A0"] }), _jsx(Link, { to: "/documents", children: t("onboarding.vatNumberAndCustomsAccount.signed.documentFind") })] })] })), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true, nextUrl: `/clients/${clientId}/onboarding/${flowId}/administrative-processes/transfering-cash-for-deposit` })] }));
}
