import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, TabList, TabPanel, TabPanels, Tabs, } from "@chakra-ui/react";
export const DrawerTabNavigation = ({ tabs, headerProps, tabsProps }) => {
    const initialIndex = 0;
    return (_jsxs(Tabs, { defaultIndex: initialIndex, isLazy: true, flexGrow: 1, display: "flex", flexDir: "column", h: "full", overflow: "hidden", ...tabsProps, children: [_jsx(TabList, { borderBottomColor: "sky.20", borderBottomWidth: "1px", ...headerProps, children: tabs.map(({ title, value }) => (_jsx(Tab, { pt: "0", pb: "10px", px: "0", transitionDuration: "0s", mr: "8", position: "relative", top: "-1px", color: "grey.60", borderBottomWidth: "2px", borderBottomColor: "transparent", _focus: { borderBottomColor: "brand.mare" }, _selected: {
                        fontWeight: 700,
                        color: "brand.mare",
                        borderBottomColor: "brand.mare",
                        borderBottomWidth: "2px",
                    }, children: title }, `tab-navigation-tab--${value}`))) }), _jsx(TabPanels, { overflow: "auto", h: "full", flexGrow: 1, children: tabs.map(({ value, element }) => (_jsx(TabPanel, { p: 0, h: "full", overflow: "auto", children: element }, `tab-navigation-panel--${value}`))) })] }));
};
