/* eslint no-param-reassign: ["error", { "props": false }] */
import { createReducer } from "@reduxjs/toolkit";
import { fetchFormById } from "~/store/actions/formData.actions";
const initialState = {
    global: "",
    hasError: false,
    formData: {},
};
export const FormErrorsReducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchFormById.rejected, (state, _) => {
        state.hasError = true;
    });
    builder.addCase(fetchFormById.pending, (state, _) => {
        state.hasError = false;
    });
    builder.addCase(fetchFormById.fulfilled, (state, _) => {
        state.hasError = false;
    });
});
