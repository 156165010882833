import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export const SeeMoreDescription = ({ description, title, ...props }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const isExpandable = (description === null || description === void 0 ? void 0 : description.length) > 400;
    const shownDescription = isExpandable && !isExpanded
        ? description.slice(0, 400).trim()
        : description;
    return (_jsxs(Box, { ...props, children: [_jsx(Text, { mb: "2", textStyle: "labelUppercase", color: "brand.mare", children: title }), _jsxs(Text, { children: [shownDescription, isExpandable && (_jsxs(_Fragment, { children: [!isExpanded && "...", " ", _jsx(Text, { as: "span", onClick: () => setIsExpanded(!isExpanded), cursor: "pointer", textDecoration: "underline", children: isExpanded ? t("basics.showLess") : t("basics.showMore") })] }))] })] }));
};
