import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "~/assets/icons/DeleteIcon";
import { EditIcon } from "~/assets/icons/EditIcon";
import { InputIcon } from "~/assets/icons/InputIcon";
import { OutputIcon } from "~/assets/icons/OutputIcon";
import { PlusIcon } from "~/assets/icons/PlusIcon";
import { WarningIcon } from "~/assets/icons/WarningIcon";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { IconButton } from "~/components/IconButton/IconButton";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Service } from "~/components/Service/Service";
import { ServiceStatus } from "~/enums/service-status.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
import { checkAccountType } from "~/utils/checkAccountType";
import { getCountryByIsoCode } from "~/utils/countries";
import { AddShipFromModal } from "../AddShipFromModal/AddShipFromModal";
import { DisableTariffingModal } from "../DisableTariffingModal/DisableTariffingModal";
import { EditRestrictionsModal } from "../EditRestrictionsModal/EditRestrictionsModal";
import { EditShipmentModal } from "../EditShipmentModal/EditShipmentModal";
import { EnableTariffingServiceModal } from "../EnableTariffingModal/EnableTariffingModal";
import { RemoveTariffingCountryModal } from "../RemoveTariffingCountryModal/RemoveTariffingCountryModal";
const FLAG_SIZE = "20px";
export const Tariffing = ({ services, selectedCountry, isLoaded, }) => {
    if (selectedCountry === null)
        return null;
    const [isChecked, setIsChecked] = useState(false);
    const [isEnableTariffingServiceModalOpen, setIsEnableTariffingServiceModalOpen,] = useState(false);
    const [isDisableTariffingServiceModalOpen, setIsDisableTariffingServiceModalOpen,] = useState(false);
    const [isAddShipFromModalOpen, setIsAddShipFromModalOpen] = useState(false);
    const [removeCountriesDirection, setRemoveCountriesDirection] = useState(null);
    const [isEditRestrictionsModalOpen, setIsEditRestrictionsModalOpen] = useState(false);
    const [editedService, setEditedService] = useState(null);
    const { t } = useTranslation();
    const shipToService = useMemo(() => services.filter((service) => service.serviceData.direction === ShipDirection.TO)[0], [services]);
    const shipFromServices = useMemo(() => services.filter((service) => service.serviceData.direction === ShipDirection.FROM), [services]);
    const restrictions = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        const maxValue = ((_a = shipToService === null || shipToService === void 0 ? void 0 : shipToService.serviceData.restrictions) === null || _a === void 0 ? void 0 : _a.maxValue)
            ? `${(_b = shipToService.serviceData.restrictions) === null || _b === void 0 ? void 0 : _b.maxValue} ${(_c = shipToService.serviceData.restrictions) === null || _c === void 0 ? void 0 : _c.maxValueUnit}`
            : "-";
        const maxWeight = ((_d = shipToService === null || shipToService === void 0 ? void 0 : shipToService.serviceData.restrictions) === null || _d === void 0 ? void 0 : _d.maxWeight)
            ? `${(_e = shipToService.serviceData.restrictions) === null || _e === void 0 ? void 0 : _e.maxWeight} ${(_f = shipToService.serviceData.restrictions) === null || _f === void 0 ? void 0 : _f.maxWeightUnit}`
            : "-";
        return {
            maxValue,
            maxWeight,
        };
    }, [shipToService]);
    const handleRemove = (service, direction) => {
        setEditedService(service);
        setRemoveCountriesDirection(direction);
    };
    const handleSwitch = () => {
        if (isChecked) {
            setIsDisableTariffingServiceModalOpen(true);
        }
        else if (!isChecked) {
            setIsEnableTariffingServiceModalOpen(true);
        }
    };
    if (!shipToService)
        return null;
    const renderCountryCard = (service) => {
        if (!service || !service.serviceProvider)
            return (_jsx(Flex, { direction: "column", backgroundColor: "brand.white", borderColor: "grey.10", borderWidth: "1px", borderRadius: "4", p: 6, children: _jsx(Text, { children: t("clients.detail.services.tariffing.noShipFromCountry") }) }));
        const { responsibleEmployee } = service;
        const { name } = service.serviceProvider;
        const { direction, shipTo, shipFrom, services: partnerServices, } = service.serviceData;
        const isShipTo = direction === ShipDirection.TO;
        const countryCode = isShipTo ? shipTo : shipFrom !== null && shipFrom !== void 0 ? shipFrom : "";
        const partnerServicesText = partnerServices.length > 0
            ? partnerServices
                .map((partnerService) => {
                var _a;
                switch (partnerService.code) {
                    case PartnerServiceCode.INITIAL_CLASSIFICATION:
                        return t("clients.detail.services.tariffing.initialClassification");
                    case PartnerServiceCode.CONTINUOUS_CLASSIFICATION:
                        return t("clients.detail.services.tariffing.continuousClassification", {
                            hours: (_a = partnerService.data) === null || _a === void 0 ? void 0 : _a.timeWindow,
                        });
                    default:
                        return "";
                }
            })
                .join(", ")
            : t("clients.detail.services.tariffing.noServices");
        return (_jsx(Flex, { direction: "column", backgroundColor: "brand.white", borderColor: "grey.10", borderWidth: "1px", borderRadius: "4", px: 6, pt: 4, pb: 6, children: _jsxs(Flex, { direction: "column", gap: "5", children: [_jsxs(Flex, { justifyContent: "space-between", children: [_jsxs(Flex, { direction: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", color: "brand.mare", children: t("clients.detail.services.tariffing.country") }), _jsxs(Flex, { alignItems: "center", gap: "3", children: [_jsx(FlagIcon, { countryCode: countryCode, style: { display: "inline-block" }, boxSize: FLAG_SIZE }), _jsx(Text, { children: getCountryByIsoCode(countryCode) })] })] }), checkAccountType(AccountTypes.INTERNAL) && (_jsxs(Flex, { gap: "4", color: "grey.40", alignSelf: "center", children: [_jsx(IconButton, { onClick: () => setEditedService(service), children: _jsx(EditIcon, {}) }), _jsx(IconButton, { onClick: () => handleRemove(service, direction), children: _jsx(DeleteIcon, {}) })] }))] }), _jsxs(Flex, { direction: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", color: "brand.mare", children: t("clients.detail.services.tariffing.services") }), _jsx(Text, { children: partnerServicesText })] }), !checkAccountType(AccountTypes.CLIENT) && (_jsxs(Flex, { direction: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", color: "brand.mare", children: t("clients.detail.services.tariffing.serviceProvider") }), _jsx(Text, { children: `${name} (${responsibleEmployee})` })] }))] }) }, service.id));
    };
    const selectedCountries = useMemo(() => {
        const countries = [];
        services.forEach(({ serviceData }) => {
            if (serviceData.shipFrom)
                countries.push(serviceData.shipFrom);
        });
        return countries;
    }, [services]);
    useEffect(() => {
        setIsChecked(shipToService.status === ServiceStatus.ENABLED);
    }, [shipToService]);
    return (_jsxs(Service, { title: t("clients.detail.services.tariffing.title"), isSwitchChecked: isChecked, switchFn: handleSwitch, isSwitchVisible: checkAccountType(AccountTypes.INTERNAL), tagLabel: isChecked
            ? t("clients.detail.services.tariffing.activeTag")
            : t("clients.detail.services.tariffing.inactiveTag"), isTagVisible: checkAccountType(AccountTypes.PARTNER) ||
            checkAccountType(AccountTypes.CLIENT), isLoaded: isLoaded, children: [_jsxs(Flex, { direction: "column", gap: 8, children: [_jsxs(Flex, { direction: "column", gap: 4, children: [_jsx(Flex, { justifyContent: "space-between", alignItems: "center", children: _jsxs(Flex, { gap: "4", children: [_jsx(InputIcon, { width: 6, height: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("clients.detail.services.tariffing.shipToCountry") })] }) }), shipToService.status === ServiceStatus.ENABLED &&
                                renderCountryCard(shipToService)] }), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", children: [_jsxs(Flex, { gap: "4", children: [_jsx(OutputIcon, { width: 6, height: 6, color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("clients.detail.services.tariffing.shipFromCountry") })] }), checkAccountType(AccountTypes.INTERNAL) && (_jsx(IconButton, { onClick: () => setIsAddShipFromModalOpen(true), children: _jsx(PlusIcon, {}) }))] }), _jsx(Flex, { direction: "column", gap: 6, children: shipFromServices.length > 0
                                    ? shipFromServices.map(renderCountryCard)
                                    : renderCountryCard() })] }), _jsxs(Flex, { direction: "column", gap: 4, children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", children: [_jsxs(Flex, { gap: "4", children: [_jsx(WarningIcon, { color: "brand.mare" }), _jsx(Text, { textStyle: "h4", children: t("clients.detail.services.tariffing.restrictions") })] }), checkAccountType(AccountTypes.INTERNAL) && (_jsx(IconButton, { onClick: () => setIsEditRestrictionsModalOpen(true), children: _jsx(EditIcon, {}) }))] }), _jsxs(Flex, { backgroundColor: "brand.white", borderColor: "grey.10", borderWidth: "1px", borderRadius: "4", px: 6, py: 4, children: [_jsxs(Flex, { direction: "column", flex: 1, gap: 2, children: [_jsx(Text, { textStyle: "labelUppercase", children: t("clients.detail.services.tariffing.maxWeight") }), _jsx(Text, { children: restrictions.maxWeight })] }), _jsxs(Flex, { direction: "column", flex: 1, gap: 2, children: [_jsx(Text, { textStyle: "labelUppercase", children: t("clients.detail.services.tariffing.maxValue") }), _jsx(Text, { children: restrictions.maxValue })] })] })] })] }), _jsx(RemoveTariffingCountryModal, { handleClose: () => {
                    setRemoveCountriesDirection(null);
                    setEditedService(null);
                }, shipDirection: removeCountriesDirection, service: editedService }), _jsx(EnableTariffingServiceModal, { service: shipToService, isOpen: isEnableTariffingServiceModalOpen, setIsOpen: setIsEnableTariffingServiceModalOpen }), _jsx(DisableTariffingModal, { service: shipToService, isOpen: isDisableTariffingServiceModalOpen, setIsOpen: setIsDisableTariffingServiceModalOpen }), _jsx(AddShipFromModal, { selectedCountries: selectedCountries, shipTo: selectedCountry, isOpen: isAddShipFromModalOpen, setIsOpen: setIsAddShipFromModalOpen }), _jsx(EditShipmentModal, { selectedCountries: selectedCountries, service: editedService, isOpen: !!editedService && !removeCountriesDirection, handleClose: () => setEditedService(null) }), _jsx(EditRestrictionsModal, { service: services.find(({ serviceData }) => serviceData.shipTo === selectedCountry), isOpen: isEditRestrictionsModalOpen, setIsOpen: setIsEditRestrictionsModalOpen })] }));
};
