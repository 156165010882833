import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActiveStep, ApplicationVatUidExternalRepresentationSignInfo, ApplicationVatUidNumberCompanyInformation, ApplicationVatUidNumberComplete, ApplicationVatUidNumberDeclarationOfFiscalRepresentation, ApplicationVatUidNumberDeclarationOfFiscalRepresentationFertigCheck, ApplicationVatUidNumberDeclarationOfSubordination, ApplicationVatUidNumberFiscalRepresentationOffice, ApplicationVatUidNumberFiscalSecurityDeposit, ApplicationVatUidNumberFiscalSecurityDepositCompleted, ApplicationVatUidNumberFiscalSecurityDepositSign, ApplicationVatUidNumberPowerOfAttorney, ApplicationVatUidNumberStart, ApplicationVatUidNumberVatUuidNumber, ApplicationVatUidTaxDocumentsSignInfo, ApplicationVatUidTaxInSwitzerland, ApplicationVatUidTaxSignDocuments, CustomsAccountStart, YourCustomAccountComplete, YourCustomAccountCustomsAccountNumber, YourCustomAccountCustomsCalculationOfSecurityDeposit, YourCustomAccountCustomsCalculationOfSecurityDepositSign, YourCustomAccountCustomsDeclarationOfAccession, YourCustomAccountCustomsKindOfSecurityDeposit, YourCustomAccountCustomsLastschriftverfahrenEinrichten, YourCustomAccountCustomsPaymentOfCustomFees, YourCustomAccountCustomsTransferCashForDeposit, } from "./AdministrativeProcesses";
import { CompanyDetailsContactInformation, CompanyDetailsContactPerson, CompanyDetailsOverview, } from "./CompanyDetails";
import { OnboardingStart } from "./OnboardingStart";
import { CustomsClassification, 
// ProductDetailInitialProductClassification,
ProductDetailInitialOverview, ProductDetailsCategories, 
// ProductDetailTariffNumberDestinationCountry,
// ProductDetailTariffNumberShippingCountry,
ProductDetailsDefineImportFile, ProductDetailsExportRegulation, } from "./ProductDetails";
import { OutBoundPCompleteProcessOverview, OutBoundProcessDelivery, OutBoundProcessDeliveryFromWarehouseLocation, OutBoundProcessExportFromEu, OutBoundProcessImportDestinationCountry, OutBoundProcessOverview, OutBoundProcessTransportDetails, OutBoundProcessTransportToUk, } from "./OutboundProcess";
import { InboundCompleteProcessOverview, InboundProcesReturnWarehouseLocation, InboundProcesReturns, InboundProcessOverview, InboundProcessReturnClosure, InboundProcessReturnPickUp, InboundProcessReturnPlaceOfPresentation, InboundProcessReturnProcessing, } from "./InboundProcess";
import { TechnicalSetupCustomizationFiles } from "./TechnicalSetup";
import { ProcessOverview } from "./ProcessOverview";
import { GoLive } from "./GoLive";
export default [
    { path: "/", element: _jsx(OnboardingStart, {}) },
    {
        title: "onboarding.companyDetails.title",
        path: "/company-details",
        children: [
            {
                title: "onboarding.overview",
                path: "/",
                element: _jsx(CompanyDetailsOverview, {}),
            },
            {
                title: "onboarding.companyDetails.contactInformation.title",
                path: "/contact-information",
                element: _jsx(CompanyDetailsContactInformation, {}),
            },
            {
                title: "onboarding.companyDetails.contactPerson.title",
                path: "/contact-details",
                element: _jsx(CompanyDetailsContactPerson, {}),
            },
        ],
    },
    {
        title: "onboarding.companyDetails.title",
        path: "/administrative-processes",
        children: [
            {
                title: "onboarding.steps.start",
                path: "/",
                element: _jsx(ActiveStep, {}),
            },
            {
                title: "onboarding.steps.start",
                path: "/start",
                element: _jsx(ApplicationVatUidNumberStart, {}),
            },
            {
                title: "onboarding.steps.start",
                path: "/has-tax-in-switzerland",
                element: _jsx(ApplicationVatUidTaxInSwitzerland, {}),
            },
            {
                title: "onboarding.steps.start",
                path: "/tax-documents",
                element: _jsx(ApplicationVatUidTaxSignDocuments, {}),
            },
            {
                title: "onboarding.steps.start",
                path: "/tax-documents-sign-info",
                element: _jsx(ApplicationVatUidTaxDocumentsSignInfo, {}),
            },
            {
                title: "onboarding.steps.start",
                path: "/external-representation",
                element: _jsx(ApplicationVatUidExternalRepresentationSignInfo, {}),
            },
            {
                title: "onboarding.steps.vat_uid_number",
                path: "/vat-uid-number",
                element: _jsx(ApplicationVatUidNumberVatUuidNumber, {}),
            },
            {
                title: "onboarding.steps.fiscal_representation_office",
                path: "/fiscal-representation-office",
                element: _jsx(ApplicationVatUidNumberFiscalRepresentationOffice, {}),
            },
            {
                title: "onboarding.steps.complete_your_company_information",
                path: "/company-information",
                element: _jsx(ApplicationVatUidNumberCompanyInformation, {}),
            },
            {
                title: "onboarding.steps.declaration_of_fiscal_representation",
                path: "/declaration-of-fiscal-representation",
                element: _jsx(ApplicationVatUidNumberDeclarationOfFiscalRepresentation, {}),
            },
            {
                title: "onboarding.steps.declaration_of_fiscal_representation_fertig_check",
                path: "/declaration-of-fiscal-representation-fertig-check",
                element: (_jsx(ApplicationVatUidNumberDeclarationOfFiscalRepresentationFertigCheck, {})),
            },
            {
                title: "onboarding.steps.security_deposit",
                path: "/security-deposit",
                element: _jsx(ApplicationVatUidNumberFiscalSecurityDeposit, {}),
            },
            {
                title: "onboarding.steps.security_deposit",
                path: "/security-deposit-sign",
                element: _jsx(ApplicationVatUidNumberFiscalSecurityDepositSign, {}),
            },
            {
                title: "onboarding.steps.security_deposit",
                path: "/security-deposit-completed",
                element: _jsx(ApplicationVatUidNumberFiscalSecurityDepositCompleted, {}),
            },
            {
                title: "onboarding.steps.power_of_attorney_for_estv_swiss_tax",
                path: "/power-of-attorney",
                element: _jsx(ApplicationVatUidNumberPowerOfAttorney, {}),
            },
            {
                title: "onboarding.steps.declaration_of_subordination",
                path: "/declaration-of-subordination",
                element: _jsx(ApplicationVatUidNumberDeclarationOfSubordination, {}),
            },
            {
                title: "onboarding.steps.completed",
                path: "/vat-application-complete",
                element: _jsx(ApplicationVatUidNumberComplete, {}),
            },
            {
                title: "onboarding.steps.do_you_have_a_customs_account_number",
                path: "/customs-account",
                element: _jsx(CustomsAccountStart, {}),
            },
            {
                title: "onboarding.steps.zaz_account_number",
                path: "/zaz-account-start",
                element: _jsx(ApplicationVatUidTaxInSwitzerland, {}),
            },
            {
                title: "onboarding.steps.zaz_account_number",
                path: "/zaz-account-number",
                element: _jsx(YourCustomAccountCustomsAccountNumber, {}),
            },
            {
                title: "onboarding.steps.calculation_of_security_deposit",
                path: "/calculation-of-security-deposit",
                element: _jsx(YourCustomAccountCustomsCalculationOfSecurityDeposit, {}),
            },
            {
                title: "onboarding.steps.kind_of_security_deposit",
                path: "/kind-of-security-deposit",
                element: _jsx(YourCustomAccountCustomsKindOfSecurityDeposit, {}),
            },
            {
                title: "onboarding.steps.transfering_cash_for_deposit",
                path: "/transfering-cash-for-deposit",
                element: _jsx(YourCustomAccountCustomsTransferCashForDeposit, {}),
            },
            {
                title: "onboarding.steps.transfering_cash_for_deposit",
                path: "/calculation-of-security-deposit-sign",
                element: _jsx(YourCustomAccountCustomsCalculationOfSecurityDepositSign, {}),
            },
            {
                title: "onboarding.steps.payment_of_custom_fees",
                path: "/payment-of-custom-fees",
                element: _jsx(YourCustomAccountCustomsPaymentOfCustomFees, {}),
            },
            {
                title: "onboarding.steps.lastschriftverfahren_einrichten",
                path: "/lastschriftverfahren_einrichten",
                element: _jsx(YourCustomAccountCustomsLastschriftverfahrenEinrichten, {}),
            },
            {
                title: "onboarding.steps.declaration_of_accession",
                path: "/declaration-of-accession",
                element: _jsx(YourCustomAccountCustomsDeclarationOfAccession, {}),
            },
            {
                title: "onboarding.steps.completed",
                path: "/customs-complete",
                element: _jsx(YourCustomAccountComplete, {}),
            },
        ],
    },
    {
        title: "onboarding.steps.start",
        path: "/product-details",
        children: [
            {
                title: "onboarding.overview",
                path: "/",
                element: _jsx(ProductDetailInitialOverview, {}),
            },
            {
                title: "onboarding.productDetails.categories.title",
                path: "/product-range-and-master-data",
                element: _jsx(ProductDetailsCategories, {}),
            },
            {
                title: "onboarding.productDetails.exportRegulation.title",
                path: "/export-regulation",
                element: _jsx(ProductDetailsExportRegulation, {}),
            },
            // {
            //   title: 'onboarding.productDetails.initialProductClassification.title',
            //   path: '/initial-product-classification',
            //   element: <ProductDetailInitialProductClassification />
            // },
            {
                title: "onboarding.productDetails.customsClassification.title",
                path: "/customs-classification",
                element: _jsx(CustomsClassification, {}),
            },
            // {
            //   title: 'onboarding.productDetails.tariffNumberDestinationCountry',
            //   path: '/tariff-number-destination-country',
            //   element: <ProductDetailTariffNumberDestinationCountry />
            // },
            // {
            //   title: 'onboarding.productDetails.tariffNumberShippingCountry',
            //   path: '/tariff-number-shipping-country',
            //   element: <ProductDetailTariffNumberShippingCountry />
            // },
            // {
            //   title: 'onboarding.productDetails.countinousTariffing.title',
            //   path: '/continuos-tariffing',
            //   element: <ProductDetailsContinuosTariffing />
            // },
            {
                title: "onboarding.productDetails.defineImportFile.title",
                path: "/define-import-file",
                element: _jsx(ProductDetailsDefineImportFile, {}),
            },
        ],
    },
    {
        title: "onboarding.steps.start",
        path: "/outbound-process",
        children: [
            {
                title: "onboarding.overview",
                path: "/",
                element: _jsx(OutBoundProcessOverview, {}),
            },
            {
                title: "onboarding.outboundProcesses.originWarehouse.title",
                path: "/origin-warehouse",
                element: _jsx(OutBoundProcessDeliveryFromWarehouseLocation, {}),
            },
            {
                title: "onboarding.outboundProcesses.exportEU.title",
                path: "/export_EU",
                element: _jsx(OutBoundProcessExportFromEu, {}),
            },
            {
                title: "onboarding.outboundProcesses.transportUk.title",
                path: "/transport_UK",
                element: _jsx(OutBoundProcessTransportToUk, {}),
            },
            {
                title: "onboarding.outboundProcesses.transportUK.title",
                path: "/transport-details",
                element: _jsx(OutBoundProcessTransportDetails, {}),
            },
            {
                title: "onboarding.outboundProcesses.import.title",
                path: "/customs-declaration",
                element: _jsx(OutBoundProcessImportDestinationCountry, {}),
            },
            {
                title: "onboarding.outboundProcesses.delivery.title",
                path: "/delivery-parcel-hub",
                element: _jsx(OutBoundProcessDelivery, {}),
            },
            {
                title: "onboarding.steps.completed",
                path: "/complete-outbound",
                element: _jsx(OutBoundPCompleteProcessOverview, {}),
            },
        ],
    },
    {
        path: "/inbound-process",
        children: [
            {
                title: "onboarding.overview",
                path: "/",
                element: _jsx(InboundProcessOverview, {}),
            },
            {
                title: "onboarding.inboundProcesses.returns.title",
                path: "/return-start",
                element: _jsx(InboundProcesReturns, {}),
            },
            {
                title: "onboarding.inboundProcesses.returnProcessing.title",
                path: "/return-processing",
                element: _jsx(InboundProcessReturnProcessing, {}),
            },
            {
                title: "onboarding.inboundProcesses.returnClosure.title",
                path: "/return-closure",
                element: _jsx(InboundProcessReturnClosure, {}),
            },
            {
                title: "onboarding.inboundProcesses.returnPickUp.title",
                path: "/return-pick-up",
                element: _jsx(InboundProcessReturnPickUp, {}),
            },
            {
                title: "onboarding.inboundProcesses.returnPlaceOfPresent.title",
                path: "/return-delivery-process",
                element: _jsx(InboundProcessReturnPlaceOfPresentation, {}),
            },
            {
                title: "onboarding.inboundProcesses.returnWarehouseLocation.title",
                path: "/return-warehouse-location",
                element: _jsx(InboundProcesReturnWarehouseLocation, {}),
            },
            {
                title: "onboarding.steps.completed",
                path: "/complete-inbound",
                element: _jsx(InboundCompleteProcessOverview, {}),
            },
        ],
    },
    {
        title: "onboarding.steps.start",
        path: "/technical-setup",
        children: [
            {
                title: "onboarding.overview",
                path: "/",
                element: _jsx(TechnicalSetupCustomizationFiles, {}),
            },
        ],
    },
    {
        title: "onboarding.steps.start",
        path: "/go-live/",
        element: _jsx(GoLive, {}),
    },
    {
        path: "/process-overview-test/",
        element: _jsx(ProcessOverview, {}),
    },
];
