import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import Link from "~/components/Link/Link";
export function YourCustomAccountCustomsLastschriftverfahrenEinrichten() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.your_customs_account"), subtitle: t("onboarding.securityDeposit.setUpDirectDebit"), description: t("onboarding.securityDeposit.setUpDirectDebitDescription") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: ["1. ", t("onboarding.securityDeposit.downloadAuthorizationForm"), " ", _jsx(Link, { to: "/", children: t("onboarding.actions.here") })] }), _jsxs(Text, { children: ["2. ", t("onboarding.securityDeposit.completAndSend")] }), _jsx(Text, { fontWeight: "bold", children: t("onboarding.securityDeposit.agreementDirectDebit") })] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
