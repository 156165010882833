import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Flex, Menu, MenuButton, MenuItem, MenuList, } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { TableSelectionMode } from "~/types/table-selection.type";
import { TableSelectionBanner } from "../TableSelectionBanner/TableSelectionBanner";
export const TableSelectHeader = ({ selectedItems, setSelectedItems, selectionMode, setSelectionMode, pageData, itemCount, allSelectionTextKey, currentPageSelectionTextKey, inPageMode, setInPageMode, metadata, }) => {
    const { t } = useTranslation();
    // checkbox is indeterminate, if
    // - we are in INCLUDE mode and selectedItems.length < itemCount
    // - we are in EXCLUDE mode and selectedItems.length > 0
    const isIndeterminate = selectionMode === TableSelectionMode.INCLUDE
        ? selectedItems.length < itemCount && selectedItems.length > 0
        : selectionMode === TableSelectionMode.EXCLUDE
            ? selectedItems.length > 0
            : false;
    const isChecked = selectionMode === TableSelectionMode.ALL
        ? true
        : selectionMode === TableSelectionMode.INCLUDE
            ? selectedItems.length === itemCount && itemCount > 0
            : selectionMode === TableSelectionMode.EXCLUDE
                ? selectedItems.length === 0
                : false;
    useEffect(() => {
        if (selectionMode === TableSelectionMode.EXCLUDE &&
            selectedItems.length === itemCount) {
            setSelectionMode(TableSelectionMode.INCLUDE);
            setSelectedItems([]);
        }
    }, [selectedItems]);
    const handleSelectAllData = () => {
        var _a;
        // we change the mode to ALL and empty the selected item ids
        setInPageMode(false);
        setSelectedItems([...((_a = metadata === null || metadata === void 0 ? void 0 : metadata.totalImportedArticleIds) !== null && _a !== void 0 ? _a : [])]);
        setSelectionMode(TableSelectionMode.INCLUDE);
    };
    const handleSelectCurrentPage = () => {
        // we change the mode to INCLUDE, empty the selected item ids and add the current page item ids
        setInPageMode(true);
        setSelectionMode(TableSelectionMode.INCLUDE);
        setSelectedItems(pageData.map((item) => item.id));
    };
    const handleCheckbox = () => {
        if (isChecked || isIndeterminate) {
            setInPageMode(false);
            setSelectedItems([]);
            setSelectionMode(TableSelectionMode.INCLUDE);
        }
        else
            handleSelectCurrentPage();
    };
    // table banner logic
    const onSelectedPage = useMemo(() => {
        const pageItems = pageData.map((item) => item.id);
        const isEqual = pageItems.sort().join(",") === selectedItems.sort().join(",");
        return inPageMode && isEqual;
    }, [pageData, selectedItems]);
    const showTableBanner = selectionMode === TableSelectionMode.ALL || (inPageMode && onSelectedPage);
    const bannerElement = document.getElementById("table-selection-portal");
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(Checkbox, { isIndeterminate: isIndeterminate, isChecked: isChecked, onChange: handleCheckbox }), _jsxs(Menu, { children: [_jsx(MenuButton, { px: "3", display: "flex", justifyContent: "center", alignItems: "center", children: _jsx(Icon, { h: "2", name: "chevron-thick-down", color: "grey.40", w: "3" }) }), _jsxs(MenuList, { py: "0", overflow: "hidden", children: [_jsx(MenuItem, { textStyle: "body", color: "brand.black", px: "6", py: "3", borderBottom: "1px solid", borderBottomColor: "grey.10", onClick: handleSelectAllData, children: t("basics.table.selectAll", { count: itemCount }) }), _jsx(MenuItem, { color: "brand.black", textStyle: "body", px: "6", py: "3", onClick: handleSelectCurrentPage, children: t("basics.table.selectPage", { count: pageData.length }) })] })] })] }), !!allSelectionTextKey &&
                !!currentPageSelectionTextKey &&
                showTableBanner &&
                !!bannerElement &&
                createPortal(_jsx(TableSelectionBanner, { text: inPageMode
                        ? t(currentPageSelectionTextKey, { count: pageData.length })
                        : t(allSelectionTextKey, { count: itemCount }) }), bannerElement)] }));
};
