import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import Link from "~/components/Link/Link";
import UploadFilesArea from "~/components/UploadFilesArea/UploadFilesArea";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
import { fetchFormById, patchFormField, } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, lastSavedFieldState, } from "~/store/selectors/formData.selectors";
export function ApplicationVatUidNumberDeclarationOfFiscalRepresentation() {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formdata = useSelector(getFormData);
    const lastSavedField = useSelector(lastSavedFieldState);
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "declarationOfFiscalRepresentation",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const updateField = (e) => {
        dispatch(patchFormField({
            fieldName: "files",
            fieldValue: JSON.stringify(e.map((el) => ({
                ...el,
                file: {
                    type: el.file.type,
                    size: el.file.size,
                    name: el.file.name,
                },
            }))),
            id,
            flowId,
            pageSlug: "declarationOfFiscalRepresentation",
            processSlug: "administrativeProcesses",
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.application_vat_uid_number"), subtitle: t("onboarding.declarationOfFiscalRepresentation.declarationOfFiscalRepresentation"), description: t("onboarding.declarationOfFiscalRepresentation.companyDetailsForOfficialTaxRepresentation") }), _jsxs(FormBody, { gap: "4", children: [_jsxs(Text, { children: ["1.", " ", t("onboarding.declarationOfFiscalRepresentation.downloadDocument"), " ", _jsx(Link, { to: "/", children: t("onboarding.actions.here") })] }), _jsx(UploadFilesArea, { onFileAccepted: (acceptedFiles) => {
                            updateField(acceptedFiles);
                        }, files: JSON.parse((_b = (_a = formdata === null || formdata === void 0 ? void 0 : formdata.files) === null || _a === void 0 ? void 0 : _a.fieldValue) !== null && _b !== void 0 ? _b : "[]"), placeholder: t("onboarding.uploadFilesArea.files"), multiple: true }), lastSavedField === "files" && (_jsx(InputSuccess, { text: "FIELD_SAVE_SUCCESS" }))] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
