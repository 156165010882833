import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { FormControl, FormErrorMessage, VStack } from "@chakra-ui/react";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState, } from "react";
import Dropzone from "~/components/UploadFilesArea/Dropzone/Dropzone";
import FilesList from "../FilesList/FileList";
function UploadFilesArea({ onFileAccepted, files, isDisabled, error, acceptFiles, ...rest }, ref) {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);
    useEffect(() => {
        if (files === null || files === void 0 ? void 0 : files.length) {
            setUploadedFiles(files);
        }
    }, [files]);
    const handleUploadFile = async (file) => {
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles.push(file);
        onFileAccepted(newUploadedFiles);
    };
    const handleChangeUploadedFiles = (newFiles) => {
        if (isDisabled)
            return;
        const newUploadingFiles = Array.from(newFiles.filter((file) => file &&
            !uploadedFiles.find((uploadedFile) => uploadedFile.file.name === file.name) &&
            !uploadingFiles.find((uploadingFile) => uploadingFile.file.name === file.name))).map((file) => ({
            file,
            uploading: true,
        }));
        setUploadingFiles([...uploadingFiles].concat(newUploadingFiles));
    };
    const handleOnRemoveFile = useCallback((file) => {
        if (isDisabled)
            return;
        onFileAccepted(uploadedFiles.filter((uploadedFile) => {
            return uploadedFile.file.name !== file.file.name;
        }));
    }, [uploadedFiles]);
    const handlerUploadFile = useCallback((file) => {
        if (isDisabled)
            return;
        handleUploadFile(file);
    }, [uploadingFiles, uploadedFiles]);
    return (_jsxs(FormControl, { isInvalid: Boolean(error), children: [_jsxs(VStack, { w: "full", h: "auto", spacing: "4", alignItems: "flex-start", maxW: "inherit", children: [!isDisabled && (_jsx(Dropzone, { acceptFiles: acceptFiles, uploadedFiles: uploadedFiles, handleChangeUploadedFiles: handleChangeUploadedFiles, ...rest })), (uploadedFiles.length || uploadingFiles.length) && (_jsx(VStack, { w: "full", alignItems: "flex-start", justifyContent: "flex-start", children: _jsx(FilesList, { files: uploadedFiles.concat(uploadingFiles.filter((uploadedFile) => !uploadedFiles.find((uploadingFile) => uploadingFile.file.name === uploadedFile.file.name))), isDisabled: isDisabled, handleOnRemoveFile: handleOnRemoveFile, handlerUploadFile: handlerUploadFile }) }))] }), _jsx("input", { type: "hidden", ref: innerRef, value: JSON.stringify(uploadedFiles) }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(FormErrorMessage, { children: error.message })) : null] }));
}
export default memo(forwardRef(UploadFilesArea));
