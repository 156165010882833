import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returnPickUp.title",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "h3",
                label: "onboarding.inboundProcesses.returnPickUp.pickUpFrequency",
            },
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "carrier",
                options: {
                    pt: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnPickUp.carrierToPickUp",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "seven_sender",
                                label: "onboarding.inboundProcesses.returnPickUp.severSenders",
                                value: "seven_sender",
                                forKey: "carrier",
                            },
                            {
                                type: "radio",
                                key: "other",
                                label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.other",
                                value: "other",
                                forKey: "carrier",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                            pb: 2,
                        },
                        elements: [
                            {
                                type: "layout",
                                direction: "vertical",
                                options: {
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "name-of-company",
                                        type: "text",
                                        label: "clients.companyName",
                                    },
                                    {
                                        key: "contact-person",
                                        type: "text",
                                        label: "onboarding.companyDetails.contactPerson.title",
                                    },
                                    {
                                        type: "layout",
                                        direction: "horizontal",
                                        options: {
                                            gap: 3,
                                        },
                                        elements: [
                                            {
                                                key: "street",
                                                type: "text",
                                                label: "onboarding.companyInformation.street",
                                            },
                                            {
                                                key: "no",
                                                type: "text",
                                                label: "onboarding.companyInformation.number",
                                            },
                                        ],
                                    },
                                    {
                                        type: "layout",
                                        direction: "horizontal",
                                        options: {
                                            gap: 3,
                                        },
                                        elements: [
                                            {
                                                key: "zip-code",
                                                type: "text",
                                                label: "onboarding.companyInformation.zipCode",
                                            },
                                            {
                                                key: "city",
                                                type: "text",
                                                label: "onboarding.companyInformation.city",
                                            },
                                        ],
                                    },
                                    {
                                        key: "country",
                                        type: "customComponent",
                                        componentName: "base.CountrySelect",
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "carrier",
                            targetValue: "other",
                        },
                    },
                ],
            },
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "special_pallet",
                options: {
                    pt: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnPickUp.specialPallet",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "yes",
                                label: "onboarding.actions.yes",
                                value: "yes",
                                forKey: "special_pallet",
                            },
                            {
                                type: "radio",
                                key: "no",
                                label: "onboarding.actions.no",
                                value: "no",
                                forKey: "special_pallet",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                        },
                        elements: [
                            {
                                key: "instructions-for-labels",
                                type: "text",
                                isTextarea: true,
                                label: "onboarding.inboundProcesses.returnPickUp.specialInstructions",
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "special_pallet",
                            targetValue: "yes",
                        },
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
