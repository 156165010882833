import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { translatedCountryName } from "~/helpers/country-codes";
import { Card } from "../Card/Card";
export const AddressCard = ({ address, }) => {
    const { t } = useTranslation();
    return (_jsx(Card, { children: _jsxs(Grid, { templateColumns: "1fr 1fr", columnGap: 4, rowGap: 8, children: [_jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("partners.detail.profile.streetLabel") }), _jsx(Text, { color: !address ? "grey.40" : "", children: address
                                ? [address === null || address === void 0 ? void 0 : address.street, address === null || address === void 0 ? void 0 : address.number].join(" ")
                                : t("partners.detail.profile.notSetAddress") })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("partners.detail.profile.cityLabel") }), _jsx(Text, { color: !address ? "grey.40" : "", children: address
                                ? address.city
                                : t("partners.detail.profile.notSetAddress") })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("partners.detail.profile.countryLabel") }), _jsx(Text, { color: !address ? "grey.40" : "", children: address
                                ? translatedCountryName(address.country)
                                : t("partners.detail.profile.notSetAddress") })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("partners.detail.profile.zipLabel") }), _jsx(Text, { color: !address ? "grey.40" : "", children: address
                                ? address.zipCode
                                : t("partners.detail.profile.notSetAddress") })] })] }) }));
};
