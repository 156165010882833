import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import FormError from "~/components/FormError/FormError";
import { getFormLoadingState } from "~/store/selectors/formData.selectors";
import { getHasError } from "~/store/selectors/formError.selectors";
export default function FormBody({ children, gap, }) {
    const hasError = useSelector(getHasError);
    const isLoading = useSelector(getFormLoadingState);
    if (hasError) {
        return _jsx(FormError, { gap: gap });
    }
    if (isLoading) {
        return (_jsx(Flex, { direction: "column", p: gap !== null && gap !== void 0 ? gap : 5, gap: "2", mb: "8", bgColor: "white", children: _jsx(CircularProgress, { isIndeterminate: true, size: "30px", thickness: "8px" }) }));
    }
    return (_jsx(Flex, { direction: "column", p: gap !== null && gap !== void 0 ? gap : 5, gap: "2", mb: "8", bgColor: "white", children: children }));
}
