import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ChakraProvider, Flex, useMediaQuery, useTheme, } from "@chakra-ui/react";
import { Outlet } from "@tanstack/react-location";
import theme from "~/theme";
import { Header } from "./components/Header/Header";
import { Sidebar } from "./components/Sidebar/Sidebar";
export const Index = () => {
    const { sizes } = useTheme();
    const [isTablet] = useMediaQuery(`(max-width: ${sizes.container.md}`);
    return (_jsx(ChakraProvider, { theme: theme, children: _jsxs(Flex, { direction: "column", height: "100vh", overflow: "hidden", children: [_jsx(Header, {}), _jsxs(Flex, { grow: 1, overflow: "hidden", children: [!isTablet && _jsx(Sidebar, {}), _jsx(Box, { backgroundColor: "sky.10", w: "full", overflow: "auto", children: _jsx(Outlet, {}) })] })] }) }));
};
