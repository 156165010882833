import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "~/helpers/withBaseUrl";
export const API_REDUCER_KEY = "api";
export const AUTH_V1_BASEURL = "/auth/api/v1";
export const ONBOARDING_BASEURL = "/onboarding/api";
export const ONBOARDING_V1_BASEURL = "/onboarding/api/v1";
export const ONBOARDING_V2_BASEURL = "/onboarding/api/v2";
// export const V1_BASEURL = '/api/v1';
// export const AUTH_V1_BASEURL = 'http://localhost:3001/api/v1';
// export const ONBOARDING_BASEURL = '/api';
// export const ONBOARDING_V2_BASEURL = '/api/v2';
export const ARTICLES_V1_BASEURL = "/articles/api/v1";
const baseQuery = fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers, api) => {
        var _a;
        const rootState = api.getState();
        headers.set("Authorization", `Bearer ${(_a = rootState === null || rootState === void 0 ? void 0 : rootState.auth) === null || _a === void 0 ? void 0 : _a.token}`);
        return headers;
    },
});
export const api = createApi({
    reducerPath: API_REDUCER_KEY,
    baseQuery,
    endpoints: () => ({}),
});
