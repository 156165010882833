import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, GridItem, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAllTranslationsQuery, useBulkInsertTranslationsMutation, } from "~/api/hooks/translations";
import Button from "~/components/Button/Button";
import TextArea from "~/components/TextArea/TextArea";
function BackUpTranslation() {
    const { t } = useTranslation();
    const allTranslation = useAllTranslationsQuery();
    const bulkInsert = useBulkInsertTranslationsMutation();
    const translationData = allTranslation.data;
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            EN: JSON.stringify((translationData === null || translationData === void 0 ? void 0 : translationData.EN) || {}),
            DE: JSON.stringify((translationData === null || translationData === void 0 ? void 0 : translationData.DE) || {}),
        },
    });
    useEffect(() => {
        setValue("EN", JSON.stringify((translationData === null || translationData === void 0 ? void 0 : translationData.EN) || {}));
        setValue("DE", JSON.stringify((translationData === null || translationData === void 0 ? void 0 : translationData.DE) || {}));
    }, [translationData]);
    const onSubmit = async (input) => {
        const formattedData = {
            ...input,
        };
        bulkInsert.mutate(formattedData);
    };
    return (_jsx(GridItem, { colStart: 1, colEnd: 13, children: _jsxs(Flex, { as: "form", flexDirection: "column", height: "calc(100vh - 72px)", onSubmit: handleSubmit(onSubmit), children: [_jsxs(VStack, { flex: 1, overflowY: "auto", boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.25)", p: 6, spacing: 6, alignItems: "start", children: [_jsx(TextArea, { label: t("translations.en"), ...register("EN") }), _jsx(TextArea, { label: t("translations.de"), ...register("DE") })] }), _jsxs(Flex, { paddingX: "24px", flexBasis: "72px", justifyContent: "space-between", alignItems: "center", fontSize: "sm", children: [_jsx(Flex, { alignItems: "center" }), _jsx(Flex, { alignItems: "center", gap: 2, children: _jsx(Button, { loadingText: t("submitting"), type: "submit", children: t("translations.button.import") }) })] })] }) }));
}
export default BackUpTranslation;
