import i18n from "~/i18n";
export default function exportEu(flowFields) {
    var _a, _b, _c, _d, _e, _f;
    const exportOrganizer = ((_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsClearer" &&
        data.pageSlug === "customsDeclaration")) === null || _a === void 0 ? void 0 : _a.fieldValue) || "";
    let myselfCompanyName = ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "goodsClearerCompanyName" &&
        data.pageSlug === "customsDeclaration")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    if (exportOrganizer === "myself") {
        myselfCompanyName =
            ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" &&
                data.pageSlug === "contactInformation")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
    }
    const customClearanceMethod = ((_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "CustomClearanceMethod" &&
        data.pageSlug === "exportFromEu")) === null || _d === void 0 ? void 0 : _d.fieldValue) || "";
    const customsOfficeCompanyName = ((_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "customsOfficeCompanyName" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _e === void 0 ? void 0 : _e.fieldValue) || "";
    const transitDocuments = ((_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "transitDocuments" &&
        data.pageSlug === "exportFromEu")) === null || _f === void 0 ? void 0 : _f.fieldValue) || "";
    let what = "";
    if (customsOfficeCompanyName !== "" &&
        (customClearanceMethod === "transit_document"
            ? transitDocuments !== ""
            : true))
        what = `${i18n.t("processOverview.outboundProcess.exportEu.customOffice", {
            customOffice: customsOfficeCompanyName,
        })}<br />
    ${i18n.t("processOverview.outboundProcess.exportEu.preparation")}<br />
 ${customClearanceMethod === "transit_document"
            ? i18n.t("processOverview.outboundProcess.exportEu.tranzit", {
                tranzit: transitDocuments,
            })
            : ""}
 `;
    return {
        when: "-",
        who: exportOrganizer !== "msDirect" ? myselfCompanyName : "MS Direct",
        what,
        icon: "exporteu",
        title: i18n.t("processOverview.outboundProcess.exportEu.title"),
    };
}
