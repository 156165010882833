import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { graphApiRequest, loginRequest } from "~/auth-config";
import { authSelector, setAuth } from "~/pages/auth/redux/auth.slice";
import { ONBOARDING_V2_BASEURL } from "~/store/api";
import { get } from "../utils";
export function useSignOutMutation() {
    const queryClient = useQueryClient();
    return useMutation(async () => { }, {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
}
export function useAuthDataQuery() {
    try {
        const data = useSelector(authSelector);
        return { data };
    }
    catch (e) {
        return { data: undefined };
    }
}
export function useGetCurrentUserProfileQuery() {
    return useQuery(["users"], async () => get("/profile"), {
        onError: (err) => {
            if (err.code === "ERROR_EXPIRED_AUTHORIZATION_HEADER") {
                //
            }
        },
    });
}
export function useGetUserQuery(email) {
    return useQuery(["users", email], async () => get(`${ONBOARDING_V2_BASEURL}/users/${email}`), {
        enabled: !!email,
        onError: (err) => {
            if (err.code === "ERROR_EXPIRED_AUTHORIZATION_HEADER") {
                //
            }
        },
    });
}
export const usersRequest = async (where) => get("/users/all", where);
export function useADAccessTokenQuery(skip) {
    if (!skip) {
        const { instance, accounts } = useMsal();
        const dispatch = useDispatch();
        const apiRequest = {
            ...loginRequest,
            account: accounts[0],
        };
        const graphRequest = {
            ...graphApiRequest,
            account: accounts[0],
        };
        if (accounts.length === 0)
            instance.loginRedirect(loginRequest).catch(() => { });
        else
            instance
                .acquireTokenSilent(apiRequest)
                .then(async (apiResponse) => {
                instance
                    .acquireTokenSilent(graphRequest)
                    .then(async (graphResponse) => {
                    var _a;
                    dispatch(setAuth({
                        token: apiResponse.accessToken,
                        graphToken: graphResponse.accessToken,
                        tokenExpiry: (_a = apiResponse.expiresOn) === null || _a === void 0 ? void 0 : _a.getTime(),
                    }));
                });
            })
                .catch(() => {
                instance.loginRedirect(loginRequest);
            });
        return accounts;
    }
    return null;
}
