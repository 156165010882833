import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.application_vat_uid_number",
        subtitle: "onboarding.vatUidNumber.title",
        description: "onboarding.vatUidNumber.vatUidDescription",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label3",
                type: "label",
                label: [
                    {
                        href: "",
                        targetBlank: true,
                        displayLabel: "onboarding.vatUidNumber.downloadNotice",
                    },
                ],
            },
            {
                key: "label4",
                type: "label",
                label: "onboarding.vatUidNumber.enterSwissVatAndUidNumber",
            },
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "vat_number",
                        type: "text",
                        label: "onboarding.vatUidNumber.vatNumber",
                    },
                    {
                        key: "uid_number",
                        type: "text",
                        label: "onboarding.vatUidNumber.uidNumber",
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        vat_number: {
            type: "string",
            minLength: 1,
            pattern: "^((CHE)(-|\\s)?|)\\d{3}(\\.|\\s)?\\d{3}(\\.|\\s)?\\d{3}(\\s)?(IVA|TVA|MWST|VAT)?$",
            errorMessage: {
                minLength: "onboarding.requiredFields.vatNumber",
                pattern: "onboarding.invalidVatNumber",
            },
        },
        uid_number: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.uidNumber",
            },
        },
    },
    additionalProperties: false,
    required: ["vat_number", "uid_number"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
