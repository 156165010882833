import { jsx as _jsx } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
import { useEditPartnerServiceMutation } from "~/pages/partners/redux/partners.api";
export const DisableTariffingServiceModal = ({ isOpen, setIsOpen }) => {
    const { params: { id }, } = useMatch();
    const toast = useToast();
    const { t } = useTranslation();
    const [disableService, { isLoading }] = useEditPartnerServiceMutation();
    const handleDisable = async () => {
        try {
            const res = await disableService({
                id,
                serviceCode: ServiceCode.TARIFFING,
                body: { status: ServiceStatus.DISABLED },
            });
            if ("data" in res) {
                toast({
                    title: t("partners.disableTariffingModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.disableTariffing"),
                status: "error",
                duration: 3000,
            });
        }
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("partners.disableTariffingModal.title"), primaryTitle: t("partners.disableTariffingModal.primaryTitle"), primaryAction: handleDisable, primaryVariant: "danger", primaryActionDisabled: isLoading, primaryActionLoading: isLoading, children: _jsx(Text, { mt: "2", color: "grey.60", children: t("partners.disableTariffingModal.subtitle") }) }));
};
