import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useNavigate } from "@tanstack/react-location";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArchiveIcon } from "~/assets/icons/ArchiveIcon";
import { EditIcon } from "~/assets/icons/EditIcon";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { Table } from "~/components/Table/Table";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { WithPermission } from "~/components/WithPermission/WithPermission";
import { hasPermission } from "~/helpers/hasPermission";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { RouteMap } from "~/routing/route.config";
import { OrderDirection } from "~/types/pagination.type";
import { CreatePartnerModal } from "../../components/CreatePartnerModal/CreatePartnerModal";
import { PartnerInfoOverlay } from "../../components/PartnerInfoOverlay/PartnerInfoOverlay";
import { useGetPaginatedPartnersQuery } from "../../redux/partners.api";
export const PartnerOverviewPage = () => {
    const { t } = useTranslation();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [showCreatePartnerModal, setShowCreatePartnerModal] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const { PARTNERS_ACCOUNTS_DELETE, PARTNERS_ACCOUNTS_CREATE, PARTNERS_ACCOUNTS_MANAGE, } = EPermissionCodes;
    const navigate = useNavigate();
    const { data: partners, isLoading: isLoadingPartners, isFetching: isFetchingPartners, } = useGetPaginatedPartnersQuery({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        searchQuery,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
    });
    const headerActions = (_jsx(WithPermission, { permission: [PARTNERS_ACCOUNTS_CREATE, PARTNERS_ACCOUNTS_MANAGE], children: _jsx(Button, { onClick: () => setShowCreatePartnerModal(true), children: t("partners.createPartnerCTA") }) }));
    const tableActions = (id) => [
        {
            label: t("basics.edit"),
            element: _jsx(EditIcon, {}),
            onClick: () => navigate({ to: `${RouteMap.PARTNERS}/${id}` }),
        },
        ...(hasPermission(PARTNERS_ACCOUNTS_DELETE)
            ? [
                {
                    label: t("basics.archive"),
                    element: _jsx(ArchiveIcon, {}),
                    onClick: () => id,
                },
            ]
            : []),
    ];
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: () => t("partners.table.name"),
            cell: ({ getValue }) => getValue(),
            size: undefined,
        }),
        columnHelper.accessor("userCount", {
            header: () => t("partners.table.users"),
            enableSorting: false,
            size: 140,
        }),
        columnHelper.accessor("clientCount", {
            header: () => t("partners.table.clients"),
            enableSorting: false,
            size: 140,
        }),
        columnHelper.accessor("serviceCount", {
            header: () => t("partners.table.services"),
            enableSorting: false,
            size: 140,
        }),
        columnHelper.display({
            id: "actions",
            size: undefined,
            header: () => t("partners.table.actions"),
            cell: ({ row }) => renderTableRowActions({
                tableActions: tableActions(row.original.id.toString()),
            }),
        }),
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { actions: headerActions, title: t("partners.pageHeadline") }), _jsxs(ContentSection, { children: [_jsx(FilterBar, { children: _jsx(FilterSearch, { disabled: isLoadingPartners || isFetchingPartners, value: search, setValue: setSearch }) }), _jsx(Table, { columns: columns, data: partners, onRowClick: (row) => setSelectedPartner(row.original.id), sorting: sorting, onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: setPagination, isLoading: isLoadingPartners, isFetching: isFetchingPartners })] }), _jsx(CreatePartnerModal, { show: showCreatePartnerModal, handleHide: () => setShowCreatePartnerModal(false) }), _jsx(PartnerInfoOverlay, { partnerId: selectedPartner, onClose: () => setSelectedPartner(null) })] }));
};
