import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useDisclosure, } from "@chakra-ui/react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
export const ChangeRecipientComponent = ({ onSubmitData, disabled, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, getValues, formState: { isSubmitting }, } = useForm();
    const changeEnvelopeRecipient = useCallback(() => {
        const values = getValues();
        onSubmitData(values);
        onClose();
    }, [onSubmitData]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "link", colorScheme: "blue", onClick: onOpen, my: "3", p: "3", disabled: disabled, children: t("onboarding.vatNumberAndCustomsAccount.unsigned.sendLinkButton") }), _jsxs(Modal, { isOpen: isOpen, onClose: onClose, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.title") }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsxs("form", { children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: "email", children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.email") }), _jsx(Input, { id: "email", placeholder: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.email"), ...register("email", {
                                                        required: "This is required",
                                                        minLength: {
                                                            value: 4,
                                                            message: "Minimum length should be 4",
                                                        },
                                                    }) })] }), _jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: "firstName", children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.firstName") }), _jsx(Input, { id: "firstName", placeholder: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.firstName"), ...register("firstName", {
                                                        required: "This is required",
                                                        minLength: {
                                                            value: 4,
                                                            message: "Minimum length should be 4",
                                                        },
                                                    }) })] }), _jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: "lastName", children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.lastName") }), _jsx(Input, { id: "lastName", placeholder: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.lastName"), ...register("lastName", {
                                                        required: "This is required",
                                                        minLength: {
                                                            value: 4,
                                                            message: "Minimum length should be 4",
                                                        },
                                                    }) })] }), _jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: "message", children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.message") }), _jsx(Input, { id: "message", placeholder: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.message"), ...register("message", {
                                                        required: "This is required",
                                                        minLength: {
                                                            value: 4,
                                                            message: "Minimum length should be 4",
                                                        },
                                                    }) })] })] }) }), _jsx(ModalFooter, { children: _jsxs(Flex, { w: "full", children: [_jsx(Button, { rounded: "none", variant: "outline", colorScheme: "blue", mr: 3, onClick: onClose, children: t("documents.upload.cancel") }), _jsx(Spacer, {}), _jsx(Button, { variant: "solid", rounded: "none", colorScheme: "blue", onClick: changeEnvelopeRecipient, isLoading: isSubmitting, children: t("onboarding.vatNumberAndCustomsAccount.changeSigner.form.actionButton") })] }) })] })] })] }));
};
