import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.zazAccount.accountNumber",
        description: "onboarding.zazAccount.accountNumberDescription",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: "onboarding.zazAccount.customClearanceMSDirect",
            },
            {
                key: "zaz_account_number",
                type: "text",
                label: "onboarding.zazAccount.accountNumber",
            },
            {
                key: "use_external_custom_clearance",
                type: "checkbox",
                value: "yes",
                label: "onboarding.zazAccount.customClearanceExternalPartner",
            },
            {
                key: "button3",
                type: "button",
                variant: "text",
                transition: "I_DONT_HAVE_ZAZ_ACCOUNT_NUMBER",
                label: "onboarding.zazAccount.noZazNumber",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        zaz_account_number: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.zazAccountNumber",
            },
        },
        use_external_custom_clearance: {
            type: "string",
        },
    },
    additionalProperties: false,
    required: ["zaz_account_number"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
