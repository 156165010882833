import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.countinousTariffing.countinousTariffingClassification",
        description: "onboarding.productDetails.countinousTariffing.countinousTariffingClassificationDescription",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "continuous-tarffing",
                options: {
                    gap: 3,
                },
                elements: [
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.productDetails.countinousTariffing.wantContinuousTariffing",
                    },
                    {
                        type: "radio",
                        key: "yes",
                        label: "onboarding.actions.yes",
                        value: "yes",
                        forKey: "continuous-tarffing",
                    },
                    {
                        type: "radio",
                        key: "no",
                        label: "onboarding.actions.no",
                        value: "no",
                        forKey: "continuous-tarffing",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.productDetails.countinousTariffing.warning",
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "continuous-tarffing",
                            targetValue: "no",
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    gap: 3,
                },
                elements: [
                    {
                        key: "label1",
                        type: "h3",
                        label: "onboarding.productDetails.countinousTariffing.estimatedNumberOfProducts",
                    },
                    {
                        key: "amount",
                        type: "text",
                        isTextarea: true,
                        label: "onboarding.productDetails.countinousTariffing.amount",
                    },
                ],
            },
        ],
        rule: {
            effect: "SHOW",
            targetKey: "continuous-tarffing",
            targetValue: "yes",
        },
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    gap: 3,
                },
                elements: [
                    {
                        key: "label1",
                        type: "h3",
                        label: "onboarding.productDetails.countinousTariffing.instructionIntegrationInterface",
                    },
                    {
                        key: "label1",
                        type: "label",
                        label: "onboarding.productDetails.countinousTariffing.countinousTariffingInstructions",
                    },
                    {
                        key: "download",
                        type: "label",
                        label: [
                            "onboarding.productDetails.countinousTariffing.downloadTariFile",
                            " ",
                            { href: "", displayLabel: "onboarding.actions.here" },
                        ],
                    },
                    {
                        key: "tari-file",
                        type: "file",
                        multiple: false,
                        label: "onboarding.productDetails.countinousTariffing.uploadTariFile",
                    },
                ],
            },
        ],
        rule: {
            effect: "SHOW",
            targetKey: "continuous-tarffing",
            targetValue: "yes",
        },
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
