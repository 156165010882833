import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, GridItem, Text, VStack } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEditTranslationsMutation } from "~/api/hooks/translations";
import Button from "~/components/Button/Button";
import { Slot } from "~/components/Slots/Slots";
import TextField from "~/components/TextField/TextField";
export default function EditTranslation() {
    const { t } = useTranslation();
    const editTranslationsMutation = useEditTranslationsMutation();
    const { data, params: { key }, } = useMatch();
    const translationData = data.translationData;
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm({
        defaultValues: translationData,
    });
    const onSubmit = async (input) => {
        const formattedData = {
            ...input,
            key,
        };
        editTranslationsMutation.mutate(formattedData);
        navigate({ to: "/settings/translations" });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Slot, { name: "left-nav", children: _jsx(Text, { fontSize: "2xl", fontWeight: "bold", children: t("translations.editTranslationValue") }) }), _jsx(GridItem, { colStart: 1, colEnd: 13, children: _jsxs(Flex, { as: "form", flexDirection: "column", height: "calc(100vh - 72px)", onSubmit: handleSubmit(onSubmit), children: [_jsxs(VStack, { flex: 1, overflowY: "auto", boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.25)", p: 6, spacing: 6, alignItems: "start", children: [_jsx(TextField, { label: "Translation Key", value: key, ...register("key"), isReadOnly: true }), _jsx(TextField, { label: t("translations.en"), ...register("EN") }), _jsx(TextField, { label: t("translations.de"), ...register("DE") })] }), _jsxs(Flex, { paddingX: "24px", flexBasis: "72px", justifyContent: "space-between", alignItems: "center", fontSize: "sm", children: [_jsx(Flex, { alignItems: "center" }), _jsxs(Flex, { alignItems: "center", gap: 2, children: [_jsx(Button, { onClick: () => navigate({ to: "/settings/translations" }), variant: "secondary", children: t("clients.cancel") }), _jsx(Button, { loadingText: t("submitting"), type: "submit", children: t("clients.save") })] })] })] }) })] }));
}
