import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Checkbox } from "@chakra-ui/react";
import { useMemo } from "react";
import { TableSelectionMode } from "~/types/table-selection.type";
export const renderTableCheckbox = (itemId, selectedItems, onChange, mode, setSelectionMode) => {
    const isChecked = useMemo(() => {
        const includes = selectedItems.includes(itemId);
        // if we are in EXCLUDE or ALL mode, the logic is inverted – we check those that are NOT within the array
        return mode === TableSelectionMode.INCLUDE ? includes : !includes;
    }, [selectedItems, mode]);
    const handleChange = () => {
        if (mode === TableSelectionMode.ALL) {
            setSelectionMode(TableSelectionMode.EXCLUDE);
        }
        onChange(itemId);
    };
    return (
    /* stop propagation to still enable row click */
    _jsx(Box, { onClick: (e) => e.stopPropagation(), children: _jsx(Checkbox, { isChecked: isChecked, onChange: handleChange, size: "md" }) }));
};
