import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton, Table as TableComponent, TableContainer, Tbody, Td, Th, Thead, Tr, useTheme, } from "@chakra-ui/react";
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGINATION } from "~/consts/pagination";
import { TablePagination } from "./components/TablePagination/TablePagination";
import { TableSortingIcons } from "./components/TableSortingIcons/TableSortingIcons";
const emptyTableData = {
    data: [],
    pagination: {
        totalItems: 0,
        totalPages: 0,
    },
};
export const Table = ({ data = emptyTableData, columns, onRowClick, rowSelection, onRowSelectionChange, enableRowSelection = false, manualSorting = true, sorting, onSortingChange, manualPagination = true, pagination = DEFAULT_PAGINATION, onPaginationChange, getRowStyles, isLoading, isFetching, columnOrder = [], onColumnOrderChange, onColumnVisibilityChange, columnVisibility, }) => {
    var _a, _b, _c;
    const { textStyles } = useTheme();
    const { t } = useTranslation();
    const table = useReactTable({
        data: (_a = data.data) !== null && _a !== void 0 ? _a : [],
        columns,
        pageCount: (_c = (_b = data.pagination) === null || _b === void 0 ? void 0 : _b.totalPages) !== null && _c !== void 0 ? _c : -1,
        state: {
            columnVisibility,
            pagination,
            columnOrder,
            sorting,
            rowSelection,
        },
        getCoreRowModel: getCoreRowModel(),
        // row selection
        enableRowSelection,
        onRowSelectionChange,
        // sorting
        manualSorting,
        onSortingChange,
        getSortedRowModel: !manualSorting ? getSortedRowModel() : undefined,
        // pagination
        manualPagination,
        onPaginationChange,
        getPaginationRowModel: !manualPagination
            ? getPaginationRowModel()
            : undefined,
        meta: {
            getRowStyles,
        },
        // column sorting / hiding
        enableHiding: true,
        onColumnOrderChange,
        onColumnVisibilityChange,
    });
    return (_jsxs(Box, { position: "relative", children: [_jsx(Box, { id: "sorter-wrapper", position: "absolute", right: "0", top: "0" }), _jsx(Skeleton, { isLoaded: !isLoading, children: _jsx(TableContainer, { borderWidth: "1px", borderColor: "sky.20", borderRadius: "4px", children: _jsxs(TableComponent, { w: "full", style: { tableLayout: "fixed" }, children: [_jsx(Thead, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(Tr, { children: headerGroup.headers.map((header) => {
                                        var _a;
                                        return (_jsx(Th, { colSpan: header.colSpan, borderWidth: "0", borderBottomWidth: "1px", borderColor: "sky.20", backgroundColor: "sky.5", ...textStyles.labelUppercase, px: "4", py: "3", style: { width: (_a = header.column.columnDef.size) !== null && _a !== void 0 ? _a : "100%" }, children: header.isPlaceholder ? null : (_jsxs(Box, { display: "flex", justifyContent: header.column.id === "actions" ? "end" : "", alignItems: "center", cursor: header.column.getCanSort() ? "pointer" : "", userSelect: header.column.getCanSort() ? "none" : "auto", onClick: header.column.getToggleSortingHandler(), gap: "2", h: "full", children: [flexRender(header.column.columnDef.header, header.getContext()), header.column.getCanSort() && (_jsx(TableSortingIcons, { sort: header.column.getIsSorted() }))] })) }, header.id));
                                    }) }, headerGroup.id))) }), _jsxs(Tbody, { backgroundColor: "brand.white", children: [_jsx(Tr, { children: _jsx(Td, { p: "0", borderBottom: "none", colSpan: table.getAllColumns().length, id: "table-selection-portal" }) }), !data.data.length && !isLoading && (_jsx(Tr, { children: _jsx(Td, { w: "full", colSpan: table.getAllColumns().length, h: "96px", lineHeight: "96px", textAlign: "center", bg: "white", children: t("layout.table.emptyTablePlaceholder") }) })), table.getRowModel().rows.map((row, index, arr) => {
                                        var _a, _b;
                                        return (_jsx(Tr, { borderWidth: "0", borderBottomWidth: index < arr.length - 1 ? "1px" : "0px", borderColor: "grey.10", backgroundColor: "brand.white", _hover: {
                                                backgroundColor: onRowClick
                                                    ? "var(--chakra-colors-gray-5)"
                                                    : "var(--chakra-colors-brand-white)",
                                                cursor: onRowClick ? "pointer" : "auto",
                                            }, onClick: onRowClick ? () => onRowClick(row) : undefined, opacity: isFetching ? 0.25 : 1, transition: "opacity 0.5s, background-color 0.5s", style: (_b = (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.getRowStyles) === null || _b === void 0 ? void 0 : _b.call(_a, row), children: row.getVisibleCells().map((cell) => {
                                                return (_jsx(Td, { borderWidth: "0", px: "4", overflow: "hidden", py: "3", textStyle: "body", lineHeight: "6", children: _jsx(Box, { display: "flex", justifyContent: cell.column.id === "actions" ? "end" : "", alignItems: "center", h: "full", w: "full", pos: "relative", children: flexRender(cell.column.columnDef.cell, cell.getContext()) }) }, cell.id));
                                            }) }, row.id));
                                    })] })] }) }) }), _jsx(Skeleton, { isLoaded: !isLoading && !isFetching, children: _jsx(TablePagination, { table: table, pagination: pagination }) })] }));
};
