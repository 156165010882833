import cutOffInTheWarehouse from "./cut-off-in-the-warehouse";
import deliveryOfGoods from "./delivery-of-goods";
import durationUntilCustomsRelease from "./duration-until-customs-release";
import durationUntilCustomsReleaseTwo from "./duration-until-customs-release-two";
import exportEu from "./export-eu";
import importCh from "./import-ch";
import parcelDelivery from "./parcel-delivery";
import transport from "./transport";
const processOverviews = [
    cutOffInTheWarehouse,
    exportEu,
    durationUntilCustomsRelease,
    transport,
    importCh,
    durationUntilCustomsReleaseTwo,
    deliveryOfGoods,
    parcelDelivery,
];
export { hasAllFieldsFilled } from "../helpers";
export default processOverviews;
