import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { Box, Flex, Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { NarrowArrowLeftIcon } from "~/assets/icons/NarrowArrowLeftIcon";
import { NarrowArrowRightIcon } from "~/assets/icons/NarrowArrowRightIcon";
import { IconButton } from "~/components/IconButton/IconButton";
import { PAGINATION_OPTIONS } from "~/consts/pagination";
import { usePagination } from "~/hooks/usePagination";
export const TablePagination = ({ table, pagination, }) => {
    const { t } = useTranslation();
    const visiblePageRange = usePagination({
        pageCount: table.getPageCount(),
        pageIndex: pagination.pageIndex,
    });
    const handlePageSizeChange = (e) => {
        table.setPageSize(Number(e.target.value));
    };
    return (_jsxs(Box, { display: "flex", justifyContent: "space-between", mt: 6, textStyle: "body", color: "grey.60", borderTopWidth: "1px", borderTopColor: "sky.20", height: 14, children: [_jsx(Flex, { gap: "4", alignItems: "center", justifyContent: "center", flex: "1", children: _jsxs(Flex, { gap: "4", mr: "auto", alignItems: "center", children: [_jsx(Text, { flexShrink: 0, children: t("layout.table.rowsPerPage") }), _jsx(Select, { backgroundColor: "brand.white", onChange: handlePageSizeChange, defaultValue: pagination.pageSize, children: PAGINATION_OPTIONS.map((option) => (_jsx("option", { value: option, children: option }, `PAGE_SIZE_${option}`))) })] }) }), _jsx(Flex, { flex: "1", justifyContent: "center", children: _jsx(Flex, { children: visiblePageRange.map((pageIndex, i) => typeof pageIndex === "number" ? (_jsx(Text, { as: "button", onClick: () => table.setPageIndex(pageIndex), p: "4", mt: "1px", boxShadow: pagination.pageIndex === pageIndex
                            ? "0 -2px var(--chakra-colors-brand-mare)"
                            : "none", children: pageIndex + 1 }, `Page__${pageIndex}__${i}`)) : (_jsx(Text, { p: "4", mt: "1px", children: "..." }, `Page__Break__${pageIndex}__${i}`))) }) }), _jsx(Flex, { flex: "1", justifyContent: "center", alignItems: "center", children: _jsxs(Flex, { ml: "auto", gap: "4", children: [_jsx(IconButton, { onClick: () => table.previousPage(), isDisabled: !table.getCanPreviousPage(), children: _jsx(NarrowArrowLeftIcon, {}) }), _jsx(IconButton, { onClick: () => table.nextPage(), isDisabled: !table.getCanNextPage(), children: _jsx(NarrowArrowRightIcon, {}) })] }) })] }));
};
