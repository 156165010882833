import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.application_vat_uid_number",
        subtitle: "onboarding.powerOfAttorney.title",
        description: "onboarding.powerOfAttorney.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label3",
                type: "label",
                label: "onboarding.powerOfAttorney.downloadDocument",
            },
            {
                key: "label4",
                type: "label",
                label: [
                    "onboarding.powerOfAttorney.uploadDocument0",
                    {
                        displayLabel: "onboarding.powerOfAttorney.uploadDocument1",
                        strong: true,
                    },
                    "onboarding.powerOfAttorney.uploadDocument2",
                ],
            },
            {
                key: "files",
                type: "file",
                label: "onboarding.uploadFilesArea.files",
                multiple: true,
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        files: {
            type: "array",
            items: {
                type: "string",
            },
            // minItems: 1,
            uniqueItems: true,
        },
    },
    additionalProperties: false,
    required: ["files"],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
