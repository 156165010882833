import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import Select from "~/components/Select/Select";
import TextField from "~/components/TextField/TextField";
function Row({ label, children, }) {
    return (_jsxs(Flex, { justifyContent: "space-between", w: "full", children: [_jsx(Text, { width: "50%", children: label }), _jsx(Box, { width: "50%", children: children })] }));
}
export default function CalculationOfSecurityDeposit() {
    return (_jsxs(VStack, { children: [_jsx(Row, { label: "Sortiment", children: _jsx(Select, { name: "", options: {} }) }), _jsx(Row, { label: "Number of items:", children: _jsxs(Flex, { children: [_jsx(TextField, { name: "" }), _jsx(Text, { w: "100px", ml: "10px", children: "/ 60 days" })] }) }), _jsx(Row, { label: "Average weight per item", children: _jsxs(Flex, { children: [_jsx(TextField, { name: "" }), _jsx(Text, { w: "100px", ml: "10px", children: "KG" })] }) }), _jsx(Row, { label: "Avg. selling price / item in CHF (no VAT)", children: _jsxs(Flex, { children: [_jsx(TextField, { name: "" }), _jsx(Text, { w: "100px", ml: "10px", children: "CHF" })] }) }), _jsx(Row, { label: "Sortiment", children: _jsx(Text, { align: "right", children: "140,00 CHF" }) }), _jsx(Row, { label: "Customs fee / 60 days", children: _jsx(Text, { align: "right", children: "1.600,00 CHF" }) }), _jsx(Row, { label: "Vorsteuer / 60 days", children: _jsx(Text, { align: "right", children: "5.400,00 CHF" }) }), _jsx(Row, { label: "Vorsteuer / 60 days", children: _jsx(Text, { align: "right", children: "7.140,00 CHF" }) })] }));
}
