import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Flex, GridItem, IconButton, Menu, MenuButton, MenuItem, MenuList, } from "@chakra-ui/react";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useGetCurrentUserProfileQuery, useSignOutMutation, } from "~/api/hooks/users";
import Grid from "~/components/Grid/Grid";
import Icon from "~/components/Icon/Icon";
import Link from "~/components/Link/Link";
import { useSubscribe } from "~/components/Slots/Slots";
import TextButton from "~/components/TextButton/TextButton";
import { supportedLanguages } from "~/i18n";
import { useLogoutMutation } from "~/pages/auth/redux/auth.api";
export default memo(() => {
    const { t, i18n } = useTranslation();
    const { data: currentUser } = useGetCurrentUserProfileQuery();
    const signOutMutation = useSignOutMutation();
    const leftSlot = useSubscribe("left-nav");
    const rightSlot = useSubscribe("right-nav");
    const handleLogout = () => {
        useLogoutMutation();
        signOutMutation.mutate();
    };
    const menuItemStyles = {
        _hover: {
            textDecoration: "underline",
            background: "transparent",
        },
    };
    return (_jsx(Flex, { justifyContent: "center", as: "nav", width: "100%", h: "72px", px: "24px", children: _jsx(Box, { width: "100%", alignSelf: "center", children: _jsx(Grid, { children: _jsx(GridItem, { colStart: 1, colEnd: 13, children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", children: [_jsx(Box, { ref: leftSlot }), _jsxs(Flex, { gap: 3, alignItems: "center", children: [_jsx(Box, { ref: rightSlot }), _jsx(IconButton, { "aria-label": "notification", icon: _jsx(Icon, { name: "notification" }) }), _jsxs(Menu, { placement: "bottom-end", children: [_jsx(MenuButton, { children: _jsx(Avatar, { name: `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`, src: currentUser === null || currentUser === void 0 ? void 0 : currentUser.avatar }) }), _jsxs(MenuList, { boxShadow: "0 0 4px #000000ee", children: [_jsx(MenuItem, { as: "div", children: _jsx(Link, { to: "/profile", ...menuItemStyles, children: t("profile.myProfile") }) }), _jsx(MenuItem, { onClick: handleLogout, ...menuItemStyles, children: t("users.logout") }), _jsxs(MenuItem, { as: "div", children: ["Language (", supportedLanguages
                                                                .map((languageOption) => (_jsx(TextButton, { onClick: () => i18n.changeLanguage(languageOption), textTransform: "uppercase", fontWeight: "normal", ...(languageOption === i18n.resolvedLanguage
                                                                    ? {
                                                                        color: "#F48999",
                                                                    }
                                                                    : {}), children: languageOption })))
                                                                .reduce((acc, x) => acc === null ? (x) : (_jsxs(_Fragment, { children: [acc, " | ", x] })), null), ")"] })] })] })] })] }) }) }) }) }));
});
