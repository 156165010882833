import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Input, Text, useToast } from "@chakra-ui/react";
import { EImportStatus } from "@crossborderx/schemas-package";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import { Modal } from "~/components/Modal/Modal";
import { TableSelectionMode } from "~/types/table-selection.type";
import { useBulkUpdateArticleMutation } from "../../redux/customsClassification.api";
export const BulkUpdateTariffingCodeModal = ({ articleIds, showModal, handleHide, importMeta, selectionMode, itemCount, selectedTariffingCode, }) => {
    const { params: { id }, } = useMatch();
    const [code, setCode] = useState("");
    const { t } = useTranslation();
    const toast = useToast();
    const importId = Number(id);
    const isShipFrom = (importMeta === null || importMeta === void 0 ? void 0 : importMeta.status) === EImportStatus.IN_PROGRESS_SHIP_FROM;
    const [bulkPatchArticle, { isLoading }] = useBulkUpdateArticleMutation();
    const handleBulkPatchArticle = async () => {
        try {
            await bulkPatchArticle({
                type: selectionMode,
                importId,
                articleIds,
                ...(isShipFrom
                    ? { shipFromTariffingCode: code }
                    : { shipToTariffingCode: code }),
            });
            toast({
                title: t("customsClassificationTariffing.bulkTariffing.modal.success"),
                status: "success",
                duration: 3000,
            });
            handleHide();
        }
        catch (err) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    useEffect(() => {
        if (showModal) {
            setCode(selectedTariffingCode !== null && selectedTariffingCode !== void 0 ? selectedTariffingCode : "");
        }
    }, [showModal]);
    const articleCount = selectionMode === TableSelectionMode.INCLUDE
        ? articleIds.length
        : itemCount - articleIds.length;
    return (_jsx(Modal, { isOpen: showModal, onClose: handleHide, title: t("customsClassificationTariffing.bulkTariffing.modal.title"), primaryTitle: t("customsClassificationTariffing.bulkTariffing.modal.primaryCta"), primaryAction: handleBulkPatchArticle, primaryActionLoading: isLoading, children: _jsxs(Grid, { gap: "6", children: [_jsx(Text, { mt: "2", children: _jsx(Trans, { components: { strong: _jsx(Text, { as: "span", fontWeight: "700" }) }, i18nKey: "customsClassificationTariffing.bulkTariffing.modal.subtitle", values: {
                            count: articleCount,
                        } }) }), _jsxs(Box, { minW: "0", children: [_jsx(Text, { textStyle: "labelUppercase", mb: "2", children: isShipFrom
                                ? t("customsClassificationTariffing.bulkTariffing.modal.shipFromHeadline", {
                                    country: importMeta.shipFromCountry,
                                })
                                : t("customsClassificationTariffing.bulkTariffing.modal.shipToHeadline", {
                                    country: importMeta.shipToCountry,
                                }) }), _jsx(Input, { value: code, onChange: (e) => setCode(e.target.value), placeholder: !selectedTariffingCode
                                ? t("customsClassificationTariffing.bulkTariffing.modal.multipleValuesPlaceholder")
                                : "" })] }), _jsx(Banner, { text: t("customsClassificationTariffing.bulkTariffing.modal.undoneNote") })] }) }));
};
