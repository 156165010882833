import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAuthDataQuery } from "~/api/hooks/users";
import { ReactComponent as ClockIcon } from "~/assets/icons/clock.svg";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { getTask } from "~/pages/onboarding/CH/OnboardingTask/tasks";
import { fetchSavedFormById } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getSavedForm } from "~/store/selectors/formData.selectors";
export function OnboardingTask({ emptyFields }) {
    var _a, _b, _c, _d, _e;
    const dispatch = useAppDispatch();
    const { data: authData } = useAuthDataQuery();
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(fetchSavedFormById({
            id,
            flowId,
            pageSlug: "activestep",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const data = useSelector((s) => getSavedForm(s, "activestep", "administrativeProcesses"));
    if (!data || ((_a = data === null || data === void 0 ? void 0 : data.activestep) === null || _a === void 0 ? void 0 : _a.length) === 0)
        return null;
    const task = emptyFields.length > 0
        ? {
            hasTask: true,
            [AccountTypes.INTERNAL]: ["onboarding.tasks.mandatoryFieds"],
            [AccountTypes.CLIENT]: ["onboarding.tasks.mandatoryFieds"],
            [AccountTypes.PARTNER]: ["onboarding.tasks.mandatoryFieds"],
        }
        : getTask(data);
    if (!task.hasTask)
        return null;
    if (!((_b = authData === null || authData === void 0 ? void 0 : authData.user) === null || _b === void 0 ? void 0 : _b.userType))
        return null;
    const type = (_c = authData === null || authData === void 0 ? void 0 : authData.user) === null || _c === void 0 ? void 0 : _c.userType;
    if (((_d = task[type]) === null || _d === void 0 ? void 0 : _d.length) === 0)
        return null;
    return (_jsx(Box, { _hover: {
            background: "#CCE9F6",
            transition: "background 350ms linear",
        }, w: "full", bgColor: "white", minHeight: "43px", paddingTop: "10px", paddingBottom: "10px", boxShadow: "card", transition: "background 150ms linear", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(Flex, { w: "full", h: "full", justifyContent: "space-between", children: _jsx(Box, { marginLeft: "24px", marginRight: "24px", w: "100%", alignSelf: "center", children: _jsxs(HStack, { children: [_jsx(Box, { alignSelf: "center", paddingRight: "12px", children: _jsx(ClockIcon, { fill: "#ED8D99" }) }), (_e = task[type]) === null || _e === void 0 ? void 0 : _e.map((text, index) => {
                            var _a;
                            if (((_a = task[type]) === null || _a === void 0 ? void 0 : _a.length) === index + 1) {
                                return (_jsx(Text, { paddingTop: "8px", paddingBottom: "8px", children: t(text, { emptyFields: emptyFields.join(", ") }) }));
                            }
                            return (_jsx(Text, { paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid #E6F5FB", children: t(text) }));
                        })] }) }) }) }));
}
