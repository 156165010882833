import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, } from "@chakra-ui/react";
import { forwardRef, memo, useImperativeHandle, useRef, } from "react";
import { useTranslation } from "react-i18next";
import Image from "../Image/Image";
function Component({ name, label, value, defaultValue, onChange, error, showValidationIcon, placeholder, disabled, ...rest }, ref) {
    var _a;
    const innerRef = useRef(null);
    const { t } = useTranslation();
    useImperativeHandle(ref, () => innerRef.current);
    return (_jsxs(FormControl, { isInvalid: Boolean(error), ...rest, children: [label ? _jsx(FormLabel, { htmlFor: name, children: label }) : null, _jsxs(InputGroup, { size: "md", children: [_jsx(Input, { id: name, ref: innerRef, placeholder: placeholder, name: name, value: value, defaultValue: defaultValue, onChange: onChange, disabled: disabled, bgColor: "secondary.50", borderColor: "secondary.300", rounded: "none", h: "32px" }), showValidationIcon && (_jsxs(InputRightElement, { width: 10, h: "32px", children: [_jsx(Image, { name: "error-icon", alt: t("fieldValueIsInvalid"), display: error ? "block" : "none" }), _jsx(Image, { name: "check-icon", alt: t("fieldValueIsValid"), display: !error && ((_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.value) ? "block" : "none", mt: "1" })] }))] }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(FormErrorMessage, { children: error.message })) : null] }));
}
export default memo(forwardRef(Component));
