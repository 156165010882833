import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Skeleton, SkeletonCircle, Text, } from "@chakra-ui/react";
import { format } from "date-fns";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { useUpdateArticleNoteStatusByIdMutation } from "~/pages/customs-classification/redux/customsClassification.api";
import { useLazyGetUserByEmailQuery } from "~/pages/users/redux/users.api";
import { checkAccountType } from "~/utils/checkAccountType";
import { ArticleStatus, } from "~/pages/customs-classification/types/allArticles.type";
import { ArticleDrawerContext } from "./ImportArticleDrawer";
export const NoteItem = ({ note }) => {
    const { importId, articleId } = useContext(ArticleDrawerContext);
    const [getUser, { data: user, isFetching: isFetchingUser }] = useLazyGetUserByEmailQuery();
    const [updateNote, { isLoading }] = useUpdateArticleNoteStatusByIdMutation();
    const { t } = useTranslation();
    const { id: commentId, user: userEmail, status, value, createdAt } = note;
    const handleUpdateNoteStatus = async () => {
        await updateNote({
            importId,
            articleId,
            commentId,
            status: ArticleStatus.RESOLVED,
        });
    };
    useEffect(() => {
        if (userEmail) {
            getUser(userEmail);
        }
    }, [userEmail]);
    const userInitials = (user
        ? `${user.firstName} ${user.lastName}`
        : t("articles.detail.customsDeclarationAgent"))
        .split(" ")
        .map((name) => name[0])
        .slice(0, 2)
        .join("");
    return (_jsxs(Box, { w: "full", pb: "8", mb: "8", borderBottom: "1px solid", borderBottomColor: "grey.10", children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(SkeletonCircle, { isLoaded: !isFetchingUser, children: _jsx(Flex, { justifyContent: "center", alignItems: "center", minW: "8", minH: "8", cursor: "pointer", boxSizing: "content-box", rounded: "full", bgColor: "brand.mare", color: "white", fontSize: "sm", fontWeight: "500", children: userInitials.slice(0, 2) }) }), _jsxs(Box, { ml: "3", children: [_jsx(Skeleton, { isLoaded: !isFetchingUser, children: _jsx(Text, { textStyle: "label", children: user
                                        ? checkAccountType(AccountTypes.CLIENT)
                                            ? `${user.firstName} ${user.lastName[0]}.`
                                            : `${user.firstName} ${user.lastName}`
                                        : t("articles.detail.customsDeclarationAgent") }) }), _jsxs(Text, { textStyle: "bodySmall", color: "grey.60", children: [format(new Date(createdAt), "d.M.yyyy, HH:mm"), status === ArticleStatus.RESOLVED && (_jsxs(_Fragment, { children: [" · ", _jsx(Text, { as: "span", textStyle: "labelSmall", color: "success.500", children: t(`articles.status.${status}`) })] }))] })] })] }), _jsx(Text, { mt: "3", children: value }), status === ArticleStatus.UNRESOLVED &&
                !checkAccountType(AccountTypes.CLIENT) && (_jsx(Button, { variant: "secondary", mt: "4", onClick: handleUpdateNoteStatus, isLoading: isLoading, children: t("articles.detail.resolveNoteCTA") }))] }));
};
