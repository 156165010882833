import { categoriesFormNodesAndSchema, continuousProductClassificationFormNodesAndSchema, exportRegulationsFormNodesAndSchema, initialTariffingFormNodesAndSchema, overviewFormNodesAndSchema, tarifnummerVersandlandFormNodesAndSchema, tarifnummerZiellandFormNodesAndSchema, } from "../schemas/product-details";
export const PRODUCT_DETAILS = {
    product_details: {
        initial: "overview",
        id: "product_details",
        meta: {
            eventName: "PRODUCT_DETAILS",
            label: "onboarding.productDetails.title",
        },
        states: {
            overview: {
                meta: {
                    label: "onboarding.overview",
                    ...overviewFormNodesAndSchema,
                },
                on: {
                    SAVE: "categories",
                    NEXT: "categories",
                },
            },
            categories: {
                meta: {
                    label: "onboarding.productDetails.categories.title",
                    ...categoriesFormNodesAndSchema,
                },
                on: {
                    SAVE: "export_regulation",
                    NEXT: "export_regulation",
                },
            },
            export_regulation: {
                meta: {
                    label: "onboarding.productDetails.exportRegulation.title",
                    ...exportRegulationsFormNodesAndSchema,
                },
                on: {
                    SAVE: "tarifnummerVersandland",
                    NEXT: "tarifnummerVersandland",
                },
            },
            tarifnummerVersandland: {
                meta: {
                    label: "onboarding.productDetails.tariffNumberDestinationCountry",
                    ...tarifnummerVersandlandFormNodesAndSchema,
                },
                on: {
                    SAVE: "tarifnummerZielland",
                    NEXT: "tarifnummerZielland",
                },
            },
            tarifnummerZielland: {
                meta: {
                    label: "onboarding.productDetails.tariffNumberShippingCountry",
                    ...tarifnummerZiellandFormNodesAndSchema,
                },
                on: {
                    SAVE: "initialTarifierung",
                    NEXT: "initialTarifierung",
                },
            },
            initialTarifierung: {
                meta: {
                    label: "onboarding.productDetails.initialProductClassification.title",
                    ...initialTariffingFormNodesAndSchema,
                },
                on: {
                    SAVE: "kontinuierlicheTarifierung",
                    NEXT: "kontinuierlicheTarifierung",
                },
            },
            kontinuierlicheTarifierung: {
                meta: {
                    label: "onboarding.productDetails.countinousTariffing.title",
                    ...continuousProductClassificationFormNodesAndSchema,
                },
            },
            defineImportFile: {
                meta: {
                    label: "onboarding.productDetails.defineImportFile.title",
                },
                type: "final",
            },
        },
    },
};
