import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ServiceStatus } from "~/enums/service-status.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { useEditPartnerServiceMutation } from "~/pages/partners/redux/partners.api";
import { getCountryByIsoCode } from "~/utils/countries";
export const RemoveTariffingCountryModal = ({ handleClose, countryCode, shipDirection, service }) => {
    const { params: { id }, } = useMatch();
    const { t } = useTranslation();
    const [updateService, { isLoading: isUpdating }] = useEditPartnerServiceMutation();
    const isShipTo = shipDirection === ShipDirection.TO;
    const handleRemove = async () => {
        const countries = isShipTo
            ? service.serviceData.shipTo
            : service.serviceData.shipFrom;
        const shipCountries = countries.filter((country) => country !== countryCode);
        const shipTo = isShipTo ? shipCountries : service.serviceData.shipTo;
        const shipFrom = isShipTo ? service.serviceData.shipFrom : shipCountries;
        const body = {
            serviceData: {
                shipTo,
                shipFrom,
            },
            ...(![...shipTo, ...shipFrom].length
                ? { status: ServiceStatus.DISABLED }
                : {}),
        };
        await updateService({ id, serviceCode: ServiceCode.TARIFFING, body });
        // try {
        //         if ('data' in statusRes) {
        //           toast({
        //             title: t('partners.removeTariffingCountryModal.removeAndDisableSuccess'),
        //             status: 'success',
        //             duration: 3000
        //           });
        //           handleClose();
        //         }
        //       } catch (err) {
        //         toast({
        //           title: t('error.badRequest.removeTariffingCountryAndDisable'),
        //           status: 'error',
        //           duration: 3000
        //         });
        //       }
        //     } else {
        //       toast({
        //         title: t('partners.removeTariffingCountryModal.success'),
        //         status: 'success',
        //         duration: 3000
        //       });
        //       handleClose();
        //     }
        //   }
        // } catch (err) {
        //   // tbd: check this
        //   toast({
        //     title: t('partners.removeTariffingCountryModal.success'),
        //     status: 'success',
        //     duration: 3000
        //   });
        //   // toast({
        //   //   title: t('error.badRequest.removeTariffingsdCountry'),
        //   //   status: 'error',
        //   //   duration: 3000
        //   // });
        // }
    };
    return (_jsx(Modal, { isOpen: !!shipDirection, onClose: handleClose, title: t("partners.removeTariffingCountryModal.title", {
            direction: t(`partners.removeTariffingCountryModal.${isShipTo ? "shipTo" : "shipFrom"}`),
            country: getCountryByIsoCode(countryCode || ""),
        }), primaryTitle: t("partners.removeTariffingCountryModal.primaryTitle"), primaryAction: handleRemove, primaryVariant: "danger", primaryActionDisabled: isUpdating, primaryActionLoading: isUpdating, children: _jsx(Text, { mt: "2", color: "grey.60", children: t("partners.removeTariffingCountryModal.subtitle") }) }));
};
