import { useNavigate, useSearch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "~/consts/pagination";
export const useParamFilter = (name) => {
    const search = useSearch();
    const navigate = useNavigate();
    const [value, setValue] = useState(search[name] || "");
    useEffect(() => {
        navigate({
            search: (prev) => ({
                ...prev,
                [name]: value,
                page: DEFAULT_PAGINATION.pageIndex,
            }),
            replace: true,
        });
    }, [value]);
    return [value, setValue];
};
