import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from "@chakra-ui/react";
import { CountrySingleSelect } from "~/components/CountrySingleSelect/CountrySingleSelect";
import { EmployeeSelect } from "../EmployeeSelect/EmployeeSelect";
import { ServiceProviderSelect } from "../ServiceProviderSelect/ServiceProviderSelect";
import { ServiceSelection } from "../ServiceSelection/ServiceSelection";
export const ShipmentForm = ({ service, setService, selectedCountries = [], type, shipTo, isDisabled, }) => {
    const handleUpdateService = (key, value) => {
        if (service) {
            if (key === "shipFrom" && shipTo) {
                setService({
                    shipTo,
                    shipFrom: value,
                    providedServiceId: null,
                    responsibleEmployee: null,
                    services: [],
                });
            }
            else {
                setService({
                    ...service,
                    [key]: value,
                    // reset the employee if we change the service provider
                    ...(key === "providedServiceId" &&
                        value !== service.providedServiceId && {
                        responsibleEmployee: null,
                    }),
                    // reset the employee and service provider if we empty the services
                    ...(key === "services" &&
                        !value.length && {
                        providedServiceId: null,
                        responsibleEmployee: null,
                    }),
                });
            }
        }
        else if (shipTo) {
            setService({
                shipTo,
                shipFrom: value,
                providedServiceId: null,
                responsibleEmployee: null,
                services: [],
            });
        }
    };
    const isShipTo = type === "shipTo";
    const selectedCountry = service ? service[type] : "";
    return (_jsxs(Flex, { flexDirection: "column", rowGap: 8, children: [_jsx(CountrySingleSelect, { allSelectedCountries: selectedCountries, isDisabled: isDisabled || isShipTo, selectedCountry: selectedCountry || "", setSelectedCountry: !isShipTo ? (val) => handleUpdateService("shipFrom", val) : undefined }), selectedCountry && (_jsx(ServiceSelection, { services: (service === null || service === void 0 ? void 0 : service.services) || [], setServices: (services) => handleUpdateService("services", services) })), !!(service === null || service === void 0 ? void 0 : service.services.length) && (_jsx(ServiceProviderSelect, { handleSelect: (providerId) => handleUpdateService("providedServiceId", providerId), shipTo: isShipTo ? service.shipTo : undefined, shipFrom: !isShipTo ? service.shipFrom : undefined, selectedProviderId: service.providedServiceId })), !!(service === null || service === void 0 ? void 0 : service.providedServiceId) && (_jsx(EmployeeSelect, { handleSelect: (email) => handleUpdateService("responsibleEmployee", email), shipTo: isShipTo ? service.shipTo : undefined, shipFrom: !isShipTo ? service.shipFrom : undefined, providerId: service.providedServiceId, selectedEmployee: service.responsibleEmployee }))] }));
};
