import { userApi } from "~/pages/users/redux/users.api";
import { ONBOARDING_V1_BASEURL, ONBOARDING_V2_BASEURL, api } from "~/store/api";
export const clientServicesUrl = `${ONBOARDING_V1_BASEURL}/clients`;
export const clientBaseUrl = `${ONBOARDING_V2_BASEURL}/clients`;
export const clientsApi = api
    .enhanceEndpoints({
    addTagTypes: ["CLIENT", "CLIENT_DOCUMENTS", "CLIENT_SERVICES"],
})
    .injectEndpoints({
    endpoints: (builder) => ({
        getAllClients: builder.query({
            query: ({ page, size, searchQuery, orderBy, order, status, assigneeEmail, countries, }) => ({
                url: clientBaseUrl,
                params: {
                    page,
                    size,
                    ...(!!assigneeEmail && {
                        assigneeEmail: assigneeEmail.replace("%40", "@"),
                    }),
                    ...(!!status && { status }),
                    ...(!!countries && { countries }),
                    searchQuery,
                    orderBy,
                    order,
                },
            }),
            providesTags: ["CLIENT"],
        }),
        getClientById: builder.query({
            query: (id) => ({
                url: `${clientBaseUrl}/${id}`,
            }),
            providesTags: ["CLIENT"],
        }),
        getClientFilters: builder.query({
            query: () => ({
                url: `${clientBaseUrl}/filters`,
            }),
            providesTags: ["CLIENT"],
        }),
        updateClientById: builder.mutation({
            query: ({ id, ...body }) => ({
                url: `${clientBaseUrl}/${id}`,
                method: "PATCH",
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(clientsApi.util.invalidateTags(["CLIENT"]));
                    }
                }
                catch (err) { } // eslint-disable-line no-empty
            },
        }),
        getClientUsers: builder.query({
            query: (id) => ({
                url: `${clientBaseUrl}/${id}/users`,
            }),
        }),
        addClientTeamMember: builder.mutation({
            query: ({ id, body }) => ({
                method: "POST",
                url: `${clientBaseUrl}/${id}/users`,
                body,
            }),
        }),
        createClient: builder.mutation({
            query: (body) => ({
                url: `${clientBaseUrl}`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["CLIENT"],
        }),
        getClientResponsibles: builder.query({
            query: () => ({
                url: `${ONBOARDING_V2_BASEURL}/internal/users/success-managers`,
            }),
        }),
        getClientOnboardingFlows: builder.query({
            query: (id) => ({
                url: `${clientBaseUrl}/${id}/onboarding/flows`,
            }),
        }),
        updateClientUser: builder.mutation({
            query: ({ id, email, body }) => ({
                method: "PATCH",
                url: `${clientBaseUrl}/${id}/users/${email}`,
                body,
            }),
            async onQueryStarted({ id: partnerId, body }, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(userApi.util.updateQueryData("getUserByEmail", data.email, (user) => ({
                        ...user,
                        ...body,
                    })));
                    dispatch(clientsApi.util.updateQueryData("getClientUsers", partnerId, (users) => {
                        const user = users.find((u) => u.email === data.email);
                        if (user) {
                            users.splice(users.indexOf(user), 1, { ...user, ...body });
                        }
                    }));
                }
            },
        }),
        reinviteClientUser: builder.mutation({
            query: ({ id, email }) => ({
                method: "POST",
                url: `${clientBaseUrl}/${id}/users/${email}/invite`,
            }),
        }),
        getClientDocuments: builder.query({
            query: ({ id, params }) => ({
                url: `${clientServicesUrl}/${id}/documents`,
                params,
            }),
            providesTags: ["CLIENT_DOCUMENTS"],
        }),
        getClientDocumentOwners: builder.query({
            query: (id) => ({
                url: `${clientServicesUrl}/${id}/documents/owners`,
            }),
            providesTags: ["CLIENT_DOCUMENTS"],
        }),
        deleteDocumentById: builder.mutation({
            query: ({ clientId, documentId }) => ({
                url: `${clientServicesUrl}/${clientId}/documents/${documentId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CLIENT_DOCUMENTS"],
        }),
        getClientServices: builder.query({
            // we need to use serviceCode as a query param
            query: ({ clientId, service: serviceCode, shipTo }) => ({
                url: `${clientServicesUrl}/${clientId}/services`,
                params: {
                    shipTo,
                    serviceCode,
                },
            }),
        }),
        getSingleClientService: builder.mutation({
            // we need to use serviceCode as a query param
            query: ({ clientId, service: serviceCode, shipTo }) => ({
                url: `${clientServicesUrl}/${clientId}/services`,
                params: {
                    shipTo,
                    serviceCode,
                },
            }),
        }),
        removeClientService: builder.mutation({
            query: ({ clientId, clientServiceId }) => ({
                method: "DELETE",
                url: `${clientServicesUrl}/${clientId}/services/${clientServiceId}`,
            }),
            invalidatesTags: ["CLIENT_SERVICES"],
        }),
        createClientService: builder.mutation({
            query: ({ serviceCode, clientId, serviceData, providedServiceId, responsibleEmployee, }) => ({
                method: "POST",
                url: `${clientServicesUrl}/${clientId}/services`,
                body: {
                    serviceData,
                    serviceCode,
                    providedServiceId,
                    responsibleEmployee,
                },
            }),
        }),
        updateClientService: builder.mutation({
            query: ({ serviceId, serviceCode, clientId, serviceData, providedServiceId, responsibleEmployee, }) => ({
                method: "PATCH",
                url: `${clientServicesUrl}/${clientId}/services/${serviceId}`,
                body: {
                    serviceData,
                    serviceCode,
                    providedServiceId,
                    responsibleEmployee,
                },
            }),
        }),
        uploadClientDocument: builder.mutation({
            query: ({ clientId, body }) => ({
                method: "POST",
                url: `${clientServicesUrl}/${clientId}/documents/upload`,
                body,
            }),
        }),
        addShipmentDestination: builder.mutation({
            query: ({ id, body }) => ({
                method: "POST",
                url: `${clientBaseUrl}/${id}/shipment-destinations`,
                body,
            }),
            invalidatesTags: ["CLIENT"],
        }),
    }),
});
export const { useGetAllClientsQuery, useGetClientByIdQuery, useLazyGetClientByIdQuery, useGetClientFiltersQuery, useUpdateClientByIdMutation, useCreateClientMutation, useGetClientResponsiblesQuery, useGetClientUsersQuery, useAddClientTeamMemberMutation, useUpdateClientUserMutation, useReinviteClientUserMutation, useGetClientServicesQuery, useGetClientDocumentsQuery, useDeleteDocumentByIdMutation, useGetClientDocumentOwnersQuery, useLazyGetClientServicesQuery, useRemoveClientServiceMutation, useCreateClientServiceMutation, useUpdateClientServiceMutation, useUploadClientDocumentMutation, useAddShipmentDestinationMutation, useGetSingleClientServiceMutation, } = clientsApi;
export const authApiReducer = clientsApi.reducer;
export const authApiMiddleware = clientsApi.middleware;
