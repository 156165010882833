import i18n from "~/i18n";
export default function cutOffInTheWarehouse(flowFields) {
    var _a, _b, _c;
    const latestPickUpTime = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "latestPickUpTime")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    let when = "";
    if (latestPickUpTime) {
        when = i18n.t(`onboarding.outboundProcesses.originWarehouse.sections.pickupTimes.estimatedTime.selectOptions.${latestPickUpTime}`);
    }
    const who = ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" &&
        data.pageSlug === "contactInformation")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    const wareHouseCity = (_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "wareHouseCity" &&
        data.pageSlug === "deliveryFromWarehouseLocation")) === null || _c === void 0 ? void 0 : _c.fieldValue;
    let what = "";
    if (wareHouseCity) {
        what = i18n.t("processOverview.outboundProcess.cutOffInTheWareHouse.whatWareHouseCity", {
            city: wareHouseCity,
        });
    }
    const day = "01";
    return {
        when,
        who,
        what,
        day,
        icon: "cutoffwarehouse",
        title: i18n.t("processOverview.outboundProcess.cutOffInTheWareHouse.title"),
    };
}
