import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Link from "~/components/Link/Link";
import generateVatUrlSteps from "~/helpers/vat-process";
import routes from "~/layouts/onboarding/components/steps-list/routes";
import { patchFormField, resetProcess } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData } from "~/store/selectors/formData.selectors";
import { checkPageStatus } from "~/components/FormFooter/utils";
import Button from "../Button/Button";
const ResetModal = ({ id, isOpen, onClose, flowId, pageSlug, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const onClickHandler = () => {
        dispatch(resetProcess({ id, flowId, pageSlug }));
        dispatch(patchFormField({
            fieldName: "activestep",
            fieldValue: "0",
            id,
            flowId,
            pageSlug: "administrativeProcesses",
            processSlug: "activestep",
        }));
        navigate({
            to: `/clients/${id}/onboarding/${flowId}`,
        });
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: t("onboarding.actions.reset.modalTitle") }), _jsx(ModalCloseButton, {}), _jsxs(ModalBody, { children: [_jsx(Text, { children: t("onboarding.actions.reset.modalContent") }), _jsxs(Flex, { paddingTop: "10px", gap: 4, children: [_jsx(Button, { onClick: onClickHandler, children: t("onboarding.actions.reset.modalBtnOk") }), _jsx(Button, { onClick: onClose, children: t("onboarding.actions.reset.modalBtnCancel") })] })] })] })] }));
};
export default function FormFooter({ isNextDisabled, isBackVisible = true, isSaveForLaterVisible = false, isResetVisible = false, nextUrl, vatPage, processSlug = null, pageSlug = null, isCompleted, countryCode, sendPageAsSeen = false, }) {
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const formData = useSelector(getFormData);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { pathname } = useMatch();
    const dispatch = useAppDispatch();
    const [backButtonPath, setBackButtonPath] = React.useState(`/clients/${id}/onboarding/${flowId}`);
    const [nextButtonPath, setNextButtonPath] = React.useState(`/clients/${id}/onboarding/${flowId}`);
    const [isLast, setIsLast] = React.useState(true);
    const checkPath = () => {
        const pathData = pathname.split("/");
        let hasFundRoute = false;
        routes.forEach((mainRoute) => {
            var _a;
            if (`${mainRoute.path}` === `/${pathData[5]}`) {
                const childrens = mainRoute.title === "onboarding.steps.application_vat_uid_number" &&
                    ((_a = formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) === null || _a === void 0 ? void 0 : _a.fieldValue)
                    ? generateVatUrlSteps(formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId)
                    : mainRoute.childrens;
                const foundIndex = childrens === null || childrens === void 0 ? void 0 : childrens.findIndex((child) => `${child.path}` === `/${pathData[6]}`);
                if (foundIndex) {
                    let prevIndex = foundIndex - 1;
                    let nextIndex = foundIndex + 1;
                    if (countryCode === "CH" && pathData[6] === "export_EU")
                        nextIndex += 1;
                    if (countryCode === "CH" && pathData[6] === "customs-declaration")
                        prevIndex -= 1;
                    if (childrens === null || childrens === void 0 ? void 0 : childrens[foundIndex - 1]) {
                        setBackButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${childrens[prevIndex].path}`);
                    }
                    if (childrens === null || childrens === void 0 ? void 0 : childrens[foundIndex + 1]) {
                        hasFundRoute = true;
                        setNextButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${childrens[nextIndex].path}`);
                    }
                }
                else if (childrens === null || childrens === void 0 ? void 0 : childrens[1]) {
                    setNextButtonPath(`/clients/${id}/onboarding/${flowId}/${mainRoute.path}${childrens[1].path}`);
                    hasFundRoute = true;
                }
            }
        });
        setIsLast(!hasFundRoute);
    };
    useEffect(() => {
        if (nextUrl) {
            setNextButtonPath(nextUrl);
        }
        else
            checkPath();
    }, []);
    const nextButtonPathId = nextButtonPath.split("/").pop();
    return (_jsx(Flex, { direction: "row", p: "5", boxSizing: "border-box", mt: "5", w: "full", bgColor: "white", justifyContent: "space-between", alignItems: "center", children: _jsxs(Flex, { direction: "row", w: "full", justifyContent: "space-between", alignItems: "center", gap: "4", children: [_jsxs(Flex, { direction: "row", alignItems: "center", gap: "4", children: [isBackVisible && (_jsx(Button, { onClick: () => navigate({ to: backButtonPath }), variant: "secondary", colorScheme: "secondary", children: t("onboarding.actions.back") }, "back")), isSaveForLaterVisible && (_jsx(Link, { to: `/clients/${id}/onboarding/${flowId}`, children: t("onboarding.actions.saveForLater") })), isResetVisible && (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: onOpen, children: t("onboarding.actions.reset.buttonText") }), _jsx(ResetModal, { pageSlug: "administrativeProcesses", id: id, flowId: flowId, isOpen: isOpen, onClose: onClose })] }))] }), _jsx(Button, { isDisabled: isNextDisabled, onClick: async () => {
                        if (sendPageAsSeen) {
                            dispatch(patchFormField({
                                fieldName: "validationField",
                                fieldValue: "seen",
                                id,
                                flowId,
                                pageSlug,
                                processSlug,
                            }));
                        }
                        if (isNextDisabled)
                            return;
                        if (vatPage) {
                            dispatch(patchFormField({
                                fieldName: "activestep",
                                fieldValue: vatPage,
                                id,
                                flowId,
                                pageSlug: "activestep",
                                processSlug: "administrativeProcesses",
                            }));
                        }
                        if (vatPage && processSlug !== null && pageSlug !== null) {
                            const r = await checkPageStatus({
                                id,
                                flowId,
                                processSlug,
                                pageSlug,
                            });
                            if (r) {
                                navigate({ to: nextButtonPath });
                            }
                            else {
                                // TODO: make UI for incomplete page
                            }
                        }
                        else {
                            navigate({ to: nextButtonPath });
                        }
                    }, children: isCompleted
                        ? t("onboarding.goLive.buttonText")
                        : t(isLast && id === nextButtonPathId
                            ? "onboarding.actions.completeStep"
                            : "onboarding.actions.next") }, "next")] }) }));
}
