import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, Textarea, useToast } from "@chakra-ui/react";
import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useUpdateClientByIdMutation } from "../../redux/clients.api";
const TEXT_AREA_LIMIT = 300;
export const EditClientDescriptionModal = ({ isOpen, setIsOpen, client }) => {
    const [description, setDescription] = useState(client.description || "");
    const { t } = useTranslation();
    const toast = useToast();
    const [editClientDescription, { isLoading }] = useUpdateClientByIdMutation();
    const handleChange = (e) => {
        setDescription(e.target.value);
    };
    const handleSubmit = async () => {
        if (description.length >= 3) {
            const res = await editClientDescription({ id: client.id, description });
            if ("data" in res) {
                toast({
                    title: t("clients.editCompanyDescriptionModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
    };
    useEffect(() => {
        if (!isOpen)
            setDescription(client.description || "");
    }, [isOpen]);
    return (_jsx(Modal, { title: t("clients.editCompanyDescriptionModal.title"), isOpen: isOpen, onClose: () => setIsOpen(false), primaryAction: handleSubmit, primaryActionDisabled: description.length < 3 ||
            description.length > TEXT_AREA_LIMIT ||
            client.description === description, primaryActionLoading: isLoading, primaryTitle: t("basics.save"), size: "lg", children: _jsxs(Box, { display: "flex", flexDir: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("clients.editCompanyDescriptionModal.textAreaLabel") }), _jsx(Textarea, { value: description, onChange: handleChange, maxLength: TEXT_AREA_LIMIT, resize: "none" }), _jsx(Text, { color: "grey.60", children: t("clients.editCompanyDescriptionModal.nCharactersLeft", {
                        n: TEXT_AREA_LIMIT - description.length,
                    }) })] }) }));
};
