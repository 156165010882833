import { z } from "zod";
import { Option, PhoneCodeOption } from "~/pages/partners/schemas/schemas";
export const EditUserInfoValidationSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    position: z.string(),
    department: z.string(),
    phoneCode: PhoneCodeOption.optional().nullable(),
    phoneNumber: z.string().optional(),
});
export const EditUserGroupsValidationSchema = z.object({
    groups: Option.array(),
});
export const EditUserInfoSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    position: z.string(),
    department: z.string(),
    groups: z.array(z.string()).optional(),
    mobilePhone: z.string().optional(),
});
