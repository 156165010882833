import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returnProcessing.title",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "label2",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnProcessing.returnIdentification",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "system-for-identification",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.whichSystemForIdentification.question",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "system-for-identification",
                                        label: "onboarding.inboundProcesses.returnProcessing.whichSystemForIdentification.visMagic",
                                        value: "vis-magic-as400",
                                        forKey: "system-for-identification",
                                    },
                                    {
                                        type: "radio",
                                        key: "system-for-identification",
                                        label: "onboarding.inboundProcesses.returnProcessing.whichSystemForIdentification.otherSystem",
                                        value: "other",
                                        forKey: "system-for-identification",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        key: "label10",
                                        type: "text",
                                        isTextarea: true,
                                        label: "onboarding.inboundProcesses.returnProcessing.whichSystemForIdentification.system",
                                    },
                                ],
                                rule: {
                                    effect: "SHOW",
                                    targetKey: "system-for-identification",
                                    targetValue: "other",
                                },
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 2,
                        },
                        groupType: "radio",
                        forKey: "barcode",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.question",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "barcode",
                                        label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.ean18",
                                        value: "EAN18",
                                        forKey: "barcode",
                                    },
                                    {
                                        type: "radio",
                                        key: "barcode",
                                        label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.qrCode",
                                        value: "QR code",
                                        forKey: "barcode",
                                    },
                                    {
                                        type: "radio",
                                        key: "barcode",
                                        label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.other",
                                        value: "other",
                                        forKey: "barcode",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        key: "identification-method",
                                        type: "text",
                                        isTextarea: true,
                                        label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.identificationMethod",
                                    },
                                ],
                                rule: {
                                    effect: "SHOW",
                                    targetKey: "barcode",
                                    targetValue: "other",
                                },
                            },
                            {
                                key: "example",
                                type: "file",
                                label: "onboarding.inboundProcesses.returnProcessing.howIdentifyTheProduct.uploadAnExample",
                                multiple: false,
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "how_identify_product",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.howIdentifyTheProduct.question",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "retour-label",
                                        label: "onboarding.inboundProcesses.returnProcessing.howIdentifyTheProduct.retourLabel",
                                        value: "retour-label",
                                        forKey: "how_identify_product",
                                    },
                                    {
                                        type: "radio",
                                        key: "care-label",
                                        label: "onboarding.inboundProcesses.returnProcessing.howIdentifyTheProduct.careLabel",
                                        value: "care-label",
                                        forKey: "how_identify_product",
                                    },
                                    {
                                        type: "radio",
                                        key: "hangtag",
                                        label: "onboarding.inboundProcesses.returnProcessing.howIdentifyTheProduct.hangtag",
                                        value: "hangtag",
                                        forKey: "how_identify_product",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "products_not_identified",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.productsNotIdentified.question",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "capture-ms-clarify",
                                        label: "onboarding.inboundProcesses.returnProcessing.productsNotIdentified.captureInMSClarify",
                                        value: "capture-ms-clarify",
                                        forKey: "products_not_identified",
                                    },
                                    {
                                        type: "radio",
                                        key: "capture-own-system",
                                        label: "onboarding.inboundProcesses.returnProcessing.productsNotIdentified.captureInOwnSystem",
                                        value: "capture-own-system",
                                        forKey: "products_not_identified",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        key: "identification-method",
                                        type: "text",
                                        isTextarea: true,
                                        label: "onboarding.inboundProcesses.returnProcessing.productsNotIdentified.nameOfSystem",
                                    },
                                ],
                                rule: {
                                    effect: "SHOW",
                                    targetKey: "products_not_identified",
                                    targetValue: "capture-own-system",
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h2",
                label: "onboarding.inboundProcesses.returnProcessing.returnQualityCheck",
            },
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 3,
                },
                groupType: "radio",
                forKey: "check-quality-of-goods",
                elements: [
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnProcessing.wantUsToDoQualityCheck",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pb: 2,
                        },
                        elements: [
                            {
                                type: "radio",
                                key: "check-quality-of-goods",
                                label: "onboarding.actions.yes",
                                value: "yes",
                                forKey: "check-quality-of-goods",
                            },
                            {
                                type: "radio",
                                key: "check-quality-of-goods",
                                label: "onboarding.actions.no",
                                value: "no",
                                forKey: "check-quality-of-goods",
                            },
                        ],
                    },
                    {
                        key: "instructions",
                        type: "file",
                        label: "onboarding.inboundProcesses.returnProcessing.uploadInstructionsCheckGoods",
                        multiple: false,
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                type: "layout",
                                direction: "vertical",
                                groupType: "radio",
                                forKey: "classify_goods",
                                elements: [
                                    {
                                        key: "label3",
                                        type: "label",
                                        label: "onboarding.inboundProcesses.returnProcessing.classifyGoods",
                                    },
                                    {
                                        type: "layout",
                                        direction: "vertical",
                                        elements: [
                                            {
                                                type: "radio",
                                                key: "classify_goods_yes",
                                                label: "onboarding.actions.yes",
                                                value: "yes",
                                                forKey: "classify_goods",
                                            },
                                            {
                                                type: "radio",
                                                key: "classify_goods_no",
                                                label: "onboarding.actions.no",
                                                value: "no",
                                                forKey: "classify_goods",
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                groupType: "radio",
                                forKey: "physically_separated_goods",
                                elements: [
                                    {
                                        key: "label3",
                                        type: "label",
                                        label: "onboarding.inboundProcesses.returnProcessing.goodsPhysicallySeparated",
                                    },
                                    {
                                        type: "layout",
                                        direction: "vertical",
                                        elements: [
                                            {
                                                type: "radio",
                                                key: "who_provides_printers_yes",
                                                label: "onboarding.actions.yes",
                                                value: "yes",
                                                forKey: "physically_separated_goods",
                                            },
                                            {
                                                type: "radio",
                                                key: "who_provides_printers_no",
                                                label: "onboarding.actions.no",
                                                value: "no",
                                                forKey: "physically_separated_goods",
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                groupType: "radio",
                                forKey: "reason_for_return",
                                elements: [
                                    {
                                        key: "label3",
                                        type: "label",
                                        label: "onboarding.inboundProcesses.returnProcessing.reasonForReturnNecessary",
                                    },
                                    {
                                        type: "layout",
                                        direction: "vertical",
                                        elements: [
                                            {
                                                type: "radio",
                                                key: "reason_for_return_yes",
                                                label: "onboarding.actions.yes",
                                                value: "yes",
                                                forKey: "reason_for_return",
                                            },
                                            {
                                                type: "radio",
                                                key: "reason_for_return_no",
                                                label: "onboarding.actions.no",
                                                value: "delivered-to",
                                                forKey: "reason_for_return",
                                            },
                                        ],
                                    },
                                    {
                                        type: "layout",
                                        direction: "vertical",
                                        options: {
                                            pt: 2,
                                            gap: 3,
                                        },
                                        elements: [
                                            {
                                                key: "reason-1",
                                                type: "text",
                                                label: "onboarding.inboundProcesses.returnProcessing.reason",
                                            },
                                            {
                                                key: "reason-2",
                                                type: "text",
                                                label: "onboarding.inboundProcesses.returnProcessing.reason",
                                            },
                                            {
                                                key: "reasons",
                                                type: "customComponent",
                                                componentName: "base.IncrementedControlsArray",
                                                componentProps: {
                                                    controlNodes: [
                                                        {
                                                            key: "reason",
                                                            type: "text",
                                                            label: "onboarding.inboundProcesses.returnProcessing.reason",
                                                        },
                                                    ],
                                                    incrementerButtonNode: {
                                                        key: "add-reason",
                                                        type: "button",
                                                        variant: "text",
                                                        label: "onboarding.inboundProcesses.returnProcessing.addAnotherReason",
                                                    },
                                                    decrementerButtonNode: {
                                                        key: "remove-reason",
                                                        type: "button",
                                                        variant: "error",
                                                        label: "onboarding.inboundProcesses.returnProcessing.removeReason",
                                                    },
                                                    maxControlNodes: 6,
                                                },
                                            },
                                        ],
                                        rule: {
                                            effect: "SHOW",
                                            targetKey: "reason_for_return",
                                            targetValue: "yes",
                                        },
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "check-quality-of-goods",
                            targetValue: "yes",
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h2",
                label: "onboarding.inboundProcesses.returnProcessing.returnProcessing",
            },
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 2,
                },
                groupType: "radio",
                forKey: "how-goods-should-be-packed",
                elements: [
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            w: "full",
                            gap: 3,
                        },
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.goodsPack.question",
                            },
                            {
                                type: "radio",
                                key: "original",
                                label: "onboarding.inboundProcesses.returnProcessing.goodsPack.original",
                                value: "original",
                                forKey: "how-goods-should-be-packed",
                            },
                            {
                                type: "radio",
                                key: "plastic",
                                label: "onboarding.inboundProcesses.returnProcessing.goodsPack.plasticFoil",
                                value: "plastic",
                                forKey: "how-goods-should-be-packed",
                            },
                            {
                                type: "radio",
                                key: "polybag",
                                label: "onboarding.inboundProcesses.returnProcessing.goodsPack.polybag",
                                value: "polybag",
                                forKey: "how-goods-should-be-packed",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "damaged_goods_disposed",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.damagedGoodsDisposed",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "damaged_goods_disposed_yes",
                                        label: "onboarding.actions.yes",
                                        value: "yes",
                                        forKey: "damaged_goods_disposed",
                                    },
                                    {
                                        type: "radio",
                                        key: "damaged_goods_disposed_no",
                                        label: "onboarding.actions.no",
                                        value: "no",
                                        forKey: "damaged_goods_disposed",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "refresh_products",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.productRefreshedOrReady",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "refresh_products_yes",
                                        label: "onboarding.actions.yes",
                                        value: "yes",
                                        forKey: "refresh_products",
                                    },
                                    {
                                        type: "radio",
                                        key: "refresh_products_no",
                                        label: "onboarding.actions.no",
                                        value: "no",
                                        forKey: "refresh_products",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "file",
                                        key: "refresh_products_file",
                                        label: "onboarding.inboundProcesses.returnProcessing.goodsShouldBeRefurbished",
                                        multiple: false,
                                    },
                                ],
                                rule: {
                                    effect: "SHOW",
                                    targetKey: "refresh_products",
                                    targetValue: "yes",
                                },
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "new_labels_printed",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.newLabelsBePrinted",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "new_labels_printed_yes",
                                        label: "onboarding.actions.yes",
                                        value: "yes",
                                        forKey: "new_labels_printed",
                                    },
                                    {
                                        type: "radio",
                                        key: "new_labels_printed_no",
                                        label: "onboarding.actions.no",
                                        value: "no",
                                        forKey: "new_labels_printed",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "need_printers",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.msDirectNeedPrinters",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "need_printers_yes",
                                        label: "onboarding.actions.yes",
                                        value: "yes",
                                        forKey: "need_printers",
                                    },
                                    {
                                        type: "radio",
                                        key: "need_printers_no",
                                        label: "onboarding.actions.no",
                                        value: "no",
                                        forKey: "need_printers",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        groupType: "radio",
                        forKey: "who_provides_printers",
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnProcessing.printersProvider.question",
                            },
                            {
                                type: "layout",
                                direction: "vertical",
                                elements: [
                                    {
                                        type: "radio",
                                        key: "who_provides_printers",
                                        label: "onboarding.inboundProcesses.returnProcessing.printersProvider.acquisitionBy",
                                        value: "acquisition-by",
                                        forKey: "who_provides_printers",
                                    },
                                    {
                                        type: "radio",
                                        key: "who_provides_printers",
                                        label: "onboarding.inboundProcesses.returnProcessing.printersProvider.deliveredTo",
                                        value: "delivered-to",
                                        forKey: "who_provides_printers",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
