import { authReducer, authSlice } from "~/pages/auth/redux/auth.slice";
import { api } from "~/store/api";
import { DocumentsDataReducer } from "./documentsData.reducer";
import { FormDataReducer } from "./formData.reducer";
import { FormErrorsReducer } from "./formErrors.reducer";
import { StatusesReducer } from "./statuses.reducer";
export const reducers = {
    statuses: StatusesReducer,
    formData: FormDataReducer,
    formErrors: FormErrorsReducer,
    documentsData: DocumentsDataReducer,
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authReducer,
};
