import { AUTH_V1_BASEURL, api } from "~/store/api";
import { setUser } from "./auth.slice";
export const authBaseUrl = AUTH_V1_BASEURL;
export const authApi = api
    .enhanceEndpoints({ addTagTypes: ["ME"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => ({
                url: `${authBaseUrl}/profile/me`,
            }),
            providesTags: ["ME"],
        }),
        updateMe: builder.mutation({
            query: (body) => ({
                method: "PATCH",
                url: `${authBaseUrl}/profile/me`,
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    const newUser = { ...data, ...body };
                    dispatch(setUser(newUser));
                }
            },
        }),
        getLegacyToken: builder.query({
            query: () => ({
                method: "POST",
                url: `${authBaseUrl}/profile/me/legacy-token`,
            }),
        }),
        getGroups: builder.mutation({
            query: (accountType) => ({
                url: `${authBaseUrl}/groups`,
                params: {
                    accountType,
                },
            }),
        }),
        getMyPermissions: builder.query({
            query: () => ({
                url: `${authBaseUrl}/permissions/me`,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                method: "POST",
                url: `${authBaseUrl}/logout`,
            }),
        }),
    }),
});
export const { useUpdateMeMutation, useLazyGetMeQuery, useLazyGetLegacyTokenQuery, useGetGroupsMutation, useLogoutMutation, useLazyGetMyPermissionsQuery, } = authApi;
