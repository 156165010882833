import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "~/components/Avatar/Avatar";
import { Card } from "~/components/Card/Card";
import { EditIcon } from "~/components/EditIcon/EditIcon";
import { EditUserInfoModal } from "~/components/EditUserInfoModal/EditUserInfoModal";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
export const ProfilePage = () => {
    const [showEditModal, setShowEditModal] = useState(false);
    const { user } = useAppSelector(authSelector);
    const { t } = useTranslation();
    const { firstName, lastName, email, mobilePhone, jobTitle, department } = user || {};
    return user ? (_jsxs(_Fragment, { children: [_jsxs(Box, { paddingX: 10, paddingY: 8, children: [_jsx(Box, { mb: 6, children: _jsx(Text, { fontSize: "12", children: t("profile.breadcrumbTitle") }) }), _jsxs(Flex, { alignItems: "center", children: [_jsx(Flex, { justifyContent: "center", alignItems: "center", borderRadius: "full", bgSize: "cover", bgPos: "center", w: "72px", h: "72px", mr: 6, border: "1px solid", borderColor: "white", transitionDuration: "200ms", children: _jsx(Avatar, { user: user, size: 72 }) }, `user-item-${email}`), _jsxs(Box, { children: [_jsx("h1", { children: firstName && lastName
                                            ? [firstName, lastName].join(" ")
                                            : email === null || email === void 0 ? void 0 : email.split("@")[0] }), _jsx(Text, { px: 3, mt: 1, textTransform: "uppercase", color: "brand.white", fontWeight: "500", backgroundColor: "success.500", borderRadius: "full", fontSize: "sm", height: "28px", lineHeight: "28px", display: "inline-block", border: "1px solid", borderColor: "success.500", children: t("profile.activeLabel") })] })] }), _jsxs(Flex, { children: [_jsxs(Box, { flex: 1, children: [_jsxs(Flex, { justifyContent: "space-between", mb: 3, mt: 10, children: [_jsx(Text, { textStyle: "h4", mr: 3, children: t("profile.informationTitle") }), _jsx(EditIcon, { onClick: () => setShowEditModal(true) })] }), _jsx(Card, { children: _jsxs(Grid, { templateColumns: "1fr 1fr", columnGap: 4, rowGap: 8, children: [_jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("profile.positionLabel") }), _jsx(Text, { color: !jobTitle ? "grey.40" : "", children: jobTitle || t("profile.notSetPlaceholder") })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("profile.departmentLabel") }), _jsx(Text, { color: !department ? "grey.40" : "", children: department || t("profile.notSetPlaceholder") })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("profile.emailLabel") }), _jsx(Text, { children: email })] }), _jsxs(GridItem, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: 2, children: t("profile.phoneLabel") }), _jsx(Text, { color: !mobilePhone ? "grey.40" : "", children: mobilePhone || t("profile.notSetPlaceholder") })] })] }) })] }), _jsx(Box, { w: "detailPageSideSection", flexShrink: 0, ml: 24, children: " " })] })] }), _jsx(EditUserInfoModal, { isOpen: showEditModal, setIsOpen: setShowEditModal, user: user })] })) : null;
};
