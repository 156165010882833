import { jsx as _jsx } from "react/jsx-runtime";
import { formNodeFilters } from "@crossborderx/onboarding-machine";
import { GroupLayoutComponent } from "~/components/GeneratedForm/FormComponents/group-layout";
import { LayoutComponent } from "../FormComponents";
import { renderElement } from "./element";
export const renderNode = (node) => {
    const isLayout = formNodeFilters.isLayout(node);
    const isGroupLayout = isLayout && formNodeFilters.isGroupLayout(node);
    if (isGroupLayout)
        return (_jsx(GroupLayoutComponent, { ...node, children: node.elements.map(renderNode) }));
    if (isLayout)
        return (_jsx(LayoutComponent, { ...node, children: node.elements.map(renderNode) }));
    return renderElement(node);
};
