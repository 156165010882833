import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from "@chakra-ui/react";
import { useNavigate, useSearch } from "@tanstack/react-location";
import { useEffect, useState } from "react";
export const TabNavigation = ({ tabs, }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const search = useSearch();
    const navigate = useNavigate();
    const handleSwitchTab = (i) => {
        setTabIndex(i);
        navigate({
            search: () => ({
                tab: tabs[i].value,
            }),
        });
    };
    useEffect(() => {
        if (search.tab) {
            const foundTab = tabs.findIndex((tab) => tab.value === search.tab);
            if (foundTab !== -1)
                setTabIndex(foundTab);
        }
    }, [search.tab]);
    return (_jsxs(Tabs, { index: tabIndex, onChange: handleSwitchTab, isLazy: true, children: [_jsx(TabList, { borderBottomColor: "sky.20", borderBottomWidth: "1px", children: tabs.map(({ title, badge, value }) => {
                    const hasWideBadge = badge ? badge > 9 : false;
                    return (_jsxs(Tab, { pt: "0", pb: "10px", px: "0", transitionDuration: "0s", mr: "8", position: "relative", top: "-1px", color: "grey.60", borderBottomWidth: "2px", borderBottomColor: "transparent", _focus: { borderBottomColor: "brand.mare" }, _selected: {
                            fontWeight: 700,
                            color: "brand.mare",
                            borderBottomColor: "brand.mare",
                            borderBottomWidth: "2px",
                        }, display: "flex", alignItems: "center", justifyContent: "center", gap: 2, children: [title, badge && (_jsx(Center, { backgroundColor: "danger.500", borderRadius: "full", w: hasWideBadge ? "" : 5, px: hasWideBadge ? 1.5 : "", children: _jsx(Text, { textStyle: "bodySmall", color: "brand.white", children: badge > 99 ? "99+" : badge }) }))] }, `tab-navigation-tab--${value}`));
                }) }), _jsx(TabPanels, { children: tabs.map(({ value, element }) => (_jsx(TabPanel, { p: 0, children: element }, `tab-navigation-panel--${value}`))) })] }));
};
