import { Store } from "~/store/store";
export const hasPermission = (permissions) => {
    const userPermissions = Store.getState().auth.permissions;
    if (Array.isArray(permissions)) {
        let isIncluded = false;
        permissions.forEach((p) => {
            if (userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.includes(p))
                isIncluded = true;
        });
        return isIncluded;
    }
    return userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.includes(permissions);
};
