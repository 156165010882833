import i18n from "~/i18n";
export default function cutOffInTheWarehouse(flowFields) {
    var _a, _b, _c, _d, _e, _f, _g;
    const pickUpFrequency = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "pickUpFrequency" && data.pageSlug === "returnClosure")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    let when = "";
    if (pickUpFrequency === "after_a_certain_time_window") {
        const frequency = (_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "frequency" && data.pageSlug === "returnClosure")) === null || _b === void 0 ? void 0 : _b.fieldValue;
        if (frequency === "monthly") {
            when = i18n.t("onboarding.forms.frequency.monthly");
        }
        if (frequency === "daily") {
            when = i18n.t("onboarding.forms.frequency.weekly");
        }
    }
    if (pickUpFrequency === "when_a_minimum_quantity_of_pallets_is_reached") {
        const quantityOfPallet = (_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "quantityOfPallet" &&
            data.pageSlug === "returnClosure")) === null || _c === void 0 ? void 0 : _c.fieldValue;
        if (quantityOfPallet) {
            when = `${quantityOfPallet} Pallets`;
        }
    }
    let who = "";
    const whoHandlesReturns = (_d = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whoHandlesReturns" && data.pageSlug === "returns")) === null || _d === void 0 ? void 0 : _d.fieldValue;
    if (whoHandlesReturns === "ms-direct") {
        who = i18n.t("processOverview.msDirect");
    }
    if (whoHandlesReturns === "we-ourselves") {
        who =
            ((_e = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" &&
                data.pageSlug === "contactInformation")) === null || _e === void 0 ? void 0 : _e.fieldValue) || "";
    }
    if (whoHandlesReturns === "other-patners") {
        who =
            ((_f = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returns")) === null || _f === void 0 ? void 0 : _f.fieldValue) || "";
    }
    let what = "";
    if (whoHandlesReturns === "ms-direct") {
        what = `${i18n.t("processOverview.inboundProcess.cutOffInTheWareHouse.cutOff")} <br> ${i18n.t("processOverview.inboundProcess.cutOffInTheWareHouse.sendingCsv")} <br>`;
        const emails = (_g = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "emails" && data.pageSlug === "returnClosure")) === null || _g === void 0 ? void 0 : _g.fieldValue;
        if (emails) {
            what += `${i18n.t("processOverview.inboundProcess.cutOffInTheWareHouse.avisMail", {
                emails: JSON.parse(emails).join(", "),
            })}`;
        }
    }
    if (whoHandlesReturns &&
        ["we-ourselves", "other-patners"].includes(whoHandlesReturns)) {
        what = i18n.t("processOverview.inboundProcess.cutOffInTheWareHouse.shippingCsv");
    }
    return {
        when,
        who,
        what,
        icon: "cutoffwarehouse",
        title: i18n.t("processOverview.inboundProcess.cutOffInTheWareHouse.title"),
        day: "1",
    };
}
