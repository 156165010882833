import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Collapse, HStack, ListItem, Text, UnorderedList, } from "@chakra-ui/react";
import { useLocation, useMatch, useNavigate } from "@tanstack/react-location";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import Image from "~/components/Image/Image";
import generateVatUrlSteps from "~/helpers/vat-process";
import { useGetOnboardingStatusQuery } from "~/pages/onboarding/redux/onboarding.api";
import { OnboardingStepStatus, } from "~/pages/onboarding/types";
// import { useAppSelector } from '~/store/hooks';
// import { authSelector } from '~/bb-pages/auth/redux/auth.slice';
import { getFormData } from "~/store/selectors/formData.selectors";
import routes from "./routes";
function Step({ stateName, route, isStepActive, disabled, }) {
    const { t } = useTranslation();
    const { params: { id, flowId }, } = useMatch();
    const navigate = useNavigate();
    return (_jsx(HStack, { w: "full", minH: "48px", fontSize: "larger", cursor: "pointer", transition: "0.2s", px: !isStepActive ? "5" : 0, onClick: () => {
            if (!disabled)
                navigate({ to: `/clients/${id}/onboarding/${flowId}/${route}` });
        }, _hover: {
            bgColor: "secondary.100",
        }, margin: "0", padding: "0", bgColor: isStepActive ? "secondary.100" : "white", children: _jsx(Text, { w: "full", pb: isStepActive ? 3 : 0, mx: isStepActive ? 5 : 0, py: "3", borderBottomColor: "secondary.100", borderBottomWidth: "thin", borderBottomStyle: "solid", margin: "0", paddingLeft: "10px", children: t(stateName) }) }, `onboarding.${stateName}`));
}
function SubStep({ title, path, status, }) {
    const { t } = useTranslation();
    const { pathname } = useLocation().current;
    const { params: { id, flowId }, } = useMatch();
    const navigate = useNavigate();
    const link = `/clients/${id}/onboarding/${flowId}${path}`;
    const isActiveOnboardingStep = pathname === link;
    let icon = "";
    if (status === OnboardingStepStatus.COMPLETED) {
        icon = "onboarding-step-done-icon";
    }
    else if (status === OnboardingStepStatus.IN_PROGRESS) {
        icon = "onboarding-step-started-icon";
    }
    return (_jsx(ListItem, { cursor: "pointer", px: "5", w: "full", fontWeight: isActiveOnboardingStep ? "bold" : "normal", bgColor: isActiveOnboardingStep ? "#6B8CA3" : "white", color: isActiveOnboardingStep ? "white" : "black", padding: "0", fontSize: "md", transition: "0.2s", display: "flex", alignItems: "center", position: "relative", _hover: {
            bgColor: "secondary.100",
            color: "black",
        }, onClick: () => {
            navigate({ to: link });
        }, children: _jsxs(Text, { w: "full", pl: "5", h: "40px", display: "flex", alignItems: "center", borderBottomColor: "secondary.100", borderBottomWidth: "thin", borderBottomStyle: "solid", justifyContent: "space-between", children: [t(title), status !== OnboardingStepStatus.PENDING && icon && (_jsx(Image, { name: icon, alt: "step-icon", w: "24px", style: { marginRight: 20, opacity: 1 } }))] }) }, `onboarding.${path}`));
}
const transformDataForStatus = (data) => {
    const newData = {};
    data.forEach((process) => {
        newData[process.processSlug] = {
            status: process.status,
            pageSlugs: process.pageSlugs.reduce((acc, currentValue) => {
                acc[currentValue.pageSlug] = currentValue.status;
                return acc;
            }, Object.create({})),
        };
    });
    return newData;
};
function StepsList({ formStepKey, countryCode }) {
    const { t } = useTranslation();
    const { pathname } = useLocation().current;
    const { params: { id, flowId }, } = useMatch();
    const clientId = Number(id);
    const [, setVatSubStep] = React.useState([]);
    const formData = useSelector(getFormData);
    // const { user } = useAppSelector(authSelector);
    // const { accountId } = user || {}; // use AccountID to fetch the onboarding fields
    const [isDataFetched, setIsDataFetched] = React.useState(false);
    const { data: statusData, isLoading: isFetchingStatus, isSuccess: isStatusFetched, } = useGetOnboardingStatusQuery({
        clientId,
        flowId: Number(flowId),
    });
    const { data: onboardingFields, isSuccess: areFieldsFetched } = useGetOnboardingFieldsFlows(id, flowId);
    useEffect(() => {
        if (!isDataFetched)
            setIsDataFetched(true);
    }, [areFieldsFetched, isStatusFetched]);
    const vatFields = [
        "companyName",
        "email",
        "phone",
        "street.0",
        "number.0",
        "zipCode.0",
        "city.0",
        "country.0",
    ];
    const onboardingFieldsName = onboardingFields === null || onboardingFields === void 0 ? void 0 : onboardingFields.filter((el) => el.isValid).map((item) => item.fieldName);
    const emptyFields = vatFields.filter((el) => !(onboardingFieldsName === null || onboardingFieldsName === void 0 ? void 0 : onboardingFieldsName.includes(el)));
    useEffect(() => {
        if (formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId) {
            const d = generateVatUrlSteps(formData === null || formData === void 0 ? void 0 : formData.registerOrAlreadyHaveVatId);
            setVatSubStep(d);
        }
    }, [formData]);
    const transformedStatusData = useMemo(() => transformDataForStatus(statusData || []), [statusData]);
    return (_jsxs(_Fragment, { children: [_jsx(Text, { textStyle: "h4", color: "brand.mare", children: t("onboarding.yourChecklist") }), isFetchingStatus ? (_jsx(FullPageLoader, {})) : (_jsx(UnorderedList, { py: "2", w: "full", h: "full", listStyleType: "none", marginInlineStart: "none", bgColor: "white", boxSizing: "border-box", padding: "0", children: [...routes].map((state) => {
                    var _a;
                    if (state.title === "vat-tasks")
                        return null;
                    if (countryCode === "GB" &&
                        state.path === "/administrative-processes")
                        return null;
                    return (_jsxs(React.Fragment, { children: [_jsx(Box, { h: "10px", bgColor: "secondary.50" }), _jsxs(Box, { boxShadow: "card", children: [_jsx(Step, { isStepActive: pathname.includes(state.path), disabled: emptyFields.length > 0 &&
                                            state.path === "/administrative-processes", route: state.path, stateName: (_a = state.title) !== null && _a !== void 0 ? _a : "" }, state.path), _jsx(Collapse, { in: pathname.includes(state.path), children: state.childrens && (_jsx(UnorderedList, { w: "full", bgColor: "white", listStyleType: "none", marginInlineStart: "none", children: _jsx(_Fragment, { children: state.childrens.map((substate) => {
                                                    if (countryCode === "CH" &&
                                                        substate.pageSlug === "transportToUk")
                                                        return null;
                                                    let subStepStatus = OnboardingStepStatus.PENDING;
                                                    if (Object.keys(transformedStatusData).length) {
                                                        subStepStatus = OnboardingStepStatus.PENDING;
                                                        if (!substate.pageSlug) {
                                                            // this is for overview pages that are always marked as seen
                                                            subStepStatus = OnboardingStepStatus.COMPLETED;
                                                        }
                                                        else {
                                                            subStepStatus =
                                                                transformedStatusData[state.processSlug]
                                                                    .pageSlugs[substate.pageSlug];
                                                        }
                                                    }
                                                    return (_jsx(SubStep, { status: subStepStatus, title: substate.title, path: `${state.path}${substate.path}` }, `${state.path}/${substate.path}`));
                                                }) }) })) })] })] }, state.path));
                }) }, formStepKey))] }));
}
export default StepsList;
