import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { TabNavigation, } from "~/components/TabNavigation/TabNavigation";
import { hasPermission } from "~/helpers/hasPermission";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
import { ClientOnboarding } from "../../components/ClientOnboarding/ClientOnboarding";
import { ClientProfile as ProfileTab } from "../../components/ClientProfile/ClientProfile";
import { ClientServices } from "../../components/ClientServices/ClientServices";
import { useGetClientByIdQuery } from "../../redux/clients.api";
export const ClientProfilePage = () => {
    const { t } = useTranslation();
    const { user } = useAppSelector(authSelector);
    if (!user || !user.accountId)
        return null;
    const { data: client, isFetching } = useGetClientByIdQuery(user === null || user === void 0 ? void 0 : user.accountId);
    const tabs = client
        ? [
            {
                title: t("clients.detail.tabs.profile"),
                value: "profile",
                element: _jsx(ProfileTab, { client: client }),
            },
            ...(hasPermission(EPermissionCodes.CLIENT_ALL_SERVICES_SETTINGS_VIEW)
                ? [
                    {
                        title: t("clients.detail.tabs.services"),
                        value: "services",
                        element: _jsx(ClientServices, { client: client }),
                    },
                ]
                : []),
            {
                title: t("clients.detail.tabs.onboarding"),
                value: "onboarding",
                element: _jsx(ClientOnboarding, { client: client }),
            },
        ]
        : [];
    return client && !isFetching ? (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: client.name }), _jsx(ContentSection, { children: _jsx(TabNavigation, { tabs: tabs }) })] })) : (_jsx(FullPageLoader, {}));
};
