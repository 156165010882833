import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.securityDeposit.calculation",
        description: "onboarding.securityDeposit.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "customComponent",
                componentName: "vatAndCustoms.CalculationOfSecurityDeposit",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
