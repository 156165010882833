import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editable, EditableInput, EditablePreview, Flex, } from "@chakra-ui/react";
import { useState } from "react";
export const EditableField = ({ defaultValue, onBlur, isError, isDisabled, }) => {
    const [value, setValue] = useState(defaultValue || "");
    return (_jsx(Flex, { w: "full", h: "64px", mt: "-3", mb: "-3", alignItems: "center", onClick: (e) => e.stopPropagation(), children: _jsxs(Editable, { width: "full", value: value, isDisabled: isDisabled, onClick: (e) => e.stopPropagation(), variant: isError ? "tableError" : !defaultValue ? "tableEmpty" : "table", height: 9, onChange: (nextValue) => setValue(nextValue), onSubmit: (nextValue) => {
                if (defaultValue !== nextValue) {
                    onBlur(nextValue);
                }
            }, children: [_jsx(EditablePreview, {}), _jsx(EditableInput, {})] }) }));
};
