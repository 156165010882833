import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Image from "~/components/Image/Image";
import { getTask } from "~/pages/onboarding/CH/OnboardingTask/tasks";
import { useAppDispatch } from "~/store/hooks";
import { useAuthDataQuery } from "~/api/hooks/users";
import routes from "~/layouts/onboarding/components/steps-list/routes";
import { useGetLandingPageFlow, useGetOnboardingFieldsFlows, } from "~/api/hooks/onboarding";
import { OnboardingTask } from "~/pages/onboarding/CH/OnboardingTask";
import { fetchSavedFormById } from "~/store/actions/formData.actions";
import { getSavedForm } from "~/store/selectors/formData.selectors";
function OnboardingStepLink({ status, label, onClick, hasTask }) {
    let icon;
    let triangleColor;
    let iconOpacity = 1;
    const [onHoverState, setOnHoverState] = useState(false);
    if (status === "IN_PROGRESS") {
        icon = "onboarding-step-pending-icon";
        triangleColor = "white";
        iconOpacity = 0.25;
    }
    else if (status === "PENDING") {
        icon = "onboarding-step-started-icon";
        triangleColor = "#0092D4";
    }
    else {
        icon = "onboarding-step-done-icon";
        triangleColor = "success.500";
    }
    if (hasTask) {
        icon = "onboarding-step-has-task";
        triangleColor = "#ED8D99";
    }
    icon = onHoverState ? "onboarding-step-pending-icon" : icon;
    const triangleGradientGb = onHoverState
        ? "none"
        : `linear-gradient(-225deg, ${triangleColor} 20px,white 0)`;
    iconOpacity = onHoverState ? 1 : iconOpacity;
    if (hasTask)
        iconOpacity = 1;
    return (_jsx(Box, { onMouseEnter: () => setOnHoverState(true), onMouseLeave: () => setOnHoverState(false), _hover: {
            background: "#CCE9F6",
            transition: "background 350ms linear",
        }, onClick: onClick, w: "full", bgColor: "white", h: "48px", boxShadow: "card", transition: "background 150ms linear", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", children: _jsxs(Flex, { bgGradient: triangleGradientGb, w: "full", h: "full", justifyContent: "space-between", children: [_jsx(Box, { marginLeft: "32px", w: "100%", alignSelf: "center", children: _jsx(Text, { fontSize: "19px", children: label }) }), _jsx(Image, { name: icon, alt: "step-icon", w: "22px", style: { marginRight: 20, opacity: iconOpacity } })] }) }));
}
export function OnboardingStart() {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { params: { id, flowId }, } = useMatch();
    const [processesStatus, setProcessesStatus] = useState({});
    const dispatch = useAppDispatch();
    const { data } = useGetLandingPageFlow(id, flowId);
    const { data: authData } = useAuthDataQuery();
    const type = (_a = authData === null || authData === void 0 ? void 0 : authData.user) === null || _a === void 0 ? void 0 : _a.userType;
    const { data: onboardingFields } = useGetOnboardingFieldsFlows(id, flowId);
    const vatFields = [
        "companyName",
        "email",
        "phone",
        "street.0",
        "number.0",
        "zipCode.0",
        "city.0",
        "country.0",
    ];
    const onboardingFieldsName = onboardingFields === null || onboardingFields === void 0 ? void 0 : onboardingFields.filter((el) => el.isValid).map((item) => item.fieldName);
    const emptyFields = (data === null || data === void 0 ? void 0 : data.countryCode) === "GB"
        ? []
        : vatFields
            .filter((el) => !(onboardingFieldsName === null || onboardingFieldsName === void 0 ? void 0 : onboardingFieldsName.includes(el)))
            .map((el) => {
            return t(`onboarding.vat.mandatoryFields.${el}`);
        });
    useEffect(() => {
        dispatch(fetchSavedFormById({
            id,
            flowId,
            pageSlug: "activestep",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    const dataAdministrativeProcess = useSelector((s) => getSavedForm(s, "activestep", "administrativeProcesses"));
    const task = getTask(dataAdministrativeProcess);
    useEffect(() => {
        const processStatuses = data === null || data === void 0 ? void 0 : data.processStatuses;
        const currentProcessStatus = {};
        processStatuses === null || processStatuses === void 0 ? void 0 : processStatuses.forEach((status) => {
            currentProcessStatus[status.processSlug] =
                status.processStatus;
        });
        setProcessesStatus(currentProcessStatus);
    }, [data]);
    const hasTask = (data === null || data === void 0 ? void 0 : data.countryCode) !== "GB" && type ? task[type] : undefined;
    return (_jsxs(VStack, { w: "full", h: "full", alignItems: "flex-start", gap: 2, children: [_jsx(Text, { color: "primary.500", fontSize: "40px", fontWeight: "500", children: t("onboarding.landing.heading") }), _jsx(Text, { color: "primary.500", fontSize: "xl", mb: 8, children: t("onboarding.landing.subheading") }), _jsx(Text, { children: t("onboarding.landing.description") }), _jsx(VStack, { pt: "20px", w: "full", spacing: "12px", children: routes.map((state) => {
                    const link = `/clients/${id}/onboarding/${flowId}${state.path}`;
                    if ((data === null || data === void 0 ? void 0 : data.countryCode) === "GB" &&
                        state.path === "/administrative-processes")
                        return null;
                    return state.title === "vat-tasks" ? (_jsx(OnboardingTask, { emptyFields: emptyFields })) : (_jsx(OnboardingStepLink, { onClick: () => {
                            if (!(emptyFields.length > 0 &&
                                state.path === "/administrative-processes"))
                                navigate({ to: link });
                        }, hasTask: hasTask &&
                            hasTask.length > 0 &&
                            state.path === "/administrative-processes", status: processesStatus[`${state.key}`] || "PENDING", label: t(state.title) }, state.path));
                }) })] }));
}
