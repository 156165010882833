import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.zazAccount.declarationOfAccession",
        description: "onboarding.zazAccount.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label3",
                type: "h2",
                label: "onboarding.zazAccount.congratulations",
            },
            {
                key: "label4",
                type: "label",
                label: "onboarding.zazAccount.timeToWait",
            },
            {
                key: "label5",
                type: "label",
                label: "onboarding.zazAccount.openZazAccountDetails",
            },
            {
                key: "label6",
                type: "label",
                label: "onboarding.zazAccount.declarationOfMembershipDetails",
            },
            {
                key: "label7",
                type: "label",
                label: "onboarding.zazAccount.enterZazNumberSystemDetails",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
