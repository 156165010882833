import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.technicalSetup.title",
        subtitle: "onboarding.technicalSetup.csvSetup.title",
        description: "onboarding.technicalSetup.csvSetup.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label2",
                type: "label",
                label: "onboarding.technicalSetup.csvSetup.description",
            },
            {
                key: "download-csv",
                type: "label",
                label: [
                    "onboarding.technicalSetup.csvSetup.downloadYourPersonal",
                    {
                        href: "#",
                        targetBlank: true,
                        displayLabel: "onboarding.technicalSetup.overview.csvFile",
                    },
                ],
            },
            {
                key: "csv-file",
                type: "file",
                label: "onboarding.technicalSetup.csvSetup.dragPersonalCSVMessage",
                multiple: false,
            },
            {
                key: "instructions-csv",
                type: "label",
                label: [
                    "onboarding.technicalSetup.csvSetup.instructionsForCustomizeCSVFile",
                    {
                        href: "#",
                        targetBlank: true,
                        displayLabel: "onboarding.technicalSetup.csvSetup.downloadInstructions",
                    },
                ],
            },
            {
                key: "instructions-file",
                type: "file",
                label: "onboarding.technicalSetup.csvSetup.dragInstructionsMessage",
                multiple: false,
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
