import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import { useMatch, useNavigate, useSearch, } from "@tanstack/react-location";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { RouteMap } from "~/routing/route.config";
import { DeleteKeyModal } from "../../components/DeleteKeyModal/DeleteKeyModal";
import { KeyDetailView } from "../../components/KeyDetailView/KeyDetailView";
import { KeyEditView } from "../../components/KeyEditView/KeyEditView";
import { useGetKeyByIdQuery } from "../../redux/keys.api";
export const KeyDetailPage = () => {
    const { edit: inEditMode } = useSearch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { params } = useMatch();
    const { data: keyData, isError } = useGetKeyByIdQuery(params.id);
    const [showDeleteKeyModal, setShowDeleteKeyModal] = useState(false);
    const keyDetailBreadcrumbs = useMemo(() => [
        {
            title: t("layout.sidebar.menuItems.apiAndServices"),
        },
        {
            title: t("layout.sidebar.menuItems.credentials"),
            link: "/api-and-services/credentials",
        },
    ], [t]);
    useEffect(() => {
        if (isError) {
            navigate({ to: RouteMap.API_KEYS });
        }
    }, [isError]);
    const onEdit = (edit) => {
        navigate({
            search: {
                edit,
            },
        });
    };
    const headerActions = (_jsx(Button, { size: "lg", onClick: () => onEdit(true), children: t("apiKeys.detail.editKeyCTA") }));
    return keyData ? (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { breadcrumbs: keyDetailBreadcrumbs, actions: inEditMode ? null : headerActions, title: keyData.name }), _jsx(ContentSection, { children: inEditMode ? (_jsx(KeyEditView, { item: keyData, onCancel: () => onEdit(false) })) : (_jsx(KeyDetailView, { item: keyData, onDelete: () => setShowDeleteKeyModal(true) })) }), _jsx(DeleteKeyModal, { item: keyData, show: showDeleteKeyModal, handleHide: () => setShowDeleteKeyModal(false) })] })) : null;
};
