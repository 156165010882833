import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
const { INITIAL_CLASSIFICATION, CONTINUOUS_CLASSIFICATION } = PartnerServiceCode;
export const ServiceSelection = ({ services, setServices, }) => {
    var _a;
    const { t } = useTranslation();
    const initialClassificationService = useMemo(() => services.find(({ code }) => code === INITIAL_CLASSIFICATION), [services]);
    const continuousClassificationService = useMemo(() => services.find(({ code }) => code === CONTINUOUS_CLASSIFICATION), [services]);
    const handleChangeService = (type, data) => {
        if (type === INITIAL_CLASSIFICATION) {
            if (initialClassificationService) {
                // we're removing an existing service
                setServices(services.filter(({ code }) => code !== INITIAL_CLASSIFICATION));
            }
            else {
                // we're adding a new service
                setServices([
                    ...services,
                    { code: INITIAL_CLASSIFICATION, data: null },
                ]);
            }
        }
        else if (!continuousClassificationService) {
            // we're adding a new service and pre-populate timeWindow with 24
            setServices([
                ...services,
                { code: CONTINUOUS_CLASSIFICATION, data: { timeWindow: 24 } },
            ]);
        }
        else if (data) {
            // we're updating an existing service
            const newServices = [...services];
            const existing = newServices.find((service) => service.code === CONTINUOUS_CLASSIFICATION);
            if (existing) {
                newServices.splice(newServices.indexOf(existing), 1, {
                    code: CONTINUOUS_CLASSIFICATION,
                    data,
                });
                setServices(newServices);
            }
            // we're removing an existing service
        }
        else
            setServices(services.filter(({ code }) => code !== CONTINUOUS_CLASSIFICATION));
    };
    return (_jsxs(Box, { children: [_jsx(Text, { textStyle: "labelUppercase", mb: "4", children: t("clients.detail.services.tariffingModal.serviceTitle") }), _jsx(Checkbox, { w: "full", isChecked: !!initialClassificationService, onChange: () => handleChangeService(INITIAL_CLASSIFICATION), children: t("clients.detail.services.tariffingModal.initialClassificationTitle") }), _jsx(Checkbox, { mt: "4", w: "full", isChecked: !!continuousClassificationService, onChange: () => handleChangeService(CONTINUOUS_CLASSIFICATION), children: t("clients.detail.services.tariffingModal.continousClassificationTitle") }), continuousClassificationService && (_jsxs(Flex, { pl: "8", mt: "4", children: [_jsxs(Text, { mr: "4", children: [t("clients.detail.services.tariffingModal.timeWindowTitle"), ":"] }), _jsxs(RadioGroup, { value: String(((_a = continuousClassificationService.data) === null || _a === void 0 ? void 0 : _a.timeWindow) || 24), onChange: (val) => handleChangeService(CONTINUOUS_CLASSIFICATION, {
                            timeWindow: Number(val),
                        }), children: [_jsx(Radio, { value: String(24), children: t("clients.detail.services.tariffingModal.timeframe24Title") }), _jsx(Radio, { ml: "8", value: String(48), children: t("clients.detail.services.tariffingModal.timeframe48Title") })] })] }))] }));
};
