import { jsx as _jsx } from "react/jsx-runtime";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider } from "@chakra-ui/react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "reflect-metadata";
import clientSideConfig from "~/client-side-config";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import App from "~/App";
import "./i18n";
import theme from "./theme";
import { msalConfig } from "./auth-config";
const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById("app");
if (!container) {
    throw new Error("App container not found");
}
const appInsightsConnectionString = clientSideConfig.applicationinsightsConnectionString || "";
if (appInsightsConnectionString) {
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: appInsightsConnectionString,
            appId: "msdirect-ui",
            loggingLevelConsole: 1,
            loggingLevelTelemetry: 1,
        },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    console.info("AI started");
}
else {
    console.warn("AI Instrumentation Key not found", appInsightsConnectionString);
}
const root = createRoot(container);
root.render(_jsx(StrictMode, { children: _jsx(ChakraProvider, { theme: theme, children: _jsx(MsalProvider, { instance: msalInstance, children: _jsx(App, {}) }) }) }));
