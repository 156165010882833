// 'as const' assertion needed for type inference
const INPUT_TYPES = [
    "text",
    "select",
    "checkbox",
    "radio",
    "file",
    "customComponent",
];
const LABEL_TYPES = ["h1", "h2", "h3", "label", "p"];
const BUTTON_TYPES = ["button"];
const LAYOUT_TYPES = ["layout"];
export const formNodeFilters = {
    isField: (node) => INPUT_TYPES.includes(node.type),
    isTextLabel: (node) => LABEL_TYPES.includes(node.type),
    isLayout: (node) => LAYOUT_TYPES.includes(node.type),
    isGroupLayout: (node) => {
        return "groupType" in node;
    },
    isButton: (node) => node.type === "button",
    isCustomComponent: (node) => node.type === "customComponent",
};
