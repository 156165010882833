import { createMachine } from "xstate";
import { COMPANY_DETAILS, GO_LIVE, INBOUND_PROCESS, OUTBOUND_PROCESS, PRODUCT_DETAILS, TECHNICAL_SETUP, VAT_AND_CUSTOMS, } from "./state-definitions";
const onboardingMachine = createMachine({
    schema: {
        context: {},
        events: {},
    },
    id: "onboarding",
    initial: "product_details",
    states: {
        ...COMPANY_DETAILS,
        ...VAT_AND_CUSTOMS,
        ...PRODUCT_DETAILS,
        ...OUTBOUND_PROCESS,
        ...INBOUND_PROCESS,
        ...TECHNICAL_SETUP,
        ...GO_LIVE,
    },
    on: {
        COMPANY_DETAILS: { target: ".company_details" },
        VAT_AND_CUSTOMS: { target: ".application_vat_uid_number" },
        INBOUND_PROCESS: { target: ".inbound_process" },
        PRODUCT_DETAILS: { target: ".product_details" },
        TECHNICAL_SETUP: { target: ".technical_setup" },
        OUTBOUND_PROCESS: { target: ".outbound_process" },
        GO_LIVE: { target: ".go_live" },
        COMPANY_DETAILS0: { target: "#company_details.overview" },
        COMPANY_DETAILS1: { target: "#company_details.contact_information" },
        COMPANY_DETAILS2: { target: "#company_details.contact_persons" },
        PRODUCT_DETAILS0: { target: "#product_details.overview" },
        PRODUCT_DETAILS1: { target: "#product_details.categories" },
        PRODUCT_DETAILS2: { target: "#product_details.export_regulation" },
        PRODUCT_DETAILS3: { target: "#product_details.tarifnummerVersandland" },
        PRODUCT_DETAILS4: { target: "#product_details.tarifnummerZielland" },
        PRODUCT_DETAILS5: { target: "#product_details.initialTarifierung" },
        PRODUCT_DETAILS6: { target: "#product_details.kontinuierlicheTarifierung" },
        PRODUCT_DETAILS7: { target: "#product_details.defineImportFile" },
        OUTBOUND_PROCESS0: { target: "#outbound_process.overview" },
        OUTBOUND_PROCESS1: { target: "#outbound_process.origin_warehouse" },
        OUTBOUND_PROCESS2: { target: "#outbound_process.export_EU" },
        OUTBOUND_PROCESS3: { target: "#outbound_process.transport_UK" },
        OUTBOUND_PROCESS4: { target: "#outbound_process.import" },
        OUTBOUND_PROCESS5: { target: "#outbound_process.delivery" },
        OUTBOUND_PROCESS6: { target: "#outbound_process.processOverview" },
        INBOUND_PROCESS0: { target: "#inbound_process.overview" },
        INBOUND_PROCESS1: { target: "#inbound_process.return_start" },
        INBOUND_PROCESS2: { target: "#inbound_process.return_processing" },
        INBOUND_PROCESS3: { target: "#inbound_process.return_closure" },
        INBOUND_PROCESS4: { target: "#inbound_process.return_pick_up" },
        INBOUND_PROCESS5: { target: "#inbound_process.return_delivery_process" },
        INBOUND_PROCESS6: { target: "#inbound_process.return_warehouse_location" },
        INBOUND_PROCESS7: { target: "#inbound_process.completed" },
        GO_LIVE0: { target: "#go_live.start" },
        TECHNICAL_SETUP0: { target: "#technical_setup.overview" },
        TECHNICAL_SETUP1: { target: "#technical_setup.customization_files" },
        TECHNICAL_SETUP2: { target: "#technical_setup.upload_verification" },
        TECHNICAL_SETUP3: { target: "#technical_setup.completed" },
        VAT_AND_CUSTOMS0: { target: "#application_vat_uid_number.start" },
        VAT_AND_CUSTOMS1: { target: "#application_vat_uid_number.vat_uid_number" },
        VAT_AND_CUSTOMS2: { target: "#application_vat_uid_number.vat_uid_number" },
        VAT_AND_CUSTOMS3: {
            target: "#application_vat_uid_number.vat_uid_number",
        },
        VAT_AND_CUSTOMS4: {
            target: "#application_vat_uid_number.start",
        },
        VAT_AND_CUSTOMS5: {
            target: "#application_vat_uid_number.declaration_of_fiscal_representation",
        },
        VAT_AND_CUSTOMS6: {
            target: "#application_vat_uid_number.declaration_of_fiscal_representation_fertig_check",
        },
        VAT_AND_CUSTOMS7: { target: "#application_vat_uid_number.your_vat_uid" },
        VAT_AND_CUSTOMS8: {
            target: "#application_vat_uid_number.security_deposit",
        },
        VAT_AND_CUSTOMS9: {
            target: "#application_vat_uid_number.declaration_of_subordination",
        },
        VAT_AND_CUSTOMS10: { target: "#application_vat_uid_number.completed" },
        CUSTOMS_ACCOUNT0: {
            target: "#your_customs_account.do_you_have_a_customs_account_number",
        },
        CUSTOMS_ACCOUNT1: { target: "#your_customs_account.zaz_account_number" },
        CUSTOMS_ACCOUNT2: {
            target: "#your_customs_account.calculation_of_security_deposit",
        },
        CUSTOMS_ACCOUNT3: {
            target: "#your_customs_account.kind_of_security_deposit",
        },
        CUSTOMS_ACCOUNT4: {
            target: "#your_customs_account.transfering_cash_for_deposit",
        },
        CUSTOMS_ACCOUNT5: {
            target: "#your_customs_account.payment_of_custom_fees",
        },
        CUSTOMS_ACCOUNT6: {
            target: "#your_customs_account.lastschriftverfahren_einrichten",
        },
        CUSTOMS_ACCOUNT7: {
            target: "#your_customs_account.declaration_of_accession",
        },
        CUSTOMS_ACCOUNT8: { target: "#your_customs_account.completed" },
    },
});
export * from "./models";
export default onboardingMachine;
