import { jsx as _jsx } from "react/jsx-runtime";
import { Flex, Input, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useTariffArticleByIdMutation } from "../../redux/customsClassification.api";
export const PlainInput = ({ articleId, importId, value = "", addTableWrapper = false, isFetching = false, }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [updateArticle, { isLoading, isSuccess, reset }] = useTariffArticleByIdMutation();
    useEffect(() => {
        if (!isFetching)
            setInputValue(value);
    }, [isFetching]);
    useEffect(() => {
        if (isSuccess) {
            toast({
                title: t("articles.detail.articleUpdatedSuccess"),
                status: "success",
                duration: 3000,
            });
            reset();
        }
    }, [isSuccess]);
    const handleBlurField = async () => {
        if (inputValue) {
            await updateArticle({
                articleId,
                importId,
                body: { value: inputValue },
            });
        }
    };
    const handleFocusInput = (e) => {
        e.stopPropagation();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const input = (_jsx(Input, { minW: "100px", maxW: "180px", ref: inputRef, isDisabled: isLoading || isFetching, isInvalid: !inputValue && !isFetching, size: "table", value: inputValue, onChange: (e) => setInputValue(e.target.value), onBlur: handleBlurField }));
    return addTableWrapper ? (_jsx(Flex, { cursor: "default", h: "12", mt: "-3", mb: "-3", alignItems: "center", onClick: handleFocusInput, children: input })) : (_jsx(Flex, { mt: "-6px", mb: "-6px", alignItems: "center", onClick: (e) => e.stopPropagation(), children: input }));
};
