import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.steps.your_customs_account",
        subtitle: "onboarding.companyInformation.title",
        description: "onboarding.companyInformation.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 3,
                },
                elements: [
                    {
                        key: "company_name",
                        type: "text",
                        label: "onboarding.companyInformation.companyName",
                    },
                    {
                        key: "street",
                        type: "text",
                        label: "onboarding.companyInformation.street",
                    },
                    {
                        key: "street_number",
                        type: "text",
                        label: "onboarding.companyInformation.number",
                    },
                    {
                        key: "zip_code",
                        type: "text",
                        label: "onboarding.companyInformation.zipCode",
                    },
                    {
                        key: "city",
                        type: "text",
                        label: "onboarding.companyInformation.city",
                    },
                    {
                        key: "country",
                        type: "customComponent",
                        componentName: "base.CountrySelect",
                    },
                    {
                        key: "phone",
                        type: "text",
                        label: "onboarding.companyInformation.phone",
                    },
                    {
                        key: "email",
                        type: "text",
                        label: "onboarding.companyInformation.email",
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        company_name: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.companyName",
            },
        },
        street: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.street",
            },
        },
        street_number: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.streetNumber",
            },
        },
        zip_code: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.zipCode",
            },
        },
        city: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.city",
            },
        },
        country: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.country",
            },
        },
        phone: {
            type: "string",
            minLength: 1,
            errorMessage: {
                minLength: "onboarding.requiredFields.phoneNumber",
            },
        },
        email: {
            type: "string",
            minLength: 1,
            pattern: '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            errorMessage: {
                minLength: "onboarding.requiredFields.email",
                pattern: "onboarding.invalidEmail",
            },
        },
    },
    additionalProperties: false,
    required: [
        "company_name",
        // 'c_o', // TO DO - Verify why those fields are required but aren't on the form
        // 'street_fiscal_represenation', // TO DO - Verify why those fields are required but aren't on the form
        "street_number",
        "zip_code",
        "city",
        "country",
        "phone",
        "email",
    ],
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
