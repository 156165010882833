import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "~/components/Banner/Banner";
import Grid from "~/components/Grid/Grid";
import { OnboardingFormHeader } from "~/components/OnboardingFormHeader/OnboardingFormHeader";
import { ServiceCode } from "~/enums/service-code.enum";
import { useGetClientByIdQuery, useLazyGetClientServicesQuery, } from "~/pages/clients/redux/clients.api";
import { Card } from "~/components/Card/Card";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { SFTPCard } from "~/components/SFTPCard/SFTPCard";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { translatedCountryName } from "~/helpers/country-codes";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { CountryDropdown } from "~/pages/clients/components/ClientServices/components/CountryDropdown/CountryDropdown";
import { PartnerServiceCode } from "~/pages/services/enum/partner-service-code.enum";
import { useAppSelector } from "~/store/hooks";
export const ServicesTab = () => {
    const { user } = useAppSelector(authSelector);
    if (!(user === null || user === void 0 ? void 0 : user.accountId))
        return null;
    const { data: client } = useGetClientByIdQuery(user.accountId);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const sortCountries = (ctrs) => {
        const arr = [...ctrs];
        arr.sort((a, b) => a.localeCompare(b));
        return arr;
    };
    const { t } = useTranslation();
    const [fetchServices, { data: services, isFetching, isSuccess, isError }] = useLazyGetClientServicesQuery();
    useEffect(() => {
        if (client && user.accountId && selectedCountry)
            fetchServices({
                clientId: user.accountId,
                service: ServiceCode.TARIFFING,
                shipTo: selectedCountry,
            });
    }, [selectedCountry, client]);
    useEffect(() => {
        if (client === null || client === void 0 ? void 0 : client.shipmentDestination)
            setSelectedCountry(sortCountries(client.shipmentDestination)[0]);
    }, [client]);
    const shipToServices = (services === null || services === void 0 ? void 0 : services.filter((service) => service.serviceData.direction === ShipDirection.TO)) || [];
    const shipFromServices = (services === null || services === void 0 ? void 0 : services.filter((service) => service.serviceData.direction === ShipDirection.FROM)) || [];
    const renderServices = (direction) => {
        const isShipTo = direction === ShipDirection.TO;
        const finishedLoading = isSuccess || isError;
        const items = isShipTo ? shipToServices : shipFromServices;
        if (!finishedLoading)
            return _jsx(Skeleton, { w: "full", h: "72px" });
        return items.length ? (_jsx(Grid, { gap: "4", templateColumns: "1fr", children: items === null || items === void 0 ? void 0 : items.map((service) => {
                const countryCode = (isShipTo
                    ? service.serviceData.shipTo
                    : service.serviceData.shipFrom) || "";
                return (_jsxs(Card, { children: [_jsxs(Flex, { alignItems: "center", mb: "6", children: [_jsx(FlagIcon, { boxSize: "30", countryCode: countryCode }), _jsx(Text, { textStyle: "h4", ml: "14px", children: translatedCountryName(countryCode) })] }), _jsx(Text, { textStyle: "labelUppercase", mb: "2", children: t("onboarding.productDetails.customsClassification.servicesHeader") }), _jsx(Text, { children: service.serviceData.services
                                .map((s) => {
                                var _a;
                                switch (s.code) {
                                    case PartnerServiceCode.INITIAL_CLASSIFICATION:
                                        return t("clients.detail.services.tariffing.initialClassification");
                                    case PartnerServiceCode.CONTINUOUS_CLASSIFICATION:
                                        return t("clients.detail.services.tariffing.continuousClassification", {
                                            hours: (_a = s.data) === null || _a === void 0 ? void 0 : _a.timeWindow,
                                        });
                                    default:
                                        return "";
                                }
                            })
                                .join(", ") })] }, `service-card-${service.id}`));
            }) })) : (_jsx(Card, { children: t("onboarding.productDetails.customsClassification.noServiceMessage") }));
    };
    const HeaderComponent = (_jsx(Banner, { text: t("onboarding.productDetails.customsClassification.notice") }));
    const sortedCountries = useMemo(() => (client === null || client === void 0 ? void 0 : client.shipmentDestination)
        ? sortCountries(client === null || client === void 0 ? void 0 : client.shipmentDestination)
        : [], [client]);
    return selectedCountry ? (_jsxs(Box, { mt: "8", mb: "6", children: [_jsx(Box, { mb: "12", children: selectedCountry && sortedCountries.length > 1 && (_jsx(CountryDropdown, { countries: sortedCountries, selectedCountry: selectedCountry, setSelectedCountry: (c) => setSelectedCountry(c) })) }), !isFetching && isSuccess ? (_jsxs(_Fragment, { children: [_jsx(OnboardingFormHeader, { title: sortedCountries.length === 1
                            ? t("onboarding.productDetails.customsClassification.title")
                            : undefined, subtitle: t("onboarding.productDetails.customsClassification.subtitle"), description: t("onboarding.productDetails.customsClassification.info"), component: HeaderComponent }), _jsxs(Box, { mb: "10", children: [_jsx(Text, { textStyle: "h3", mb: "4", children: t("onboarding.productDetails.customsClassification.shipFromTitle") }), renderServices(ShipDirection.TO)] }), _jsxs(Box, { mb: "10", children: [_jsx(Text, { textStyle: "h3", mb: "4", children: t("onboarding.productDetails.customsClassification.shipToTitle") }), renderServices(ShipDirection.FROM)] }), _jsx(SFTPCard, {}), " "] })) : (_jsx(FullPageLoader, {}))] })) : (_jsx(Box, { mt: "8", mb: "6", children: _jsx(FullPageLoader, {}) }));
};
