import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import Icon from "~/components/Icon/Icon";
import { Table } from "~/components/Table/Table";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { hasPermission } from "~/helpers/hasPermission";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamSorting } from "~/hooks/useParamSorting";
import { ClientInfoOverlay } from "~/pages/clients/components/ClientInfoOverlay/ClientInfoOverlay";
import { OrderDirection } from "~/types/pagination.type";
import { useGetPartnerClientsQuery } from "../../redux/partners.api";
export const PartnerClients = ({ partner, }) => {
    const { t } = useTranslation();
    const { PARTNERS_ACCOUNTS_MANAGE } = EPermissionCodes;
    const [selectedClient, setSelectedClient] = useState(null);
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [sorting, onSortingChange] = useParamSorting();
    const { data: clients, isFetching, isLoading, } = useGetPartnerClientsQuery({
        id: partner.id,
        searchQuery,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
    });
    const tableActions = (id) => [
        {
            label: t("basics.detach"),
            element: _jsx(Icon, { w: "14px", h: "14px", name: "delete-x" }),
            onClick: () => setSelectedClient(id),
        },
    ];
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: () => t("partners.detail.clients.name"),
            size: undefined,
        }),
        columnHelper.accessor("services", {
            header: () => t("partners.detail.clients.services"),
        }),
        ...(hasPermission(PARTNERS_ACCOUNTS_MANAGE)
            ? [
                columnHelper.display({
                    id: "actions",
                    size: undefined,
                    header: () => t("partners.table.actions"),
                    cell: ({ row }) => renderTableRowActions({
                        tableActions: tableActions(row.original.id),
                    }),
                }),
            ]
            : []),
    ];
    return (_jsxs(Box, { h: "full", children: [_jsx(Text, { my: 8, textStyle: "h2", children: t("partners.detail.clients.headline") }), _jsx(FilterBar, { children: _jsx(FilterSearch, { disabled: isLoading, value: search, setValue: setSearch }) }), _jsx(Table, { columns: columns, data: clients, onRowClick: (row) => setSelectedClient(row.original.id), sorting: sorting, onSortingChange: onSortingChange, isLoading: isLoading, isFetching: isFetching }), _jsx(ClientInfoOverlay, { clientId: selectedClient, onClose: () => setSelectedClient(null) })] }));
};
