import { ONBOARDING_V1_BASEURL, api } from "~/store/api";
export const serviceBaseUrl = `${ONBOARDING_V1_BASEURL}/services`;
export const servicesApi = api
    .enhanceEndpoints({ addTagTypes: ["SERVICE", "PARTNER_SERVICES"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getAllServices: builder.query({
            query: () => ({
                url: serviceBaseUrl,
            }),
            providesTags: ["SERVICE"],
        }),
        getTariffingCountries: builder.query({
            query: () => ({
                url: `${serviceBaseUrl}/tariffing/countries`,
            }),
            providesTags: ["SERVICE"],
        }),
    }),
});
export const { useGetAllServicesQuery, useGetTariffingCountriesQuery } = servicesApi;
