import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.outboundProcesses.title",
        subtitle: "onboarding.outboundProcesses.transportUK.label",
        description: "onboarding.outboundProcesses.transportUK.description",
    }),
    {
        type: "layout",
        direction: "vertical",
        options: {
            bg: "unset",
            mt: 4,
            mb: 2,
        },
        elements: [
            {
                key: "transportDetailsTitle",
                type: "h2",
                label: "onboarding.outboundProcesses.transportUK.title",
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: { gap: 3 },
                    elements: [
                        {
                            key: "transportQuestion",
                            type: "h3",
                            label: "onboarding.outboundProcesses.transportUK.question",
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            options: { gap: 3 },
                            groupType: "radio",
                            forKey: "transport.method",
                            elements: [
                                {
                                    type: "radio",
                                    value: "ferry",
                                    key: "ferry",
                                    label: "onboarding.outboundProcesses.transportUK.options.ferry",
                                    forKey: "transport.method",
                                },
                                {
                                    type: "radio",
                                    value: "tunnel",
                                    key: "tunnel",
                                    label: "onboarding.outboundProcesses.transportUK.options.tunnel",
                                    forKey: "transport.method",
                                },
                            ],
                        },
                        {
                            type: "layout",
                            direction: "vertical",
                            rule: {
                                effect: "SHOW",
                                targetKey: "transport.method",
                                targetValue: "ferry",
                            },
                            elements: [
                                {
                                    type: "text",
                                    key: "transport.departure",
                                    label: "onboarding.outboundProcesses.transportUK.locationOptions.departure",
                                    rule: {
                                        effect: "ENABLE",
                                        targetKey: "transport.method",
                                        targetValue: "ferry",
                                    },
                                },
                                {
                                    type: "text",
                                    key: "transport.arrival",
                                    label: "onboarding.outboundProcesses.transportUK.locationOptions.arrival",
                                    rule: {
                                        effect: "ENABLE",
                                        targetKey: "transport.method",
                                        targetValue: "ferry",
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
            nodeKey: "transport",
        }),
    },
    schemaGenerators.formFooter(),
];
const formSchema = {
    type: "object",
    properties: {
        transport: {
            type: "object",
            properties: {
            // method: { type: 'string' },
            // departure: { type: 'string' },
            // arrival: { type: 'string' }
            },
        },
    },
    additionalProperties: true,
};
const formNodesAndSchema = {
    formNodes,
    formSchema,
};
export default formNodesAndSchema;
