import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE } from "~/consts/customs-classification-cases-max-size";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { customsClassificationApi, importsBaseUrl, } from "~/pages/customs-classification/redux/customsClassification.api";
import { useAppSelector } from "~/store/hooks";
export const UploadTariffedFileModal = ({ id, isOpen, setIsOpen }) => {
    const [isFetching, setIsFetching] = useState(false);
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token } = useAppSelector(authSelector);
    const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE,
    });
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };
    const handleFileUpload = async () => {
        setIsFetching(true);
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        try {
            const res = await fetch(withBaseUrl(`${importsBaseUrl}/${id}/bulk-tariff`), {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            if (res.ok) {
                reset();
                dispatch(customsClassificationApi.util.invalidateTags([
                    "CUSTOMS_CLASSIFICATION_CASES",
                    "CUSTOMS_CLASSIFICATION_ARTICLES_BY_IMPORT_ID",
                    "CUSTOMS_CLASSIFICATION_IMPORT_META",
                ]));
                toast({
                    title: t("customsClassificationTariffing.uploadTariffedFileModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
            else {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
            setIsFetching(false);
        }
        catch (err) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("customsClassificationTariffing.uploadTariffedFileModal.title"), primaryVariant: "danger", primaryAction: handleFileUpload, primaryActionLoading: isFetching, primaryTitle: t("customsClassificationTariffing.uploadTariffedFileModal.primaryCTA"), children: [_jsx(Text, { mt: 2, children: t("customsClassificationTariffing.uploadTariffedFileModal.subtitle") }), _jsx(Flex, { mt: 6, px: 4, py: 3, backgroundColor: "sky.20", borderColor: "sky.40", borderWidth: "1px", borderRadius: "4px", children: _jsx(Text, { children: t("customsClassificationTariffing.uploadTariffedFileModal.commentNote") }) }), _jsxs(Flex, { direction: "column", alignItems: "center", justifyContent: "center", flex: 1, gap: 3, mt: 6, minHeight: 20, backgroundColor: "sky.10", borderRadius: "4px", borderColor: "brand.sky", borderWidth: "2px", borderStyle: "dashed", cursor: "pointer", ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), acceptedFiles.length ? (_jsx(Text, { children: acceptedFiles[0].name })) : (_jsx(Text, { children: _jsx(Trans, { components: {
                                cta: _jsx(Text, { as: "span", color: "brand.sky", fontWeight: "700" }),
                            }, i18nKey: "customsClassification.uploadTariffingFileModal.dragAndDropZone.description", values: {
                                CTA: t("customsClassification.uploadTariffingFileModal.dragAndDropZone.cta"),
                            } }) }))] }), _jsx(Flex, { mt: 6, px: 4, py: 3, backgroundColor: "warning.20", borderColor: "warning.40", borderWidth: "1px", borderRadius: "4px", children: _jsx(Text, { children: t("customsClassificationTariffing.uploadTariffedFileModal.note") }) })] }));
};
