import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, FormLabel, useCheckbox } from "@chakra-ui/react";
import { CheckIcon } from "~/assets/icons/CheckIcon";
import { CloseIcon } from "~/assets/icons/CloseIcon";
export const Switch = (props) => {
    const { marginRight } = props;
    const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox(props);
    const { isChecked, isDisabled } = state;
    return (_jsxs(FormLabel, { ...htmlProps, marginBottom: 0, marginRight: marginRight !== null && marginRight !== void 0 ? marginRight : "3", position: "relative", children: [_jsx("input", { ...getInputProps(), hidden: true }), _jsx(Flex, { w: 10, h: 6, cursor: "pointer", borderRadius: "full", transitionDuration: ".3s", backgroundColor: isDisabled ? "grey.10" : isChecked ? "success.500" : "grey.10", pointerEvents: isDisabled ? "none" : "initial", ...getCheckboxProps(), children: _jsxs(Box, { w: 5, h: 5, transitionDuration: ".3s", left: isChecked ? "18px" : 0.5, top: 0.5, position: "relative", bg: "white", borderRadius: "full", children: [_jsx(CloseIcon, { width: "12px", height: "12px", position: "absolute", left: "4px", top: "4px", opacity: isChecked ? 0 : 1, transitionDuration: ".2s", color: "grey.40" }), _jsx(CheckIcon, { w: "12px", h: "12px", position: "absolute", left: "4px", top: "4px", opacity: isChecked ? 1 : 0, transitionDuration: ".2s", color: isDisabled ? "grey.40" : "success.500" })] }) })] }));
};
