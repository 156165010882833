import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.initialProductClassification.title",
        description: "onboarding.productDetails.initialProductClassification.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "help-with-swiss-tariff",
                options: {
                    gap: 3,
                },
                elements: [
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.productDetails.initialProductClassification.createAndUploadTarifFile",
                    },
                    {
                        key: "table",
                        type: "customComponent",
                        componentName: "productDetails.InitialTariffing",
                    },
                    {
                        key: "label2",
                        type: "label",
                        label: "onboarding.productDetails.initialProductClassification.helpCreateSwissTariffNumber",
                    },
                    {
                        type: "radio",
                        key: "yes",
                        label: "onboarding.actions.yes",
                        value: "yes",
                        forKey: "help-with-swiss-tariff",
                    },
                    {
                        type: "radio",
                        key: "no",
                        label: "onboarding.productDetails.initialProductClassification.takeCareMyself",
                        value: "no",
                        forKey: "help-with-swiss-tariff",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        forKey: "who-create-tariff-numbers",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label3",
                                type: "label",
                                label: "onboarding.productDetails.initialProductClassification.whoShouldCreateTariffNumbers",
                            },
                            {
                                key: "who-create-tariff-numbers",
                                type: "select",
                                label: "onboarding.productDetails.initialProductClassification.pleaseSelect",
                                options: {
                                    customOrAnotherProvider: "onboarding.productDetails.initialProductClassification.customOrAnotherProvider",
                                    viaMSDirect: "onboarding.productDetails.initialProductClassification.viaMSDirect",
                                },
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "help-with-swiss-tariff",
                            targetValue: "no",
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "download-template-tariffing",
            infoboxText: "onboarding.productDetails.initialProductClassification.tariffingDownloadInfoText",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: {
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label1",
                            type: "h3",
                            label: "onboarding.productDetails.initialProductClassification.downloadTemplateTariffing",
                        },
                        {
                            key: "label2",
                            type: "label",
                            label: "onboarding.productDetails.initialProductClassification.exampleDocumentAndTemplate",
                        },
                        {
                            key: "tariffing-file",
                            type: "label",
                            label: [
                                {
                                    href: "",
                                    targetBlank: true,
                                    displayLabel: "onboarding.productDetails.initialProductClassification.downloadExampleHere",
                                },
                            ],
                        },
                    ],
                },
            ],
        }),
    },
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "upload-template-tariffing",
            infoboxText: "onboarding.productDetails.initialProductClassification.tariffingUploadInfoText",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: {
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label1",
                            type: "h3",
                            label: "onboarding.productDetails.initialProductClassification.uploadTariffingFile",
                        },
                        {
                            key: "label2",
                            type: "label",
                            label: "onboarding.productDetails.initialProductClassification.pleaseUploadTariffingFile",
                        },
                        {
                            key: "tariffing-file",
                            type: "file",
                            multiple: false,
                            label: "onboarding.uploadFilesArea.dropFilesHere",
                        },
                    ],
                },
            ],
        }),
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
