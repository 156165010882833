import { jsx as _jsx } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useDeleteDocumentByIdMutation } from "../../redux/clients.api";
export const DeleteFileModal = ({ handleHide, clientId, file, }) => {
    const [deleteDocument, { isLoading }] = useDeleteDocumentByIdMutation();
    const { t } = useTranslation();
    const toast = useToast();
    const handleSubmit = async () => {
        if (file) {
            const res = await deleteDocument({ clientId, documentId: file.id });
            if ("data" in res) {
                toast({
                    title: t("clients.detail.documents.deleteDocumentModal.success"),
                    status: "success",
                    duration: 3000,
                });
                handleHide();
            }
            else {
                toast({
                    title: t("error.badRequest.somethingWentWrong"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
    };
    return (_jsx(Modal, { title: t("clients.detail.documents.deleteDocumentModal.header"), isOpen: !!file, onClose: handleHide, primaryAction: handleSubmit, primaryTitle: t("clients.detail.documents.deleteDocumentModal.primaryCTA"), primaryActionLoading: isLoading, primaryVariant: "danger", children: _jsx(Text, { mt: "2", color: "grey.60", children: t("clients.detail.documents.deleteDocumentModal.subHeader", {
                name: (file === null || file === void 0 ? void 0 : file.name) || "",
            }) }) }));
};
