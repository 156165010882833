import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Flex, Skeleton, Tag, TagLabel, Text, } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "~/assets/icons/ChevronDownIcon";
import { Switch } from "~/components/Switch/Switch";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
export const Service = ({ title, children, isSwitchChecked = false, switchFn, isSwitchDisabled = false, isSwitchVisible = false, tagLabel = "", isTagVisible = false, tooltipLabel = "", isLoaded = true, }) => {
    const [accordionIndex, setAccordionIndex] = useState(-1);
    useEffect(() => {
        if (isSwitchChecked) {
            setAccordionIndex(0);
        }
        else {
            setAccordionIndex(-1);
        }
    }, [isSwitchChecked]);
    return (_jsx(Skeleton, { isLoaded: isLoaded, children: _jsx(Accordion, { allowToggle: true, variant: "service", index: accordionIndex, children: _jsx(AccordionItem, { isDisabled: !isSwitchChecked, children: ({ isExpanded }) => (_jsxs(_Fragment, { children: [_jsxs(AccordionButton, { display: "flex", justifyContent: "space-between", onClick: () => setAccordionIndex(accordionIndex === 0 ? -1 : 0), role: "group", children: [_jsxs(Flex, { alignItems: "center", gap: "4", children: [isSwitchVisible && (_jsx(TooltipWrapper, { hasTooltip: isSwitchDisabled, label: tooltipLabel, children: _jsx(Switch, { isChecked: isSwitchChecked, onChange: switchFn, marginRight: "0", isDisabled: isSwitchDisabled }) })), _jsx(Text, { textStyle: "h3Bold", children: title }), isTagVisible &&
                                            (isSwitchChecked ? (_jsx(Tag, { variant: "success", children: _jsx(TagLabel, { textStyle: "labelUppercase", children: tagLabel }) })) : (_jsx(Tag, { variant: "inactive", children: _jsx(TagLabel, { textStyle: "labelUppercase", children: tagLabel }) })))] }), _jsx(ChevronDownIcon, { transform: `rotate(${isExpanded ? "180deg" : "0deg"})`, width: "24px", height: "24px", color: "grey.40", display: isSwitchChecked ? "block" : "none", transition: "transform 0.3s", _groupHover: {
                                        color: "brand.black",
                                    } })] }), _jsx(AccordionPanel, { children: children })] })) }) }) }));
};
export const ServicePlaceholder = () => {
    const { t } = useTranslation();
    return (_jsx(Service, { title: t("basics.loading"), isSwitchChecked: false, switchFn: () => { }, isLoaded: false }));
};
