import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Circle, Flex, Tag, TagLabel, Text, useToast } from "@chakra-ui/react";
import { EImportStatus } from "@crossborderx/schemas-package";
import { useNavigate } from "@tanstack/react-location";
import { createColumnHelper } from "@tanstack/react-table";
import { formatRelative } from "date-fns";
import saveAs from "file-saver";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "~/assets/icons/DownloadIcon";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { RadioFilter, } from "~/components/FilterBar/components/RadioFilter/RadioFilter";
import Icon from "~/components/Icon/Icon";
import { Table } from "~/components/Table/Table";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { TooltipWrapper } from "~/components/TooltipWrapper/TooltipWrapper";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamFilter } from "~/hooks/useParamFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { ClassificationCasesStatus, ClassificationCasesStatusColor, ClassificationCasesStatusTranslationKey, } from "~/pages/customs-classification/enums/classification-case-status.enum";
import { importsBaseUrl, useGetAllCustomsClassificationCasesQuery, useLazyDownloadImportByIdQuery, } from "~/pages/customs-classification/redux/customsClassification.api";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { locale } from "~/utils/locale";
import { DownloadFileModal } from "../../../components/DownloadFileModal/DownloadFileModal";
export const ClassificationCasesTab = () => {
    const { token } = useAppSelector(authSelector);
    const toast = useToast();
    const { FAILED, PENDING, FILE_UPLOADING, INITIAL_PROCESSING, IN_PROGRESS_SHIP_FROM, IN_PROGRESS_SHIP_TO, COMPLETED, } = EImportStatus;
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const [filteredStatusType, setFilteredStatusType] = useParamFilter("status");
    // Download Files
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadedFiles, setDownloadedFiles] = useState([]);
    const [downloadImport] = useLazyDownloadImportByIdQuery();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: customsClassification, isLoading: isLoadingCustomsClassification, isFetching: isFetchingCustomsClassification, } = useGetAllCustomsClassificationCasesQuery({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        searchQuery,
        ...(!!filteredStatusType && { status: filteredStatusType.split(", ") }),
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id,
        }),
    }, { refetchOnMountOrArgChange: true });
    const statusConfig = (value) => {
        switch (value) {
            case FAILED:
                return ClassificationCasesStatus.FAILED;
            case FILE_UPLOADING:
                return ClassificationCasesStatus.FILE_PROCESSING;
            case INITIAL_PROCESSING:
                return ClassificationCasesStatus.FILE_PROCESSING;
            case COMPLETED:
                return ClassificationCasesStatus.COMPLETED;
            case IN_PROGRESS_SHIP_FROM:
                return ClassificationCasesStatus.IN_PROGRESS;
            case IN_PROGRESS_SHIP_TO:
                return ClassificationCasesStatus.IN_PROGRESS;
            default:
                return ClassificationCasesStatus.FILE_PROCESSING;
        }
    };
    const radioFilterOptions = [
        {
            label: t("customsClassification.filters.importType.all"),
            value: "",
        },
        {
            label: t("customsClassification.filters.importType.open"),
            value: `${IN_PROGRESS_SHIP_FROM},${IN_PROGRESS_SHIP_TO}`,
        },
        {
            label: t("customsClassification.filters.importType.finished"),
            value: COMPLETED,
        },
    ];
    const handleDownload = async (id) => {
        setShowDownloadModal(true);
        setDownloadedFiles([...downloadedFiles, id]);
        await downloadImport(id);
    };
    const handleFileStreamDownload = async (id) => {
        try {
            const res = await fetch(withBaseUrl(`${importsBaseUrl}/${id}/download`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.ok) {
                const data = await res.blob();
                saveAs(data, "fileName");
            }
        }
        catch (e) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const tableActions = (row) => [
        {
            label: t("basics.download"),
            element: _jsx(DownloadIcon, {}),
            onClick: () => {
                if (row.status === EImportStatus.COMPLETED) {
                    handleFileStreamDownload(row.id);
                }
                else {
                    handleDownload(row.id);
                }
            },
        },
    ];
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("id", {
            header: () => t("customsClassification.table.id"),
            cell: ({ getValue }) => getValue(),
            size: 80,
        }),
        columnHelper.accessor("status", {
            size: 160,
            header: () => t("customsClassification.table.status"),
            cell: ({ row }) => {
                const { status } = row.original;
                return useMemo(() => {
                    const color = ClassificationCasesStatusColor[statusConfig(status)];
                    const translationKey = ClassificationCasesStatusTranslationKey[statusConfig(status)];
                    const hasInitialClassificationTag = row.original.fileName.includes("initial_");
                    return (_jsxs(Flex, { gap: 3, alignItems: "center", children: [_jsx(Circle, { size: 4, backgroundColor: color }), _jsx(Text, { children: t(`customsClassification.status.${translationKey}`) }), hasInitialClassificationTag && (_jsx(TooltipWrapper, { label: t("customsClassification.initialClassification.tooltip"), hasTooltip: true, children: _jsx(Tag, { variant: "secondary", children: _jsx(TagLabel, { children: t("customsClassification.initialClassification.tag") }) }) }))] }));
                }, [status]);
            },
        }),
        columnHelper.display({
            id: "status",
            size: 140,
            header: () => t("customsClassification.table.step"),
            cell: ({ row }) => t(`customsClassification.shipDirection.${row.original.status === EImportStatus.IN_PROGRESS_SHIP_TO
                ? "shipTo"
                : "shipFrom"}`),
        }),
        columnHelper.accessor("createdAt", {
            size: 250,
            header: () => t("customsClassification.table.timeOfImport"),
            cell: ({ getValue }) => formatRelative(new Date(getValue()), new Date(), { locale }),
        }),
        columnHelper.accessor("articlesCount", {
            header: () => t("customsClassification.table.datasets"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.display({
            id: "actions",
            size: undefined,
            header: () => t("customsClassification.table.actions"),
            cell: ({ row }) => {
                if (!(row.original.status === INITIAL_PROCESSING ||
                    row.original.status === PENDING)) {
                    if (downloadedFiles.includes(row.original.id)) {
                        return (_jsx(TooltipWrapper, { hasTooltip: true, label: t("customsClassification.downloadModal.tableIndicator"), children: _jsx(Icon, { width: "5", height: "5", color: "grey.20", name: "please-wait" }) }));
                    }
                    return renderTableRowActions({
                        tableActions: tableActions(row.original),
                    });
                }
                return null;
            },
        }),
    ];
    const getRowIsInvalid = (status) => [FILE_UPLOADING, INITIAL_PROCESSING, FAILED].includes(status);
    return (_jsxs(_Fragment, { children: [_jsx(Text, { textStyle: "h3", mt: "8", mb: "6", children: t("customsClassification.tabs.classificationCases.title") }), _jsxs(FilterBar, { children: [_jsx(FilterSearch, { disabled: isLoadingCustomsClassification, value: search, setValue: setSearch, placeholder: t("customsClassification.table.searchById") }), _jsx(RadioFilter, { options: radioFilterOptions, setSelectedValue: (value) => setFilteredStatusType(value) })] }), _jsx(Table, { columns: columns, data: customsClassification, sorting: sorting, onRowClick: ({ original: { id, status } }) => getRowIsInvalid(status) ? undefined : navigate({ to: id }), onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: setPagination, isLoading: isLoadingCustomsClassification, isFetching: isFetchingCustomsClassification, getRowStyles: (row) => ({
                    cursor: getRowIsInvalid(row.original.status) ? "default" : "pointer",
                    opacity: row.original.status === COMPLETED ? "0.37" : "1",
                }) }), _jsx(DownloadFileModal, { isOpen: showDownloadModal, handleClose: () => setShowDownloadModal(false) })] }));
};
