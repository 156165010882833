import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { translatedCountryName } from "~/helpers/country-codes";
export const ClientContactInfo = ({ client, }) => {
    const { t } = useTranslation();
    const hasContactDetails = !!(!!(client === null || client === void 0 ? void 0 : client.address) ||
        ((client === null || client === void 0 ? void 0 : client.phoneCode) && client.phoneNumber) ||
        (client === null || client === void 0 ? void 0 : client.email) ||
        (client === null || client === void 0 ? void 0 : client.website));
    return (_jsxs(_Fragment, { children: [_jsx(Text, { mt: 8, pb: 3, borderBottom: "1px solid", borderBottomColor: "grey.10", textStyle: "labelUppercase", mb: 4, children: t("clients.drawer.contactHeader") }), hasContactDetails ? (_jsxs(_Fragment, { children: [client.address && (_jsxs(Flex, { alignItems: "flex-start", mb: 4, children: [_jsx(Box, { w: "34px", children: _jsx(Icon, { w: "16px", name: "location", color: "grey.40" }) }), _jsxs(Text, { textStyle: "body", children: [client.address.street, " ", client.address.number, ",", " ", client.address.zipCode, " ", client.address.city, ",", " ", translatedCountryName(client.address.country)] })] })), client.phoneCode && client.phoneNumber && (_jsxs(Flex, { alignItems: "flex-start", mb: 4, children: [_jsx(Box, { w: "34px", children: _jsx(Icon, { w: "16px", name: "phone", color: "grey.40" }) }), _jsx(Text, { textStyle: "body", _hover: { textDecoration: "underline" }, children: _jsxs("a", { href: `tel:${client.phoneCode}${client.phoneNumber}`, children: [client.phoneCode, " ", client.phoneNumber] }) })] })), client.email && (_jsxs(Flex, { alignItems: "flex-start", mb: 4, children: [_jsx(Box, { w: "34px", children: _jsx(Icon, { w: "16px", name: "mail", color: "grey.40" }) }), _jsx(Text, { textStyle: "body", _hover: { textDecoration: "underline" }, children: _jsx("a", { href: `mailto:${client.email}`, children: client.email }) })] })), client.website && (_jsxs(Flex, { alignItems: "flex-start", mb: 4, children: [_jsx(Box, { w: "34px", children: _jsx(Icon, { w: "16px", name: "link", color: "grey.40" }) }), _jsx(Text, { textStyle: "body", _hover: { textDecoration: "underline" }, children: _jsx("a", { href: client.website, target: "_blank", rel: "noreferrer", children: client.website }) })] }))] })) : (_jsx(Text, { mb: 4, color: "grey.40", children: t("clients.drawer.noContactDetailsText") }))] }));
};
