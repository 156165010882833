import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, HStack, IconButton, StackDivider, Text, VStack, useToast, } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "~/assets/icons/close.svg";
import { apiBaseUrl } from "~/helpers/withBaseUrl";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { clientServicesUrl, useUploadClientDocumentMutation, } from "~/pages/clients/redux/clients.api";
import { useAppSelector } from "~/store/hooks";
import FileIcon from "../FileIcon/FileIcon";
function FileItem({ file, handleOnRemoveFile, index, isDisabled, totalFiles, handlerUploadFile, }) {
    const { t } = useTranslation();
    const { params: { id }, } = useMatch();
    const { token } = useAppSelector(authSelector);
    const uploaded = useRef(false);
    const toast = useToast();
    const [uploadFile, { isLoading: isUploading }] = useUploadClientDocumentMutation();
    const uploadFilesArea = async () => {
        const formData = new FormData();
        formData.append("file", file.file);
        formData.append("group", "onboarding");
        const res = await uploadFile({
            clientId: Number(id),
            body: formData,
        }).unwrap();
        const newFile = {
            ...file,
            uploaded: true,
            uploading: false,
            documentId: res.id,
            uploadedUrl: res.fileUrl,
            progress: 100,
        };
        handlerUploadFile(newFile);
    };
    useEffect(() => {
        if (!uploaded.current && !file.uploaded) {
            uploaded.current = true;
            uploadFilesArea();
        }
    }, []);
    const formatBytes = (bytes, decimals) => {
        if (bytes === 0)
            return "0 bytes";
        const k = 1024;
        const dm = decimals || 2;
        const sizes = ["bytes", "kb", "mb", "gb"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    };
    const openFile = async () => {
        if (!file || !file.uploadedUrl)
            return;
        try {
            const res = await fetch(`${apiBaseUrl}${clientServicesUrl}/${id}/documents/${file.documentId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "image/jpeg",
                    Authorization: `Bearer ${token}`,
                },
            });
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.file.name);
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            toast({
                title: t("error.badRequest.somethingWentWrong"),
                status: "error",
                duration: 3000,
            });
        }
    };
    return (_jsx(HStack, { w: "full", h: "auto", py: "2", pr: "4", children: _jsxs(VStack, { w: "full", children: [_jsxs(HStack, { w: "full", justifyContent: "space-between", children: [_jsxs(Flex, { w: "full", fontSize: "sm", width: "full", alignItems: "center", children: [_jsx(Box, { w: "6", h: "12", marginRight: "2", opacity: isUploading ? 0.5 : 1, children: _jsx(FileIcon, { file: file }) }), _jsxs(Box, { w: "full", marginLeft: 2, display: "flex", justifyContent: "flex-start", children: [_jsx(Text, { noOfLines: 1, maxW: "70%", children: _jsx("a", { style: { cursor: "pointer" }, 
                                                // biome-ignore lint/a11y/useValidAnchor: <explanation>
                                                onClick: async () => {
                                                    await openFile();
                                                }, children: file.file.name }) }), _jsxs(Text, { as: "span", children: [" - ", formatBytes(file.file.size, 1)] })] })] }), !isUploading && !isDisabled && (_jsx(IconButton, { "aria-label": t("onboarding.uploadFilesArea.removeFile"), variant: "link", icon: _jsx(CloseIcon, {}), colorScheme: "secondary", onClick: () => handleOnRemoveFile(file) }))] }), index !== totalFiles - 1 && (_jsx(StackDivider, { borderColor: "secondary.100" }))] }) }, file.file.name));
}
export default FileItem;
