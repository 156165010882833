import { useNavigate, useSearch } from "@tanstack/react-location";
import { useEffect, useRef, useState } from "react";
import { DEFAULT_PAGINATION } from "~/consts/pagination";
export const useDelayedSearchFilter = () => {
    const search = useSearch();
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const [queryValue, setQueryValue] = useState(search.search);
    const [displayValue, setDisplayValue] = useState(search.search);
    // eslint-disable-next-line
    const searchTimer = useRef(null);
    useEffect(() => {
        if (isMounted.current) {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }
            searchTimer.current = setTimeout(() => {
                setQueryValue(displayValue);
                navigate({
                    search: (prev) => ({
                        ...prev,
                        search: displayValue,
                        page: DEFAULT_PAGINATION.pageIndex,
                    }),
                    replace: true,
                });
            }, 500);
        }
        else {
            isMounted.current = true;
        }
    }, [displayValue]); // eslint-disable-line
    return [displayValue, setDisplayValue, queryValue];
};
