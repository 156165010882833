import { completedFormNodesAndSchema, overviewFormNodesAndSchema, returnClosureFormNodesAndSchema, returnDeliveryProcessFormNodesAndSchema, returnPickUpFormNodesAndSchema, returnProcessingFormNodesAndSchema, returnStartFormNodesAndSchema, returnWarehouseLocationFormNodesAndSchema, } from "../schemas/inbound-process";
export const INBOUND_PROCESS = {
    inbound_process: {
        initial: "overview",
        id: "inbound_process",
        meta: {
            eventName: "INBOUND_PROCESS",
            label: "onboarding.inboundProcesses.title",
        },
        onDone: "technical_setup",
        states: {
            overview: {
                meta: {
                    label: "onboarding.overview",
                    ...overviewFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_start",
                },
            },
            return_start: {
                meta: {
                    label: "onboarding.inboundProcesses.returns.title",
                    ...returnStartFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_processing",
                },
            },
            return_processing: {
                meta: {
                    label: "onboarding.inboundProcesses.returnProcessing.title",
                    ...returnProcessingFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_closure",
                },
            },
            return_closure: {
                meta: {
                    label: "onboarding.inboundProcesses.returnClosure.title",
                    ...returnClosureFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_pick_up",
                },
            },
            return_pick_up: {
                meta: {
                    label: "onboarding.inboundProcesses.returnPickUp.title",
                    ...returnPickUpFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_delivery_process",
                },
            },
            return_delivery_process: {
                meta: {
                    label: "onboarding.inboundProcesses.returnPlaceOfPresent.title",
                    ...returnDeliveryProcessFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "return_warehouse_location",
                },
            },
            return_warehouse_location: {
                meta: {
                    label: "onboarding.inboundProcesses.returnWarehouseLocation.title",
                    ...returnWarehouseLocationFormNodesAndSchema,
                },
                on: {
                    SAVE: "overview",
                    NEXT: "completed",
                },
            },
            completed: {
                meta: {
                    label: "onboarding.steps.completed",
                    ...completedFormNodesAndSchema,
                },
                type: "final",
            },
        },
    },
};
