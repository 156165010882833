import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { UserItem } from "../UserItem/UserItem";
export const UserList = ({ users, isLoading, }) => {
    const { t } = useTranslation();
    if (isLoading || !users) {
        return (_jsxs(_Fragment, { children: [_jsx(Skeleton, { w: "full", h: "12", mb: "4" }), _jsx(Skeleton, { w: "full", h: "12", mb: "4" }), _jsx(Skeleton, { w: "full", h: "12", mb: "4" })] }));
    }
    return (_jsx(Box, { maxH: "600px", overflowY: "auto", children: users.length ? (users.map((user) => (_jsx(UserItem, { user: user }, `user-item-${user.id}`)))) : (_jsx(Text, { color: "grey.40", mt: 1, children: t("partners.detail.profile.noUsersText") })) }));
};
