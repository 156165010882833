import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button/Button";
import InputSuccess from "~/components/InputSuccess/InputSuccess";
export default function CashDepositBankDetails() {
    const { t } = useTranslation();
    const [textCopied, setTextCopied] = useState("0");
    const copyToClipboard = (text) => {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        el.remove();
    };
    return (_jsxs(VStack, { w: "full", spacing: "1 ", justifyContent: "flex-start", alignItems: "flex-start", mb: "1", children: [_jsxs(Flex, { direction: "column", gap: "5", children: [_jsxs(VStack, { w: "full", h: "full", alignItems: "flex-start", children: [_jsx(Text, { children: t("onboarding.vat.flow.transferingCashToDeposit.bank1.description") }), _jsx(Text, { children: t("onboarding.vat.flow.transferingCashToDeposit.bank1.account") })] }), _jsxs(Flex, { direction: "column", children: [_jsx(Button, { variant: "secondary", onClick: () => {
                                    copyToClipboard(t("onboarding.vat.flow.transferingCashToDeposit.bank1.accountCopyText"));
                                    setTextCopied("1");
                                }, children: t("onboarding.vat.flow.transferingCashToDeposit.copyIban") }), textCopied === "1" && _jsx(InputSuccess, { text: "FIELD_COPIED_SUCCESS" })] })] }), _jsxs(Flex, { direction: "column", gap: "5", children: [_jsxs(VStack, { w: "full", h: "full", alignItems: "flex-start", children: [_jsx(Text, { children: t("onboarding.vat.flow.transferingCashToDeposit.bank2.description") }), _jsx(Text, { children: t("onboarding.vat.flow.transferingCashToDeposit.bank2.account") })] }), _jsxs(Flex, { direction: "column", children: [_jsx(Button, { variant: "secondary", onClick: () => {
                                    copyToClipboard(t("onboarding.vat.flow.transferingCashToDeposit.bank2.accountCopyText"));
                                    setTextCopied("2");
                                }, children: t("onboarding.vat.flow.transferingCashToDeposit.copyIban") }), textCopied === "2" && _jsx(InputSuccess, { text: "FIELD_COPIED_SUCCESS" })] })] })] }));
}
