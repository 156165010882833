import { cssVar, extendTheme } from "@chakra-ui/react";
const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");
const SIDEBAR_WIDTH = "250px";
const theme = extendTheme({
    fonts: {
        body: "Ubuntu, sans-serif",
    },
    shadows: {
        card: "0px 0px 2px rgba(0, 0, 0, 0.25);",
        avatar: "1px 1px 4px rgba(0, 0, 0, 0.25)",
    },
    styles: {
        global: {
            "html, body": {
                fontWeight: "light",
                color: "gray.700",
            },
            h1: {
                fontWeight: "700",
                fontSize: ["40px"],
                lineHeight: ["48px"],
                color: "brand.mare",
            },
            h2: {
                fontWeight: "400",
                fontSize: ["32px"],
                lineHeight: ["36px"],
                color: "brand.mare",
            },
            h3: {
                fontWeight: "400",
                fontSize: ["24px"],
                lineHeight: ["32px"],
                color: "brand.mare",
            },
            h4: {
                fontWeight: "400",
                fontSize: ["20px"],
                lineHeight: ["24px"],
                color: "brand.mare",
            },
            h5: {
                fontWeight: "700",
                fontSize: ["16px"],
                lineHeight: ["24px"],
                color: "brand.mare",
            },
            h6: {
                fontWeight: "700",
                fontSize: ["14px"],
                lineHeight: ["20px"],
                color: "brand.mare",
            },
            p: {
                fontWeight: "400",
                fontSize: ["16px"],
                lineHeight: ["24px"],
                color: "brand.black",
            },
        },
    },
    textStyles: {
        h1: {
            fontSize: "40px",
            lineHeight: "48px",
            fontWeight: 700,
            color: "brand.mare",
        },
        h2: {
            fontSize: "32px",
            lineHeight: "36.77px",
            fontWeight: 400,
            color: "brand.mare",
        },
        h3: {
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 400,
            color: "brand.mare",
        },
        h3Bold: {
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 700,
            color: "brand.mare",
        },
        h4: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 400,
            color: "brand.mare",
        },
        label: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 700,
            color: "brand.mare",
        },
        labelBlack: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 700,
            color: "brand.black",
        },
        body: {
            fontSize: "16px",
            lineHeight: "6",
            fontWeight: 400,
            color: "brand.black",
        },
        bodyPlaceholder: {
            fontSize: "16px",
            lineHeight: "6",
            fontWeight: 400,
            color: "grey.40",
        },
        labelUppercase: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.02em",
            fontWeight: 500,
            textTransform: "uppercase",
            color: "brand.mare",
        },
        labelSmall: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 700,
            color: "brand.mare",
        },
        bodySmall: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            color: "brand.black",
        },
        bodySmaller: {
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "brand.mare",
        },
        p: {
            fontSize: "1rem",
        },
        caption: {
            fontSize: "0.75rem",
        },
        overline: {
            fontSize: "0.625rem",
            fontWeight: "normal",
        },
    },
    sizes: {
        headerHeight: "72px",
        sidebar: SIDEBAR_WIDTH,
        sidebarCollapsed: "56px",
        tabletBreakpoint: "768px",
        detailPageSideSection: "306px",
        servicesContainer: "708px",
    },
    colors: {
        button: {
            primary: {
                500: "#0092D4",
                600: "#0092D4",
            },
            dark: {
                500: "#083F66",
                600: "#083F66",
            },
        },
        brand: {
            white: "#FFFFFF",
            sky: "#0092D4",
            mare: "#083F66",
            black: "#2B2A28",
            pink: "#F48999",
        },
        sky: {
            5: "#F2F9FF",
            10: "#E6F5FB",
            20: "#CCE9F6",
            40: "#99D3EE",
            "50semi": "#0092D480",
            70: "#4CB3E1",
        },
        mareAlpha: {
            600: "#083F6699",
        },
        danger: {
            10: "#FFE5E5",
            20: "#FFCCCC",
            60: "#FF6666",
            500: "rgba(255, 0, 0, 1)",
        },
        success: {
            500: "#299F55",
            200: "rgba(13, 188, 18, 0.4)",
            100: "#0F6A42",
            80: "#299F55",
            60: "#53DD6C",
            40: "#98EBA7",
            20: "#DDF8E2",
            10: "#EEFCF0",
        },
        warning: {
            200: "rgba(255, 168, 0, 0.2)",
            500: "rgba(255, 168, 0, 1)",
            100: "#FFB038",
            60: "#FFCF88",
            40: "#FFDFB0",
            20: "#FFEFD7",
            10: "#FFF7EB",
        },
        grey: {
            5: "#F1F5F6",
            10: "#E0E4E7",
            20: "#C1C9CF",
            40: "#8294A0",
            60: "#445F71",
            backgroundBlack: "rgba(0, 0, 0, 0.5)",
            backgroundLight: "#FBFBFB",
            backgroundGray: "rgba(204, 204, 204, 0.5)",
        },
        misc: {
            teal: "#13C9AA",
        },
        primary: {
            100: "rgba(6, 64, 103, 0.4)",
            200: "rgba(6, 64, 103, 0.6)",
            300: "rgba(6, 64, 103, 0.7)",
            350: "rgba(83, 121, 148,1)",
            400: "rgba(56, 102, 133, 1)",
            500: "rgba(6, 64, 103, 1)",
        },
        secondary: {
            50: "rgba(0, 146, 212, 0.1)",
            100: "rgba(0, 146, 212, 0.2)",
            200: "rgba(0, 146, 212, 0.3)",
            300: "rgba(153, 211, 238, 1)",
            400: "rgba(0, 146, 212, 0.7)",
            500: "rgba(0, 146, 212, 1)",
        },
        error: {
            500: "rgba(237, 141, 153, 1)",
        },
    },
    components: {
        Accordion: {
            variants: {
                service: {
                    container: {
                        backgroundColor: "brand.white",
                        borderWidth: "1px",
                        borderColor: "grey.10",
                        px: 6,
                        py: 4,
                    },
                    button: {
                        p: 0,
                        _hover: {
                            background: "transparent",
                        },
                        _focus: {
                            boxShadow: "none",
                        },
                        _disabled: {
                            opacity: 1,
                            cursor: "default",
                        },
                    },
                    panel: {
                        mt: 4,
                        px: 0,
                        pt: 6,
                        pb: 2,
                        borderTopWidth: "1px",
                        borderTopColor: "grey.10",
                    },
                },
                sidebarService: {
                    container: {
                        p: 0,
                        border: 0,
                    },
                    button: {
                        width: "100%",
                        borderBottom: "1px solid",
                        borderBottomColor: "grey.10",
                        py: 0,
                        px: 0,
                        pb: 3,
                        justifyContent: "space-between",
                        _hover: {
                            background: "transparent",
                        },
                        _focus: {
                            outline: "none",
                            boxShadow: "none",
                        },
                    },
                    panel: {
                        px: 0,
                        pb: 0,
                        pt: 6,
                        mt: 2,
                    },
                },
            },
        },
        Text: {
            variants: {
                recommendationField: {
                    padding: "8px 12px",
                    border: "1px solid",
                    borderColor: "transparent",
                    borderRadius: "4px",
                    height: "36px",
                    backgroundColor: "transparent",
                    lineHeight: "19px",
                    cursor: "pointer",
                    transition: ".2s",
                    _hover: {
                        borderColor: "grey.20",
                        backgroundColor: "rgba(255,255,255,.5)",
                    },
                },
            },
        },
        Avatar: {
            sizes: {
                sm: {
                    container: {
                        width: "40px",
                        height: "40px",
                    },
                },
            },
            variants: {
                table: {
                    container: {
                        width: "32px",
                        height: "32px",
                        backgroundColor: "brand.mare",
                        color: "brand.white",
                    },
                    item: {
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                    },
                },
            },
        },
        Drawer: {
            parts: ["dialog", "header", "body"],
            variants: {
                sidebar: {
                    body: {
                        w: `${SIDEBAR_WIDTH} !important`,
                    },
                    dialog: {
                        w: `${SIDEBAR_WIDTH} !important`,
                    },
                },
                rightDrawer: {
                    dialog: {
                        boxSizing: "border-box",
                        p: 0,
                        maxW: "346px",
                        boxShadow: "none",
                    },
                    body: {
                        pY: 0,
                        pt: 12,
                        px: 6,
                    },
                },
                rightDrawerWide: {
                    dialog: {
                        boxSizing: "border-box",
                        p: 0,
                        maxW: "507px",
                        boxShadow: "none",
                    },
                    body: {
                        paddingX: 0,
                        pt: 12,
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                _disabled: {
                    background: "grey.10",
                    color: "grey.40",
                    cursor: "default",
                    opacity: 1,
                },
                _hover: {
                    _disabled: {
                        background: "grey.10",
                    },
                },
            },
            variants: {
                primary: {
                    background: "brand.sky",
                    color: "brand.white",
                },
                green: {
                    background: "success.500",
                    color: "brand.white",
                },
                secondary: {
                    background: "brand.white",
                    borderColor: "brand.mare",
                    borderWidth: "1px",
                    color: "brand.mare",
                    _disabled: {
                        borderColor: "transparent",
                    },
                },
                danger: {
                    background: "danger.500",
                    color: "brand.white",
                },
                select: {
                    background: "transparent",
                },
                link: {
                    _loading: {
                        background: "transparent",
                        justifyContent: "flex-start",
                        _hover: {
                            background: "transparent",
                        },
                    },
                },
            },
            defaultProps: {
                variant: "primary",
            },
            sizes: {
                lg: {
                    height: "48px",
                    fontSize: "16px",
                    lineHeight: "48px",
                    paddingX: 4,
                    fontWeight: 700,
                },
                md: {
                    height: "40px",
                    lineHeight: "40px",
                    fontSize: "16px",
                    paddingX: 3,
                    fontWeight: 700,
                },
                sm: {
                    height: "32px",
                    lineHeight: "32px",
                    fontSize: "14px",
                    paddingX: 3,
                    fontWeight: 700,
                },
            },
        },
        Modal: {
            sizes: {
                md: {
                    dialog: {
                        w: "547px",
                        minW: "547px",
                    },
                },
            },
            baseStyle: {
                header: {
                    padding: 0,
                    fontSize: "32px",
                    lineHeight: "40px",
                    color: "brand.mare",
                },
                dialog: {
                    borderRadius: "none",
                    padding: 10,
                },
                body: {
                    paddingX: 0,
                    paddingTop: 0,
                    paddingBottom: 8,
                },
                closeButton: {
                    top: 4,
                    right: 4,
                    color: "grey.40",
                },
                footer: {
                    padding: 0,
                },
            },
        },
        Checkbox: {
            sizes: {
                md: {
                    control: {
                        boxSize: 5,
                        borderRadius: "4px",
                        backgroundColor: "brand.white",
                    },
                },
            },
            baseStyle: {
                label: {
                    marginLeft: 3,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                },
                control: {
                    padding: 0,
                    _checked: {
                        bg: "brand.sky",
                        borderColor: "brand.sky",
                    },
                },
            },
        },
        Editable: {
            baseStyle: {
                preview: {
                    fontSize: "16px",
                    lineHeight: "26px",
                    height: "36px",
                    paddingLeft: "8px",
                    width: "100%",
                    border: "1px solid",
                    overflow: "hidden",
                    borderColor: "transparent",
                    _hover: {
                        borderColor: "grey.20",
                    },
                },
                input: {
                    fontSize: "16px",
                    lineHeight: "26px",
                    backgroundColor: "#ffffff",
                    paddingLeft: "8px",
                    paddingTop: "3px",
                    width: "100%",
                },
            },
            variants: {
                table: {
                    input: {
                        borderWidth: 1,
                        borderColor: "grey.20",
                        height: "36px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                        _focus: {
                            borderColor: "grey.60",
                            boxShadow: "none",
                        },
                    },
                },
                tableEmpty: {
                    preview: {
                        borderWidth: 1,
                        borderColor: "grey.20",
                        height: "36px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                    },
                    input: {
                        borderWidth: 1,
                        borderColor: "grey.20",
                        height: "36px",
                        width: "100%",
                        backgroundColor: "#ffffff",
                        _focus: {
                            borderColor: "grey.60",
                            boxShadow: "none",
                        },
                    },
                },
                tableError: {
                    preview: {
                        borderWidth: 1,
                        borderColor: "danger.500",
                        backgroundColor: "#ffffff",
                        height: "36px",
                        width: "100%",
                        _hover: {
                            borderColor: "danger.500",
                        },
                    },
                    input: {
                        borderWidth: 1,
                        borderColor: "danger.500",
                        height: "36px",
                        backgroundColor: "#ffffff",
                        width: "100%",
                        _focus: {
                            borderColor: "danger.500",
                            boxShadow: "none",
                        },
                    },
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    color: "brand.black",
                    lineHeight: "24px",
                },
            },
            sizes: {
                lg: {
                    field: {
                        h: "48px",
                        p: 3,
                        fontSize: "16px",
                    },
                },
                md: {
                    field: {
                        h: "40px",
                        paddingY: 2,
                        paddingX: 3,
                        fontSize: "16px",
                    },
                },
                table: {
                    field: {
                        h: "36px",
                        paddingY: 2,
                        paddingX: 3,
                        fontSize: "16px",
                    },
                },
            },
            defaultProps: {
                size: "lg",
                variant: "white",
            },
            variants: {
                white: {
                    field: {
                        border: "1px solid",
                        borderColor: "grey.20",
                        borderRadius: "4px",
                        background: "white",
                        focusBorderColor: "#000",
                        _placeholder: {
                            color: "grey.60",
                        },
                        _invalid: {
                            borderColor: "danger.500",
                        },
                        _disabled: {
                            color: "grey.40",
                            backgroundColor: "grey.5",
                            borderColor: "grey.10",
                            _placeholder: {
                                color: "grey.40",
                            },
                        },
                        _focus: {
                            borderColor: "grey.60",
                        },
                    },
                },
            },
        },
        Textarea: {
            baseStyle: {
                field: {
                    color: "brand.black",
                    lineHeight: "24px",
                },
            },
            variants: {
                white: {
                    border: "1px solid",
                    borderColor: "grey.20",
                    borderRadius: "4px",
                    background: "white",
                    focusBorderColor: "#000",
                    _placeholder: {
                        color: "grey.60",
                    },
                    _invalid: {
                        errorBorderColor: "danger.500",
                        borderColor: "danger.500",
                    },
                    _disabled: {
                        color: "grey.40",
                        backgroundColor: "grey.5",
                        borderColor: "grey.10",
                        _placeholder: {
                            color: "grey.40",
                        },
                    },
                    _focus: {
                        borderColor: "grey.60",
                    },
                },
            },
            defaultProps: {
                variant: "white",
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    backgroundColor: "brand.white",
                },
            },
            variants: {
                noWrap: {
                    label: {
                        whiteSpace: "nowrap",
                    },
                },
            },
        },
        Skeleton: {
            baseStyle: {
                borderRadius: "4px",
                [$startColor.variable]: "rgba(224, 228, 231, 0.2)",
                [$endColor.variable]: "rgba(224, 228, 231, 1)",
            },
        },
        Menu: {
            baseStyle: {
                list: {
                    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
                    border: "grey.20",
                },
            },
            variants: {
                actions: {
                    list: {
                        p: 0,
                        borderRadius: "4px",
                        borderWidth: "1px",
                        borderColor: "grey.10",
                    },
                    item: {
                        display: "flex",
                        gap: 4,
                        backgroundColor: "brand.white",
                        _hover: {
                            backgroundColor: "grey.5",
                        },
                        borderBottomWidth: "1px",
                        borderBottomColor: "grey.10",
                        _first: {
                            borderTopRadius: "4px",
                        },
                        _last: {
                            borderBottomWidth: 0,
                            borderBottomRadius: "4px",
                        },
                    },
                },
                countryDropdown: {
                    list: {
                        p: 0,
                        borderRadius: 0,
                        border: 0,
                    },
                    item: {
                        display: "flex",
                        gap: 3,
                        backgroundColor: "brand.white",
                        borderBottomWidth: "1px",
                        borderBottomColor: "grey.10",
                        py: 2,
                        px: 4,
                        _hover: {
                            backgroundColor: "grey.5",
                        },
                    },
                },
            },
        },
        Select: {
            variants: {
                articleEdit: {
                    field: {
                        height: 12,
                        _disabled: {
                            background: "grey.5",
                            color: "grey.60",
                            border: "1px solid",
                            borderColor: "grey.20",
                            opacity: 1,
                        },
                    },
                },
                formSelect: {
                    field: {
                        height: 12,
                        border: "1px solid",
                        borderColor: "grey.20",
                        borderRadius: "4px",
                        background: "white",
                        focusBorderColor: "#000",
                        px: 3,
                        _placeholder: {
                            color: "grey.60",
                        },
                        _invalid: {
                            borderColor: "danger.500",
                        },
                        _disabled: {
                            color: "grey.60",
                            backgroundColor: "grey.5",
                            borderColor: "grey.20",
                            opacity: 1,
                            _placeholder: {
                                color: "grey.60",
                            },
                        },
                        _focus: {
                            borderColor: "grey.60",
                        },
                    },
                },
                table: {
                    field: {
                        borderWidth: 1,
                        borderColor: "grey.20",
                        px: 2,
                        py: 1,
                        fontSize: "16px",
                        lineHeight: "24px",
                        height: "36px",
                        _focus: {
                            borderColor: "grey.60",
                            boxShadow: "none",
                        },
                    },
                },
                tableEmpty: {
                    field: {
                        borderWidth: 1,
                        borderColor: "grey.20",
                        width: 200,
                        px: 2,
                        py: 1,
                        fontSize: "16px",
                        lineHeight: "24px",
                        _focus: {
                            borderColor: "grey.60",
                            boxShadow: "none",
                        },
                    },
                },
                tableError: {
                    field: {
                        borderWidth: 1,
                        borderColor: "danger.500",
                        width: 200,
                        px: 2,
                        py: 1,
                        fontSize: "16px",
                        lineHeight: "24px",
                        _focus: {
                            borderColor: "danger.500",
                            boxShadow: "none",
                        },
                    },
                },
            },
        },
        Tag: {
            variants: {
                country: {
                    container: {
                        background: "brand.white",
                        borderRadius: "full",
                        borderWidth: 1,
                        borderColor: "grey.20",
                        height: "8",
                    },
                    closeButton: {
                        marginLeft: 0,
                        color: "brand.mare",
                        opacity: 1,
                        width: "20px",
                        height: "20px",
                    },
                },
                disabledCountry: {
                    container: {
                        background: "grey.5",
                        borderRadius: "full",
                        borderWidth: 1,
                        borderColor: "grey.20",
                        height: "8",
                    },
                    closeButton: {
                        marginLeft: 0,
                        color: "brand.mare",
                        opacity: 1,
                        width: "20px",
                        height: "20px",
                    },
                },
                success: {
                    container: {
                        background: "success.500",
                        borderRadius: "full",
                    },
                    label: {
                        color: "brand.white",
                    },
                },
                inactive: {
                    container: {
                        background: "white",
                        borderRadius: "full",
                        border: "1px solid",
                        borderColor: "grey.10",
                    },
                    label: {
                        color: "grey.40",
                    },
                },
                secondary: {
                    container: {
                        borderWidth: 1,
                        borderRadius: "32px",
                        borderColor: "brand.mare",
                        py: "2px",
                        px: "8px",
                    },
                    label: {
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        color: "brand.mare",
                    },
                },
            },
        },
    },
});
export default theme;
