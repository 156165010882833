import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Button, ChakraProvider, Flex, Modal as CUIModal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
export const Modal = ({ title, isOpen, onClose, primaryAction, primaryActionLoading = false, primaryTitle, primaryType = "button", primaryVariant = "primary", primaryActionDisabled, children, secondaryAction, size = "md", isCentered = true, secondaryTitle, hideSecondaryAction = false, }) => {
    const { t } = useTranslation();
    const initialRef = useRef(null);
    return (_jsx(ChakraProvider, { theme: theme, children: _jsxs(CUIModal, { isOpen: isOpen, onClose: onClose, initialFocusRef: initialRef, size: size, isCentered: isCentered, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: title }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: children }), _jsx(ModalFooter, { children: _jsxs(Flex, { justifyContent: "space-between", width: "full", children: [!hideSecondaryAction && (_jsx(Button, { size: "md", variant: "outline", color: "brand.black", colorScheme: "button.dark", background: "white", onClick: secondaryAction || onClose, ref: initialRef, children: secondaryTitle || t("customModal.cancel") })), _jsx(Button, { size: "md", variant: primaryVariant, type: primaryType === "submit" ? "submit" : "button", onClick: primaryAction, isDisabled: primaryActionDisabled || primaryActionLoading, isLoading: primaryActionLoading, children: primaryTitle })] }) })] })] }) }));
};
