import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { useCreatePartnerMutation } from "../../redux/partners.api";
export const CreatePartnerModal = ({ show, handleHide, }) => {
    const [createKey, { isLoading, isError, reset }] = useCreatePartnerMutation();
    const [partnerName, setPartnerName] = useState("");
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (show)
            setPartnerName("");
    }, [show]);
    const handleSubmit = async () => {
        if (partnerName) {
            const res = await createKey(partnerName.trim());
            if ("data" in res) {
                navigate({ to: res.data.id });
                toast({
                    title: t("partners.createPartnerSuccess"),
                    status: "success",
                    duration: 3000,
                });
            }
        }
    };
    const handleChange = (e) => {
        if (isError)
            reset();
        setPartnerName(e.target.value);
    };
    return (_jsx(Modal, { title: t("partners.createPartnerModalTitle"), isOpen: show, onClose: handleHide, primaryAction: handleSubmit, primaryTitle: t("partners.createPartnerModalCTA"), primaryActionLoading: isLoading, primaryActionDisabled: !partnerName, children: _jsxs(Box, { mt: 2, children: [_jsx(Text, { mb: 2, mt: 6, textStyle: "labelUppercase", children: t("partners.createPartnerModalInputLabel") }), _jsx(Input, { isInvalid: isError, value: partnerName, onChange: handleChange, placeholder: t("partners.createPartnerModalInputPlaceholder") }), isError && (_jsx(ErrorMessage, { text: t("error.badRequest.duplicatePartner") }))] }) }));
};
