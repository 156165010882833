import i18n from "~/i18n";
export default function importDe(flowFields) {
    var _a, _b, _c;
    const when = "-";
    let who = "";
    const whoHandlesReturns = (_a = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "whoHandlesReturns" && data.pageSlug === "returns")) === null || _a === void 0 ? void 0 : _a.fieldValue;
    if (whoHandlesReturns === "ms-direct") {
        who = i18n.t("processOverview.msDirect");
    }
    if (whoHandlesReturns === "we-ourselves") {
        who =
            ((_b = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" &&
                data.pageSlug === "contactInformation")) === null || _b === void 0 ? void 0 : _b.fieldValue) || "";
    }
    if (whoHandlesReturns === "other-patners") {
        who =
            ((_c = flowFields === null || flowFields === void 0 ? void 0 : flowFields.find((data) => data.fieldName === "companyName" && data.pageSlug === "returns")) === null || _c === void 0 ? void 0 : _c.fieldValue) || "";
    }
    const what = `${i18n.t("processOverview.inboundProcess.importDe.import")}
    <br>${i18n.t("processOverview.inboundProcess.importDe.preparation")}`;
    return {
        when,
        who,
        what,
        icon: "import-ch",
        title: i18n.t("processOverview.inboundProcess.importDe.title"),
        day: "1-4",
    };
}
