import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, List, ListItem, Text } from "@chakra-ui/react";
import { Link, useLocation } from "@tanstack/react-location";
import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { PermissionCheck } from "~/components/PermissionCheck/PermissionCheck";
export const SidebarItem = ({ iconName = "", textKey = "", to = "", permissions = [], collapsedItems, isCollapsed, setIsCollapsed, }) => {
    const [isItemCollapsed, setIsItemCollapsed] = useState(true);
    const [isSubItemActive, setIsSubItemActive] = useState(false);
    const { t } = useTranslation();
    const ts = (key) => t(`layout.sidebar.menuItems.${key}`);
    const { current } = useLocation();
    const pathArr = current.pathname.split("/");
    const isActive = `/${pathArr[1]}` === to;
    const basePath = "/";
    useEffect(() => {
        if (collapsedItems === null || collapsedItems === void 0 ? void 0 : collapsedItems.length) {
            setIsSubItemActive(collapsedItems
                .map((item) => basePath + item.to)
                .includes(current.pathname));
        }
    }, [current.pathname]);
    useEffect(() => {
        if (isCollapsed) {
            setIsItemCollapsed(true);
        }
    }, [isCollapsed]);
    useEffect(() => {
        if (!isItemCollapsed) {
            setIsCollapsed(false);
        }
    }, [isItemCollapsed]);
    const renderItem = () => (_jsxs(Flex, { shrink: 0, gap: "4", p: "4", borderBottom: "1px", borderColor: "whiteAlpha.100", backgroundColor: isActive || (isItemCollapsed && isSubItemActive) ? "whiteAlpha.100" : "", cursor: "pointer", maxH: "sidebarCollapsed", children: [_jsx(Icon, { name: iconName, strokeOpacity: isActive ? "1" : "0.7", fillOpacity: isActive ? "1" : "0.7" }), _jsxs(Flex, { justifyContent: "space-between", w: "full", children: [_jsx(Text, { whiteSpace: "nowrap", transition: "opacity 0.3s 0.2s", opacity: isCollapsed ? 0 : 1, color: isActive || (isCollapsed && isSubItemActive)
                            ? "brand.white"
                            : "whiteAlpha.700", fontWeight: isActive || (isItemCollapsed && isSubItemActive) ? 500 : 400, children: ts(textKey) }), (collapsedItems === null || collapsedItems === void 0 ? void 0 : collapsedItems.length) && (_jsx(Box, { transition: "opacity 0.3s 0.2s", opacity: isCollapsed ? 0 : 1, children: _jsx(Icon, { name: "chevron-up", transition: "all 0.3s", transform: `rotate(${isItemCollapsed ? "-180deg" : "0"})`, strokeOpacity: isCollapsed ? "0" : "0.7", fillOpacity: isCollapsed ? "0" : "0.7" }) }))] })] }));
    const renderSubItem = (item) => {
        var _a, _b, _c;
        const itemPathArr = (_b = (_a = item.to) === null || _a === void 0 ? void 0 : _a.split("/")) !== null && _b !== void 0 ? _b : [];
        const isItemActive = itemPathArr[itemPathArr.length - 1] === pathArr[pathArr.length - 1];
        const title = ts((item === null || item === void 0 ? void 0 : item.textKey) ? item.textKey : textKey);
        return (_jsx(PermissionCheck, { requiredPermissions: (_c = item.permissions) !== null && _c !== void 0 ? _c : [], children: _jsxs(Flex, { h: "sidebarCollapsed", shrink: 0, gap: "4", px: "4", alignItems: "center", borderBottom: "1px", borderColor: "whiteAlpha.100", backgroundColor: isItemActive ? "whiteAlpha.100" : "", cursor: "pointer", overflow: "hidden", children: [_jsx(Box, { boxSize: "24px", flexShrink: 0 }), _jsx(Text, { overflow: "hidden", title: title, transition: "opacity 0.3s 0.2s", opacity: isCollapsed ? 0 : 1, fontWeight: isItemActive ? 500 : 400, color: isItemActive ? "brand.white" : "whiteAlpha.700", textOverflow: "ellipsis", children: title })] }) }));
    };
    return (_jsx(PermissionCheck, { requiredPermissions: permissions, children: (collapsedItems === null || collapsedItems === void 0 ? void 0 : collapsedItems.length) ? (_jsxs(ListItem, { children: [_jsx(Box, { w: "full", textAlign: "start", as: "button", onClick: () => setIsItemCollapsed((prev) => !prev), children: renderItem() }), _jsx(Flex, { children: _jsx(List, { transition: "all 0.3s", h: isItemCollapsed ? "0" : "full", w: "full", opacity: isItemCollapsed ? 0 : 1, children: collapsedItems.map((item) => (_jsx(Link, { to: basePath + item.to, hidden: isItemCollapsed, children: renderSubItem(item) }, `${textKey}__${item.textKey}`))) }) })] })) : (_jsx(ListItem, { children: _jsx(Link, { to: basePath + to, children: renderItem() }) })) }));
};
