import { useMutation, useQuery, useQueryClient } from "react-query";
import { ONBOARDING_BASEURL } from "~/store/api";
import { get, put } from "../utils";
export function useAllTranslationsQuery() {
    return useQuery(["translations"], async () => {
        return get(`${ONBOARDING_BASEURL}/translations/all`);
    });
}
export function useEditTranslationsMutation() {
    const queryClient = useQueryClient();
    return useMutation(async (input) => {
        return put("/onboarding/api/translations", input);
    }, {
        onSuccess: () => queryClient.invalidateQueries(["translations"]),
    });
}
export function useBulkInsertTranslationsMutation() {
    const queryClient = useQueryClient();
    return useMutation(async (input) => {
        return put("/onboarding/api/translations/bulk", input);
    }, {
        onSuccess: () => queryClient.invalidateQueries(["translations"]),
    });
}
