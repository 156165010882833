/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clientSideConfig from "./client-side-config";
export const msalConfig = {
    auth: {
        clientId: clientSideConfig.adClientId,
        authority: clientSideConfig.adAuthority,
        redirectUri: clientSideConfig.adRedirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};
// Scopes for ID token to be used at Microsoft identity platform endpoints
export const loginRequest = {
    scopes: [clientSideConfig.adApiScope],
};
export const graphApiRequest = {
    scopes: ["User.ReadBasic.All"],
};
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
