import arrivalAtTheWarehouse from "./arrival-at-the-warehouse";
import arrivalOfReturnShipmentsAndProcessing from "./arrival-of-return-shipments-and-processing";
import cutOffInTheWarehouse from "./cut-off-in-the-warehouse";
import deliveryOfGoods from "./delivery-of-goods";
import exportCh from "./export-ch";
import importDe from "./import-de";
import transport from "./transport";
const processOverviews = [
    arrivalOfReturnShipmentsAndProcessing,
    cutOffInTheWarehouse,
    exportCh,
    importDe,
    transport,
    deliveryOfGoods,
    arrivalAtTheWarehouse,
];
export { hasAllFieldsFilled } from "../helpers";
export default processOverviews;
