import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuList, Radio, RadioGroup, Text, } from "@chakra-ui/react";
import Icon from "~/components/Icon/Icon";
export const FilterSingleSelect = ({ options, selectedOption, setSelectedOption, placeholder, menuLabel, disabled, }) => {
    var _a;
    const selectedOptionText = (_a = options.find((option) => option.value === selectedOption)) === null || _a === void 0 ? void 0 : _a.label;
    const isDisabled = disabled || !options.length;
    return (_jsxs(Menu, { children: [_jsx(MenuButton, { role: "group", disabled: isDisabled, cursor: isDisabled ? "default" : "pointer", children: _jsxs(InputGroup, { children: [_jsx(Input, { disabled: isDisabled, tabIndex: -1, color: selectedOption ? "brand.black" : "grey.60", _groupFocus: { borderColor: "brand.mare" }, type: "search", size: "md", placeholder: placeholder, value: selectedOptionText || "" }), _jsx(InputRightElement, { h: "full", children: _jsx(Icon, { name: "chevron-down", w: "12px", h: "6px", color: "grey.40" }) })] }) }), _jsxs(MenuList, { p: "0", maxH: 300, overflow: "auto", children: [menuLabel && (_jsx(Text, { pt: "2", pb: "3", textStyle: "labelUppercase", children: menuLabel })), _jsx(RadioGroup, { p: "0", onChange: setSelectedOption, value: selectedOption, children: _jsx(Flex, { flexDirection: "column", children: options.map(({ value, label }) => {
                                return (_jsx(Box, { py: "3", px: "3", borderBottomWidth: "1px", borderBottom: "1px solid", borderBottomColor: "grey.10", _last: { borderBottomWidth: 0 }, children: _jsx(Radio, { value: value, children: label }, value) }, `filter-single-select-${value}`));
                            }) }) })] })] }));
};
