import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, List, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { SidebarItem } from "~/layouts/app/components/SidebarItems/SidebarItem";
import sidebarItems from "~/layouts/app/components/SidebarItems/sidebarItems";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { useAppSelector } from "~/store/hooks";
const LOCAL_STORAGE_SIDEBAR_COLLAPSE_KEY = "isSidebarCollapsed";
export const Sidebar = ({ isMobile = false, }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { user } = useAppSelector(authSelector);
    const items = useMemo(() => {
        if (user === null || user === void 0 ? void 0 : user.userType) {
            return sidebarItems[user === null || user === void 0 ? void 0 : user.userType];
            // return sidebarItems.PARTNER; // TBD undo
        }
        return [];
    }, [user === null || user === void 0 ? void 0 : user.userType]);
    const { t } = useTranslation();
    useEffect(() => {
        var _a;
        if (!isMobile) {
            setIsCollapsed(JSON.parse((_a = localStorage.getItem(LOCAL_STORAGE_SIDEBAR_COLLAPSE_KEY)) !== null && _a !== void 0 ? _a : "false"));
        }
    }, [isMobile]);
    useEffect(() => {
        if (!isMobile) {
            localStorage.setItem(LOCAL_STORAGE_SIDEBAR_COLLAPSE_KEY, JSON.stringify(isCollapsed));
        }
    }, [isCollapsed, isMobile]);
    return (_jsxs(Flex, { direction: "column", justifyContent: "space-between", backgroundColor: "brand.mare", transition: "width 0.5s, min-width 0.5s", w: isCollapsed ? "sidebarCollapsed" : "sidebar", minW: isCollapsed ? "sidebarCollapsed" : "sidebar", height: "full", maxH: "calc(100vh - var(--chakra-sizes-headerHeight))", overflowY: "clip", overflowX: "hidden", children: [_jsx(List, { flexGrow: 1, overflowX: "hidden", overflowY: "auto", children: (items || []).map((item) => (_jsx(SidebarItem, { iconName: item.iconName, textKey: item.textKey, to: item.to, collapsedItems: item.collapsedItems, isCollapsed: isCollapsed, setIsCollapsed: setIsCollapsed, permissions: item.permissions }, `Sidebar__Item__${item.textKey}`))) }), !isMobile && (_jsxs(Flex, { as: "button", onClick: () => setIsCollapsed((prev) => !prev), shrink: 0, gap: "4", p: "4", cursor: "pointer", maxH: "sidebarCollapsed", children: [_jsx(Icon, { name: "left-arrow", transition: "transform 0.3s", transform: `rotate(${isCollapsed ? "-180deg" : "0"})` }), _jsx(Text, { transition: "opacity 0.3s 0.2s", opacity: isCollapsed ? 0 : 1, color: "whiteAlpha.700", whiteSpace: "nowrap", children: t("layout.sidebar.collapseMenu") })] }))] }));
};
