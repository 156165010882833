import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Box, Center, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, useMediaQuery, useTheme, } from "@chakra-ui/react";
import { Link } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import { useAuthDataQuery } from "~/api/hooks/users";
import { Avatar } from "~/components/Avatar/Avatar";
import Icon from "~/components/Icon/Icon";
import Image from "~/components/Image/Image";
import TextButton from "~/components/TextButton/TextButton";
import i18n, { supportedLanguages } from "~/i18n";
import { resetAuth } from "~/pages/auth/redux/auth.slice";
import { MobileSidebar } from "../MobileSidebar/MobileSidebar";
export const Header = () => {
    const { instance } = useMsal();
    const handleLogout = () => {
        resetAuth();
        instance.logout({ postLogoutRedirectUri: "/logout" });
    };
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const { sizes } = useTheme();
    const [isTablet] = useMediaQuery(`(max-width: ${sizes.container.md}`);
    const { data: authData } = useAuthDataQuery();
    const currentUser = authData === null || authData === void 0 ? void 0 : authData.user;
    useEffect(() => {
        if (!isTablet) {
            onClose();
        }
    }, [isTablet]);
    const { firstName = "", lastName = "", email = "" } = currentUser || {};
    return (_jsxs("header", { children: [_jsxs(Flex, { justifyContent: "space-between", alignItems: "center", backgroundColor: "white", height: "headerHeight", px: "8", children: [_jsx(Link, { to: "/", children: _jsx(Image, { name: "msdirect_logo", mimeType: "png" }) }), _jsxs(Flex, { py: "4", gap: "6", children: [_jsxs(Center, { gap: "2", as: "button", children: [_jsxs(Flex, { cursor: "default", direction: "column", justifyContent: "center", alignItems: "end", children: [_jsx(Text, { textStyle: "bodySmaller", color: "brand.mare", children: t("layout.header.loggedInAs") }), _jsx(Text, { textStyle: "bodySmaller", color: "brand.sky", children: firstName || lastName
                                                    ? [firstName, lastName].join(" ")
                                                    : email })] }), _jsxs(Menu, { children: [_jsx(MenuButton, { as: "div", children: currentUser && _jsx(Avatar, { user: currentUser, size: 40 }) }), _jsxs(MenuList, { children: [_jsx(Link, { to: "/profile", children: _jsx(MenuItem, { as: "div", children: t("profile.myProfile") }) }), _jsx(MenuItem, { as: "div", onClick: handleLogout, children: t("users.logout") }), _jsx(MenuItem, { as: "div", children: supportedLanguages
                                                            .map((languageOption) => (_jsx(TextButton, { as: "div", onClick: () => i18n.changeLanguage(languageOption), textTransform: "uppercase", fontWeight: "normal", ...(languageOption === i18n.resolvedLanguage
                                                                ? {
                                                                    color: "#F48999",
                                                                }
                                                                : {}), children: languageOption })))
                                                            .reduce((acc, x) => acc === null ? (x) : (_jsxs(_Fragment, { children: [acc, " | ", x] })), null) })] })] })] }), _jsx(Divider, { colorScheme: "brand.mare", orientation: "vertical", height: "40px" }), _jsx(Center, { hidden: !isTablet, children: _jsx(Box, { as: "button", onClick: () => (isOpen ? onClose() : onOpen()), boxSize: "32px", children: _jsx(Icon, { name: isOpen ? "close-menu" : "hamburger-menu", w: "full", h: "full" }) }) })] })] }), isTablet && _jsx(MobileSidebar, { isOpen: isOpen, onClose: onClose })] }));
};
