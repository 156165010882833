import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "~/assets/icons/ChevronDownIcon";
import { FlagIcon } from "~/components/FlagIcon/FlagIcon";
import { getCountryByIsoCode } from "~/utils/countries";
export const CountryDropdown = ({ countries, selectedCountry, setSelectedCountry, }) => {
    const { t } = useTranslation();
    const hasOptions = countries.length > 1;
    return (_jsx(Menu, { placement: "bottom-end", variant: "countryDropdown", children: ({ isOpen }) => (_jsxs(_Fragment, { children: [_jsx(MenuButton, { disabled: !hasOptions, pointerEvents: !hasOptions ? "none" : "initial", children: _jsxs(Flex, { gap: 4, alignItems: "center", children: [_jsx(FlagIcon, { countryCode: selectedCountry, boxSize: 12 }), _jsx(Text, { textStyle: "h2", color: "brands.mare", children: getCountryByIsoCode(selectedCountry) }), hasOptions && (_jsx(ChevronDownIcon, { boxSize: 5, transform: `rotate(${isOpen ? "180deg" : "0deg"})`, transition: "transform 300ms" }))] }) }), _jsx(MenuList, { children: countries.map((country) => (_jsxs(MenuItem, { onClick: () => setSelectedCountry(country), children: [_jsx(FlagIcon, { countryCode: country, boxSize: 5 }), _jsx(Text, { children: t("clients.detail.services.serviceCountry", { country }) })] }, `Service__Ship__To__Country__${country}`))) })] })) }));
};
