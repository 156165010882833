import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returnPlaceOfPresent.title",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                groupType: "radio",
                forKey: "who-clears-goods",
                options: {
                    pt: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label2",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.customDeclaration",
                    },
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.whoClearsTheGoods",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        elements: [
                            {
                                type: "radio",
                                key: "ms-direct",
                                label: "onboarding.inboundProcesses.returnPlaceOfPresent.msDirect",
                                value: "ms-direct",
                                forKey: "who-clears-goods",
                            },
                            {
                                type: "radio",
                                key: "other",
                                label: "onboarding.inboundProcesses.returnProcessing.whichBarcode.other",
                                value: "other",
                                forKey: "who-clears-goods",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                        },
                        elements: [
                            {
                                type: "layout",
                                direction: "vertical",
                                options: {
                                    pt: 2,
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "name",
                                        type: "text",
                                        label: "users.name",
                                    },
                                    {
                                        key: "contact-person",
                                        type: "text",
                                        label: "onboarding.companyDetails.contactPerson.title",
                                    },
                                    {
                                        type: "layout",
                                        direction: "horizontal",
                                        options: {
                                            gap: 3,
                                        },
                                        elements: [
                                            {
                                                key: "street",
                                                type: "text",
                                                label: "onboarding.companyInformation.street",
                                            },
                                            {
                                                key: "no",
                                                type: "text",
                                                label: "onboarding.companyInformation.number",
                                            },
                                        ],
                                    },
                                    {
                                        type: "layout",
                                        direction: "horizontal",
                                        options: {
                                            gap: 3,
                                        },
                                        elements: [
                                            {
                                                key: "zip-code",
                                                type: "text",
                                                label: "onboarding.companyInformation.zipCode",
                                            },
                                            {
                                                key: "city",
                                                type: "text",
                                                label: "onboarding.companyInformation.city",
                                            },
                                        ],
                                    },
                                    {
                                        key: "country",
                                        type: "customComponent",
                                        componentName: "base.CountrySelect",
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: "SHOW",
                            targetKey: "who-clears-goods",
                            targetValue: "other",
                        },
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                },
                elements: [
                    {
                        key: "label3",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.placePresentation",
                    },
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.whereAreGoodsPlaced",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                            gap: 3,
                        },
                        elements: [
                            {
                                key: "name",
                                type: "text",
                                label: "users.name",
                            },
                            {
                                key: "contact-person",
                                type: "text",
                                label: "onboarding.companyDetails.contactPerson.title",
                            },
                            {
                                type: "layout",
                                direction: "horizontal",
                                options: {
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "street",
                                        type: "text",
                                        label: "onboarding.companyInformation.street",
                                    },
                                    {
                                        key: "no",
                                        type: "text",
                                        label: "onboarding.companyInformation.number",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "horizontal",
                                options: {
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "zip-code",
                                        type: "text",
                                        label: "onboarding.companyInformation.zipCode",
                                    },
                                    {
                                        key: "city",
                                        type: "text",
                                        label: "onboarding.companyInformation.city",
                                    },
                                ],
                            },
                            {
                                key: "country",
                                type: "customComponent",
                                componentName: "base.CountrySelect",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    pt: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label3",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.frequencyCollection",
                    },
                    {
                        key: "often-good-transportaded",
                        type: "select",
                        label: "onboarding.inboundProcesses.returnPlaceOfPresent.oftenGoodsTransported",
                        options: {
                            daily: "onboarding.forms.frequency.daily",
                            fourTimesByWeek: "onboarding.forms.frequency.4xweek",
                            threeTimesByWeek: "onboarding.forms.frequency.3xweek",
                            twoTimesByWeek: "onboarding.forms.frequency.2xweek",
                            weekly: "onboarding.forms.frequency.weekly",
                            lessThanOnceByWeek: "onboarding.forms.frequency.lessThankWeekly",
                            onceInByMonth: "onboarding.forms.frequency.monthly",
                            lessThanOneByMonth: "onboarding.forms.frequency.lessThanMonthly",
                        },
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
