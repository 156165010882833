import codes from "country-calling-code";
import countries from "i18n-iso-countries";
import i18n from "~/i18n";
export const ALLOW_COUNTRIES = ["GB", "CH"];
export function getCountryCodeLabelKeyPairs() {
    return Object.fromEntries(codes.map((country) => [country.isoCode2, country.country]));
}
export function getCountryCodePhonePrefixKeyPairs() {
    return Object.fromEntries(codes.map((country) => [
        country.countryCodes[0],
        `${country.isoCode2}  (+${country.countryCodes[0]})`,
    ]));
}
export function translatedCountryName(code) {
    return countries.getName(code, i18n.languages[0], { select: "official" });
}
export const getCodeDropdownOptions = () => codes.flatMap((item) => item.countryCodes.map((codeItem) => ({
    value: `+${codeItem}`,
    label: `(+${codeItem}) ${translatedCountryName(item.isoCode2)}`,
    iso2: item.isoCode2.toLowerCase(),
})));
export const getSelectedCountryOption = (code) => {
    return { value: code !== null && code !== void 0 ? code : "", label: translatedCountryName(code !== null && code !== void 0 ? code : "") };
};
export const getCountryDropdownOptions = () => Object.entries(countries.getNames(i18n.languages[0])).map(([key, value]) => ({
    value: key,
    label: value,
}));
