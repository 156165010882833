import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, InputGroup, Textarea, } from "@chakra-ui/react";
import { forwardRef, memo, useImperativeHandle, useRef, } from "react";
function Component(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
{ name, label, value, onChange, error, showValidationIcon, placeholder, ...rest }, ref) {
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);
    return (_jsxs(FormControl, { isInvalid: Boolean(error), ...rest, children: [label ? _jsx(FormLabel, { htmlFor: name, children: label }) : null, _jsx(InputGroup, { size: "md", children: _jsx(Textarea, { id: name, ref: innerRef, placeholder: placeholder, name: name, defaultValue: value, onChange: onChange, bgColor: "secondary.50", borderColor: "secondary.300", rounded: "none", h: "32px" }) }), (error === null || error === void 0 ? void 0 : error.message) ? (_jsx(FormErrorMessage, { children: error.message })) : null] }));
}
export default memo(forwardRef(Component));
