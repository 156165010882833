import { Store } from "~/store/store";
export const PermissionCheck = ({ children, requiredPermissions, }) => {
    if (requiredPermissions.length === 0)
        return children;
    const userPermissions = Store.getState().auth.permissions;
    if (!userPermissions)
        return null;
    const hasPermission = requiredPermissions.some((permission) => userPermissions.includes(permission));
    return hasPermission ? children : null;
};
