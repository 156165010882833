import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
import Link from "~/components/Link/Link";
export function ApplicationVatUidNumberDeclarationOfFiscalRepresentationFertigCheck() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.application_vat_uid_number"), subtitle: t("onboarding.declarationOfFiscalRepresentation.declarationOfFiscalRepresentationFinishedCheck"), description: t("onboarding.declarationOfFiscalRepresentation.uploadedSignedDeclarationOfFiscalRepresentation") }), _jsxs(FormBody, { gap: "4", children: [_jsx(Text, { fontSize: "xl", children: t("onboarding.declarationOfFiscalRepresentation.applicationForVatNumber") }), _jsx(Text, { children: t("onboarding.declarationOfFiscalRepresentation.fillOutApplicationForVatNumber") }), _jsx(Text, { children: t("onboarding.declarationOfFiscalRepresentation.applicationForVatNumberAppointment") }), _jsx(Text, { children: t("onboarding.declarationOfFiscalRepresentation.applicationForVatNumberFirstStep") }), _jsx(Text, { children: t("onboarding.declarationOfFiscalRepresentation.applicationForVatNumberSecondStep") }), _jsx(Text, { children: t("onboarding.declarationOfFiscalRepresentation.applicationForVatNumberThirdStep") }), _jsx(Link, { to: "https://www.estv.admin.ch/estv/de/home/mehrwertsteuer/mwst-an-abmeldung/mwst-anmeldung-online.html", target: "_blank", children: t("onboarding.declarationOfFiscalRepresentation.linkToSwissTaxPage") })] }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
