import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useGetOnboardingFieldsFlows } from "~/api/hooks/onboarding";
import ProcessOverviewItem from "~/components/ProcessOverviewItem/ProcessOverviewItem";
import ProcessOverviewItemEmpty from "~/components/ProcessOverviewItemEmpty/ProcessOverviewItemEmpty";
import processOverviews, { hasAllFieldsFilled, } from "~/process-overviews/outbound-process";
export function OutBoundPCompleteProcess() {
    const { params: { id, flowId }, } = useMatch();
    const { data: flowFields } = useGetOnboardingFieldsFlows(id, flowId);
    let processOverviewItems = (_jsx(Box, { mt: "8", mb: "8", children: processOverviews.map((overview, index) => {
            return (_jsx(ProcessOverviewItem, { last: index + 1 === processOverviews.length, ...overview(flowFields) }));
        }) }));
    if (!hasAllFieldsFilled(processOverviews, flowFields)) {
        processOverviewItems = _jsx(ProcessOverviewItemEmpty, {});
    }
    return (_jsx(Box, { mt: "8", mb: "8", children: processOverviewItems }));
}
