import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    {
        type: "layout",
        direction: "vertical",
        elements: [
            {
                key: "label2",
                type: "customComponent",
                componentName: "outboundProcess.StepsOverview",
            },
        ],
    },
    schemaGenerators.formFooter(true),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
