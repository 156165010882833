import { jsx as _jsx } from "react/jsx-runtime";
import { Icon as IconBase, forwardRef } from "@chakra-ui/react";
import { useMemo } from "react";
export default forwardRef(({ name, ...rest }, ref) => {
    const Component = useMemo(() => {
        var _a;
        return (_a = require(`~/assets/icons/${name}.svg`).ReactComponent) !== null && _a !== void 0 ? _a : null;
    }, [name]);
    return _jsx(IconBase, { as: Component, w: 6, h: 6, ref: ref, ...rest });
});
