import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Editable, EditablePreview, Flex, Select as ChakraSelect, useEditableContext, } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
const Select = ({ fieldName, defaultValue, options = [], isError, }) => {
    const selectRef = useRef(null);
    const { getInputProps, onSubmit } = useEditableContext();
    const handleKeyDown = (event) => {
        var _a;
        if (event.key === "Escape") {
            (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    return (_jsx(ChakraSelect, { "aria-disabled": getInputProps()["aria-disabled"], autoFocus: true, w: "full", defaultValue: defaultValue, disabled: getInputProps().disabled, hidden: getInputProps().hidden, onBlur: () => onSubmit(), onChange: getInputProps().onChange, ref: selectRef, variant: isError ? "tableError" : !defaultValue ? "tableEmpty" : "table", children: options.map((option) => (_jsx("option", { value: option, children: option }, `Editable__Select__${fieldName}__${option}`))) }));
};
export const EditableSelect = ({ fieldName, defaultValue, onBlur, isError, isDisabled, options, }) => {
    const [value, setValue] = useState(defaultValue || "");
    return (_jsx(Flex, { w: "full", h: "64px", mt: "-3", mb: "-3", alignItems: "center", onClick: (e) => e.stopPropagation(), children: _jsx(Editable, { w: "full", value: value, isDisabled: isDisabled, defaultValue: defaultValue, onClick: (e) => e.stopPropagation(), height: 9, onChange: (nextValue) => setValue(nextValue), onSubmit: () => defaultValue !== value && onBlur(value), variant: isError ? "tableError" : !defaultValue ? "tableEmpty" : "table", children: ({ isEditing }) => {
                return (_jsxs(_Fragment, { children: [_jsx(EditablePreview, {}), isEditing && !isDisabled && (_jsx(Select, { fieldName: fieldName, defaultValue: defaultValue, options: options, isError: isError }))] }));
            } }) }));
};
