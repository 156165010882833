import * as schemaGenerators from "../../helpers/schema-generators";
const countryOfWarehouseLocation = [
    {
        key: "country",
        type: "customComponent",
        componentName: "base.CountrySelect",
    },
    {
        type: "layout",
        direction: "vertical",
        forKey: "tariff-number",
        groupType: "radio",
        elements: [
            {
                key: "label3",
                type: "label",
                label: "onboarding.productDetails.productClassification.alreadyHaveTariffNumber",
            },
            {
                type: "radio",
                key: "tariff-number-yes",
                label: "onboarding.actions.yes",
                value: "yes",
                forKey: "tariff-number",
            },
            {
                type: "radio",
                key: "tariff-number-no",
                label: "onboarding.actions.no",
                value: "no",
                forKey: "tariff-number",
            },
        ],
    },
];
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.productDetails.title",
        subtitle: "onboarding.productDetails.productClassification.title",
        description: "onboarding.productDetails.productClassification.description",
    }),
    {
        ...schemaGenerators.formCardInfoNotes({
            nodeKey: "onboarding.productDetails.productClassification.title",
            infoboxText: "onboarding.productDetails.productClassification.noTariffNumberInfo",
            formNodes: [
                {
                    type: "layout",
                    direction: "vertical",
                    options: {
                        pt: 2,
                        gap: 3,
                    },
                    elements: [
                        {
                            key: "label2",
                            type: "h3",
                            label: "onboarding.productDetails.productClassification.shipFromClassification",
                        },
                        {
                            key: "new_country_warehouse_incrementer",
                            type: "customComponent",
                            componentName: "base.IncrementedControlsArray",
                            componentProps: {
                                forKey: "add-new-country-warehouse-location",
                                controlNodes: countryOfWarehouseLocation,
                                incrementerButtonNode: {
                                    key: "add-country",
                                    type: "button",
                                    variant: "text",
                                    label: "onboarding.productDetails.productClassification.addNewCountryWarehouse",
                                },
                                decrementerButtonNode: {
                                    key: "remove-country",
                                    type: "button",
                                    variant: "error",
                                    label: "onboarding.productDetails.productClassification.removeCountryWarehouse",
                                },
                            },
                        },
                    ],
                },
            ],
        }),
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
