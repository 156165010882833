import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format, formatRelative } from "date-fns";
import saveAs from "file-saver";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "~/assets/icons/DeleteIcon";
import { DownloadIcon } from "~/assets/icons/DownloadIcon";
import { FilterBar } from "~/components/FilterBar/FilterBar";
import { FilterSearch } from "~/components/FilterBar/components/FilterSearch/FilterSearch";
import { FlagIconGroup } from "~/components/FlagIcon/FlagIcon";
import { AccountTypes } from "~/components/PermissionCheck/permission-codes";
import { Table } from "~/components/Table/Table";
import { renderTableCheckbox } from "~/components/Table/components/TableCheckbox/TableCheckbox";
import { renderTableRowActions } from "~/components/Table/components/TableRowActions/TableRowActions";
import { TableSelectHeader } from "~/components/Table/components/TableSelectHeader/TableSelectHeader";
import { TableBulkActions } from "~/components/TableBulkActions/TableBulkActions";
import { TableSorter, } from "~/components/TableSorter/TableSorter";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { useDelayedSearchFilter } from "~/hooks/useDelayedSearchFilter";
import { useParamPagination } from "~/hooks/useParamPagination";
import { useParamSorting } from "~/hooks/useParamSorting";
import { useStorageColumnConfig } from "~/hooks/useStorageColumnConfig";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { articlesBaseUrl, useDeleteArticlesMutation, useGetAllArticlesQuery, } from "~/pages/customs-classification/redux/customsClassification.api";
import { useAppSelector } from "~/store/hooks";
import { OrderDirection } from "~/types/pagination.type";
import { checkAccountType } from "~/utils/checkAccountType";
import { locale } from "~/utils/locale";
import { useIdRowSelection } from "~/hooks/useIdRowSelection";
import { ArticleDetailDrawer } from "../../../../articles/components/ArticleDetailDrawer/ArticleDetailDrawer";
export const AllArticlesTab = ({ clientId, }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const defaultTableSortingOptions = [
        {
            value: "select",
            enableSorting: false,
            isVisible: true,
        },
        {
            title: t("customsClassification.tabs.allArticles.table.article"),
            value: "articleNumber",
            enableSorting: true,
            isVisible: true,
        },
        {
            title: t("customsClassification.tabs.allArticles.table.lastUpdated"),
            value: "updatedAt",
            enableSorting: true,
            isVisible: true,
        },
        {
            title: t("customsClassification.tabs.allArticles.table.description"),
            value: "description",
            enableSorting: true,
            isVisible: true,
        },
        {
            title: t("customsClassification.tabs.allArticles.table.materials"),
            value: "materialDescription",
            enableSorting: true,
            isVisible: true,
        },
        {
            title: t("customsClassification.tabs.allArticles.table.countries"),
            value: "countries",
            enableSorting: true,
            isVisible: true,
        },
        {
            value: "actions",
            enableSorting: false,
            isVisible: true,
        },
    ];
    const [sorting, onSortingChange] = useParamSorting();
    const [pagination, setPagination] = useParamPagination();
    const [search, setSearch, searchQuery] = useDelayedSearchFilter();
    const { selectedRows, setSelectedRows, reset, resetAll, selectionMode, setSelectionMode, inPageMode, setInPageMode, showBulkActions, } = useIdRowSelection();
    useEffect(() => {
        resetAll();
    }, [searchQuery]);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [columnOrder, columnVisibility, sorterOptions, handleChangeColumnConfig,] = useStorageColumnConfig("clientCustomsClassificationAllArticles", defaultTableSortingOptions);
    const { token } = useAppSelector(authSelector);
    const { data: allArticles, isLoading: isLoadingAllArticles, isFetching: isFetchingAllArticles, refetch, } = useGetAllArticlesQuery({
        searchQuery,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        ...(!!sorting[0] && {
            order: sorting[0].desc ? OrderDirection.DESC : OrderDirection.ASC,
            orderBy: sorting[0].id || null,
        }),
        ...(!!clientId && { clientId }),
    });
    const [deleteArticles] = useDeleteArticlesMutation();
    const handleDeleteArticles = async (articleIds) => {
        if (!articleIds.length)
            return;
        try {
            const res = await deleteArticles(articleIds);
            if ("data" in res) {
                toast({
                    title: t("customsClassification.tabs.allArticles.deleteArticleSuccess"),
                    status: "success",
                    duration: 3000,
                });
                reset();
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.deleteArticle"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const handleExportArticles = async (articleIds) => {
        if (!articleIds.length)
            return;
        try {
            const res = await fetch(withBaseUrl(`${articlesBaseUrl}/export?`) +
                new URLSearchParams({
                    articleIds: articleIds.join(),
                    type: selectionMode,
                }), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.ok) {
                const blob = await res.blob();
                const filename = `articles_export_${format(new Date(), "yyyyMMdd_HHmmss")}.csv`;
                saveAs(blob, filename);
                reset();
            }
            else {
                toast({
                    title: t("error.badRequest.exportArticle"),
                    status: "error",
                    duration: 3000,
                });
            }
        }
        catch (err) {
            toast({
                title: t("error.badRequest.exportArticle"),
                status: "error",
                duration: 3000,
            });
        }
    };
    const columnData = useMemo(() => {
        var _a;
        return ((_a = allArticles === null || allArticles === void 0 ? void 0 : allArticles.data.map(({ countries, ...rest }) => {
            var _a, _b;
            return ({
                description: (_b = (_a = countries.find(({ countryCode }) => countryCode === "DE")) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : "",
                countries,
                ...rest,
            });
        })) !== null && _a !== void 0 ? _a : []);
    }, [allArticles]);
    const data = useMemo(() => {
        var _a;
        return ({
            data: columnData,
            pagination: (_a = allArticles === null || allArticles === void 0 ? void 0 : allArticles.pagination) !== null && _a !== void 0 ? _a : {
                totalItems: pagination.pageSize,
                totalPages: pagination.pageIndex,
            },
        });
    }, [allArticles, columnData]);
    const tableActions = (ids) => {
        const actions = [
            {
                label: t("customsClassification.tabs.allArticles.actions.export"),
                onClick: () => handleExportArticles(ids),
                element: _jsx(DownloadIcon, { boxSize: 5 }),
            },
            ...(checkAccountType(AccountTypes.CLIENT)
                ? [
                    {
                        label: t("customsClassification.tabs.allArticles.actions.delete"),
                        onClick: () => handleDeleteArticles(ids),
                        element: _jsx(DeleteIcon, { boxSize: 5 }),
                    },
                ]
                : []),
        ];
        return actions;
    };
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.display({
            id: "select",
            header: () => (_jsx(TableSelectHeader, { selectionMode: selectionMode, setSelectionMode: setSelectionMode, selectedItems: selectedRows, setSelectedItems: reset, itemCount: (allArticles === null || allArticles === void 0 ? void 0 : allArticles.pagination.totalItems) || 0, pageData: (allArticles === null || allArticles === void 0 ? void 0 : allArticles.data) || [], allSelectionTextKey: t("articles.detail.allArticleSelectionAll"), currentPageSelectionTextKey: t("articles.detail.articleSelectionPage"), inPageMode: inPageMode, setInPageMode: setInPageMode })),
            cell: ({ row }) => renderTableCheckbox(row.original.id, selectedRows, setSelectedRows, selectionMode, setSelectionMode),
            enableSorting: false,
        }),
        columnHelper.accessor("articleNumber", {
            header: () => t("customsClassification.tabs.allArticles.table.article"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor("updatedAt", {
            header: () => t("customsClassification.tabs.allArticles.table.lastUpdated"),
            cell: ({ getValue }) => formatRelative(new Date(getValue()), new Date(), { locale }),
        }),
        columnHelper.accessor("description", {
            header: () => t("customsClassification.tabs.allArticles.table.description"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor("materialDescription", {
            header: () => t("customsClassification.tabs.allArticles.table.materials"),
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor("countries", {
            header: () => t("customsClassification.tabs.allArticles.table.countries"),
            cell: ({ getValue }) => useMemo(() => (_jsx(FlagIconGroup, { countries: getValue().map((item) => item.countryCode), boxSize: 6 })), [getValue()]),
            enableSorting: false,
        }),
        columnHelper.display({
            id: "actions",
            size: undefined,
            header: () => t("customsClassification.tabs.allArticles.table.actions"),
            cell: ({ row }) => renderTableRowActions({
                tableActions: tableActions([row.original.id.toString()]),
            }),
        }),
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Text, { textStyle: "h3", mt: 8, mb: 6, children: t("customsClassification.tabs.allArticles.name") }), _jsxs(FilterBar, { children: [_jsx(FilterSearch, { disabled: false, value: search, setValue: setSearch }), _jsxs(Flex, { gap: 2, children: [showBulkActions && (_jsx(TableBulkActions, { bulkActions: tableActions(selectedRows) })), _jsx(TableSorter, { options: sorterOptions, handleChange: handleChangeColumnConfig })] })] }), _jsx(Table, { columns: columns, data: data, onRowClick: (row) => setSelectedArticle(row.original.id), sorting: sorting, onSortingChange: onSortingChange, pagination: pagination, onPaginationChange: setPagination, columnVisibility: columnVisibility, columnOrder: columnOrder, isLoading: isLoadingAllArticles, isFetching: isFetchingAllArticles }), _jsx(ArticleDetailDrawer, { articleId: selectedArticle, onClose: () => {
                    setSelectedArticle(null);
                    refetch();
                } })] }));
};
