import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, Flex, Grid, Menu, MenuButton, MenuList, Text, } from "@chakra-ui/react";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState, } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
import { OrderOption } from "./OrderOption";
export const TableSorter = ({ options, handleChange, }) => {
    const { t } = useTranslation();
    const [order, setOrder] = useState(options);
    useEffect(() => {
        if (options)
            setOrder(options);
    }, [options]);
    const handleSave = (onClose) => {
        handleChange(order);
        onClose();
    };
    const handleAbort = (onClose) => {
        setOrder(options);
        onClose();
    };
    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setOrder((prevCards) => update(prevCards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevCards[dragIndex]],
            ],
        }));
    }, []);
    const handleSelectAll = () => {
        const newOrder = [...order];
        newOrder.forEach((option) => {
            option.isVisible = true;
        });
        setOrder(newOrder);
    };
    const hasSelectedAll = useMemo(() => order.filter(({ isVisible }) => isVisible).length === order.length, [order]);
    const handleSelectOption = (option) => {
        const newOrder = [...order];
        const item = newOrder.find((i) => i.value === option.value);
        if (item)
            item.isVisible = !item.isVisible;
        setOrder(newOrder);
    };
    const renderCard = useCallback((option, index) => {
        return option.enableSorting ? (_jsx(OrderOption, { index: index, id: option.value, option: option, text: option.title || "", moveCard: moveCard, handleSelect: () => handleSelectOption(option) }, `order-item-${option.value}`)) : null;
    }, [order]);
    return (_jsx(Menu, { children: ({ onClose }) => (_jsxs(_Fragment, { children: [_jsx(MenuButton, { children: _jsx(Button, { variant: "secondary", children: _jsx(Icon, { name: "table", w: "15px" }) }) }), _jsxs(MenuList, { overflow: "hidden", px: "0", py: "6", w: "326px", children: [_jsx(Text, { pb: "4", px: "4", textStyle: "labelUppercase", children: t("basics.table.orderHeader") }), _jsx(Box, { children: _jsx(Checkbox, { mb: "2", px: "4", isChecked: hasSelectedAll, onChange: handleSelectAll, children: t("basics.table.orderSelectAll") }) }), _jsx(DndProvider, { backend: HTML5Backend, children: _jsx(Grid, { templateColumns: "1fr", maxH: "250px", overflowY: "auto", children: order.map((option, i) => renderCard(option, i)) }) }), _jsxs(Flex, { mx: "4", pt: "6", mt: "2.5", borderTop: "1px solid", borderTopColor: "grey.10", justifyContent: "space-between", children: [_jsx(Button, { variant: "secondary", onClick: () => handleAbort(onClose), children: t("basics.table.orderSecondaryCTA") }), _jsx(Button, { onClick: () => handleSave(onClose), children: t("basics.table.orderCTA") })] })] })] })) }));
};
