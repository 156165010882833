var _a, _b;
import countries from "i18n-iso-countries";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { get } from "./api/utils";
import { ONBOARDING_BASEURL } from "./store/api";
export const i18nextLngKey = "i18nextLng";
export const supportedLanguages = ["de", "en"];
supportedLanguages.forEach((lng) => {
    countries.registerLocale(require(`i18n-iso-countries/langs/${lng}.json`));
});
const selectedLang = (_b = (_a = localStorage.getItem(i18nextLngKey)) === null || _a === void 0 ? void 0 : _a.split("-")[0]) !== null && _b !== void 0 ? _b : "en";
const backendOptions = {
    request: async (_options, _url, _payload, callback) => {
        var _a, _b;
        const actualLanguage = (_b = (_a = localStorage.getItem(i18nextLngKey)) === null || _a === void 0 ? void 0 : _a.split("-")[0]) !== null && _b !== void 0 ? _b : "en";
        const translations = await get(`${ONBOARDING_BASEURL}/translations/all`);
        const actualLanguageKey = actualLanguage.toLocaleUpperCase();
        callback(null, {
            data: translations[actualLanguageKey],
            status: 200,
        });
    },
};
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    // .use(
    //   resourcesToBackend((language, namespace, callback) => {
    //     import(`./locales/${language}/${namespace}.json`)
    //       .then(resources => {
    //         callback(null, resources);
    //       })
    //       .catch(error => {
    //         callback(error, null);
    //       });
    //   })
    // )
    .init({
    backend: backendOptions,
    supportedLngs: supportedLanguages,
    debug: false,
    fallbackLng: selectedLang,
    load: "languageOnly",
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});
export default i18n;
