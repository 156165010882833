import { jsx as _jsx } from "react/jsx-runtime";
import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { hideSuccessMessage } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
export default function InputError({ text }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentTimeout, setCurrentTimeout] = React.useState();
    useEffect(() => {
        if (currentTimeout) {
            clearTimeout(currentTimeout);
        }
        const currentT = setTimeout(() => {
            dispatch(hideSuccessMessage());
        }, 3000);
        setCurrentTimeout(currentT);
        return () => {
            clearTimeout(currentTimeout);
        };
    }, []);
    return (_jsx(Flex, { direction: "column", fontSize: "sm", onClick: () => {
            dispatch(hideSuccessMessage());
        }, children: _jsx(Text, { overflow: "auto", color: "red.400", children: t(text) }) }));
}
