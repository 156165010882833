import { createReducer } from "@reduxjs/toolkit";
import { fetchStatuses } from "../actions/statuses.actions";
const initialState = {
    forms: {},
    groups: {},
};
export const StatusesReducer = createReducer(initialState, (builder) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(fetchStatuses.fulfilled, (state, action) => {
        // TODO:
    });
});
