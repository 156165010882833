import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import FormHeader from "~/components/FormHeader/FormHeader";
export function ApplicationVatUidNumberComplete() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(FormHeader, { title: t("onboarding.steps.application_vat_uid_number"), subtitle: t("onboarding.declarationOfSubordination.title"), description: t("onboarding.declarationOfSubordination.description") }), _jsx(FormBody, { gap: "4", children: _jsxs(Text, { children: [t("onboarding.steps.completed"), " "] }) }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false, isSaveForLaterVisible: true })] }));
}
