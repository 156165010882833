import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    {
        ...schemaGenerators.formCard(),
        elements: [
            { key: "label1", type: "h2", label: "onboarding.completedAllSteps" },
        ],
    },
    schemaGenerators.formFooter(true),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
