import { EPermissionCodes } from "@crossborderx/schemas-package";
const interalUserSidebarItems = [
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayCh",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/ch/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/ch/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.reporting",
                to: "/gateway/ch/report_index?title=legacy.msgw.reporting",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.clientManagement",
                to: "/gateway/ch/admin_accounts?title=legacy.msgw.client_management",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.importDataModel",
                to: "/gateway/ch/columndefinitions_index?title=legacy.msgw.import_data_model",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.systemSettings",
                to: "/gateway/ch/admin_editsystemvalues?title=legacy.msgw.system_settings",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.articleMasterDatabase",
                to: "/gateway/ch/article_index?title=legacy.msgw.article_master_database",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.customsNumberMapping",
                to: "/gateway/ch/customnumber_germancustomnumbermappings?title=legacy.msgw.customs_number_mapping",
            },
        ],
    },
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayUk",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/uk/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/uk/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.reporting",
                to: "/gateway/uk/report_index?title=legacy.msgw.reporting",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.clientManagement",
                to: "/gateway/uk/admin_accounts?title=legacy.msgw.client_management",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.importDataModel",
                to: "/gateway/uk/columndefinitions_index?title=legacy.msgw.import_data_model",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.systemSettings",
                to: "/gateway/uk/admin_editsystemvalues?title=legacy.msgw.system_settings",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.articleMasterDatabase",
                to: "/gateway/uk/article_index?title=legacy.msgw.article_master_database",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.ukCustomsNumberMasterData",
                to: "/gateway/uk/customnumber_index?title=legacy.msgw.uk_customs_number_master_data",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.customsNumberMapping",
                to: "/gateway/uk/customnumber_germancustomnumbermappings?title=legacy.msgw.customs_number_mapping",
            },
        ],
    },
    {
        iconName: "document-outline",
        textKey: "legacy.msclarify",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.createClarificationCase",
                to: "/clarify/de-CH_Clarification_Create?title=legacy.mscl.createClarificationCase",
                permissions: [EPermissionCodes.CLARIFY_CLARIFICATIONCASE_MANAGE],
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.openClarificationCase",
                to: "/clarify/de-CH_Clarification_QueueNew?title=legacy.mscl.openClarificationCase",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.settings",
                to: "/clarify/Admin_Settings?title=legacy.mscl.settings",
                permissions: [EPermissionCodes.CLARIFY_SETTINGS_MANAGE],
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.companyManagement",
                to: "/clarify/Admin_Provider?title=legacy.mscl.companyManagement",
                permissions: [EPermissionCodes.CLARIFY_COMPANY_MANAGEMENT_MANAGE],
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.storagePlaceManagement",
                to: "/clarify/Admin_Storage?title=legacy.mscl.storagePlaceManagement",
                permissions: [EPermissionCodes.CLARIFY_STORAGEPLACE_MANAGEMENT_MANAGE],
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.resourceManagement",
                to: "/clarify/Admin_Resource?title=legacy.mscl.resourceManagement",
                permissions: [EPermissionCodes.CLARIFY_RESOURCE_MANAGEMENT_MANAGE],
            },
            {
                iconName: "document-outline",
                textKey: "legacy.mscl.protocols",
                to: "/clarify/Admin_Protocols?title=legacy.mscl.protocols",
                permissions: [EPermissionCodes.CLARIFY_PROTOCOLS_MANAGE],
            },
        ],
    },
];
const partnerUserSidebarItems = [
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayCh",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/ch/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/ch/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.reporting",
                to: "/gateway/ch/report_index?title=legacy.msgw.reporting",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.articleMasterDatabase",
                to: "/gateway/ch/article_index?title=legacy.msgw.article_master_database",
            },
        ],
    },
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayUk",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/uk/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/uk/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.reporting",
                to: "/gateway/uk/report_index?title=legacy.msgw.reporting",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.articleMasterDatabase",
                to: "/gateway/uk/article_index?title=legacy.msgw.article_master_database",
            },
        ],
    },
];
const clientUserSidebarItems = [
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayCh",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/ch/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/ch/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
        ],
    },
    {
        iconName: "document-outline",
        textKey: "legacy.msgatewayUk",
        collapsedItems: [
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.outboundShipments",
                to: "/gateway/uk/home_index?title=legacy.msgw.outbound_shipments",
            },
            {
                iconName: "document-outline",
                textKey: "legacy.msgw.tariFilesOverview",
                to: "/gateway/uk/tarifile_index?title=legacy.msgw.tari_files_overview",
            },
        ],
    },
];
export default {
    interalUserSidebarItems,
    partnerUserSidebarItems,
    clientUserSidebarItems,
};
