import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Input, Text, VStack } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ReactComponent as UploadIcon } from "~/assets/icons/upload.svg";
export default function Dropzone({ uploadedFiles, handleChangeUploadedFiles, placeholder, acceptFiles, ...rest }) {
    const { t } = useTranslation();
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles) {
            handleChangeUploadedFiles(acceptedFiles);
        }
    };
    const handleValidation = (meta) => {
        const fileAlreadyExists = uploadedFiles.find((file) => {
            return (file.file.name === meta.name &&
                file.file.size === meta.size &&
                file.file.type === meta.type);
        });
        if (fileAlreadyExists)
            return {
                message: t("onboarding.uploadFilesArea.fileAlreadyExists"),
                code: "duplicated-file-name",
            };
        return null;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        validator: handleValidation,
        accept: acceptFiles,
        ...rest,
    });
    const dragMessage = (placeholder === null || placeholder === void 0 ? void 0 : placeholder.length)
        ? t(placeholder)
        : t("onboarding.uploadFilesArea.dragFilesToUpload");
    return (_jsxs(VStack, { minW: "full", h: "60px", p: "2", bg: "secondary.50", boxSizing: "border-box", borderColor: isDragActive ? "secondary.300" : "secondary.400", borderWidth: "1px", borderStyle: "dashed", justifyContent: "center", alignContent: "center", cursor: "pointer", transition: "border-color 0.2s ease", _hover: { bg: "secondary.100" }, ...getRootProps(), children: [_jsx(Input, { ...getInputProps(), size: "md", display: "none" }), _jsxs(Flex, { w: "full", h: "full", justifyContent: "space-between", alignContent: "center", px: "4", children: [_jsx(UploadIcon, {}), _jsx(Text, { textAlign: "center", color: "secondary.500", children: isDragActive
                            ? t("onboarding.uploadFilesArea.dropFilesHere")
                            : dragMessage }), _jsx(UploadIcon, {})] })] }));
}
