import i18nCountries from "i18n-iso-countries";
import { difference, intersection } from "lodash";
import { ShipDirection } from "~/enums/ship-direction.enum";
import i18n from "~/i18n";
const EU_COUNTRIES = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
];
export const SHIP_TO_COUNTRIES = ["CH", "GB"];
export const SHIP_FROM_COUNTRIES = [
    "AT",
    "CH",
    "CZ",
    "DE",
    "ES",
    "FR",
    "HU",
    "NL",
    "NO",
    "PL",
    "GB",
];
export const getEUCountries = (countries) => {
    return intersection(countries, EU_COUNTRIES);
};
export const getNonEUCountries = (countries) => {
    return difference(countries, EU_COUNTRIES);
};
export const getCountryByIsoCode = (isoCode) => {
    return i18nCountries.getName(isoCode, i18n.languages[0]);
};
export const isCountryUsed = ({ country, usedCountries, }) => {
    return usedCountries.some((usedCountry) => usedCountry === country);
};
export const getShippingCountriesList = ({ shipDirection, selectedCountries, addedCountries, usedCountries, }) => {
    const countries = shipDirection === ShipDirection.TO
        ? SHIP_TO_COUNTRIES
        : SHIP_FROM_COUNTRIES;
    return countries.filter((country) => !selectedCountries.includes(country) &&
        !(addedCountries === null || addedCountries === void 0 ? void 0 : addedCountries.includes(country)) &&
        !(usedCountries === null || usedCountries === void 0 ? void 0 : usedCountries.includes(country)));
};
export const getShippingCountriesOptions = ({ shipDirection, selectedCountries, addedCountries, usedCountries, }) => {
    return getShippingCountriesList({
        shipDirection,
        selectedCountries,
        addedCountries,
        usedCountries,
    }).map((country) => ({
        value: country,
        label: getCountryByIsoCode(country),
    }));
};
