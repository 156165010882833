import { jsx as _jsx } from "react/jsx-runtime";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormBody from "~/components/FormBody/FormBody";
import { fetchFormById } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
import { getFormData, getFormLoadingState, } from "~/store/selectors/formData.selectors";
export const ActiveSteps = {
    "0": {
        url: "administrative-processes/start",
    },
    "1.1": {
        url: "administrative-processes/vat-uid-number",
    },
    "1.2": {
        url: "administrative-processes/fiscal-representation-office",
    },
    "1.3": {
        url: "administrative-processes/zaz-account-start",
    },
    "1.4": {
        url: "administrative-processes/zaz-account-number",
    },
    "1.5": {
        url: "administrative-processes/calculation-of-security-deposit",
    },
    "1.6": {
        url: "administrative-processes/calculation-of-security-deposit-sign",
    },
    "1.7": {
        url: "administrative-processes/transfering-cash-for-deposit",
    },
    "2.1": {
        url: "administrative-processes/tax-documents",
    },
    "2.3": {
        url: "administrative-processes/tax-documents-sign-info",
    },
    "2.4": {
        url: "administrative-processes/vat-uid-number",
    },
    "2.5": {
        url: "administrative-processes/security-deposit",
    },
    "2.6": {
        url: "administrative-processes/security-deposit-sign",
    },
    "2.7": {
        url: "administrative-processes/security-deposit-completed",
    },
    "3.1": {
        url: "administrative-processes/external-representation",
    },
    "3.2": {
        url: "administrative-processes/vat-uid-number",
    },
    "3.3": {
        url: "administrative-processes/fiscal-representation-office",
    },
    "4.0": {
        url: "administrative-processes/customs-complete",
    },
};
export const ActiveStep = () => {
    const { params: { id, flowId }, } = useMatch();
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getFormLoadingState);
    const [loading, setLoading] = useState(true);
    const formData = useSelector(getFormData);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchFormById({
            id,
            flowId,
            pageSlug: "activestep",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);
    useEffect(() => {
        var _a, _b, _c, _d;
        if ((_a = formData === null || formData === void 0 ? void 0 : formData.activestep) === null || _a === void 0 ? void 0 : _a.fieldValue) {
            if (ActiveSteps[(_b = formData === null || formData === void 0 ? void 0 : formData.activestep) === null || _b === void 0 ? void 0 : _b.fieldValue])
                navigate({
                    to: `/clients/${id}/onboarding/${flowId}/${ActiveSteps[(_c = formData === null || formData === void 0 ? void 0 : formData.activestep) === null || _c === void 0 ? void 0 : _c.fieldValue].url}`,
                });
            else if (!isLoading)
                navigate({
                    to: `/clients/${id}/onboarding/${flowId}/${ActiveSteps["0"].url}`,
                });
        }
        if (!((_d = formData === null || formData === void 0 ? void 0 : formData.activestep) === null || _d === void 0 ? void 0 : _d.fieldValue) && !isLoading && !loading) {
            navigate({
                to: `/clients/${id}/onboarding/${flowId}/${ActiveSteps["0"].url}`,
            });
        }
    }, [formData, isLoading, loading]);
    return (_jsx(FormBody, { gap: "4", children: _jsx("a", { href: `/clients/${id}/onboarding/${flowId}/${ActiveSteps["0"].url}`, children: "Start VAT Process" }) }));
};
