import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Icon from "~/components/Icon/Icon";
export const CopyText = ({ text, textProps }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const handleClick = () => {
        navigator.clipboard.writeText(text);
        toast({
            title: t("basics.copiedSuccess"),
            status: "success",
            duration: 3000,
        });
    };
    return (_jsxs(Flex, { alignItems: "center", w: "full", children: [_jsx(Text, { color: "grey.60", flexGrow: "1", ...textProps, children: text }), _jsx(Icon, { color: "grey.40", _hover: { color: "brand.sky" }, transitionDuration: "200ms", width: "14px", ml: "4", cursor: "pointer", name: "copy", onClick: handleClick })] }));
};
