export default [
    {
        title: "onboarding.companyDetails.title",
        path: "/company-details",
        key: "companyDetails",
        processSlug: "companyDetails",
        childrens: [
            {
                title: "onboarding.overview",
                path: "/",
                pageSlug: "",
            },
            {
                title: "onboarding.companyDetails.contactInformation.title",
                path: "/contact-information",
                pageSlug: "contactInformation",
            },
            {
                title: "onboarding.companyDetails.contactPerson.title",
                path: "/contact-details",
                pageSlug: "contactPerson",
            },
        ],
    },
    {
        title: "onboarding.steps.application_vat_uid_number",
        path: "/administrative-processes",
        key: "administrativeProcesses",
        processSlug: "administrativeProcesses",
    },
    {
        title: "vat-tasks",
        path: "/",
    },
    {
        title: "onboarding.productDetails.title",
        key: "productDetails",
        processSlug: "productDetails",
        path: "/product-details",
        childrens: [
            {
                title: "onboarding.overview",
                path: "/",
            },
            {
                title: "onboarding.productDetails.categories.title",
                pageSlug: "productRangeAndMasterData",
                path: "/product-range-and-master-data",
            },
            {
                title: "onboarding.productDetails.exportRegulation.title",
                pageSlug: "exportRegulation",
                path: "/export-regulation",
            },
            {
                title: "onboarding.productDetails.customsClassification.title",
                pageSlug: "customsClassification",
                path: "/customs-classification",
            },
            // {
            //   title: 'onboarding.productDetails.tariffNumberDestinationCountry',
            //   pageSlug: 'tariffNumberDestinationCountry',
            //   path: '/tariff-number-destination-country'
            // },
            // {
            //   title: 'onboarding.productDetails.tariffNumberShippingCountry',
            //   pageSlug: 'tariffNumberShippingCountry',
            //   path: '/tariff-number-shipping-country'
            // },
            // {
            //   title: 'onboarding.productDetails.countinousTariffing.title',
            //   pageSlug: 'continuousTariffing',
            //   path: '/continuos-tariffing'
            // },
            {
                title: "onboarding.productDetails.defineImportFile.title",
                pageSlug: "defineImportFile",
                path: "/define-import-file",
            },
        ],
    },
    {
        title: "onboarding.outboundProcesses.title",
        path: "/outbound-process",
        key: "outboundProcess",
        processSlug: "outboundProcess",
        childrens: [
            {
                title: "onboarding.overview",
                path: "/",
            },
            {
                title: "onboarding.outboundProcesses.originWarehouse.title",
                path: "/origin-warehouse",
                pageSlug: "deliveryFromWarehouseLocation",
            },
            {
                title: "onboarding.outboundProcesses.exportEU.title",
                pageSlug: "exportFromEu",
                path: "/export_EU",
            },
            {
                title: "onboarding.outboundProcesses.transportUK.label",
                pageSlug: "transportToUk",
                path: "/transport_UK",
            },
            {
                title: "onboarding.outboundProcesses.import.title",
                pageSlug: "customsDeclaration",
                path: "/customs-declaration",
            },
            {
                title: "onboarding.outboundProcesses.delivery.title",
                pageSlug: "deliveryPoarcelHub",
                path: "/delivery-parcel-hub",
            },
            {
                title: "onboarding.steps.completed",
                path: "/complete-outbound",
            },
        ],
    },
    {
        title: "onboarding.inboundProcesses.title",
        key: "inboundProcess",
        processSlug: "inboundProcess",
        path: "/inbound-process",
        childrens: [
            {
                title: "onboarding.overview",
                path: "/",
            },
            {
                title: "onboarding.inboundProcesses.returns.title",
                pageSlug: "returns",
                path: "/return-start",
            },
            {
                title: "onboarding.inboundProcesses.returnProcessing.title",
                pageSlug: "returnProcessing",
                path: "/return-processing",
            },
            {
                title: "onboarding.inboundProcesses.returnClosure.title",
                pageSlug: "returnClosure",
                path: "/return-closure",
            },
            {
                title: "onboarding.inboundProcesses.returnPickUp.title",
                pageSlug: "returnPickUp",
                path: "/return-pick-up",
            },
            {
                title: "onboarding.inboundProcesses.returnPlaceOfPresent.title",
                pageSlug: "returnPlaceOfPresentation",
                path: "/return-delivery-process",
            },
            {
                title: "onboarding.inboundProcesses.returnWarehouseLocation.title",
                pageSlug: "returnWarehouseLocation",
                path: "/return-warehouse-location",
            },
            {
                title: "onboarding.steps.completed",
                path: "/complete-inbound",
            },
        ],
    },
    {
        title: "onboarding.technicalSetup.title",
        key: "technicalSetup",
        processSlug: "technicalSetup",
        path: "/technical-setup",
        childrens: [
        // {
        //   title: 'onboarding.overview',
        //
        //   path: '/customization_files'
        // },
        // {
        //   title: 'onboarding.technicalSetup.customizationFiles',
        //
        //   path: '/customization_files'
        // },
        // {
        //   title: 'onboarding.steps.completed',
        //
        //   path: '/technical-setup-complete'
        // }
        ],
    },
    {
        title: "onboarding.steps.start",
        path: "/go-live/",
    },
];
export const registerVatId = [
    {
        title: "onboarding.steps.start",
        path: "/start",
    },
    {
        title: "onboarding.steps.vat_uid_number",
        path: "/vat-uid-number",
    },
    {
        title: "onboarding.steps.fiscal_representation_office",
        path: "/fiscal-representation-office",
    },
    {
        title: "onboarding.steps.zaz_account_number",
        path: "/zaz-account-start",
    },
];
export const alreadyHaveVatIdRoutes = [
    {
        title: "onboarding.steps.start",
        path: "/start",
    },
    {
        title: "onboarding.vatUidNumber.taxInSwiterland.title",
        path: "/has-tax-in-switzerland",
    },
];
export const VatRoutes = [
    {
        title: "onboarding.steps.start",
        path: "/start",
    },
    {
        title: "onboarding.steps.vat_uid_number",
        path: "/vat-uid-number",
    },
    {
        title: "onboarding.steps.fiscal_representation_office",
        path: "/fiscal-representation-office",
    },
    {
        title: "onboarding.steps.complete_your_company_information",
        path: "/company-information",
    },
    {
        title: "onboarding.steps.declaration_of_fiscal_representation",
        path: "/declaration-of-fiscal-representation",
    },
    {
        title: "onboarding.steps.declaration_of_fiscal_representation_fertig_check",
        path: "/declaration-of-fiscal-representation-fertig-check",
    },
    {
        title: "onboarding.steps.security_deposit",
        path: "/security-deposit",
    },
    {
        title: "onboarding.steps.power_of_attorney_for_estv_swiss_tax",
        path: "/power-of-attorney",
    },
    {
        title: "onboarding.steps.declaration_of_subordination",
        path: "/declaration-of-subordination",
    },
    {
        title: "onboarding.steps.completed",
        path: "/vat-application-complete",
    },
    {
        title: "onboarding.steps.do_you_have_a_customs_account_number",
        path: "/customs-account",
    },
    {
        title: "onboarding.steps.zaz_account_number",
        path: "/zaz-account-number",
    },
    {
        title: "onboarding.steps.calculation_of_security_deposit",
        path: "/calculation-of-security-deposit",
    },
    {
        title: "onboarding.steps.kind_of_security_deposit",
        path: "/kind-of-security-deposit",
    },
    {
        title: "onboarding.steps.transfering_cash_for_deposit",
        path: "/transfering-cash-for-deposit",
    },
    {
        title: "onboarding.steps.payment_of_custom_fees",
        path: "/payment-of-custom-fees",
    },
    {
        title: "onboarding.steps.lastschriftverfahren_einrichten",
        path: "/lastschriftverfahren_einrichten",
    },
    {
        title: "onboarding.steps.declaration_of_accession",
        path: "/declaration-of-accession",
    },
    {
        title: "onboarding.steps.completed",
        path: "/customs-complete",
    },
];
