import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.inboundProcesses.title",
        subtitle: "onboarding.inboundProcesses.returnWarehouseLocation.title",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                type: "layout",
                direction: "vertical",
                options: {
                    py: 2,
                    gap: 2,
                },
                elements: [
                    {
                        key: "label2",
                        type: "h3",
                        label: "onboarding.inboundProcesses.returnWarehouseLocation.deliveryDetails",
                    },
                    {
                        key: "label3",
                        type: "label",
                        label: "onboarding.inboundProcesses.returnWarehouseLocation.whatIsYourDeliveryDetails",
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 3,
                        },
                        elements: [
                            {
                                key: "name",
                                type: "text",
                                label: "users.name",
                            },
                            {
                                type: "layout",
                                direction: "horizontal",
                                options: {
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "street",
                                        type: "text",
                                        label: "onboarding.companyInformation.street",
                                    },
                                    {
                                        key: "no",
                                        type: "text",
                                        label: "onboarding.companyInformation.number",
                                    },
                                ],
                            },
                            {
                                type: "layout",
                                direction: "horizontal",
                                options: {
                                    gap: 3,
                                },
                                elements: [
                                    {
                                        key: "zip-code",
                                        type: "text",
                                        label: "onboarding.companyInformation.zipCode",
                                    },
                                    {
                                        key: "city",
                                        type: "text",
                                        label: "onboarding.companyInformation.city",
                                    },
                                ],
                            },
                            {
                                key: "country",
                                type: "customComponent",
                                componentName: "base.CountrySelect",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label4",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnWarehouseLocation.futherDetails",
                            },
                            {
                                key: "further-details",
                                type: "text",
                                isTextarea: true,
                                label: "onboarding.inboundProcesses.returnWarehouseLocation.numberUnloadingRamp",
                            },
                        ],
                    },
                    {
                        type: "layout",
                        direction: "vertical",
                        options: {
                            pt: 2,
                            gap: 2,
                        },
                        elements: [
                            {
                                key: "label4",
                                type: "label",
                                label: "onboarding.inboundProcesses.returnWarehouseLocation.provideContactDetails",
                            },
                            {
                                key: "contact-name",
                                type: "text",
                                label: "users.name",
                            },
                            {
                                key: "contact-email",
                                type: "text",
                                label: "onboarding.companyDetails.contactInformation.emailAddress",
                            },
                            {
                                key: "contact-phone",
                                type: "text",
                                label: "onboarding.companyInformation.phone",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
