import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, post, remove } from "~/api/utils";
export const fetchDocuments = createAsyncThunk("documentData/fetchDocuments", async () => {
    const group = "onboarding";
    const data = await get("/storage/documents", { group });
    return data;
});
export const fetchFilterDocuments = createAsyncThunk("documentData/fetchFilterDocuments", async ({ user }) => {
    const group = "onboarding";
    const data = await get("/storage/documents", {
        group,
        user,
    });
    return data;
});
export const deleteDocumentById = createAsyncThunk("documentData/deleteDocumentById", async ({ id, group }) => {
    await remove(`/storage/documents/${id}`);
    await get("/storage/documents", { group });
    const newData = await get("/storage/documents", { group });
    return newData;
});
export const fetchDocumentByName = createAsyncThunk("documentData/fetchByName", async ({ name, fileUrl, group, }) => {
    const data = await get("/storage/documents", {
        group,
        name,
    });
    if (data.length) {
        const newData = await patch(`/storage/documents/${data[0].id}`, {
            name,
            group,
            fileUrl,
            contentLength: 0,
            contentType: "string",
        });
        return newData;
    }
    if (!data.length) {
        await post("/storage/documents", {
            name,
            group,
            fileUrl,
            contentLength: 0,
            contentType: "string",
        });
        const newData = await get("/storage/documents", {
            group,
        });
        return newData;
    }
    return data;
});
