import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    schemaGenerators.formHeader({
        title: "onboarding.technicalSetup.title",
        subtitle: "onboarding.overview",
        description: "onboarding.technicalSetup.overview.description",
    }),
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h3",
                label: "onboarding.technicalSetup.overview.dataTransfer",
            },
            {
                type: "radio",
                key: "output_of_the_data_transfer",
                label: "onboarding.technicalSetup.overview.csvFile",
                value: "csv",
                forKey: "output_of_the_data_transfer",
            },
            {
                key: "label-csv",
                type: "label",
                label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            },
            {
                type: "label",
                key: "shopify",
                label: [
                    {
                        strong: true,
                        displayLabel: "onboarding.technicalSetup.overview.shopify",
                    },
                ],
            },
            {
                key: "label-shopify",
                type: "label",
                label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            },
            {
                type: "label",
                key: "api",
                label: [
                    {
                        strong: true,
                        displayLabel: "onboarding.technicalSetup.overview.api",
                    },
                ],
            },
            {
                key: "label-api",
                type: "label",
                label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
            },
        ],
    },
    schemaGenerators.formFooter(),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
