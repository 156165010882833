import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useReinviteClientUserMutation } from "~/pages/clients/redux/clients.api";
import { useReinvitePartnerUserMutation } from "~/pages/partners/redux/partners.api";
export const ReinviteUserCard = ({ email, isPartner, companyId, }) => {
    const [reinviteUser, { isSuccess, isLoading }] = isPartner
        ? useReinvitePartnerUserMutation()
        : useReinviteClientUserMutation();
    const toast = useToast();
    const { t } = useTranslation();
    useEffect(() => {
        if (isSuccess)
            toast({
                title: t("profile.resendInviteSuccess"),
                status: "success",
                duration: 3000,
            });
    }, [isSuccess]);
    const handleResendInvite = async () => {
        await reinviteUser({ id: companyId, email });
    };
    return (_jsxs(Box, { bg: "warning.20", border: "1px solid", rounded: "4px", borderColor: "warning.40", p: "3", children: [_jsx(Text, { children: t("profile.resendInviteText") }), _jsx(Button, { isLoading: isLoading, colorScheme: "button.primary", variant: "link", mt: "3", onClick: handleResendInvite, children: t("profile.resendInviteCTA") })] }));
};
