import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import { Box, Button, Grid } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { CreateKeyModal } from "../../components/CreateKeyModal/CreateKeyModal";
import { KeyItem } from "../../components/KeyItem/KeyItem";
import { KeySkeleton } from "../../components/KeySkeleton/KeySkeleton";
import { useGetAllKeysQuery } from "../../redux/keys.api";
export const KeyOverviewPage = () => {
    const { data: keyData, isLoading } = useGetAllKeysQuery();
    const { t } = useTranslation();
    const [showCreateKeyModal, setShowCreateKeyModal] = useState(false);
    const hasKeys = !!(keyData === null || keyData === void 0 ? void 0 : keyData.data.length);
    const headerActions = (_jsxs(Button, { onClick: () => setShowCreateKeyModal(true), children: [" ", t("apiKeys.createKeyCTA")] }));
    return (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { actions: headerActions, title: t("apiKeys.pageHeadline") }), _jsxs(ContentSection, { children: [_jsx("h3", { children: t("apiKeys.existingKeysHeadline") }), isLoading ? (_jsx(Box, { pt: 6, children: _jsx(KeySkeleton, {}) })) : hasKeys ? (_jsx(Grid, { gap: 4, mt: 6, children: keyData.data.map((key) => (_jsx(KeyItem, { item: key }, `api-key-${key.id}`))) })) : (_jsx(Box, { bg: "white", p: 6, borderRadius: 4, mt: 6, children: _jsx("p", { children: t("apiKeys.noKeysText") }) }))] }), _jsx(CreateKeyModal, { show: showCreateKeyModal, handleHide: () => setShowCreateKeyModal(false) })] }));
};
