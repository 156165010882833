import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useTranslation } from 'react-i18next';
import { Box, Flex, GridItem, Spinner } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useRef, useState } from "react";
import { useMvcAppCookieQuery } from "~/api/hooks/mvc-app-cookies";
import clientSideConfig from "~/client-side-config";
// import { Slot } from '~/components/slots';
import LayoutShell from "~/layouts/shell";
function getIframeUrl(app, path) {
    const updatedPath = path.replace(/_/g, "/");
    if (app === "gatewayCH") {
        return `${clientSideConfig.msGatewayChRootUrl}${updatedPath}`;
    }
    if (app === "gatewayUK") {
        return `${clientSideConfig.msGatewayUkRootUrl}${updatedPath}`;
    }
    if (app === "clarify") {
        return `${clientSideConfig.msClarifyRootUrl}${updatedPath}`;
    }
    return null;
}
export default function Iframe({ app }) {
    // const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const match = useMatch();
    const { data: mvcAppCookieData, isFetched: mvcAppCookieIsFetched } = useMvcAppCookieQuery(app, isLoading);
    const iframeRef = useRef(null);
    const { params: { path },
    // search: { title }
     } = match;
    const iframeUrl = getIframeUrl(String(app), String(path));
    if (!iframeUrl) {
        return null;
    }
    useEffect(() => {
        setIsLoading(true);
        if (mvcAppCookieIsFetched) {
            if (mvcAppCookieData) {
                if (iframeRef.current) {
                    iframeRef.current.src = iframeUrl;
                }
            }
            else {
                setIsLoading(false);
                setIsError(true);
            }
        }
    }, [mvcAppCookieIsFetched, iframeUrl]);
    return (_jsx(LayoutShell, { children: _jsx(GridItem, { colStart: 1, colEnd: 13, position: "relative", children: _jsxs(Flex, { height: "calc(100vh - 72px)", width: "100%", alignItems: "center", justifyContent: "center", children: [_jsx("iframe", { ref: iframeRef, title: app, style: { height: "100%", width: "100%" }, onLoad: (e) => {
                            if (e.currentTarget.src) {
                                setIsLoading(false);
                            }
                        } }), _jsx(Spinner, { position: "absolute", thickness: "4px", speed: "0.65s", emptyColor: "gray.200", color: "blue.500", size: "xl", display: isLoading ? "inline-block" : "none" }), _jsx(Box, { position: "absolute", display: isError ? "inline-block" : "none", children: "Failed to load iframe" })] }) }) }));
}
