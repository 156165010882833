export var ActionType;
(function (ActionType) {
    ActionType[ActionType["AddSlot"] = 0] = "AddSlot";
})(ActionType || (ActionType = {}));
export const initialState = {
    nav: null,
};
export const reducer = (state, action) => {
    switch (action.type) {
        case ActionType.AddSlot: {
            const { name, ref } = action.payload;
            return {
                ...state,
                [name]: ref,
            };
        }
        default:
            return state;
    }
};
