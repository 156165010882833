import { ONBOARDING_V2_BASEURL, api } from "~/store/api";
export const userBaseUrl = `${ONBOARDING_V2_BASEURL}/users`;
export const userApi = api
    .enhanceEndpoints({ addTagTypes: ["USERS"] })
    .injectEndpoints({
    endpoints: (builder) => ({
        getUserByEmail: builder.query({
            query: (email) => ({
                url: `${userBaseUrl}/${email}`,
            }),
        }),
    }),
});
export const { useGetUserByEmailQuery, useLazyGetUserByEmailQuery } = userApi;
