import { calculationOfSecurityDepositFormNodesAndSchema, completeYourCompanyInformationFormNodesAndSchema, completedFormNodesAndSchema, declarationOfAccessionDoneFormNodesAndSchema, declarationOfAccessionFormNodesAndSchema, declarationOfFiscalRepresentationFertigCheckFormNodesAndSchema, declarationOfFiscalRepresentationFormNodesAndSchema, declarationOfSubordinationFormNodesAndSchema, doYouHaveACustomsAccountNumberFormNodesAndSchema, fiscalRepresentationOfficeCompletedFormNodesAndSchema, fiscalRepresentationOfficeFormNodesAndSchema, kindOfSecurityDepositFormNodesAndSchema, lastschriftverfahrenEinrichtenFormNodesAndSchema, paymentOfCustomFeesFormNodesAndSchema, powerOfAttorneyForEstvSwissTaxFormNodesAndSchema, securityDepositFormNodesAndSchema, startFormNodesAndSchema, transferingCashForDepositFormNodesAndSchema, vatUidNumberFormNodesAndSchema, yourVatUidFormNodesAndSchema, zazAccountNumberFormNodesAndSchema, } from "../schemas/vat-and-customs";
export const VAT_AND_CUSTOMS = {
    application_vat_uid_number: {
        id: "application_vat_uid_number",
        initial: "start",
        meta: {
            eventName: "VAT_AND_CUSTOMS",
            label: "onboarding.steps.application_vat_uid_number",
        },
        onDone: "your_customs_account",
        states: {
            start: {
                meta: {
                    label: "onboarding.steps.start",
                    ...startFormNodesAndSchema,
                },
                on: {
                    REGISTER_NEW_VAT_ID: "complete_your_company_information",
                    USE_EXISTING_VAT_ID: "vat_uid_number",
                },
            },
            vat_uid_number: {
                meta: {
                    label: "onboarding.steps.vat_uid_number",
                    ...vatUidNumberFormNodesAndSchema,
                },
                on: {
                    SAVE: "vat_uid_number",
                    NEXT: "fiscal_representation_office",
                    I_DONT_HAVE_VAT_NUMBER: "complete_your_company_information",
                    MANUAL_UPDATE: {
                        assign: (context, event) => {
                            return { ...context, ...event.value };
                        },
                    },
                },
            },
            fiscal_representation_office: {
                meta: {
                    label: "onboarding.steps.fiscal_representation_office",
                },
                initial: "start",
                onDone: "completed",
                states: {
                    start: {
                        meta: {
                            ...fiscalRepresentationOfficeFormNodesAndSchema,
                        },
                        on: {
                            SAVE: "start",
                            NEXT: "completed_confirmation",
                            MANUAL_UPDATE: {
                                assign: (context, event) => {
                                    return { ...context, ...event.value };
                                },
                            },
                        },
                    },
                    completed_confirmation: {
                        meta: {
                            label: "onboarding.steps.fiscal_representation_office_completed",
                            ...fiscalRepresentationOfficeCompletedFormNodesAndSchema,
                        },
                        on: {
                            NEXT: "completed",
                        },
                    },
                    completed: {
                        meta: {
                            label: "onboarding.steps.completed",
                        },
                        type: "final",
                    },
                },
            },
            complete_your_company_information: {
                meta: {
                    label: "onboarding.steps.complete_your_company_information",
                    ...completeYourCompanyInformationFormNodesAndSchema,
                },
                on: {
                    SAVE: "complete_your_company_information",
                    NEXT: "declaration_of_fiscal_representation",
                },
            },
            declaration_of_fiscal_representation: {
                meta: {
                    label: "onboarding.steps.declaration_of_fiscal_representation",
                    ...declarationOfFiscalRepresentationFormNodesAndSchema,
                },
                on: {
                    SAVE: "declaration_of_fiscal_representation",
                    NEXT: "declaration_of_fiscal_representation_fertig_check",
                },
            },
            declaration_of_fiscal_representation_fertig_check: {
                meta: {
                    label: "onboarding.steps.declaration_of_fiscal_representation_fertig_check",
                    ...declarationOfFiscalRepresentationFertigCheckFormNodesAndSchema,
                },
                on: {
                    // SAVE: 'completed', // Need to upload document sent by Swiss tax office. A Pending state
                    SAVE: "declaration_of_fiscal_representation_fertig_check",
                    NEXT: "your_vat_uid",
                },
            },
            your_vat_uid: {
                meta: {
                    label: "onboarding.steps.your_vat_uid",
                    ...yourVatUidFormNodesAndSchema,
                },
                on: {
                    SAVE: "your_vat_uid",
                    NEXT: "security_deposit",
                },
            },
            security_deposit: {
                meta: {
                    label: "onboarding.steps.security_deposit",
                    ...securityDepositFormNodesAndSchema,
                },
                on: {
                    SAVE: "security_deposit",
                    NEXT: "power_of_attorney_for_estv_swiss_tax",
                },
            },
            power_of_attorney_for_estv_swiss_tax: {
                meta: {
                    label: "onboarding.steps.power_of_attorney_for_estv_swiss_tax",
                    ...powerOfAttorneyForEstvSwissTaxFormNodesAndSchema,
                },
                on: {
                    SAVE: "power_of_attorney_for_estv_swiss_tax",
                    NEXT: "declaration_of_subordination",
                },
            },
            declaration_of_subordination: {
                meta: {
                    label: "onboarding.steps.declaration_of_subordination",
                    ...declarationOfSubordinationFormNodesAndSchema,
                },
                on: {
                    SAVE: "declaration_of_subordination",
                    NEXT: "completed",
                },
            },
            completed: {
                meta: {
                    label: "onboarding.steps.completed",
                },
                type: "final",
            },
        },
    },
    your_customs_account: {
        initial: "do_you_have_a_customs_account_number",
        id: "your_customs_account",
        meta: {
            eventName: "CUSTOMS_ACCOUNT",
            label: "onboarding.steps.your_customs_account",
        },
        states: {
            do_you_have_a_customs_account_number: {
                meta: {
                    label: "onboarding.steps.do_you_have_a_customs_account_number",
                    ...doYouHaveACustomsAccountNumberFormNodesAndSchema,
                },
                on: {
                    ENTER_ZAZ_ACCOUNT_NUMBER: "zaz_account_number",
                    ENTER_SECURITY_DEPOSIT: "calculation_of_security_deposit",
                },
            },
            zaz_account_number: {
                meta: {
                    label: "onboarding.steps.zaz_account_number",
                    ...zazAccountNumberFormNodesAndSchema,
                },
                on: {
                    SAVE: "zaz_account_number",
                    NEXT: "completed",
                    I_DONT_HAVE_ZAZ_ACCOUNT_NUMBER: "do_you_have_a_customs_account_number",
                },
            },
            calculation_of_security_deposit: {
                meta: {
                    label: "onboarding.steps.calculation_of_security_deposit",
                    ...calculationOfSecurityDepositFormNodesAndSchema,
                },
                on: {
                    SAVE: "calculation_of_security_deposit",
                    NEXT: "kind_of_security_deposit",
                },
            },
            kind_of_security_deposit: {
                meta: {
                    label: "onboarding.steps.kind_of_security_deposit",
                    ...kindOfSecurityDepositFormNodesAndSchema,
                },
                on: {
                    SAVE: "kind_of_security_deposit",
                    NEXT: "transfering_cash_for_deposit",
                },
            },
            transfering_cash_for_deposit: {
                meta: {
                    label: "onboarding.steps.transfering_cash_for_deposit",
                    ...transferingCashForDepositFormNodesAndSchema,
                },
                on: {
                    SAVE: "transfering_cash_for_deposit",
                    NEXT: "payment_of_custom_fees",
                },
            },
            payment_of_custom_fees: {
                meta: {
                    label: "onboarding.steps.payment_of_custom_fees",
                    ...paymentOfCustomFeesFormNodesAndSchema,
                },
                on: {
                    SAVE: "payment_of_custom_fees",
                    NEXT: "lastschriftverfahren_einrichten",
                },
            },
            lastschriftverfahren_einrichten: {
                meta: {
                    label: "onboarding.steps.lastschriftverfahren_einrichten",
                    ...lastschriftverfahrenEinrichtenFormNodesAndSchema,
                },
                on: {
                    SAVE: "lastschriftverfahren_einrichten",
                    NEXT: "declaration_of_accession",
                },
            },
            declaration_of_accession: {
                meta: {
                    label: "onboarding.steps.declaration_of_accession",
                },
                initial: "start",
                onDone: "completed",
                states: {
                    start: {
                        meta: {
                            ...declarationOfAccessionFormNodesAndSchema,
                        },
                        on: {
                            SAVE: "start",
                            NEXT: "complete_confirmation",
                        },
                    },
                    complete_confirmation: {
                        meta: {
                            ...declarationOfAccessionDoneFormNodesAndSchema,
                        },
                        on: {
                            SAVE: "complete_confirmation",
                            NEXT: "completed",
                        },
                    },
                    completed: {
                        type: "final",
                    },
                },
            },
            // payment_of_bill: {
            //   on: {
            //     X: '',
            //   },
            // },
            completed: {
                meta: {
                    label: "onboarding.steps.completed",
                    ...completedFormNodesAndSchema,
                },
                type: "final",
            },
        },
    },
};
