import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, IconButton, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useFilters, usePagination, useSortBy, useTable, } from "react-table";
import { get } from "~/api/utils";
import { ReactComponent as ArrowLeftIcon } from "~/assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRightIcon } from "~/assets/images/arrow-right-icon.svg";
// Include these SVGs as inline SVGs because we need to change their colors using CSS
import { ReactComponent as FilterIcon } from "~/assets/images/filter-icon.svg";
import Button from "../Button/Button";
import Image from "../Image/Image";
import Link from "../Link/Link";
import TextButton from "../TextButton/TextButton";
function isString(value) {
    return typeof value === "string";
}
function createDataFetcher(dataSource) {
    if (isString(dataSource)) {
        return async ({ pageIndex, pageSize }) => {
            var _a;
            const url = new URL(`http://localhost:3000${dataSource}`);
            const params = new URLSearchParams(url.search);
            const { data, page } = await get(url.pathname, {
                AccountResponsibleUsers: (_a = params.get("AccountResponsibleUsers")) !== null && _a !== void 0 ? _a : undefined,
                page: pageIndex,
                size: pageSize,
            });
            return { data, page };
        };
    }
    if (Array.isArray(dataSource)) {
        return async ({ pageIndex, pageSize }) => {
            const data = dataSource.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
            const page = Math.ceil(dataSource.length / pageSize);
            return { data, page };
        };
    }
    if (typeof dataSource === "function") {
        return dataSource;
    }
    return async (_args) => ({ data: [], page: 0 });
}
function DataTableHeaderSortIcon({ isSorted, isSortedDesc, }) {
    const { t } = useTranslation();
    if (!isSorted) {
        return null;
    }
    return (_jsx(Box, { display: "inline-block", marginLeft: 1, children: _jsx(Image, { name: isSortedDesc ? "arrow-down-icon" : "arrow-up-icon", alt: t("sortTable") }) }));
}
function DataTableFilterButton({ isFiltering, setIsFiltering, resetFilters, }) {
    const { t } = useTranslation();
    const props = {
        borderColor: "#F48999",
        borderWidth: 1,
        borderStyle: "solid",
        _hover: {
            bg: "#F7A7B3",
            color: "#fff",
        },
        ...(isFiltering
            ? {
                bg: "#fff",
                color: "#F48999",
            }
            : {
                bg: "#F48999",
            }),
    };
    return (_jsxs(Flex, { gap: 2, justifyContent: "flex-end", children: [_jsxs(Button, { ...props, onClick: () => setIsFiltering(!isFiltering), children: [_jsx(Box, { mr: 1, children: _jsx(FilterIcon, {}) }), t("filter")] }), isFiltering && (_jsxs(TextButton, { onClick: () => resetFilters(), color: "#F48999", children: [t("reset"), " \u00D7"] }))] }));
}
// unique string with lenth 8
const generateRandomString = () => Math.random().toString(36).substring(2, 10);
export default function DataTable({ id = generateRandomString(), columns, data: sourceData, footerButton, hideDetailsLink, rowActions, hidePagination, hideFilter, hideBoxShadow, detailsLink, manualPagination = true, }) {
    var _a, _b, _c;
    const { t } = useTranslation();
    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const fetchDataOptions = {
        pageIndex,
        pageSize,
    };
    const dataFecher = useMemo(() => createDataFetcher(sourceData), [sourceData]);
    const dataQueryKey = useMemo(() => [isString(sourceData) ? sourceData : id, fetchDataOptions], [id, fetchDataOptions, sourceData]);
    const dataQuery = useQuery(dataQueryKey, () => dataFecher(fetchDataOptions), {
        keepPreviousData: true,
    });
    const defaultData = React.useMemo(() => [], []);
    const pagination = React.useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize]);
    const table = useTable({
        columns,
        data: ((_a = dataQuery.data) === null || _a === void 0 ? void 0 : _a.data) || defaultData,
        pageCount: (_c = (_b = dataQuery.data) === null || _b === void 0 ? void 0 : _b.page) !== null && _c !== void 0 ? _c : -1,
        initialState: pagination,
        manualPagination,
        debugTable: true,
    }, useFilters, useSortBy, usePagination);
    // Source data changes on filtering outside of the table itself, like on the CLIENTS page.
    useEffect(() => {
        table.gotoPage(0);
        setPagination({
            pageIndex: 0,
            pageSize,
        });
    }, [sourceData]);
    const [isFiltering, setIsFiltering] = useState(false);
    return (_jsxs(Flex, { flexDirection: "column", height: "calc(100vh - 72px)", w: "full", maxH: "inherit", children: [_jsx(TableContainer, { flex: 1, overflowY: "auto", boxShadow: !hideBoxShadow ? "inset 0 0 10px rgba(0, 0, 0, 0.25)" : "none", children: _jsxs(Table, { variant: "simple", ...table.getTableProps(), children: [_jsx(Thead, { children: 
                            // Loop over the header rows
                            table.headerGroups.map((headerGroup) => (_jsxs(Tr, { ...headerGroup.getHeaderGroupProps(), children: [
                                    // Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                    // Apply the header cell props
                                    _jsxs(Th, { ...column.getHeaderProps({
                                            style: {
                                                width: column.width,
                                            },
                                        }), children: [_jsxs(Box, { ...column.getSortByToggleProps(), children: [
                                                    // Render the header
                                                    column.render("Header"), _jsx(DataTableHeaderSortIcon, { isSorted: column.isSorted, isSortedDesc: column.isSortedDesc })] }), isFiltering && column.canFilter && (_jsx(Box, { mt: 2, children: column.render("Filter") }))] }))), !hideFilter && (_jsx(Th, { width: "200px", verticalAlign: "bottom", colSpan: rowActions ? 2 : 1, children: _jsx(DataTableFilterButton, { isFiltering: isFiltering, setIsFiltering: () => setIsFiltering(!isFiltering), resetFilters: () => table.setAllFilters([]) }) }))] }))) }), _jsx(Tbody, { ...table.getTableBodyProps(), children: 
                            // Loop over the table rows
                            table.page.map((row) => {
                                // Prepare the row for display
                                table.prepareRow(row);
                                return (
                                // Apply the row props
                                _jsxs(Tr, { ...row.getRowProps(), children: [
                                        // Loop over the rows cells
                                        row.cells.map((cell) => {
                                            // Apply the cell props
                                            return (_jsx(Td, { ...cell.getCellProps(), maxW: cell.column.width, children: _jsx(Text, { isTruncated: true, maxW: cell.column.width === "auto" ? "350px" : "250px", children: 
                                                    // Render the cell contents
                                                    cell.render("Cell") }) }));
                                        }), _jsx(Td, { textAlign: "end", children: !hideDetailsLink && (_jsx(Link, { to: detailsLink
                                                    ? // @ts-ignore
                                                        `${detailsLink}/${row.original.id}`
                                                    : "/", children: "Details >" })) }), rowActions && (_jsx(Td, { width: "120px", children: _jsx(Flex, { gap: 3, justifyContent: "flex-end", children: rowActions.map((action) => (_jsx(IconButton, { size: "xs", color: "gray.700", bgColor: "inherit", "aria-label": action.label, icon: action.icon, onClick: () => action.onClick(row) }, action.label))) }) }))] }));
                            }) })] }) }), !hidePagination && (_jsxs(Flex, { paddingX: "24px", flexBasis: "72px", justifyContent: "space-between", alignItems: "center", fontSize: "sm", children: [_jsxs(Flex, { alignItems: "center", children: [_jsx(Box, { mr: 2, children: t("rowsPerPage") }), _jsx(Select, { value: pageSize, onChange: (e) => {
                                    const newPageSize = Number(e.target.value);
                                    table.setPageSize(newPageSize);
                                    table.gotoPage(0);
                                    setPagination({
                                        pageIndex: 0,
                                        pageSize: newPageSize,
                                    });
                                }, size: "sm", width: "70px", children: [10, 20, 30, 40, 50, 100].map((pageSizeOption) => (_jsx("option", { value: pageSizeOption, children: pageSizeOption }, pageSizeOption))) })] }), _jsxs(Flex, { alignItems: "center", children: [_jsxs(Flex, { alignItems: "center", gap: "2", children: [_jsxs("span", { children: [_jsxs("strong", { children: [dataQuery.isFetching ? "Loading..." : null, " ", pageIndex * pageSize, "-", Math.min(table.pageCount * pageSize, (pageIndex + 1) * pageSize)] }), " ", "of ", table.pageCount * pageSize] }), _jsx(TextButton, { onClick: () => {
                                            table.previousPage();
                                            setPagination((prevPagination) => ({
                                                ...prevPagination,
                                                pageIndex: prevPagination.pageIndex - 1,
                                            }));
                                        }, isDisabled: !table.canPreviousPage || dataQuery.isFetching, children: _jsx(Box, { p: 2, children: _jsx(ArrowLeftIcon, {}) }) }), _jsx(TextButton, { onClick: () => {
                                            table.nextPage();
                                            setPagination((prevPagination) => ({
                                                ...prevPagination,
                                                pageIndex: prevPagination.pageIndex + 1,
                                            }));
                                        }, isDisabled: !table.canNextPage || dataQuery.isFetching, children: _jsx(Box, { p: 2, children: _jsx(ArrowRightIcon, {}) }) })] }), footerButton && _jsx(Box, { ml: 2, children: footerButton })] })] }))] }));
}
