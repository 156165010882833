import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex } from "@chakra-ui/react";
import { formNodeFilters } from "@crossborderx/onboarding-machine/src/models/layout-schema";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ButtonComponent } from "../../FormComponents";
import { useFormContext } from "../../FormContextProvider";
import renderFormElements from "../../renderer";
function IncrementedControlsArray(props) {
    const { controlNodes, incrementerButtonNode, decrementerButtonNode, forKey, maxControlNodes, hasDefaultNodes = true, } = props;
    const defaultNodesQty = hasDefaultNodes ? 1 : 0;
    const { watch, setValue, previousValues } = useFormContext();
    const fieldArray = watch(forKey);
    const [counter, setCounter] = useState((fieldArray === null || fieldArray === void 0 ? void 0 : fieldArray.length) || defaultNodesQty);
    useEffect(() => {
        const fieldValues = get(previousValues, forKey);
        if (fieldValues === null || fieldValues === void 0 ? void 0 : fieldValues.length)
            setCounter(fieldValues.length);
    }, [previousValues]);
    const isIncrementalButtonDisabled = useMemo(() => {
        return (maxControlNodes &&
            (counter >= maxControlNodes + 1 ||
                (!hasDefaultNodes && counter >= maxControlNodes)));
    }, [counter, hasDefaultNodes, maxControlNodes]);
    const addFieldSet = () => {
        if (isIncrementalButtonDisabled)
            return;
        setCounter((prevCounter) => prevCounter + 1);
    };
    const removeFieldSet = () => {
        fieldArray === null || fieldArray === void 0 ? void 0 : fieldArray.splice(-1);
        setValue(forKey, fieldArray);
        setCounter((prevCounter) => (prevCounter > 2 && prevCounter - 1) || defaultNodesQty);
    };
    return (_jsxs(_Fragment, { children: [Array.from(Array(counter).keys()).map((index) => {
                const fieldSetName = `${forKey}.[${index}]`;
                const mapKeys = (node) => {
                    var _a;
                    return formNodeFilters.isLayout(node)
                        ? {
                            ...node,
                            elements: node.elements.map(mapKeys),
                            ...(formNodeFilters.isGroupLayout(node) && {
                                forKey: node.forKey && `${fieldSetName}.${node === null || node === void 0 ? void 0 : node.forKey}`,
                            }),
                        }
                        : {
                            ...node,
                            key: `${fieldSetName}.${node === null || node === void 0 ? void 0 : node.key}`,
                            ...(formNodeFilters.isCustomComponent(node) &&
                                node.componentName === "base.IncrementedControlsArray" && {
                                componentProps: {
                                    ...node.componentProps,
                                    forKey: ((_a = node === null || node === void 0 ? void 0 : node.componentProps) === null || _a === void 0 ? void 0 : _a.forKey) &&
                                        `${fieldSetName}.${node === null || node === void 0 ? void 0 : node.componentProps.forKey}`,
                                },
                            }),
                        };
                };
                const fieldSetNodes = controlNodes.map(mapKeys);
                return renderFormElements(fieldSetNodes);
            }), _jsxs(Flex, { justifyContent: "space-between", w: "full", flexWrap: "wrap", children: [_jsx(ButtonComponent, { node: incrementerButtonNode, onClick: addFieldSet, disabled: !!isIncrementalButtonDisabled }), counter > defaultNodesQty && decrementerButtonNode && (_jsx(ButtonComponent, { node: decrementerButtonNode, onClick: removeFieldSet }))] })] }));
}
export default IncrementedControlsArray;
