import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, useState, } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { clientsApi, useCreateClientServiceMutation, } from "~/pages/clients/redux/clients.api";
import { servicesApi } from "~/pages/services/redux/services.api";
import { ShipmentForm } from "../ShipmentForm/ShipmentForm";
export const EnableTariffingServiceModal = ({ isOpen, service, setIsOpen }) => {
    var _a, _b, _c;
    const [step, setStep] = useState(ShipDirection.TO);
    const { params: { id }, } = useMatch();
    const defaultFormValues = useMemo(() => ({
        shipFrom: null,
        shipTo: {
            shipFrom: null,
            shipTo: service.serviceData.shipTo,
            services: [],
            providedServiceId: null,
            responsibleEmployee: null,
        },
    }), [service]);
    const { t } = useTranslation();
    const toast = useToast();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState(defaultFormValues);
    const [createService] = useCreateClientServiceMutation();
    useEffect(() => {
        if (isOpen) {
            setServices(defaultFormValues);
            setStep(ShipDirection.TO);
        }
    }, [isOpen]);
    const handleSubmit = async () => {
        setIsLoading(true);
        const params = { clientId: Number(id), serviceCode: ServiceCode.TARIFFING };
        const allServices = [
            {
                direction: ShipDirection.TO,
                serviceData: services.shipTo,
            },
            {
                direction: ShipDirection.FROM,
                serviceData: services.shipFrom,
            },
        ];
        for (const s of allServices) {
            const { serviceData, direction } = s;
            if (serviceData === null || serviceData === void 0 ? void 0 : serviceData.responsibleEmployee) {
                const { responsibleEmployee, providedServiceId, ...data } = serviceData;
                await createService({
                    ...params,
                    serviceData: { ...data, direction },
                    responsibleEmployee,
                    providedServiceId: Number(providedServiceId),
                });
            }
        }
        toast({
            title: t("clients.detail.services.tariffingModal.success"),
            status: "success",
            duration: 3000,
        });
        setIsOpen(false);
        setIsLoading(false);
        dispatch(clientsApi.util.invalidateTags(["CLIENT_SERVICES"]));
        dispatch(servicesApi.util.invalidateTags(["SERVICE"]));
    };
    const handleUpdateService = (newService) => {
        setServices((prev) => ({ ...prev, [step]: newService }));
    };
    const primaryActionDisabled = !((_a = services.shipTo) === null || _a === void 0 ? void 0 : _a.responsibleEmployee);
    const isShipFrom = step === ShipDirection.FROM;
    const primaryTitle = isShipFrom
        ? ((_b = services.shipFrom) === null || _b === void 0 ? void 0 : _b.responsibleEmployee)
            ? t("basics.save")
            : t("basics.skip")
        : t("basics.next");
    return (_jsx(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: !isShipFrom
            ? t("clients.detail.services.tariffingModal.shipToTitle")
            : t("clients.detail.services.tariffingModal.shipFromTitle"), secondaryTitle: isShipFrom ? t("basics.back") : undefined, secondaryAction: isShipFrom ? () => setStep(ShipDirection.TO) : undefined, primaryTitle: primaryTitle, primaryAction: isShipFrom ? () => handleSubmit() : () => setStep(ShipDirection.FROM), primaryActionDisabled: primaryActionDisabled, primaryActionLoading: isLoading, children: _jsxs(Flex, { direction: "column", gap: 8, children: [_jsx(Text, { mt: 2, children: _jsx(Trans, { components: { bold: _jsx(Text, { as: "span", fontWeight: 700 }) }, i18nKey: !isShipFrom
                            ? "clients.detail.services.tariffingModal.shipToSubtitle"
                            : "clients.detail.services.tariffingModal.shipFromSubtitle" }) }), _jsx(Flex, { direction: "column", gap: 4, children: _jsx(ShipmentForm, { service: services[step], setService: handleUpdateService, type: step, shipTo: (_c = services.shipTo) === null || _c === void 0 ? void 0 : _c.shipTo }) })] }) }));
};
