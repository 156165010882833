import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { EPermissionCodes } from "@crossborderx/schemas-package";
import { useMatch, useNavigate } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader } from "~/components/ContentHeader/ContentHeader";
import { ContentSection } from "~/components/ContentSection/ContentSection";
import { EditIcon } from "~/components/EditIcon/EditIcon";
import { FullPageLoader } from "~/components/FullPageLoader/FullPageLoader";
import { TabNavigation, } from "~/components/TabNavigation/TabNavigation";
import { WithPermission } from "~/components/WithPermission/WithPermission";
import { hasPermission } from "~/helpers/hasPermission";
import { AllArticlesTab } from "~/pages/customs-classification/pages/client/AllArticles/AllArticles";
import { ErrorCasesTab } from "~/pages/customs-classification/pages/client/ErrorCases/ErrorCases";
import { RouteMap } from "~/routing/route.config";
import { ChangeStatusModal } from "../../components/ChangeStatusModal/ChangeStatusModal";
import { ClientDocuments } from "../../components/ClientDocuments/ClientDocuments";
import { ClientOnboarding } from "../../components/ClientOnboarding/ClientOnboarding";
import { ClientProfile } from "../../components/ClientProfile/ClientProfile";
import { ClientServices } from "../../components/ClientServices/ClientServices";
import { useGetClientByIdQuery } from "../../redux/clients.api";
export const ClientDetailPage = () => {
    const { params: { id }, } = useMatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: client, isError, isFetching, } = useGetClientByIdQuery(Number(id));
    const [showStatusModal, setShowStatusModal] = useState(false);
    useEffect(() => {
        if (isError) {
            navigate({ to: RouteMap.CLIENTS });
        }
    }, [isError]);
    const breadcrumbs = [
        {
            title: t("clients.pageHeadline"),
            link: RouteMap.CLIENTS,
        },
    ];
    const tabs = client
        ? [
            {
                title: t("clients.detail.tabs.profile"),
                value: "profile",
                element: _jsx(ClientProfile, { client: client }),
            },
            {
                title: t("clients.detail.tabs.services"),
                value: "services",
                element: _jsx(ClientServices, { client: client }),
            },
            {
                title: t("clients.detail.tabs.documents"),
                value: "documents",
                element: _jsx(ClientDocuments, { client: client }),
            },
            {
                title: t("customsClassification.tabs.allArticles.name"),
                value: "articles",
                element: _jsx(AllArticlesTab, { clientId: client.id }),
            },
            {
                title: t("customsClassification.tabs.errorCases.name"),
                value: "error-cases",
                element: _jsx(ErrorCasesTab, { client: client }),
            },
        ]
        : [];
    if (hasPermission([
        EPermissionCodes.CLIENT_ONBOARDING_MANAGE,
        EPermissionCodes.CLIENTS_ONBOARDING_MANAGE,
    ]) &&
        client) {
        tabs.push({
            title: t("clients.detail.tabs.onboarding"),
            value: "onboarding",
            element: _jsx(ClientOnboarding, { client: client }),
        });
    }
    const headerAction = (_jsxs(InputGroup, { w: "auto", children: [_jsx(Input, { w: "auto", color: "grey.60", size: "md", pr: "4", value: `${t("clients.detail.profile.statusSelectLabel")}: ${t(`accountStatus.${client === null || client === void 0 ? void 0 : client.status}`)}`, disabled: true, _disabled: { bg: "white" } }), _jsx(WithPermission, { permission: [
                    EPermissionCodes.CLIENTS_ACCOUNTS_EDIT,
                    EPermissionCodes.CLIENTS_ACCOUNTS_MANAGE,
                ], children: _jsx(InputRightElement, { h: "full", children: _jsx(EditIcon, { onClick: () => setShowStatusModal(true) }) }) })] }));
    return client && !isFetching ? (_jsxs(_Fragment, { children: [_jsx(ContentHeader, { title: client.name, breadcrumbs: breadcrumbs, actions: headerAction }), _jsx(ContentSection, { children: _jsx(TabNavigation, { tabs: tabs }) }), _jsx(ChangeStatusModal, { isOpen: showStatusModal, setIsOpen: setShowStatusModal, client: client })] })) : (_jsx(FullPageLoader, {}));
};
