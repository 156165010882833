import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useMatch } from "@tanstack/react-location";
import { useTranslation } from "react-i18next";
import FormBody from "~/components/FormBody/FormBody";
import FormFooter from "~/components/FormFooter/FormFooter";
import { patchFormField } from "~/store/actions/formData.actions";
import { useAppDispatch } from "~/store/hooks";
export function YourCustomAccountComplete() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params: { id, flowId }, } = useMatch();
    useEffect(() => {
        dispatch(patchFormField({
            fieldName: "activestep",
            fieldValue: "4.0",
            id,
            flowId,
            pageSlug: "activestep",
            processSlug: "administrativeProcesses",
        }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(FormBody, { gap: "4", children: _jsx(Text, { fontSize: "2xl", children: t("onboarding.completedAllSteps") }) }), _jsx(FormFooter, { isResetVisible: true, isBackVisible: false })] }));
}
