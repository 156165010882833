import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, Textarea, useToast } from "@chakra-ui/react";
import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "~/components/Modal/Modal";
import { useEditCompanyMutation } from "../../redux/partners.api";
const TEXT_AREA_LIMIT = 300;
export const EditCompanyDescriptionModal = ({ isOpen, setIsOpen, id, oldDescription }) => {
    const [description, setDescription] = useState(oldDescription);
    const { t } = useTranslation();
    const toast = useToast();
    const [editCompanyDescription, { isLoading }] = useEditCompanyMutation();
    const handleChange = (e) => {
        setDescription(e.target.value);
    };
    const handleSubmit = async () => {
        if ((description === null || description === void 0 ? void 0 : description.length) >= 3) {
            const res = await editCompanyDescription({ id, body: { description } });
            if ("data" in res) {
                toast({
                    title: t("partners.editCompanyDescriptionModal.success"),
                    status: "success",
                    duration: 3000,
                });
                setIsOpen(false);
            }
        }
    };
    useEffect(() => {
        if (!isOpen)
            setDescription(oldDescription);
    }, [isOpen]);
    return (_jsx(Modal, { title: t("partners.editCompanyDescriptionModal.title"), isOpen: isOpen, onClose: () => setIsOpen(false), primaryAction: handleSubmit, primaryActionDisabled: (description === null || description === void 0 ? void 0 : description.length) < 3 ||
            (description === null || description === void 0 ? void 0 : description.length) > TEXT_AREA_LIMIT ||
            oldDescription === description, primaryActionLoading: isLoading, primaryTitle: t("basics.save"), size: "lg", children: _jsxs(Box, { display: "flex", flexDir: "column", gap: "2", children: [_jsx(Text, { textStyle: "labelUppercase", mt: "6", children: t("partners.editCompanyDescriptionModal.textAreaLabel") }), _jsx(Textarea, { value: description, onChange: handleChange, maxLength: TEXT_AREA_LIMIT, resize: "none" }), _jsx(Text, { color: "grey.60", children: t("partners.editCompanyDescriptionModal.nCharactersLeft", {
                        n: TEXT_AREA_LIMIT - ((description === null || description === void 0 ? void 0 : description.length) || 0),
                    }) })] }) }));
};
