import { useMemo } from "react";
const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_el, index) => index + start);
};
export const usePagination = ({ siblingSize = 1, paginationBreak = "...", pageIndex, pageCount, }) => {
    const pagination = useMemo(() => {
        const pageItems = siblingSize + 5;
        // 1, 2, 3
        if (pageItems >= pageCount) {
            return range(0, pageCount - 1);
        }
        const leftSiblingIndex = Math.max(pageIndex - siblingSize, 1);
        const rightSiblingIndex = Math.min(pageIndex + siblingSize, pageCount - 1);
        const isLeftBreakVisible = leftSiblingIndex > 2;
        const isRightBreakVisible = rightSiblingIndex < pageCount - 2;
        // 1, 2, 3, ..., 5
        if (!isLeftBreakVisible && isRightBreakVisible) {
            const leftItemCount = 3 + 2 * siblingSize;
            const leftRange = range(0, leftItemCount);
            return [...leftRange, paginationBreak, pageCount - 1];
        }
        // 1, ..., 3, 4, 5
        if (isLeftBreakVisible && !isRightBreakVisible) {
            const rightItemCount = 3 + 2 * siblingSize;
            const rightRange = range(pageCount - rightItemCount + 1, pageCount - 1);
            return [0, paginationBreak, ...rightRange];
        }
        // 1, ..., 3, ..., 5
        if (isLeftBreakVisible && isRightBreakVisible) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [
                0,
                paginationBreak,
                ...middleRange,
                paginationBreak,
                pageCount - 1,
            ];
        }
        return [];
    }, [siblingSize, pageIndex, pageCount]);
    return pagination;
};
