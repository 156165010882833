import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState, } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "~/components/ErrorMessage/ErrorMessage";
import { Modal } from "~/components/Modal/Modal";
import { CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE } from "~/consts/customs-classification-cases-max-size";
import { withBaseUrl } from "~/helpers/withBaseUrl";
import { authSelector } from "~/pages/auth/redux/auth.slice";
import { customsClassificationApi, importsBaseUrl, } from "~/pages/customs-classification/redux/customsClassification.api";
import { useAppSelector } from "~/store/hooks";
export const UploadTariffingFileModal = ({ isOpen, setIsOpen }) => {
    const [isError, setIsError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user, token } = useAppSelector(authSelector);
    const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: CUSTOMS_CLASSIFICATION_CASES_MAX_SIZE,
    });
    const reset = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            acceptedFiles.splice(0, acceptedFiles.length);
        }
    };
    const handleFileUpload = async () => {
        if (user === null || user === void 0 ? void 0 : user.accountId) {
            setIsFetching(true);
            const formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            formData.append("clientId", String(user.accountId));
            try {
                const res = await fetch(withBaseUrl(importsBaseUrl), {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                });
                if (res.ok) {
                    setIsError(false);
                    reset();
                    dispatch(customsClassificationApi.util.invalidateTags([
                        "CUSTOMS_CLASSIFICATION_CASES",
                    ]));
                    toast({
                        title: t("customsClassification.uploadTariffingFileModal.success"),
                        status: "success",
                        duration: 3000,
                    });
                    setIsOpen(false);
                }
                else {
                    setIsError(true);
                }
                setIsFetching(false);
            }
            catch (err) {
                setIsError(true);
            }
        }
    };
    useEffect(() => {
        if (!isOpen) {
            reset();
            setIsError(false);
            setIsFetching(false);
        }
    }, [isOpen]);
    return (_jsxs(Modal, { isOpen: isOpen, onClose: () => setIsOpen(false), title: t("customsClassification.uploadTariffingFileModal.title"), primaryTitle: t("customsClassification.uploadTariffingFileModal.primaryTitle"), primaryAction: handleFileUpload, primaryActionDisabled: !acceptedFiles.length, primaryActionLoading: isFetching, children: [_jsx(Text, { mt: 2, children: t("customsClassification.uploadTariffingFileModal.subtitle") }), _jsxs(Flex, { direction: "column", alignItems: "center", justifyContent: "center", flex: 1, gap: 3, mt: 4, minHeight: 24, backgroundColor: "sky.10", borderRadius: "4px", borderColor: "brand.sky", borderWidth: "2px", borderStyle: "dashed", cursor: "pointer", ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), acceptedFiles.length ? (_jsx(Text, { children: acceptedFiles[0].name })) : (_jsxs(_Fragment, { children: [_jsx(Text, { children: _jsx(Trans, { components: {
                                        cta: _jsx(Text, { as: "span", color: "brand.sky", fontWeight: "700" }),
                                    }, i18nKey: "customsClassification.uploadTariffingFileModal.dragAndDropZone.description", values: {
                                        CTA: t("customsClassification.uploadTariffingFileModal.dragAndDropZone.cta"),
                                    } }) }), _jsx(Text, { textStyle: "bodySmall", color: "grey.60", children: t("customsClassification.uploadTariffingFileModal.dragAndDropZone.restriction") })] }))] }), isError && (_jsx(ErrorMessage, { text: t("error.badRequest.somethingWentWrong") }))] }));
};
