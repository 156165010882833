import * as schemaGenerators from "../../helpers/schema-generators";
const formNodes = [
    {
        ...schemaGenerators.formCard(),
        elements: [
            {
                key: "label1",
                type: "h2",
                label: "onboarding.technicalSetup.completed.title",
            },
            {
                key: "label2",
                type: "label",
                label: "onboarding.technicalSetup.completed.description",
            },
        ],
    },
    schemaGenerators.formFooter(true),
];
const formNodesAndSchema = {
    formNodes,
};
export default formNodesAndSchema;
