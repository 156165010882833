/* eslint no-param-reassign: ["error", { "props": false }] */
import { createReducer } from "@reduxjs/toolkit";
import { deleteDocumentById, fetchDocumentByName, fetchDocuments, fetchFilterDocuments, } from "../actions/documentsData.actions";
const initialState = {
    data: [],
};
export const DocumentsDataReducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchFilterDocuments.fulfilled, (state, action) => {
        state.data = action.payload;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
        state.data = action.payload;
    });
    builder.addCase(fetchDocumentByName.fulfilled, (state, action) => {
        state.data = action.payload;
    });
    builder.addCase(deleteDocumentById.fulfilled, (state, action) => {
        state.data = action.payload;
    });
});
