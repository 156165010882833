import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useToast } from "@chakra-ui/react";
import { useMatch } from "@tanstack/react-location";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Modal } from "~/components/Modal/Modal";
import { ServiceCode } from "~/enums/service-code.enum";
import { ShipDirection } from "~/enums/ship-direction.enum";
import { clientsApi, useUpdateClientServiceMutation, } from "~/pages/clients/redux/clients.api";
import { servicesApi } from "~/pages/services/redux/services.api";
import { ShipmentForm } from "../ShipmentForm/ShipmentForm";
export const EditShipmentModal = ({ isOpen, service: editedService, handleClose, selectedCountries, }) => {
    const dispatch = useDispatch();
    const { params: { id }, } = useMatch();
    const defaultFormValues = useMemo(() => ({
        shipFrom: (editedService === null || editedService === void 0 ? void 0 : editedService.serviceData.shipFrom) || "",
        shipTo: (editedService === null || editedService === void 0 ? void 0 : editedService.serviceData.shipTo) || "",
        services: (editedService === null || editedService === void 0 ? void 0 : editedService.serviceData.services) || [],
        providedServiceId: (editedService === null || editedService === void 0 ? void 0 : editedService.providedServiceId) || null,
        responsibleEmployee: (editedService === null || editedService === void 0 ? void 0 : editedService.responsibleEmployee) || null,
    }), [editedService]);
    const isShipTo = (editedService === null || editedService === void 0 ? void 0 : editedService.serviceData.direction) === ShipDirection.TO;
    const { t } = useTranslation();
    const toast = useToast();
    const [service, setService] = useState(defaultFormValues);
    const [updateService, { isLoading, isSuccess }] = useUpdateClientServiceMutation();
    useEffect(() => {
        if (editedService)
            setService(defaultFormValues);
    }, [editedService]);
    useEffect(() => {
        if (isSuccess) {
            toast({
                title: t("clients.detail.services.tariffingModal.success"),
                status: "success",
                duration: 3000,
            });
            handleClose();
            dispatch(servicesApi.util.invalidateTags(["SERVICE"]));
            dispatch(clientsApi.util.invalidateTags(["CLIENT_SERVICES"]));
        }
    }, [isSuccess]);
    const handleSubmit = async () => {
        if (editedService === null || editedService === void 0 ? void 0 : editedService.id) {
            const { responsibleEmployee, providedServiceId, ...serviceData } = service;
            const params = {
                serviceId: editedService.id,
                clientId: Number(id),
                serviceCode: ServiceCode.TARIFFING,
                serviceData: {
                    ...serviceData,
                    direction: editedService.serviceData.direction,
                },
                responsibleEmployee,
                providedServiceId: Number(providedServiceId),
            };
            await updateService(params);
        }
    };
    const handleUpdateService = (newService) => {
        setService(newService);
    };
    const primaryActionDisabled = !(service === null || service === void 0 ? void 0 : service.responsibleEmployee);
    return (_jsx(Modal, { isOpen: isOpen, onClose: handleClose, title: t(`clients.detail.services.tariffingModal.editTitle.${isShipTo ? "to" : "from"}`), primaryTitle: t("basics.save"), primaryAction: handleSubmit, primaryActionDisabled: primaryActionDisabled, primaryActionLoading: isLoading, children: service && (_jsx(Box, { mt: 8, children: _jsx(ShipmentForm, { isDisabled: true, selectedCountries: selectedCountries, service: service, setService: handleUpdateService, type: service.shipFrom ? ShipDirection.FROM : ShipDirection.TO, shipTo: service.shipFrom || "" }) })) }));
};
