import { useTranslation } from "react-i18next";
const mandatoryFieldsConfig = [
    { name: "articleNumber" },
    { name: "shipFromCountry" },
    { name: "tariffNumberShipFrom", isOptional: true },
    { name: "shipToCountry" },
    { name: "tariffNumberShipTo", isOptional: true },
    { name: "descriptionShipFrom", isAndOr: true },
    { name: "descriptionShipTo", isAndOr: true },
    { name: "materialDescription" },
    { name: "netWeight", isOptional: true },
    { name: "unit", isOptional: true },
    { name: "countryOfOrigin" },
    { name: "manufacturingCountry" },
    { name: "urlLink", isOptional: true },
    { name: "articleValue", isOptional: true },
    { name: "articleValueUnit", isOptional: true },
    { name: "deImportTariffNumber", isOptional: true },
];
export const useGetMandatoryFields = () => {
    const { t } = useTranslation();
    const mandatoryFields = mandatoryFieldsConfig.map(({ name, isAndOr, isOptional }) => ({
        name: t(`onboarding.productDetails.defineImportFile.csvFields.fields.${name}.name`),
        description: t(`onboarding.productDetails.defineImportFile.csvFields.fields.${name}.description`),
        isOptional,
        isAndOr,
    }));
    const mandatoryFieldsArr = mandatoryFieldsConfig.map((field) => t(`onboarding.productDetails.defineImportFile.csvFields.fields.${field.name}.name`));
    return { mandatoryFields, mandatoryFieldsArr };
};
